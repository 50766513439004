import React from 'react';

import { Container, Header, Paragraph, SubHeader, ThirdHeader } from '@components/Modal';

import { locale } from './locale';

const PRIVACY_POLICY_DESCRIPTION_ID = 'privacy_policy_description';

export const PrivacyPolicy = () => (
  <Container>
    <Header text={locale.header} id={PRIVACY_POLICY_DESCRIPTION_ID} />
    <SubHeader text={locale.sub_header} />
    <Paragraph html={locale.p_1} />
    <Paragraph html={locale.p_2} />
    <Paragraph html={locale.p_3} />
    <ThirdHeader text={locale.item_1} />
    <Paragraph html={locale.p_item1_1} />
    <ThirdHeader text={locale.item_2} />
    <Paragraph html={locale.p_item2_1} />
    <Paragraph html={locale.p_item2_2} />
    <ThirdHeader text={locale.item_3} />
    <Paragraph html={locale.p_item3_1} />
    <Paragraph html={locale.p_item3_2} />
    <Paragraph html={locale.p_item3_3} />
    <ThirdHeader text={locale.item_4} />
    <Paragraph html={locale.p_item4_1} />
    <Paragraph html={locale.p_item4_2} />
    <ThirdHeader text={locale.item_5} />
    <Paragraph html={locale.p_item5_1} />
    <Paragraph html={locale.p_item5_2} />
    <ThirdHeader text={locale.item_6} />
    <Paragraph html={locale.p_item6_1} />
    <ThirdHeader text={locale.item_7} />
    <Paragraph html={locale.p_item7_1} />
    <Paragraph html={locale.p_item7_2} />
    <Paragraph html={locale.p_item7_3} />
    <ThirdHeader text={locale.item_8} />
    <Paragraph html={locale.p_item8_1} />
    <ThirdHeader text={locale.item_9} />
    <Paragraph html={locale.p_item9_1} />
    <ThirdHeader text={locale.item_10} />
    <Paragraph html={locale.p_item10_1} />
    <ThirdHeader text={locale.item_11} />
    <Paragraph html={locale.p_item11_1} />
    <ThirdHeader text={locale.item_12} />
    <Paragraph html={locale.p_item12_1} />
    <Paragraph html={locale.p_item12_2} />
    <ThirdHeader text={locale.item_13} />
    <Paragraph html={locale.p_item13_1} />
    <Paragraph html={locale.p_item13_2} />
    <Paragraph html={locale.p_item13_3} />
    <Paragraph html={locale.p_item13_4} />
  </Container>
);
