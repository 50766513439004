import { WellnessTypesEnum } from './constants';

export const locale = {
  title: 'Wellness Logs',
  card_titles: {
    [WellnessTypesEnum.Fitness]: 'Fitness',
    [WellnessTypesEnum.Weight]: 'Weight',
    [WellnessTypesEnum.Steps]: 'Steps',
    [WellnessTypesEnum.Sleep]: 'Sleep',
    [WellnessTypesEnum.Water]: 'Water',
  },
  button_labels: {
    [WellnessTypesEnum.Fitness]: 'Record Activity',
    [WellnessTypesEnum.Weight]: 'Record Weight',
    [WellnessTypesEnum.Steps]: 'Record Steps',
    [WellnessTypesEnum.Sleep]: 'Record Sleep',
    [WellnessTypesEnum.Water]: 'Record Water',
  },
  errors: {
    time: 'Time is required',
    date: `Date should not be less than 5 years than the current year`,
    late_date: `Date should not be more than current day`,
  },
  connect_device: 'Connect My Device',
  entry_recorder: {
    single: {
      [WellnessTypesEnum.Fitness]: 'Fitness Activity entry recorded.',
      [WellnessTypesEnum.Weight]: 'Weight entry recorded.',
      [WellnessTypesEnum.Steps]: 'Steps entry recorded.',
      [WellnessTypesEnum.Sleep]: 'Sleep entry recorded.',
      [WellnessTypesEnum.Water]: 'Water entry recorded.',
    },
    multiple: {
      [WellnessTypesEnum.Fitness]: 'Fitness Activity entries recorded.',
      [WellnessTypesEnum.Weight]: 'Weight entries recorded.',
      [WellnessTypesEnum.Steps]: 'Steps entries recorded.',
      [WellnessTypesEnum.Sleep]: 'Sleep entries recorded.',
      [WellnessTypesEnum.Water]: 'Water entries recorded.',
    },
  },
};
