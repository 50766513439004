import { PATHS } from '@common/routes';

const { SCHEDULE_APPOINTMENT, COMPLETE_LIST_OF_VISIT_REASONS, CHOOSE_TIME, CONFIRM, SCHEDULED, CANCELLATION } =
  PATHS.APPOINTMENTS;

const { INCENTIVES, WELLNESS_LOGS, CHALLENGES } = PATHS.WELLNESS;
const { ROOT, EDIT } = PATHS.MESSAGES;
const { COMPLETE, COMPLETE_WITHOUT_TOKEN, SIGN_UP, REGISTRATION_CAMPAIGN, SIGN_UP_CAMPAIGN } = PATHS.REGISTER;
const { TEST_RESULTS, RISK_PROFILE, MY_HEALTH_DASHBOARD, CARE_GUIDELINES, GOALS, HEALTH_SUMMARY, QUESTIONNAIRES } =
  PATHS.MY_HEALTH;

export const TRACKING_PAGE = {
  Appointments: [SCHEDULE_APPOINTMENT, COMPLETE_LIST_OF_VISIT_REASONS, CHOOSE_TIME, CONFIRM, SCHEDULED, CANCELLATION],
  Incentives: [INCENTIVES, WELLNESS_LOGS, CHALLENGES],
  Messages: [ROOT, EDIT],
  Registration: [
    PATHS.REGISTER.ROOT,
    COMPLETE,
    COMPLETE_WITHOUT_TOKEN,
    SIGN_UP,
    SIGN_UP_CAMPAIGN,
    REGISTRATION_CAMPAIGN,
  ],
  'RX Refills': [PATHS.MEDICATION_REFILLS.ROOT],
  'Health Records': [MY_HEALTH_DASHBOARD],
  'Lab/Tests Results': [TEST_RESULTS],
  'Risk Profile': [RISK_PROFILE],
  'Health Summary': [
    HEALTH_SUMMARY.ROOT,
    HEALTH_SUMMARY.MEDICATIONS,
    HEALTH_SUMMARY.ALLERGIES,
    HEALTH_SUMMARY.IMMUNIZATIONS,
    HEALTH_SUMMARY.HEALTH_ISSUES,
    HEALTH_SUMMARY.SCREENINGS,
    HEALTH_SUMMARY.VISIT_SUMMARIES,
    HEALTH_SUMMARY.DOCUMENTS,
  ],
  Goals: [GOALS],
  'Care Guidelines': [CARE_GUIDELINES.ROOT],
  'HRA Participation': [QUESTIONNAIRES],
  Homepage: [PATHS.MAIN_PAGE],
  Help: [PATHS.HELP.ROOT, PATHS.HELP.FAQ, PATHS.HELP.FEEDBACK],
  Providers: [PATHS.APPOINTMENTS.PROVIDERS.ROOT, PATHS.APPOINTMENTS.PROVIDERS.BIO],
  Information: [
    PATHS.APPOINTMENTS.HEALTH_CENTER_SERVICES,
    PATHS.APPOINTMENTS.HEALTH_CENTER_LOCATIONS,
    PATHS.APPOINTMENTS.HEALTH_CENTER_LOCATION_DETAILS,
  ],
  Login: [PATHS.LOGIN],
};
