import React, { ForwardedRef, useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';

import { WellnessTypesEnum } from '../../constants';
import { fitnessDataSelector, getItemsSelector } from '../../store';
import { Dashboard, useDashboard } from '../Dashboard';
import { MEASUREMENT_TYPES } from './constants';
import { locale } from './locale';
import { MeasurementTypeListItem } from './types';

type FitnessDashboardComponent = {
  setRef: ForwardedRef<HTMLDivElement>;
};

export function FitnessDashboardComponent({ setRef }: FitnessDashboardComponent) {
  const [selectedMeasurement, setSelectedMeasurement] = useState<MeasurementTypeListItem>(MEASUREMENT_TYPES[0]);

  const { requestLogStatus, summaryByMeasurement, summaryMonth, removeStatus } = useSelector(fitnessDataSelector);

  const items = useSelector(getItemsSelector(WellnessTypesEnum.Fitness));

  const handleChangeMeasurement = useCallback((measurement: MeasurementTypeListItem) => {
    setSelectedMeasurement(measurement);
  }, []);

  const { chartData, handleHistoryItemClick, handleRecordButtonClick, handleDeleteItemClick } = useDashboard({
    summaryMonth,
    summaryData: summaryByMeasurement[selectedMeasurement.type],
    type: WellnessTypesEnum.Fitness,
  });

  return (
    <div ref={setRef}>
      <Dashboard
        type={WellnessTypesEnum.Fitness}
        heading={locale.dashboard_title}
        chartTitle={locale.title}
        data={chartData}
        onChangeMeasurement={handleChangeMeasurement}
        measurements={MEASUREMENT_TYPES}
        selectedMeasurement={selectedMeasurement}
        historyItems={items}
        historyTitle={locale.history_title}
        loading={
          [StatusEnum.Pending, StatusEnum.Uninitialized].includes(requestLogStatus) ||
          removeStatus === StatusEnum.Pending
        }
        recordButtonText={locale.record_activity}
        onRecordButtonClick={handleRecordButtonClick}
        onEditItemClick={handleHistoryItemClick}
        onDeleteItemClick={handleDeleteItemClick}
      />
    </div>
  );
}

export const FitnessDashboard = React.forwardRef<HTMLDivElement>((props, ref) => (
  <FitnessDashboardComponent setRef={ref} {...props} />
));

FitnessDashboard.displayName = 'FitnessDashboard';
