import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@components/Modal';

import { AccessibilityStatement } from './components/AccessibilityStatement';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { TermsOfUse } from './components/TermsOfUse';
import { locale } from './locale';
import { getModalTypeSelector, setModal } from './store';

const MODALS = {
  termsOfUse: {
    title: locale.terms_of_use.toUpperCase(),
    component: <TermsOfUse />,
    labelId: 'terms_of_use_label',
  },
  privacyPolicy: {
    title: locale.privacy_policy.toUpperCase(),
    component: <PrivacyPolicy />,
    labelId: 'privacy_policy_label',
  },
  accessibilityStatement: {
    title: locale.accessibility_statement.toUpperCase(),
    component: <AccessibilityStatement />,
    labelId: 'accessibility_statement_label',
  },
};

const MODAL_ID = 'global_modal';

export const GlobalModal = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalTypeSelector);

  const handleClose = useCallback(() => {
    dispatch(setModal(null));
  }, [dispatch]);

  return (
    <Modal
      open={!!modalType}
      title={modalType && MODALS[modalType].title}
      onClose={handleClose}
      withCloseButton
      titleId={modalType ? MODALS[modalType].labelId : undefined}
      id={MODAL_ID}
    >
      {modalType && MODALS[modalType].component}
    </Modal>
  );
};
