import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';

import { ErrorCodes } from '../constants';
import { resetPassword, sendResetLink, verifyResetToken } from './actions';
import { PasswordRecoveryState } from './types';

const initialState: PasswordRecoveryState = {
  sendResetLinkStatus: StatusEnum.Uninitialized,
  resetPasswordStatus: StatusEnum.Uninitialized,
  error: null,
  resetPasswordError: null,
  blockingTime: null,
  tokenValidationStatus: StatusEnum.Uninitialized,
  tokenValid: null,
};

const passwordRecoverySlice = createSlice({
  name: 'passwordRecovery',
  initialState,
  reducers: {
    resetPasswordRecovery: () => initialState,
    clearError: (state) => {
      state.error = initialState.error;
      state.blockingTime = initialState.blockingTime;
    },
  },
  extraReducers: {
    [sendResetLink.pending.type]: (state: PasswordRecoveryState) => {
      state.sendResetLinkStatus = StatusEnum.Pending;
    },
    [sendResetLink.fulfilled.type]: (state: PasswordRecoveryState) => {
      state.sendResetLinkStatus = StatusEnum.Fulfilled;
    },
    [sendResetLink.rejected.type]: (state: PasswordRecoveryState, error) => {
      state.sendResetLinkStatus = StatusEnum.Rejected;
      state.error =
        error.payload.response?.data?.error_code || error.payload.response?.data?.message || ErrorCodes.Default;

      if (error.payload.response?.data?.error_code === ErrorCodes.AccountLocked) {
        state.blockingTime = error.payload.response?.data?.additional_data;
      }
    },
    [resetPassword.pending.type]: (state: PasswordRecoveryState) => {
      state.resetPasswordStatus = StatusEnum.Pending;
    },
    [resetPassword.fulfilled.type]: (state: PasswordRecoveryState) => {
      state.resetPasswordStatus = StatusEnum.Fulfilled;
    },
    [resetPassword.rejected.type]: (state: PasswordRecoveryState, error) => {
      state.resetPasswordStatus = StatusEnum.Rejected;
      state.resetPasswordError = error.payload.response?.data?.error_code || ErrorCodes.Default;
    },
    [verifyResetToken.pending.type]: (state: PasswordRecoveryState) => {
      state.tokenValidationStatus = StatusEnum.Pending;
    },
    [verifyResetToken.fulfilled.type]: (
      state: PasswordRecoveryState,
      action: PayloadAction<{ token_ok?: boolean }>,
    ) => {
      state.tokenValidationStatus = StatusEnum.Fulfilled;
      state.tokenValid = action.payload.token_ok;
    },
    [verifyResetToken.rejected.type]: (state: PasswordRecoveryState, error) => {
      state.tokenValidationStatus = StatusEnum.Rejected;
      state.tokenValidationStatus = error.payload.response?.data?.error_code || ErrorCodes.Default;
    },
  },
});

export const passwordRecoveryReducer = passwordRecoverySlice.reducer;
export const { resetPasswordRecovery, clearError } = passwordRecoverySlice.actions;
