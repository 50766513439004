import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import { downloadProblemsReport, getHealthIssues } from './actions';
import { HealthIssuesState } from './types';

const initialState: HealthIssuesState = {
  healthIssuesRequest: USER_SPECIFIC_INITIAL_STATE,
  downloadStatus: StatusEnum.Uninitialized,
};

export const healthIssuesSlice = createSlice({
  name: 'health issues',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getHealthIssues,
      updateState: (state, updates) => {
        state.healthIssuesRequest = {
          ...state.healthIssuesRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.healthIssuesRequest.userId,
    });

    builder.addCase(downloadProblemsReport.pending, (state) => {
      state.downloadStatus = StatusEnum.Pending;
    });
    builder.addCase(downloadProblemsReport.fulfilled, (state) => {
      state.downloadStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadProblemsReport.rejected, (state) => {
      state.downloadStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const healthIssuesReducer = healthIssuesSlice.reducer;
