import { MeasurementEnum } from '../../constants';
import { locale } from './locale';

export const MEASUREMENT_TYPES = [
  {
    title: locale.measurements[MeasurementEnum.Steps],
    shortTitle: locale.measurementsShort[MeasurementEnum.Steps],
    yScaleTitle: locale.measurementsYScaleTitle[MeasurementEnum.Steps],
    type: MeasurementEnum.Steps,
  },
];
