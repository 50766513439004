import React, { useCallback, useState } from 'react';

type OutputType = {
  closeMenu: VoidFunction;
  selectedTabId: number | string | null;
  menuAnchorEl: HTMLElement | null;
  handleTabSelection: (event: React.MouseEvent<HTMLElement>, tabId: number | string) => unknown;
};

export function useTabSelection(): OutputType {
  const [selectedTabId, setSelectedTabId] = useState<number | string | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = useCallback(
    (currentTarget: HTMLElement) => {
      if (currentTarget !== menuAnchorEl) {
        setMenuAnchorEl(currentTarget);
      }
    },
    [menuAnchorEl],
  );

  const closeMenu = useCallback(() => {
    setMenuAnchorEl(null);
    setSelectedTabId(null);
  }, []);

  const handleTabSelection = (event: React.MouseEvent<HTMLElement>, tabId: number | string) => {
    if (tabId !== selectedTabId) {
      setSelectedTabId(tabId);
      handleOpenMenu(event.currentTarget);
    }
  };

  return {
    closeMenu,
    handleTabSelection,
    selectedTabId,
    menuAnchorEl,
  };
}
