import { GetFlipperValueType, isHideElementByFlipperValue } from '@modules/flippers';

import { FiltrationFunction, MenuItemWithSubItems, FlipperItemProps, OnlySourceUserItemProps } from './types';

export function filterMenuAndSubMenuItems<IP extends object, MI extends MenuItemWithSubItems<IP>>(
  menuItems: MI[],
  filterFunction: FiltrationFunction<IP>,
): MI[] {
  return menuItems.reduce((acc, menuItem) => {
    if (!filterFunction(menuItem)) {
      return acc;
    }

    let newMenuItem = menuItem;

    if (menuItem.subMenuItems?.length) {
      newMenuItem = {
        ...newMenuItem,
        // ensure to call filterFunction with one argument
        subMenuItems: menuItem.subMenuItems.filter((item: IP) => filterFunction(item)),
      };
    }

    return [...acc, newMenuItem];
  }, [] as MI[]);
}

export const getFiltrationByFlipperFunction = (getFlipperValue: GetFlipperValueType) => (item: FlipperItemProps) =>
  !item.flipper || !isHideElementByFlipperValue(getFlipperValue(item.flipper), item.flipper);

export const getFiltrationByOnlySourceUserFunction =
  (isSourceUserAndSelectedFamilyMemberSimilar: boolean) => (item: OnlySourceUserItemProps) =>
    !item.onlySourceUser || isSourceUserAndSelectedFamilyMemberSimilar;
