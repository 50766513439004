export const locale = {
  cancel: 'Cancel',
  submit: 'Record Sleep',
  record_another_entry: 'Save & Record Another Entry',
  header: 'Record Sleep',

  field_labels: {
    date: 'Date',
    time: 'Time',
    hours: 'Hours',
    minutes: 'Minutes',
    hours_aria_label: '{{type}} - Hours',
    minutes_aria_label: '{{type}} - Minutes',
  },
  field_row_labels: {
    duration: 'Time Slept',
  },
  errors: {
    date: 'Date is required',
    duration: 'Time Slept is required',
  },
};
