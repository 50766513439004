import {
  addDays,
  addMonths,
  addWeeks,
  addBusinessDays,
  differenceInDays,
  differenceInSeconds,
  differenceInYears,
  eachMonthOfInterval,
  getDaysInMonth,
  differenceInMinutes,
  format,
  getYear,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  parse,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  startOfWeek,
  subWeeks,
  setMonth,
  isDate,
  add,
  sub,
  isWithinInterval,
} from 'date-fns';
import { format as timeZoneFormat, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

const DEFAULT_LOCALE = { locale: enUS };

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const INVALID_DATE_MESSAGE = 'Invalid Date';

export function isValid(date?: unknown, dateFormat?: string) {
  if (date === null || date === undefined) {
    return null;
  }

  if (typeof date === 'string' && dateFormat && date.length !== dateFormat?.length) {
    return false;
  }

  return new Date(date as Date).toString() !== INVALID_DATE_MESSAGE;
}

export const API_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS";

export function parseDateInTimeZone(date = '') {
  let newDate = new Date(date);

  if (date[date.length - 1] !== 'Z') {
    newDate = new Date(`${date}Z`);
  }

  return newDate;
}

export function parseISOLocal(dateString = '') {
  const splittedDateString = dateString.split(/\D/);

  return new Date(
    +splittedDateString[0], // year
    +splittedDateString[1] - 1, // mon  0-11 this is why we do -1, because 1.1.1970 is 1st jan, but for computer is 1st feb
    +splittedDateString[2], // day  1-31
    +splittedDateString[3], // hour 0-23
    +splittedDateString[4], // min  0-59
    +splittedDateString[5], // sec  0-59
  );
}
export function formatDateForServer(dateString: string | number | Date) {
  return zonedTimeToUtc(dateString, timeZone).toISOString();
}

export const formatDate = (date: Date | string | number, formatStr = 'MM/dd/yyyy') => {
  try {
    let preparedDate;

    if (date instanceof Date && isValid(date)) {
      preparedDate = date;
    } else if (typeof date === 'string') {
      preparedDate = parseISO(date);

      if (!isValid(preparedDate)) {
        preparedDate = parse(date, formatStr, new Date());
      }
    } else {
      preparedDate = new Date(date);
    }

    return format(preparedDate, formatStr, DEFAULT_LOCALE);
  } catch (error) {
    return INVALID_DATE_MESSAGE;
  }
};

export function transformDate(str: string, patternFrom: string, patternTo: string) {
  const parsed = parse(str, patternFrom, new Date());

  return formatDate(parsed, patternTo);
}

export function dateStringToDateObject(date: string) {
  return new Date(`${date}${date.includes('T') ? '' : 'T00:00:00'}`);
}

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;

export function secondsToHms(seconds: number | undefined = 0): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  return {
    hours: Math.floor(seconds / SECONDS_IN_HOUR),
    minutes: Math.floor((seconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE),
    seconds: Math.floor((seconds % SECONDS_IN_HOUR) % SECONDS_IN_MINUTE),
  };
}

export function hmsToSeconds({
  hours = 0,
  minutes = 0,
  seconds = 0,
}: {
  hours?: number | string;
  minutes?: number | string;
  seconds?: number | string;
}): number {
  return Number(hours) * SECONDS_IN_HOUR + Number(minutes) * SECONDS_IN_MINUTE + Number(seconds);
}

export function hmToMinutes({
  hours = 0,
  minutes = 0,
}: {
  hours?: number | string;
  minutes?: number | string;
}): number {
  return Number(hours) * MINUTES_IN_HOUR + Number(minutes);
}

export function minutesToHm(minutes: number | undefined): {
  hours?: number;
  minutes?: number;
} {
  if (!minutes) {
    return {
      hours: 0,
      minutes: 0,
    };
  }

  const hours = minutes / 60;
  const roundedHours = Math.floor(hours);

  return {
    hours: roundedHours ?? 0,
    minutes: Math.round((hours - roundedHours) * 60) ?? 0,
  };
}

export function datePlusTime(enterDate: Date | string, time?: number | Date) {
  let date = enterDate;

  if (!isDate(enterDate)) {
    date = new Date(enterDate);
  }

  if (!time) {
    return date;
  }
  const hours = getHours(time);
  const minutes = getMinutes(time);
  const seconds = getSeconds(time);

  return setSeconds(setMinutes(setHours(date as Date, hours), minutes), seconds);
}

const isAmPmTimeFormat = (date: string | Date | number) => {
  const timeString = new Date(date).toLocaleString([], { hour: 'numeric', minute: 'numeric' });

  return timeString.indexOf('AM') > -1 || timeString.indexOf('PM') > -1;
};

const isDateOverOneYear = (itemDate: Date, currentDate: Date) => {
  // Calculate the difference in years between the two dates
  const dateDifference = differenceInYears(currentDate, itemDate);

  // Compare the date difference with one year
  return dateDifference >= 1;
};

export {
  differenceInSeconds,
  differenceInMinutes,
  getYear,
  getMonth,
  getSeconds,
  differenceInDays,
  differenceInYears,
  eachMonthOfInterval,
  isSameMonth,
  isSameYear,
  addMonths,
  timeZoneFormat,
  formatInTimeZone,
  isSameDay,
  isAfter,
  addDays,
  addWeeks,
  isBefore,
  startOfWeek,
  subWeeks,
  addBusinessDays,
  setMonth,
  getDaysInMonth,
  parseISO,
  parse,
  isDate,
  add,
  isAmPmTimeFormat,
  sub,
  isWithinInterval,
  isDateOverOneYear,
  timeZone as browserTimeZone,
};
