import { TIME_ZONES_ADDITIONAL_INFO } from './constants';

export const getUsIanaTimeZone = (ianaTimeZone: string): string | undefined =>
  TIME_ZONES_ADDITIONAL_INFO.find((info) => info.ianaTimeZone === ianaTimeZone)?.usIanaTimeZone;

export const getTimeZoneAbbreviation = (ianaTimeZone: string): string | undefined =>
  TIME_ZONES_ADDITIONAL_INFO.find((info) => info.ianaTimeZone === ianaTimeZone)?.abbreviation;

export const getTimeZoneDisplayName = (ianaTimeZone: string): string | undefined =>
  TIME_ZONES_ADDITIONAL_INFO.find((info) => info.ianaTimeZone === ianaTimeZone)?.displayName;
