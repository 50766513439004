export const locale = {
  cancel: 'Cancel',
  submit: 'Record Water',
  record_another_entry: 'Save & Record Another Entry',
  header: 'Record Water',

  field_labels: {
    date: 'Date',
    time: 'Time',
    water: 'Water',
  },
  adornments: {
    water: 'OZ',
  },
  errors: {
    date: 'Date is required',
    water: 'Water is required',
  },
};
