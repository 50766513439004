import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { FilterNamesEnum, DEFAULT_SORT_ORDER, DEFAULT_SORT_BY } from '../constants';
import { getProviders } from './actions';
import { ProvidersState, SetSortingType, ProviderResponseType } from './types';

const initialState: ProvidersState = {
  providers: {
    data: {
      providers: [],
      all_individual_provider_types: [],
      relevant_individual_provider_types: [],
    },
    status: StatusEnum.Uninitialized,
  },
  sorting: {
    order: DEFAULT_SORT_ORDER,
    orderBy: DEFAULT_SORT_BY,
  },
  search: '',
  filter: {
    [FilterNamesEnum.ProviderTypes]: [],
    [FilterNamesEnum.HealthCenters]: [],
    [FilterNamesEnum.Networks]: [],
  },
};

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<SetSortingType>) => {
      state.sorting = action.payload;
    },

    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    setFilter(state, action: PayloadAction<Partial<ProvidersState['filter']>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },

    clearFilter(state) {
      state.filter = initialState.filter;
      state.search = '';
    },
  },
  extraReducers: {
    [getProviders.pending.type]: (state: ProvidersState) => {
      state.providers.status = StatusEnum.Pending;
    },
    [getProviders.fulfilled.type]: (state: ProvidersState, action: PayloadAction<ProviderResponseType>) => {
      state.providers.status = StatusEnum.Fulfilled;
      state.providers.data = action.payload;
    },
    [getProviders.rejected.type]: (state: ProvidersState) => {
      state.providers.status = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const providersReducer = providersSlice.reducer;
export const { setSorting, setSearch, setFilter, clearFilter } = providersSlice.actions;
