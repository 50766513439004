/* eslint-disable camelcase */
import React from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { useQueryParamsObject } from '@common/router';
import { browserTimeZone, formatInTimeZone } from '@common/utils';
import { getLocale } from '@common/utils/locale';
import { Attachment } from '@components/Attachment';
import { userTimeZoneSelector } from '@modules/userProfileData/store/selectors';

import { MailboxTypeEnum } from '../../../constants';
import { MessageType, getMailboxItemByConversationId } from '../../../store';
import { locale } from '../locale';
import { SenderRecipient } from './SenderRecipient';

import styles from '../MessageBox.module.css';

const cn = classNames.bind(styles);

type Props = {
  message: MessageType;
  topMessage: boolean;
  id?: string;
};

export const MessageHeader = ({ message, topMessage, id }: Props) => {
  const { conversationId, mailboxType } = useQueryParamsObject();
  const mailboxItem = useSelector((state: RootState) =>
    getMailboxItemByConversationId(state, { type: mailboxType, conversationId }),
  );
  const userTimeZone = useSelector(userTimeZoneSelector) || browserTimeZone;
  const showArchived = mailboxType === MailboxTypeEnum.Archive && mailboxItem?.archived_at && topMessage;

  return (
    <div className={cn('message-box__header')} id={id}>
      <h2
        className={cn('message-box__subject')}
        aria-label={`${getLocale(locale.subject, { subject: message.subject })},`}
      >
        {message.subject}
      </h2>
      <div className={cn('message-box__sent-container')}>
        <SenderRecipient message={message} />
        {!message.draft && message.sent_at && userTimeZone && (
          <div className={cn('message-box__sent')}>
            {getLocale(locale.sent, {
              sent: formatInTimeZone(message.sent_at, userTimeZone, 'M/dd/yyyy h:mm a'),
            })}
          </div>
        )}
        {showArchived && mailboxItem.archived_at && userTimeZone && (
          <div className={cn('message-box__sent')}>
            {getLocale(locale.archived_at, {
              archivedAt: formatInTimeZone(mailboxItem.archived_at, userTimeZone, 'M/dd/yyyy h:mm a'),
            })}
          </div>
        )}
      </div>
      {message.file_attachments?.length > 0 && (
        <div className={cn('message-box__attachments')}>
          {message.file_attachments.map(({ id, original_filename, url, file }) => (
            <Attachment className={cn('message-box__attachment')} name={original_filename ?? file} key={id} url={url} />
          ))}
        </div>
      )}
    </div>
  );
};
