import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { NetWorkForAllMemberPayloadType } from '../types';
import { getNetworkForAllMembers } from './actions';

export interface NetworksState {
  networkForAllMembersStatus: StatusEnum;
  networkForAllMembers: NetWorkForAllMemberPayloadType;
}

const initialState: NetworksState = {
  networkForAllMembersStatus: StatusEnum.Uninitialized,
  networkForAllMembers: {} as NetWorkForAllMemberPayloadType,
};

const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {},
  extraReducers: {
    [getNetworkForAllMembers.pending.type]: (state: NetworksState) => {
      state.networkForAllMembersStatus = StatusEnum.Pending;
    },
    [getNetworkForAllMembers.fulfilled.type]: (
      state: NetworksState,
      action: PayloadAction<NetWorkForAllMemberPayloadType>,
    ) => {
      state.networkForAllMembersStatus = StatusEnum.Fulfilled;
      state.networkForAllMembers = action.payload;
    },
    [getNetworkForAllMembers.rejected.type]: (state: NetworksState) => {
      state.networkForAllMembersStatus = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const networksReducer = networksSlice.reducer;
