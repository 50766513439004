import React, { ReactNode } from 'react';

import FormControl from '@mui/material/FormControl';
import TextFieldMaterial, { TextFieldProps as TextFieldPropsMaterial } from '@mui/material/TextField';
import classNames from 'classnames/bind';

import { FormHelperText } from '@components/FormHelperText';

import { MaskedTextField } from './MaskedTextField';

import styles from './TextField.module.css';

const cn = classNames.bind(styles);

export type TextFieldProps = {
  className?: string;
  label?: ReactNode;
  min?: number;
  max?: number;
  maxLength?: number;
  minLength?: number;
  inputMode?: TextFieldPropsMaterial['inputMode'];
  pattern?: RegExp;
  variant?: 'outlined' | 'standard' | 'filled';
  inputClassName?: string;
  inputRootClassName?: string;
  autoComplete?: string;
  mask?: string | RegExp;
  unmask?: boolean;
  // debounceDelay?: number;  TODO check twice. We have a separate Debounced component, this prop is not used in just TextFieldComponent
} & Pick<
  TextFieldPropsMaterial,
  | 'autoFocus'
  | 'onChange'
  | 'onFocus'
  | 'onBlur'
  | 'required'
  | 'disabled'
  | 'multiline'
  | 'fullWidth'
  | 'placeholder'
  | 'rows'
  | 'error'
  | 'name'
  | 'helperText'
  | 'type'
  | 'inputProps'
  | 'size'
  | 'InputProps'
  | 'value'
  | 'autoComplete'
  | 'id'
  | 'inputRef'
  | 'aria-labelledby'
  | 'aria-label'
  | 'onKeyDown'
  | 'onKeyUp'
>;

export function TextField({
  autoFocus = false,
  autoComplete,
  className,
  disabled = false,
  error = false,
  fullWidth = false,
  helperText = '',
  label,
  min,
  max,
  maxLength,
  minLength,
  pattern,
  inputMode,
  multiline = false,
  name = '',
  onFocus,
  onBlur,
  onChange,
  placeholder = '',
  required = false,
  rows,
  value = '',
  variant = 'standard',
  type = 'text',
  size,
  mask,
  unmask,
  id,
  inputRef,
  inputClassName,
  inputRootClassName,
  inputProps = {},
  InputProps = {},
  onKeyDown,
  onKeyUp,
  'aria-labelledby': ariaLabelledBy,
  'aria-label': ariaLabel,
}: TextFieldProps) {
  const isOutlined = variant === 'outlined';
  const variantProp = variant === 'outlined' ? 'standard' : variant;
  const helperTextId = helperText ? `${id || name}_description` : undefined;

  return (
    <FormControl variant={variantProp} disabled={disabled} fullWidth={fullWidth} className={cn(className)}>
      <TextFieldMaterial
        className={cn('text-field')}
        disabled={disabled}
        autoFocus={autoFocus}
        error={error}
        fullWidth={fullWidth}
        autoComplete={autoComplete}
        InputLabelProps={{
          shrink: true,
          className: cn('text-field__label'),
          classes: {
            asterisk: cn('text-field__label-asterisk'),
            focused: cn('text-field__label--focused'),
            disabled: cn('text-field__label--disabled'),
          },
        }}
        inputProps={{
          autoComplete,
          maxLength,
          minLength,
          pattern,
          min,
          max,
          inputMode,
          disabled: inputProps?.disabled ?? disabled,
          placeholder: placeholder || inputProps?.placeholder,
          mask,
          unmask,
          ...(!label && !ariaLabelledBy && { 'aria-label': placeholder || name }),
          'aria-describedby': helperTextId,
          'aria-labelledby': ariaLabelledBy,
          'aria-label': ariaLabel,
          ...inputProps,
          className: cn('text-field__input', inputClassName, inputProps?.className, {
            'text-field__input--outlined': isOutlined,
          }),
        }}
        InputProps={{
          ...InputProps,
          endAdornment: InputProps.endAdornment,
          startAdornment: InputProps.startAdornment,
          classes: {
            ...(InputProps.classes || {}),
            focused: cn('text-field--focused'),
            root: cn('text-field__input-root', inputRootClassName, {
              'text-field__input-root--outlined': isOutlined,
            }),
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: mask ? (MaskedTextField as any) : undefined,
          autoComplete,
        }}
        label={label}
        multiline={multiline}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        required={required}
        rows={rows}
        value={value}
        variant={variantProp}
        type={type}
        size={size}
        id={id}
        inputRef={inputRef}
      />

      {!!helperText && <FormHelperText id={helperTextId} error={error} helperText={helperText} />}
    </FormControl>
  );
}
