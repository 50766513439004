import { MeasurementEnum } from '../../constants';
import { locale } from './locale';
import { MeasurementTypeListItem } from './types';

export const MEASUREMENT_TYPES: MeasurementTypeListItem[] = [
  {
    title: locale.measurements[MeasurementEnum.Minutes],
    shortTitle: locale.measurementsShort[MeasurementEnum.Minutes],
    yScaleTitle: locale.measurementsYScaleTitle[MeasurementEnum.Minutes],
    type: MeasurementEnum.Minutes,
  },
  {
    title: locale.measurements[MeasurementEnum.Miles],
    shortTitle: locale.measurementsShort[MeasurementEnum.Miles],
    yScaleTitle: locale.measurementsYScaleTitle[MeasurementEnum.Miles],
    type: MeasurementEnum.Miles,
  },
];
