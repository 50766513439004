import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { familyMembersStatusSelector, getFamilyMembers } from '@modules/userProfileData';

export const useGetFamilyMembers = () => {
  const dispatch = useDispatch();
  const familyMembersStatus = useSelector(familyMembersStatusSelector);

  useIgnoreEffectDeps(() => {
    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(familyMembersStatus)) {
      dispatch(getFamilyMembers());
    }
  }, [familyMembersStatus]);
};
