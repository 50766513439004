import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import { downloadImmunizationsReport, getImmunizations } from './actions';
import { ImmunizationsState } from './types';

const initialState: ImmunizationsState = {
  immunizationsRequest: USER_SPECIFIC_INITIAL_STATE,
  downloadStatus: StatusEnum.Uninitialized,
};

export const immunizationsSlice = createSlice({
  name: 'immunizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getImmunizations,
      updateState: (state, updates) => {
        state.immunizationsRequest = {
          ...state.immunizationsRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.immunizationsRequest.userId,
    });

    builder.addCase(downloadImmunizationsReport.pending, (state) => {
      state.downloadStatus = StatusEnum.Pending;
    });
    builder.addCase(downloadImmunizationsReport.fulfilled, (state) => {
      state.downloadStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadImmunizationsReport.rejected, (state) => {
      state.downloadStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const immunizationsReducer = immunizationsSlice.reducer;
