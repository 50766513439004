export enum GtmCommandsEnum {
  config = 'config',
  consent = 'consent',
  event = 'event',
  get = 'get',
  set = 'set',
}

export enum GtmEventsEnum {
  click = 'click',
  exception = 'exception',
  fieldEntry = 'field_entry',
  fullContentLoaded = 'full_content_loaded',
  pageLoad = 'page_load',
  newMessage = 'NewMessage',
  confirmAndScheduleAppointment = 'ConfirmAndScheduleAppointment',
  signUpComplete = 'SignUpComplete',
  signUp = 'SignUp',
  signIn = 'SignIn',
  portalJump = 'PortalJump',
  samlJump = 'SamlJump',
}

export const userOnlyEvents = [GtmEventsEnum.click, GtmEventsEnum.fieldEntry];

export enum GtmClickTypesEnum {
  abandonOnboardingTour = 'abandon_onboardingtour',
  appointmentCancellation = 'appointment_cancellation',
  appointmentsDropdown = 'Appointments_Dropdown',
  commonVisitReason = 'common_visit_reason',
  completedOnboardingtour = 'completed_onboardingtour',
  exitSchedulingGoBack = 'ExitScheduling_GoBack',
  exitSchedulingModalClose = 'ExitScheduling_ModalClose',
  firstAttemptAbandon = 'first_attempt_abandon',
  loginNewUserLink = 'Login_NewUser_LinkClick ',
  newAppointmentAvailabilityFilter = 'NewAppointment_AvailabilityFilter',
  newAppointmentCalendarDateSelect = 'NewAppointment_CalendarDateSelect',
  newAppointmentChangeVisitType = 'NewAppointment_ChangeVisitType',
  newAppointmentChooseDifferentTime = 'NewAppointment_ChooseDifferentTime',
  newAppointmentCompleteList = 'NewAppointment_CompleteList',
  newAppointmentConfirmAndSchedule = 'NewAppointment_ConfirmAndSchedule',
  newAppointmentDirections = 'NewAppointment_Directions',
  newAppointmentFamPermissions = 'NewAppointment_FamPermissions',
  newAppointmentFilterTooltip = 'NewAppointment_FilterTooltip',
  newAppointmentHealthCenterFilter = 'NewAppointment_HealthCenterFilter',
  newAppointmentInPerson = 'NewAppointment_InPerson',
  newAppointmentListSelection = 'NewAppointment_ListSelection',
  newAppointmentLocationNetworkFilter = 'NewAppointment_LocationNetworkFilter',
  newAppointmentProviderSearch = 'NewAppointment_ProviderSearch',
  newAppointmentProvidersFilter = 'NewAppointment_ProvidersFilter',
  newAppointmentReasonSearchSelection = 'NewAppointment_ReasonSearchSelection',
  newAppointmentRequestHelp = 'NewAppointment_RequestHelp',
  newAppointmentRequestHelpSubmit = 'NewAppointment_RequestHelpSubmit',
  newAppointmentSearchAvailability = 'NewAppointment_SearchAvailability',
  newAppointmentSearchNextAvailableDay = 'NewAppointment_SearchNextAvailableDay',
  newAppointmentSearchNextAvailableWeek = 'NewAppointment_SearchNextAvailableWeek',
  newAppointmentTimeSelect = 'NewAppointment_TimeSelect',
  newAppointmentVirtual = 'NewAppointment_Virtual',
  newAppointmentWhoDropdown = 'NewAppointment_WhoDropDown',
  newAppointmentWhoSelection = 'NewAppointment_Who_Selection',
  newAppointmentViewProfile = 'NewAppointment_ViewProfile',
  reschedulingConfirmAndSchedule = 'Rescheduling_ConfirmAndSchedule',
  scheduleAppointmentHomePageTile = 'ScheduleAppointment_homepagetile',
  scheduleViewAppointments = 'ScheduleViewAppointments_Dropdown',
  secondAttemptAbandon = 'second_attempt_abandon',
  signUpBasicInfoBack = 'SignUpRYI_BackClick',
  signUpBasicInfoMRTPhone = 'SignUpRYI_MRTPhoneClick',
  signUpBasicInfoNext = 'SignUpRYI_NextClick',
  signUpCompleteMRTPhone = 'SignUpComplete_MRTPhoneClick',
  signUpCredentialsBack = 'SignUpCC_BackClick',
  signUpCredentialsFinish = 'SignUpCC_FinishClick',
  signUpCredentialsMRTPhone = 'SignUpCC_MRTPhoneClick',
  signUpIdentificationConfirmationMRTPhone = 'SignUpVYI_MRTPhoneClick',
  signUpIdentificationConfirmationVerify = 'SignUpVYI_VerifyClick',
  signUpIdentificationCompleteValidated = 'SignUpVYI_NextClick',
  signUpPotentialFinishRegistrationClick = 'SignUpPotentialFinishRegistrationClick',
  signUpPotentialRegisterAs = 'SignUpPotential_RegisterAs',
  startOnboardingTour = 'start_onboardingtour',
  upcomingAppointmentAddToCalendar = 'UpcomingAppointment_AddToCalendar',
  upcomingAppointmentCancel = 'UpcomingAppointment_Cancel',
  upcomingAppointmentDirections = 'UpcomingAppointment_Directions',
  upcomingAppointmentReschedule = 'UpcomingAppointment_Reschedule',
  upcomingAppointmentViewClinicInfo = 'UpcomingAppointment_ViewClinicInfo',
  upcomingAppointmentViewMap = 'UpcomingAppointment_ViewMap',
  videotourOnboarding = 'Videotour_onboarding',
  virtualVisitCheck = 'virtual_visit_check',
  virtualVisitStart = 'virtual_visit_start',
  visitReason = 'visit_reason',
  homePageNavToIncentive = 'HomePage_ButtonClick_NavTo_Incentivespage',
  homePageIncentiveStatusSummary = 'Homepage_View_IncentiveStatusSummary',

  incentivesClickScheduleAppointmentGoal = 'Incentives_Click_ScheduleAppointmentGoal',
  incentivesClickCompleteHRAGoal = 'Incentives_Click_completeHRAGoal',
  incentivesClickWellnessChallengeGoal = 'Incentives_Click_WellnessChallengeGoal',
  incentivesClickWellnessLogsGoal = 'Incentives_Click_WellnessLogsGoal',
  incentivesClickSelfReportedGoal = 'Incentives_Click_SelfReportedGoal',
  incentivesClickViewGroupedGoals = 'Incentives_Click_ViewGroupedGoals',
  incentivesClickWellnessWorkshopGoal = 'Incentives_Click_WellnessworkshopGoal',

  incentivesClickViewDocuments = 'Incentives_Click_ViewDocuments',
  incentivesViewUploadFormModal = 'Incentives_View_UploadFormModal',
  incentivesUploadIncentivesForm = 'Incentives_Upload_IncentivesForm',
  incentivesClickViewUploadedForm = 'Incentives_Click_ViewUploadedForm',
  incentivesClickDeleteUploadedForm = 'Incentives_Click_DeleteUploadedform',
  incentivesClickProgramCompletionGoal = 'Incentives_Click_ProgramCompletionGoal',

  incentivesViewHistoricalPrograms = 'Incentives_View_HistoricalPrograms',
  incentivesDropDownMenuSelectIncentiveProgram = 'Incentives_DropDownMenu_SelectIncentiveProgram',
  incentivesViewGoalDetails = 'Incentives_View_GoalDetails',
  incentivesNavigationBackToAllGoals = 'Incentives_Navigation_BackToAllGoals',
}

export enum GtmFieldEntryTypesEnum {
  newAppointmentPatientNotesEntry = 'NewAppointment_PatientNotesEntry',
  signUpPotentialSSNEntered = 'SignUpPotential_SSNEntered',
  exitSchedulingDetailsEntry = 'ExitScheduling_DetailsEntry',
  newAppointmentNoMatchForVisitReason = 'NewAppointment_NoMatchForVisitReason',
}

export enum GtmPageLoadedTypesEnum {
  signUpBasicInfo = 'SignUpRYI_PageLoad',
  signUpCredentials = 'SignUpCC_PageLoad',
  signUpIdentification = 'SignUpPotential_PageLoad',
  signUpIdentificationConfirmation = 'SignUpVYI_PageLoad',

  incentiveProgramsPageLoad = 'IncentivePrograms_PageLoad',
  incentivesHistoricalProgramsPageLoad = 'Incentives_HistoricalPrograms_PageLoad',
}
