import React from 'react';

import { Header, SubHeader, ThirdHeader, Paragraph, Container } from '@components/Modal';

import { locale } from './locale';

const ACCESSIBILITY_STATEMENT_DESCRIPTION_ID = 'accessibility_statement_description';

export const AccessibilityStatement = () => (
  <Container>
    <Header text={locale.header} id={ACCESSIBILITY_STATEMENT_DESCRIPTION_ID} />
    <SubHeader text={locale.sub_header} />
    <ThirdHeader text={locale.item1} />
    <Paragraph html={locale.p_item1_1} />
    <ThirdHeader text={locale.item2} />
    <Paragraph html={locale.p_item2_1} />
    <ThirdHeader text={locale.item3} />
    <Paragraph html={locale.p_item3_1} />
    <Paragraph html={locale.p_item3_2} />
    <Paragraph html={locale.p_item3_3} />
  </Container>
);
