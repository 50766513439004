import React, { ReactNode, useCallback, useMemo, useRef } from 'react';

import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';
import { PATHS } from '@common/routes';
import { sizeFormat } from '@common/utils/sizeFormat';
import { Alert } from '@components/Alert';
import { Attachment } from '@components/Attachment';
import { AttachmentInfo } from '@components/AttachmentInfo';
import { Button } from '@components/Button';
import { FieldRow } from '@components/FieldRow';
import { SelectWithControl, TextFieldWithControl, useForm } from '@components/Form';
import { Link } from '@components/Link';
import { Modal, ModalProps } from '@components/Modal';
import { Typography } from '@components/Typography';
import { ModalTypeEnum, setModal } from '@modules/GlobalModal';
import { DocumentTypeIdsEnum } from '@modules/getDocuments';

import { DOCUMENT_TYPES, DOCUMENT_TYPE_SELECT_ID } from './constants';
import { useAttachment } from './hooks/useAttachment';
import { locale } from './locale';
import { FormValues, SubmitUploadDocumentParams } from './types';

import styles from './UploadDocumentModal.module.css';

const cn = classNames.bind(styles);

const TITLE_ID = 'upload-document-modal-title';
const DESCRIPTION_ID = 'upload-document-modal-description';

export type UploadDocumentModalProps = {
  title?: string;
  userId: number;
  uploadStatus: StatusEnum;
  onChangeAttachment?: () => void;
  onSubmit: (params: SubmitUploadDocumentParams) => void;
  onCustomSubmit?: (document: FormValues) => void;
  customSubmitDisabled?: boolean;
  onModalCloseClick: VoidFunction;
  open: boolean;
  documentTypeId?: DocumentTypeIdsEnum;
  hideDocumentTypeField?: boolean;
  description?: ReactNode;
  hideButtons?: boolean;
  hideDescription?: boolean;
  hideMessageDescription?: boolean;
  getAddFileButtonTitle?: (params: { attachment: Nullable<File>; defaultTitle: string }) => ReactNode;
  renderAdditionalButtons?: (props: { className: string; disabled: boolean }) => ReactNode;
  renderCustomAttachment?: (props: { className: string; bodyClassName: string; disabled: boolean }) => ReactNode;
  uploadAnalyticsEvent?: VoidFunction;
} & Pick<ModalProps, 'transitionDuration'>;

export function UploadDocumentModal({
  title = locale.title,
  onModalCloseClick,
  open,
  userId,
  uploadStatus,
  onSubmit,
  onCustomSubmit,
  customSubmitDisabled,
  onChangeAttachment,
  documentTypeId,
  hideDocumentTypeField,
  description,
  hideButtons,
  getAddFileButtonTitle,
  renderAdditionalButtons,
  renderCustomAttachment,
  hideDescription,
  hideMessageDescription,
  uploadAnalyticsEvent,
  ...restProps
}: UploadDocumentModalProps) {
  const dispatch = useDispatch();
  const fileRef = useRef<HTMLInputElement>(null);

  const {
    attachment,
    attachmentsError,
    attachmentsFormats,
    clearAttachmentError,
    handleAttachmentDelete,
    handleAttachmentsOnLoad,
  } = useAttachment({ onChangeAttachment });

  const uploadPending = uploadStatus === StatusEnum.Pending;
  const uploadFulfilled = uploadStatus === StatusEnum.Fulfilled;

  const defaultValues: Partial<FormValues> = useMemo(
    () => ({
      DocumentType: documentTypeId,
      UserId: userId,
    }),
    [userId, documentTypeId],
  );

  const { handleSubmit, control, reset, setValue } = useForm<FormValues>({
    defaultValues,
    mode: 'onSubmit',
  });

  const handleConfirmClick = useCallback(
    (values: FormValues) => {
      if (attachment) {
        uploadAnalyticsEvent?.();
        onSubmit({ attachment, document: values });
      }
    },
    [attachment, uploadAnalyticsEvent, onSubmit],
  );

  const handleConfirmSelectedClick = useCallback(
    (values: FormValues) => {
      uploadAnalyticsEvent?.();
      onCustomSubmit?.(values);
    },
    [onCustomSubmit, uploadAnalyticsEvent],
  );

  useIgnoreEffectDeps(() => {
    if (uploadFulfilled) {
      reset(defaultValues);
      handleAttachmentDelete();
    }
  }, [uploadFulfilled]);

  const resetOnClose = useCallback(() => {
    reset(defaultValues);
    clearAttachmentError();
    handleAttachmentDelete();
  }, [reset, defaultValues, clearAttachmentError, handleAttachmentDelete]);

  const handleCloseClick = useCallback(() => {
    resetOnClose();
    onModalCloseClick();
  }, [onModalCloseClick, resetOnClose]);

  useIgnoreEffectDeps(() => {
    if (!open) {
      resetOnClose();
    }
  }, [open]);

  useIgnoreEffectDeps(
    () => () => {
      reset(defaultValues);
    },
    [],
  );

  useIgnoreEffectDeps(() => {
    setValue('UserId', userId);
  }, [userId]);

  useIgnoreEffectDeps(() => {
    if (documentTypeId) {
      setValue('DocumentType', documentTypeId);
    }
  }, [documentTypeId]);

  const handleOpenPrivacyPolicy = useCallback(() => {
    dispatch(setModal(ModalTypeEnum.PrivacyPolicy));
  }, [dispatch]);

  const handleAttachmentsOnClick = useCallback(() => {
    fileRef?.current?.click?.();
  }, []);

  const handleDeleteAttachmentsClick = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.files = null;
      fileRef.current.value = '';
    }

    handleAttachmentDelete();
  }, [handleAttachmentDelete]);

  const buttons = useMemo(() => {
    const className = cn('button');
    const disabled = uploadPending;
    const defaultTitle = attachment ? locale.replace_file : locale.add_file;

    return (
      <>
        {renderAdditionalButtons?.({
          className,
          disabled,
        })}
        <Button onClick={handleAttachmentsOnClick} variant="contained" className={className} disabled={disabled}>
          {getAddFileButtonTitle?.({ attachment, defaultTitle }) ?? defaultTitle}
        </Button>
      </>
    );
  }, [renderAdditionalButtons, attachment, handleAttachmentsOnClick, uploadPending, getAddFileButtonTitle]);

  const renderedAttachment = useMemo(() => {
    const className = cn('attachment');
    const bodyClassName = cn('attachment-name');
    const disabled = uploadPending;
    if (renderCustomAttachment) {
      return renderCustomAttachment({ className, bodyClassName, disabled });
    }
    if (attachment) {
      return (
        <Attachment
          size={sizeFormat(attachment.size)}
          name={attachment.name}
          className={className}
          bodyClassName={bodyClassName}
          onDelete={disabled ? undefined : handleDeleteAttachmentsClick}
        />
      );
    }

    return null;
  }, [attachment, uploadPending, handleDeleteAttachmentsClick, renderCustomAttachment]);

  return (
    <Modal
      open={open}
      onClose={handleCloseClick}
      showCrossButton={false}
      className={cn('root')}
      paperClassName={cn('paper')}
      ignoreBackdropClick={uploadPending}
      ariaLabelledby={TITLE_ID}
      ariaDescribedby={DESCRIPTION_ID}
      {...restProps}
    >
      <Typography className={cn('title')} variant="body0" component="p" id={TITLE_ID}>
        {title}
      </Typography>

      {!hideDocumentTypeField && (
        <div className={cn('required-hint')}>
          <span className={cn('asterisk')}>*</span> {locale.required_hint}
        </div>
      )}

      {description && <div className={cn('description-container')}>{description}</div>}

      <div className={cn('body')}>
        {!hideDocumentTypeField && (
          <FieldRow size="small" label={locale.fields.document_type} required id={DOCUMENT_TYPE_SELECT_ID}>
            <SelectWithControl
              name="DocumentType"
              labelId={DOCUMENT_TYPE_SELECT_ID}
              options={DOCUMENT_TYPES}
              placeholder={locale.placeholder.document_type}
              control={control}
              fullWidth
              required
              disabled={uploadPending}
            />
          </FieldRow>
        )}
        {!hideMessageDescription && (
          <Typography className={cn('description')} variant="body2" component="p" id={DESCRIPTION_ID}>
            {locale.description}
            <Link to={PATHS.MESSAGES.EDIT}>{locale.send_message}</Link>
          </Typography>
        )}
        {!hideButtons && <div className={cn('buttons')}>{buttons}</div>}

        <input
          type="file"
          ref={fileRef}
          className={cn('hidden-attachments')}
          onChange={handleAttachmentsOnLoad}
          multiple={false}
          accept={attachmentsFormats.map((format) => `.${format}`).join(', ')}
        />

        {renderedAttachment}

        {!hideDescription && (
          <FieldRow size="small">
            <TextFieldWithControl
              id="add_new_document_notes"
              name="Notes"
              placeholder={locale.placeholder.notes}
              control={control}
              multiline
              rows="2"
              variant="outlined"
              fullWidth
              disabled={uploadPending}
              maxLength={100}
            />
          </FieldRow>
        )}

        {attachmentsError && (
          <Alert type="alert" variant="standard" bodyClassName={cn('alert')}>
            {attachmentsError}
          </Alert>
        )}

        <AttachmentInfo attachmentsFormats={attachmentsFormats} handleOpenPrivacyPolicy={handleOpenPrivacyPolicy} />
      </div>

      <div className={cn('buttons')}>
        <Button onClick={handleCloseClick} variant="outlined" className={cn('button')} disabled={uploadPending}>
          {locale.cancel}
        </Button>

        <Button
          onClick={onCustomSubmit ? handleSubmit(handleConfirmSelectedClick) : handleSubmit(handleConfirmClick)}
          variant="contained"
          className={cn('button')}
          disabled={onCustomSubmit ? customSubmitDisabled : !attachment}
          loading={uploadPending}
        >
          {locale.save}
        </Button>
      </div>
    </Modal>
  );
}
