import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { downloadSharedDocument } from './actions';
import { SharedDocumentsStoreType } from './types';

const initialState: SharedDocumentsStoreType = {
  status: StatusEnum.Uninitialized,
};

export const sharedDocumentsSlice = createSlice({
  name: 'sharedDocuments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadSharedDocument.pending, (state) => {
      state.status = StatusEnum.Pending;
    });

    builder.addCase(downloadSharedDocument.fulfilled, (state) => {
      state.status = StatusEnum.Fulfilled;
    });

    builder.addCase(downloadSharedDocument.rejected, (state) => {
      state.status = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const sharedDocumentsReducer = sharedDocumentsSlice.reducer;
