import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { fetchDevices } from './actions';
import { ConnectMyDeviceState } from './types';

const initialState: ConnectMyDeviceState = {
  status: StatusEnum.Uninitialized,
  error: {},
};

export const connectMyDeviceSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDevices.pending.type]: (state: ConnectMyDeviceState) => {
      state.status = StatusEnum.Pending;
    },
    [fetchDevices.fulfilled.type]: (state: ConnectMyDeviceState) => {
      state.status = StatusEnum.Fulfilled;
    },
    [fetchDevices.rejected.type]: (state: ConnectMyDeviceState) => {
      state.status = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const connectMyDeviceReducer = connectMyDeviceSlice.reducer;
