import { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { LineChartProps } from '@components/Charts';

import { WellnessTypesEnum } from '../../constants';
import { deleteExercise, setEditModalVisible, WellnessLogStateItemType } from '../../store';
import { ItemType } from '../../types';

type UseDashboardReturnType = {
  chartData: LineChartProps['data'];
  handleRecordButtonClick: VoidFunction;
  handleHistoryItemClick: (item: ItemType) => unknown;
  handleDeleteItemClick: (item: ItemType) => unknown;
};

type UseDashboardType = {
  summaryMonth?: WellnessLogStateItemType['summaryMonth'];
  summaryData?: WellnessLogStateItemType['summaryData'];
  type: WellnessTypesEnum;
};

export function useDashboard({ summaryMonth = [], summaryData = [], type }: UseDashboardType): UseDashboardReturnType {
  const dispatch = useDispatch();

  const chartData = useMemo(
    () => ({
      labels: summaryMonth,
      datasets: [{ data: summaryData }],
    }),
    [summaryMonth, summaryData],
  );

  const handleRecordButtonClick = () => {
    dispatch(setEditModalVisible({ type }));
  };

  const handleHistoryItemClick = (item: ItemType) => {
    dispatch(setEditModalVisible({ type, item }));
  };

  const handleDeleteItemClick = (item: ItemType) => {
    dispatch(deleteExercise({ id: item.id, type }));
  };

  return { chartData, handleRecordButtonClick, handleHistoryItemClick, handleDeleteItemClick };
}
