import { addToStorageArray, addToStorageObjectKeyValue, getLastStorageArrayItem } from '@common/utils/storage';

export const ACCESS_TOKEN_KEY = 'access_token';
export const WELLNESS_IDS_KEY = 'wellness_ids';

export interface ITokenService {
  getToken: () => string | null;
  getTokenByWellnessId: (wellnessId: string) => string | null;
  saveToken: (accessToken: string, wellnessId: string) => void;
  removeToken: () => void;
}

class TokenService implements ITokenService {
  /* eslint-disable class-methods-use-this */
  getTokenByWellnessId(wellnessId: string) {
    const stringifiedTokens = sessionStorage.getItem(ACCESS_TOKEN_KEY);
    const tokens = stringifiedTokens ? JSON.parse(stringifiedTokens) : {};

    return tokens[wellnessId];
  }

  getToken() {
    const lastWellnessId = getLastStorageArrayItem(WELLNESS_IDS_KEY);
    const stringifiedTokens = sessionStorage.getItem(ACCESS_TOKEN_KEY);
    const tokens = stringifiedTokens ? JSON.parse(stringifiedTokens) : {};

    return tokens[lastWellnessId];
  }

  saveToken(accessToken: string | null, wellnessId: string) {
    if (accessToken) {
      addToStorageObjectKeyValue(accessToken, wellnessId, ACCESS_TOKEN_KEY);
      addToStorageArray(wellnessId, WELLNESS_IDS_KEY);
    } else {
      this.removeToken();
    }
  }

  removeToken() {
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  }
  /* eslint-enable class-methods-use-this */
}

export const tokenService = new TokenService();
