import * as React from 'react';

import ToggleButtonGroupMaterial, {
  ToggleButtonGroupProps as ToggleButtonGroupPropsMaterial,
} from '@mui/material/ToggleButtonGroup';
import classNames from 'classnames/bind';

import styles from './ToggleButtonGroup.module.css';

const cn = classNames.bind(styles);

export type ToggleButtonGroupProps = {
  className?: string;
  ariaLabel?: string | undefined;
  toggleButtonGroupRootClass?: string;
  separatedButtons?: boolean;
} & Pick<
  ToggleButtonGroupPropsMaterial,
  'onChange' | 'children' | 'classes' | 'value' | 'orientation' | 'size' | 'color' | 'exclusive' | 'role'
>;

export function ToggleButtonGroup({
  className,
  children,
  value,
  orientation,
  size,
  color,
  exclusive,
  onChange,
  ariaLabel,
  toggleButtonGroupRootClass,
  separatedButtons,
  role,
  ...restProps
}: ToggleButtonGroupProps) {
  return (
    <ToggleButtonGroupMaterial
      {...restProps}
      className={cn(className, 'toggle-button-group', { 'toggle-button-group--separated': separatedButtons })}
      onChange={onChange}
      value={value}
      orientation={orientation}
      size={size}
      color={color}
      exclusive={exclusive}
      aria-label={ariaLabel}
      role={role}
      classes={{
        root: toggleButtonGroupRootClass,
      }}
    >
      {children}
    </ToggleButtonGroupMaterial>
  );
}
