export const locale = {
  save: 'Upload',
  cancel: 'Cancel',
  add_file: 'Add File',
  replace_file: 'Replace File',
  title: 'Add Document',
  attachment_required: 'Attachment is required',
  upload_error: 'File upload error. Please try again.',
  required_hint: 'Required field',
  fields: {
    document_type: 'Document Type',
    notes: 'Notes',
  },
  placeholder: {
    document_type: 'Please select a document type',
    notes: 'Description',
  },
  documentTypes: {
    COVID: 'COVID-19 Vaccination',
    other: 'Other',
  },
  exceeds: 'File exceeds 10MB size limit.',
  description:
    'Your uploaded document will be saved to your Documents. If you need to send a document to a provider or health center, please ',
  send_message: 'send a message.',
};
