import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { WellnessTypesEnum, WellnessAdditionalTypesEnum, MAP_SOURCE_TYPES } from '../constants';
import { ExerciseTypesItemType } from '../types';
import { deleteExercise, getExerciseTypes, getList, saveLogItem } from './actions';
import {
  DeleteExerciseMetaType,
  ErrorType,
  ExerciseMetaType,
  ExerciseResponseType,
  ExerciseTypesResponseType,
  HideEditModalPayloadType,
  ResetSaveFormStatusPayloadType,
  SaveExerciseMetaType,
  SaveResponseType,
  SetEditModalVisiblePayloadType,
  SetPagePayloadType,
  WellnessLogStateItemType,
} from './types';

export interface WellnessLogState {
  [WellnessTypesEnum.Fitness]: WellnessLogStateItemType;
  [WellnessTypesEnum.Sleep]: WellnessLogStateItemType;
  [WellnessTypesEnum.Steps]: WellnessLogStateItemType;
  [WellnessTypesEnum.Water]: WellnessLogStateItemType;
  [WellnessTypesEnum.Weight]: WellnessLogStateItemType;
  [WellnessAdditionalTypesEnum.FitnessAndSteps]: WellnessLogStateItemType;
  exerciseTypes: ExerciseTypesItemType[];
  exerciseTypesStatus: StatusEnum;
}

const INITIAL_DATA = {
  summaryMonth: [],
  summaryMinutes: [],
  summaryData: [],
  items: [],
  saveFormStatus: StatusEnum.Uninitialized,
  removeStatus: StatusEnum.Uninitialized,
  requestLogStatus: StatusEnum.Uninitialized,
  editModalVisible: false,
  selectedItem: null,
  currentPage: 1,
};

const initialState: WellnessLogState = {
  [WellnessTypesEnum.Fitness]: INITIAL_DATA,
  [WellnessTypesEnum.Sleep]: INITIAL_DATA,
  [WellnessTypesEnum.Steps]: INITIAL_DATA,
  [WellnessTypesEnum.Water]: INITIAL_DATA,
  [WellnessTypesEnum.Weight]: INITIAL_DATA,
  [WellnessAdditionalTypesEnum.FitnessAndSteps]: INITIAL_DATA,
  exerciseTypes: [],
  exerciseTypesStatus: StatusEnum.Uninitialized,
};

export const wellnessLogSlice = createSlice({
  name: 'wellnessLog',
  initialState,
  reducers: {
    setEditModalVisible(state: WellnessLogState, action: PayloadAction<SetEditModalVisiblePayloadType>) {
      state[action.payload.type].editModalVisible = true;
      state[action.payload.type].selectedItem = action.payload.item;
      state[action.payload.type].saveFormStatus = StatusEnum.Uninitialized;
    },

    hideEditModal(state: WellnessLogState, action: PayloadAction<HideEditModalPayloadType>) {
      state[action.payload.type].editModalVisible = false;
      state[action.payload.type].selectedItem = null;
      state[action.payload.type].saveFormStatus = StatusEnum.Uninitialized;
    },

    resetSaveFormStatus(state: WellnessLogState, action: PayloadAction<ResetSaveFormStatusPayloadType>) {
      state[action.payload.type].saveFormStatus = StatusEnum.Uninitialized;
    },

    setPage(state: WellnessLogState, action: PayloadAction<SetPagePayloadType>) {
      state[action.payload.type].currentPage = action.payload.page;
    },
  },
  extraReducers: {
    [getList.pending.type]: (
      state: WellnessLogState,
      action: PayloadAction<ExerciseResponseType, string, ExerciseMetaType>,
    ) => {
      const type = action.meta.arg;
      state[type] = {
        ...state[type],
        requestLogStatus: StatusEnum.Pending,
      };
    },
    [getList.fulfilled.type]: (
      state: WellnessLogState,
      action: PayloadAction<ExerciseResponseType, string, ExerciseMetaType>,
    ) => {
      const type = action.meta.arg;
      state[type] = {
        ...state[type],
        summaryMinutes: action.payload.summary_minutes || [],
        summaryData: action.payload.summary_data || [],
        summaryMonth: action.payload.summary_months,
        items: action.payload.items.map((item) => ({
          ...item,
          source: (MAP_SOURCE_TYPES as Record<string, string | undefined>)[item.source] || item.source,
        })),
        requestLogStatus: StatusEnum.Fulfilled,
      };
    },
    [getList.rejected.type]: (
      state: WellnessLogState,
      action: PayloadAction<ExerciseResponseType, string, ExerciseMetaType>,
    ) => {
      const type = action.meta.arg;
      state[type] = {
        ...state[type],
        requestLogStatus: StatusEnum.Rejected,
      };
    },

    [getExerciseTypes.pending.type]: (state: WellnessLogState) => {
      state.exerciseTypes = [];
      state.exerciseTypesStatus = StatusEnum.Pending;
    },
    [getExerciseTypes.fulfilled.type]: (state: WellnessLogState, action: PayloadAction<ExerciseTypesResponseType>) => {
      state.exerciseTypes = action.payload;
      state.exerciseTypesStatus = StatusEnum.Fulfilled;
    },
    [getExerciseTypes.rejected.type]: (state: WellnessLogState) => {
      state.exerciseTypes = [];
      state.exerciseTypesStatus = StatusEnum.Rejected;
    },

    [saveLogItem.pending.type]: (
      state: WellnessLogState,
      action: PayloadAction<SaveResponseType, string, SaveExerciseMetaType>,
    ) => {
      const { type, additionalType } = action.meta.arg;
      state[type] = {
        ...state[type],
        saveFormStatus: StatusEnum.Pending,
      };

      if (additionalType) {
        state[additionalType] = {
          ...state[additionalType],
          selectedItem: null,
          saveFormStatus: StatusEnum.Pending,
        };
      }
    },
    [saveLogItem.fulfilled.type]: (
      state: WellnessLogState,
      action: PayloadAction<SaveResponseType, string, SaveExerciseMetaType>,
    ) => {
      const { type, additionalType, recordAnotherEntry } = action.meta.arg;
      state[type] = {
        ...state[type],
        editModalVisible: recordAnotherEntry,
        selectedItem: null,
        saveFormStatus: StatusEnum.Fulfilled,
      };
      if (additionalType) {
        state[additionalType] = {
          ...state[additionalType],
          editModalVisible: recordAnotherEntry,
          selectedItem: null,
          saveFormStatus: StatusEnum.Fulfilled,
        };
      }
    },
    [saveLogItem.rejected.type]: (
      state: WellnessLogState,
      action: PayloadAction<ErrorType, string, SaveExerciseMetaType>,
    ) => {
      const { type, additionalType } = action.meta.arg;
      state[type] = {
        ...state[type],
        saveFormStatus: StatusEnum.Rejected,
      };
      if (additionalType) {
        state[additionalType] = {
          ...state[additionalType],
          selectedItem: null,
          saveFormStatus: StatusEnum.Rejected,
        };
      }
    },

    [deleteExercise.pending.type]: (
      state: WellnessLogState,
      payload: PayloadAction<unknown, string, DeleteExerciseMetaType>,
    ) => {
      const { type } = payload.meta.arg;

      state[type] = {
        ...state[type],
        removeStatus: StatusEnum.Pending,
      };
    },
    [deleteExercise.fulfilled.type]: (
      state: WellnessLogState,
      payload: PayloadAction<unknown, string, DeleteExerciseMetaType>,
    ) => {
      const { type } = payload.meta.arg;

      state[type] = {
        ...state[type],
        removeStatus: StatusEnum.Fulfilled,
      };
    },
    [deleteExercise.rejected.type]: (
      state: WellnessLogState,
      payload: PayloadAction<unknown, string, DeleteExerciseMetaType>,
    ) => {
      const { type } = payload.meta.arg;
      state[type] = {
        ...state[type],
        removeStatus: StatusEnum.Rejected,
      };
    },

    ...resetStoreActions(() => initialState),
  },
});

export const { setEditModalVisible, hideEditModal, resetSaveFormStatus, setPage } = wellnessLogSlice.actions;
export const WellnessLogReducer = wellnessLogSlice.reducer;
