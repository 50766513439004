import {
  faEnvelope as faEnvelopeDuo,
  faCalendar as faCalendarDuo,
  faCalendarXmark as faCalendarXmarkDuo,
  faCalendarDay as faCalendarDayDuo,
  faCalendarCheck as faCalendarCheckDuo,
  faCircleUser as faCircleUseDuo,
  faLaptopMobile as faLaptopMobileDuo,
  faMobileScreen as faMobileScreenDuo,
  faInbox as faInboxDuo,
  faPrescriptionBottleMedical as faPrescriptionBottleMedicalDuo,
  faPrescriptionBottlePill as faPrescriptionBottlePillDuo,
  faClipboardMedical as faClipboardMedicalDuo,
  faPrescriptionBottle as faPrescriptionBottleDuo,
  faTrophyStar as faTrophyStarDuo,
  faStars as faStarsDuo,
  faWeightScale as faWeightScaleDuo,
  faBed as faBedDuo,
  faBedFront as faBedFrontDuo,
  faGlassWaterDroplet as faGlassWaterDropletDuo,
  faPersonWalking as faPersonWalkingDuo,
  faPersonRunningFast as faPersonRunningFastDuo,
  faWatchFitness as faWatchFitnessDuo,
  faPersonRunning as faPersonRunningDuo,
  faShoePrints as faShoePrintsDuo,
  faLockKeyhole as faLockKeyholeDuo,
  faUnlockKeyhole as faUnlockKeyholeDuo,
  faLoader as faLoaderDuo,
  faBadgeCheck as faBadgeCheckDuo,
  faBookHeart as faBookHeartDuo,
  faUserMagnifyingGlass as faUserMagnifyingGlassDuo,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faCircleArrowUp,
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowDownLeft,
  faCircleArrowDownRight,
  faCircleArrowUpLeft,
  faCircleArrowUpRight,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronsUp,
  faChevronsDown,
  faChevronsLeft,
  faChevronsRight,
  faAnglesUpDown,
  faArrowRightFromBracket,
  faArrowUpRightFromSquare,
  faArrowDownToLine,
  faTriangleExclamation,
  faSquareExclamation,
  faOctagonExclamation,
  faDiamondExclamation,
  faCircleExclamation,
  faBellExclamation,
  faPhone,
  faUserLarge,
  faFile,
  faMagnifyingGlass,
  faEyeSlash,
  faEye,
  faClockThree,
  faXmarkLarge,
  faXmark,
  faCircleInfo,
  faVideo,
  faSlider,
  faSliders,
  faPen,
  faCheck,
  faHouse,
  faUserGroup,
  faCircleUser,
  faHouseBlank,
  faStar,
  faTrashCan,
  faPrint,
  faLock,
  faLockOpen,
  faUserSecret,
  faCircleQuestion,
  faCircleCheck,
  faCirclePlus,
  faLocationDot,
  faPaperclip,
  faInbox,
  faHeart,
  faLaptopMobile,
  faBuilding,
  faHouseBuilding,
  faBuildingUser,
  faChartLine,
  faGear,
  faBars,
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faCalendarWeek,
  faCalendarRange,
  faCalendarCheck,
  faCalendarXmark,
  faCalendarExclamation,
  faCalendarArrowUp,
  faCalendarArrowDown,
  faCalendarPlus,
  faCalendarMinus,
  faCalendarPen,
  faCalendarStar,
  faCalendarHeart,
  faCalendarImage,
  faCalendarLines,
  faCalendarCircleExclamation,
  faCalendarClock,
  faCalendarCircleMinus,
  faCalendarCirclePlus,
  faCalendarCircleUser,
  faCalendarLinesPen,
  faCalendars,
  faCircleCalendar,
  faCalendarUsers,
  faClipboardMedical,
  faPrescriptionBottle,
  faNoteMedical,
  faStethoscope,
  faHouseMedical,
  faLaptopMedical,
  faEnvelope,
  faEnvelopeDot,
  faEnvelopeOpen,
  faMessages,
  faEnvelopeCircleCheck,
  faTrophy,
  faBullseyeArrow,
  faWatchFitness,
  faPersonRunning,
  faShoePrints,
  faRankingStar,
  faSquare,
  faArrowRotateRight,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretUp,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheck as faCheckSolid,
  faStar as faStarSolid,
  faLocationDot as faLocationDotSolid,
  faSquareCheck,
  faSquareMinus,
} from '@fortawesome/pro-solid-svg-icons';

export const ICONS = {
  faLocationDotSolid,
  faArrowRotateRight,
  faSquare,
  faSquareCheck,
  faSquareMinus,
  faEnvelopeDuo,
  faBookHeartDuo,
  faLockKeyholeDuo,
  faUnlockKeyholeDuo,
  faLoaderDuo,
  faBadgeCheckDuo,
  faCalendarDuo,
  faCalendarXmarkDuo,
  faCalendarDayDuo,
  faCalendarCheckDuo,
  faCircleUseDuo,
  faLaptopMobileDuo,
  faMobileScreenDuo,
  faInboxDuo,
  faPrescriptionBottleMedicalDuo,
  faPrescriptionBottlePillDuo,
  faClipboardMedicalDuo,
  faPrescriptionBottleDuo,
  faTrophyStarDuo,
  faStarsDuo,
  faWeightScaleDuo,
  faBedDuo,
  faBedFrontDuo,
  faGlassWaterDropletDuo,
  faPersonWalkingDuo,
  faPersonRunningFastDuo,
  faWatchFitnessDuo,
  faPersonRunningDuo,
  faShoePrintsDuo,
  faCaretUp,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheckSolid,
  faStarSolid,
  faCircleArrowUp,
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowDownLeft,
  faCircleArrowDownRight,
  faCircleArrowUpLeft,
  faCircleArrowUpRight,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronsUp,
  faChevronsDown,
  faChevronsLeft,
  faChevronsRight,
  faAnglesUpDown,
  faArrowRightFromBracket,
  faArrowUpRightFromSquare,
  faArrowDownToLine,
  faTriangleExclamation,
  faSquareExclamation,
  faOctagonExclamation,
  faDiamondExclamation,
  faCircleExclamation,
  faBellExclamation,
  faPhone,
  faUserLarge,
  faFile,
  faMagnifyingGlass,
  faEyeSlash,
  faEye,
  faClockThree,
  faXmarkLarge,
  faXmark,
  faCircleInfo,
  faVideo,
  faSlider,
  faSliders,
  faPen,
  faCheck,
  faHouse,
  faUserGroup,
  faCircleUser,
  faHouseBlank,
  faStar,
  faTrashCan,
  faPrint,
  faLock,
  faLockOpen,
  faUserSecret,
  faCircleQuestion,
  faCircleCheck,
  faCirclePlus,
  faLocationDot,
  faPaperclip,
  faInbox,
  faHeart,
  faLaptopMobile,
  faBuilding,
  faHouseBuilding,
  faBuildingUser,
  faChartLine,
  faGear,
  faBars,
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faCalendarWeek,
  faCalendarRange,
  faCalendarCheck,
  faCalendarXmark,
  faCalendarExclamation,
  faCalendarArrowUp,
  faCalendarArrowDown,
  faCalendarPlus,
  faCalendarMinus,
  faCalendarPen,
  faCalendarStar,
  faCalendarHeart,
  faCalendarImage,
  faCalendarLines,
  faCalendarCircleExclamation,
  faCalendarClock,
  faCalendarCircleMinus,
  faCalendarCirclePlus,
  faCalendarCircleUser,
  faCalendarLinesPen,
  faCalendars,
  faCircleCalendar,
  faCalendarUsers,
  faClipboardMedical,
  faPrescriptionBottle,
  faNoteMedical,
  faStethoscope,
  faHouseMedical,
  faLaptopMedical,
  faEnvelope,
  faEnvelopeDot,
  faEnvelopeOpen,
  faMessages,
  faEnvelopeCircleCheck,
  faTrophy,
  faBullseyeArrow,
  faWatchFitness,
  faPersonRunning,
  faShoePrints,
  faRankingStar,
  faUserMagnifyingGlassDuo,
};
