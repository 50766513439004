import React, { useEffect, useRef, useState } from 'react';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { DEFAULT_OPTIONS, numberFormatter } from '@common/utils/numberFormatter';
import { FieldRow } from '@components/FieldRow';
import { NumberFieldWithControl, useForm } from '@components/Form';
import { Grid } from '@components/Grid';
import { RecordActivityForm } from '@components/RecordActivityForm';
import { InputAdornment } from '@components/TextField';

import { GRID_ROW_SPACING, GRID_SPACING, WellnessTypesEnum } from '../../../constants';
import { WaterItemType } from '../../../types';
import { useResetOnSuccess } from '../../../useResetFormOnSuccess';
import { DateTimePicker } from '../../DateTimePicker';
import { FormValuesType } from '../types';
import { FIELD_NAMES } from './constants';
import { locale } from './locale';

type FormProps = {
  onClose: VoidFunction;
  onSave: (formValues: FormValuesType, recordAnotherEntry: boolean) => unknown;
  initialValues?: WaterItemType | null;
  saveFormStatus: StatusEnum;
};

const getDefaultInitialValues = () => ({
  id: 0,
  date: new Date(),
  time: new Date(),
  ounces: 0,
});

const makeInitialValues = (initialValues: WaterItemType) => ({
  id: initialValues.id,
  date: new Date(initialValues.recorded_at),
  time: new Date(initialValues.recorded_at),
  ounces: initialValues.ounces,
});

const waterFormatter = numberFormatter({ ...DEFAULT_OPTIONS, precision: 0, min: 1, max: 999 });

export const WaterForm = ({ initialValues, onClose, onSave, saveFormStatus }: FormProps) => {
  const { handleSubmit, control, reset } = useForm<FormValuesType>({
    defaultValues: initialValues ? makeInitialValues(initialValues) : getDefaultInitialValues(),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    firstInputRef?.current?.focus();
  });

  useIgnoreEffectDeps(() => {
    if (initialValues === null) reset();
  }, [initialValues]);

  useResetOnSuccess(saveFormStatus, reset, getDefaultInitialValues(), WellnessTypesEnum.Water);

  const [edit] = useState(initialValues?.can_edit_or_delete);
  const isLoading = saveFormStatus === StatusEnum.Pending;

  return (
    <RecordActivityForm
      cancelText={locale.cancel}
      headerText={locale.header}
      isLoading={isLoading}
      onClose={onClose}
      onSubmitRepeatForm={!edit ? handleSubmit((formValues) => onSave(formValues, true)) : undefined}
      onSubmit={handleSubmit((formValues) => onSave(formValues, false))}
      repeatFormText={locale.record_another_entry}
      submitText={locale.submit}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DateTimePicker<FormValuesType>
            control={control}
            dateLabel={locale.field_labels.date}
            isLoading={isLoading}
            timeLabel={locale.field_labels.time}
            inputRef={firstInputRef}
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={GRID_ROW_SPACING} columnSpacing={GRID_SPACING}>
        <Grid item xs={12} md={6}>
          <FieldRow size="small">
            <NumberFieldWithControl
              name={FIELD_NAMES.WATER}
              control={control}
              fullWidth
              label={locale.field_labels.water}
              min={{ value: 1, message: locale.errors.water }}
              max={999}
              required={locale.errors.water}
              InputProps={{
                endAdornment: <InputAdornment position="end">{locale.adornments.water}</InputAdornment>,
              }}
              formatter={waterFormatter}
              id="water_form_water"
            />
          </FieldRow>
        </Grid>

        <Grid item xs={12} md={6} />
      </Grid>
    </RecordActivityForm>
  );
};
