import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';

import { getTimezones, getTimezonesStatusSelector } from '../store';

export function useFetchTimezones() {
  const status = useSelector(getTimezonesStatusSelector);
  const dispatch = useDispatch();

  useIgnoreEffectDeps(() => {
    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(status)) {
      dispatch(getTimezones());
    }
  }, []);
}
