import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

import { createLinkToOpen } from '../util';

export const fetchDevices = createAsyncThunk('connectmyDevice/fetchDevices', async (_, { rejectWithValue }) => {
  try {
    const data = await fetch.get({
      url: '/WellnessLog/provision-user',
    });

    const res = await data.response;

    createLinkToOpen(res?.data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
