import { createStructuredSelector } from 'reselect';

const getDefaultClinicPhoneSelector = (state: RootState) => state.defaultClinicPhone.defaultClinicPhone;
export const getDefaultClinicPhoneStatusSelector = (state: RootState) =>
  state.defaultClinicPhone.defaultClinicPhoneStatus;
const showDefaultClinicPhoneModalSelector = (state: RootState) => state.defaultClinicPhone.showDefaultClinicPhoneModal;
const getAnywhereNumberSelector = (state: RootState) => state.defaultClinicPhone.anywhereNumber;
const getDeafultClinicPhoneNameSelector = (state: RootState) => state.defaultClinicPhone.defaultClinicName;

export const defaultClinicPhoneSelector = createStructuredSelector({
  status: getDefaultClinicPhoneStatusSelector,
  show: showDefaultClinicPhoneModalSelector,
  phone: getDefaultClinicPhoneSelector,
  name: getDeafultClinicPhoneNameSelector,
  anywhereNumber: getAnywhereNumberSelector,
});
