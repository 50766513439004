import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { enqueueSnackbar } from '@modules/Snackbar';

import { locale } from '../locale';
import { InviteMemberPayload } from './types';

export const inviteMember = createAsyncThunk<unknown, InviteMemberPayload>(
  'inviteMember/invite',
  async ({ email, userId }, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.post({
        url: '/User/invite-family-member-by-email',
        data: { user_id: userId, email },
      });
      const res = await data.response;
      dispatch(
        enqueueSnackbar({
          message: locale.success,
          options: {
            variant: 'success',
          },
        }),
      );

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
