import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

import { MAIN_MENU_ID } from '@common/constants/viewIds';
import { useIgnoreEffectDeps } from '@common/hooks';
import { getInitialTabByPath, getMenuId, getTabById, isTouchDevice, TabItemProp } from '@common/utils';
import { AppBar } from '@components/AppBar';
import { SvgIcon } from '@components/SvgIcon';
import { NextTab, Tabs } from '@components/Tabs';
import { gtmEvent, GtmEventsEnum } from '@modules/gtm';

import { SubMenu } from './components';
import { useTabSelection } from './hooks';
import { TabProps } from './horizontal-menu-types';

import styles from './HorizontalMenu.module.css';

const cn = classNames.bind(styles);

export interface HorizontalMenuProps {
  menuItemList: Array<TabProps>;
  className?: string;
}

export function HorizontalMenu({ menuItemList, className }: HorizontalMenuProps) {
  const location = useLocation();

  const [currentTabId, setCurrentTabId] = useState(() =>
    getInitialTabByPath(location.pathname, menuItemList as TabItemProp[]),
  );

  useIgnoreEffectDeps(() => {
    const tabId = getInitialTabByPath(location.pathname, menuItemList as TabItemProp[]);

    if (tabId !== currentTabId) {
      setCurrentTabId(getInitialTabByPath(location.pathname, menuItemList as TabItemProp[]));
    }
  }, [location.pathname]);

  const { closeMenu, handleTabSelection, selectedTabId, menuAnchorEl } = useTabSelection();

  const handleTabChange = useCallback(
    (_: unknown, newId?: string | number) => {
      const tabIndex = getTabById(newId, menuItemList as TabItemProp[]);
      const tab = menuItemList[tabIndex];

      if (tab.to && !tab.subMenuItems && !isTouchDevice()) {
        closeMenu();
      }
    },
    [menuItemList, closeMenu],
  );

  const handleSubMenuCloseByKeyboard = useCallback((anchorElement: HTMLElement | null) => {
    setTimeout(() => {
      anchorElement?.focus();
    });
  }, []);

  const menuItemsWithSubMenu = useMemo(
    () => menuItemList.filter((menuItem) => menuItem.subMenuItems?.length),
    [menuItemList],
  );

  const handleTabClick = (tab: TabProps) => (event: React.MouseEvent<HTMLElement>) => {
    if (tab.gtmType) gtmEvent(GtmEventsEnum.click, { type: tab.gtmType });
    handleTabSelection(event, tab.id);
  };

  return (
    <AppBar className={cn('horizontal-menu', className)} component="nav" ariaLabel="primary">
      <Tabs onChange={handleTabChange} value={currentTabId} id={MAIN_MENU_ID}>
        {menuItemList.map((tab) => {
          const hasSubMenu = !!tab.subMenuItems?.length;
          const selected = tab.id === currentTabId;
          const hovered = tab.id === selectedTabId;

          return (
            <NextTab
              key={tab.id}
              value={tab.id}
              to={tab.to}
              aria-haspopup={hasSubMenu ? 'menu' : undefined}
              aria-controls={hasSubMenu && selectedTabId === tab.id ? getMenuId(tab.id) : undefined}
              aria-expanded={hasSubMenu ? selectedTabId === tab.id : undefined}
              label={
                <div
                  className={cn('horizontal-menu__label-wrapper', {
                    'horizontal-menu__label-wrapper--has-submenu': hasSubMenu,
                  })}
                >
                  <span className={cn('horizontal-menu__label-text')}>{tab.label}</span>
                  {hasSubMenu && <SvgIcon className={cn('horizontal-menu__label-submenu-icon')} icon="chevronDown" />}
                </div>
              }
              onClick={tab.subMenuItems ? handleTabClick(tab) : undefined}
              className={cn('horizontal-menu__tab', {
                'horizontal-menu__tab--selected': selected,
                'horizontal-menu__tab--hovered': hovered,
              })}
              focusVisibleClassName={cn('horizontal-menu__tab--focus-visible')}
              asRouterLink={!hasSubMenu}
            />
          );
        })}
      </Tabs>
      {menuItemsWithSubMenu.map((tab) => (
        <SubMenu
          key={tab.id}
          tab={tab}
          open={selectedTabId === tab.id}
          closeMenu={closeMenu}
          menuAnchorEl={menuAnchorEl}
          onCloseByKeyboard={handleSubMenuCloseByKeyboard}
        />
      ))}
    </AppBar>
  );
}
