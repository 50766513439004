import { useContext, useRef, useCallback } from 'react';

import { Navigator, Location } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';

export interface Transition {
  location: Location;
  retry(): void;
}

export interface Blocker {
  (tx: Transition, unblock: VoidFunction): void;
}

type ExtendNavigator = Navigator & {
  block: (onBlock: Blocker) => () => void;
};

export const useBlocker = (onBlock: Blocker, historyBlocked = true) => {
  const { navigator } = useContext(NavigationContext);
  const navigateDestination = useRef<Transition | undefined>();
  const unblockNavigationRef = useRef<VoidFunction | undefined>();

  const unblock = useCallback(() => {
    unblockNavigationRef.current?.();
  }, []);

  const retry = useCallback(() => {
    unblockNavigationRef.current?.();
    navigateDestination.current?.retry();
  }, []);

  const block = useCallback(() => {
    unblockNavigationRef.current = (navigator as ExtendNavigator).block((transition) => {
      navigateDestination.current = transition;
      onBlock(transition, unblock);
    });
  }, [onBlock, navigator, unblock]);

  useIgnoreEffectDeps(() => {
    if (historyBlocked) {
      block();
    } else {
      unblock();
    }
  }, [historyBlocked]);

  return { unblock, retry };
};
