import { ActionReducerMapBuilder, AsyncThunk, Draft } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';

import { ParamsWithDependentUserId } from '../../types';
import { RequestWithUserId } from './types';

type Params<State, Payload, Args extends ParamsWithDependentUserId | undefined> = {
  builder: ActionReducerMapBuilder<State>;
  asyncThunk: AsyncThunk<Payload, Args, object>;
  updateState: (state: Draft<State>, updates: Partial<RequestWithUserId<Payload>>) => unknown;
  getUserId: (state: Draft<State>) => number | null;
};

export const addUserSpecificBuilderCases = <State, Payload, Args extends ParamsWithDependentUserId | undefined>({
  builder,
  asyncThunk,
  updateState,
  getUserId,
}: Params<State, Payload, Args>) => {
  builder.addCase(asyncThunk.pending, (state, action) => {
    updateState(state, {
      status: StatusEnum.Pending,
      userId: action.meta.arg?.dependentUserId ?? null,
    });
  });
  builder.addCase(asyncThunk.fulfilled, (state, action) => {
    if (action.meta.arg?.dependentUserId === getUserId(state)) {
      updateState(state, {
        status: StatusEnum.Fulfilled,
        data: action.payload,
      });
    }
  });
  builder.addCase(asyncThunk.rejected, (state, action) => {
    if (action.meta.arg?.dependentUserId === getUserId(state)) {
      updateState(state, {
        status: StatusEnum.Rejected,
      });
    }
  });
};
