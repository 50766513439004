import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { getStates, getLanguages, getTimezones, getEligibility } from './actions';
import { StaticListState, StateType, LanguagesType, TimezoneType, Eligibility } from './types';

const initialState: StaticListState = {
  states: [],
  statesStatus: StatusEnum.Uninitialized,

  languages: [],
  languagesStatus: StatusEnum.Uninitialized,

  timezones: [],
  timezonesStatus: StatusEnum.Uninitialized,

  eligibility: null,
  eligibilityStatus: StatusEnum.Uninitialized,
};

export const staticListSlice = createSlice({
  name: 'staticList',
  initialState,
  reducers: {},
  extraReducers: {
    [getStates.pending.type]: (state: StaticListState) => {
      state.statesStatus = StatusEnum.Pending;
    },
    [getStates.fulfilled.type]: (state: StaticListState, action: PayloadAction<StateType[]>) => {
      state.statesStatus = StatusEnum.Fulfilled;
      state.states = action.payload;
    },
    [getStates.rejected.type]: (state: StaticListState) => {
      state.statesStatus = StatusEnum.Rejected;
    },

    [getLanguages.pending.type]: (state: StaticListState) => {
      state.languagesStatus = StatusEnum.Pending;
    },
    [getLanguages.fulfilled.type]: (state: StaticListState, action: PayloadAction<LanguagesType>) => {
      state.languagesStatus = StatusEnum.Fulfilled;
      state.languages = action.payload;
    },
    [getLanguages.rejected.type]: (state: StaticListState) => {
      state.languagesStatus = StatusEnum.Rejected;
    },

    [getTimezones.pending.type]: (state: StaticListState) => {
      state.timezonesStatus = StatusEnum.Pending;
    },
    [getTimezones.fulfilled.type]: (state: StaticListState, action: PayloadAction<TimezoneType[]>) => {
      state.timezonesStatus = StatusEnum.Fulfilled;
      state.timezones = action.payload;
    },
    [getTimezones.rejected.type]: (state: StaticListState) => {
      state.timezonesStatus = StatusEnum.Rejected;
    },
    [getEligibility.pending.type]: (state: StaticListState) => {
      state.eligibilityStatus = StatusEnum.Pending;
    },
    [getEligibility.fulfilled.type]: (state: StaticListState, action: PayloadAction<Eligibility>) => {
      state.eligibilityStatus = StatusEnum.Fulfilled;
      state.eligibility = action.payload;
    },
    [getEligibility.rejected.type]: (state: StaticListState) => {
      state.eligibilityStatus = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const staticListReducer = staticListSlice.reducer;
