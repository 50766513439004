import { HideFlippersEnum } from './constants';
import { FlipperType } from './types';

export const isHideFlipperKey = (flipper: FlipperType) => !!(HideFlippersEnum as UnknownObject<string>)[flipper];

// If it's HideFlippersEnum we return value as is
// If it's ShowFlippersEnum we return value vice versa
export const isHideElementByFlipperValue = (flipperValue: boolean, flipper: FlipperType): boolean => {
  if (isHideFlipperKey(flipper)) {
    return flipperValue;
  }

  return !flipperValue;
};
