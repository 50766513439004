import React from 'react';

import { Skeleton, SkeletonProps } from '@components/Skeleton';

export type ChartSkeletonProps = {
  className?: string;
} & Pick<SkeletonProps, 'width' | 'height'>;

export function ChartSkeleton({ width = '100%', height = '20rem', className }: ChartSkeletonProps) {
  return <Skeleton width={width} height={height} className={className} variant="rectangular" />;
}
