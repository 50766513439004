import React from 'react';

import MenuMaterial, { MenuProps as MenuPropsMaterial } from '@mui/material/Menu';
import { MenuListProps as MenuListPropsMaterial } from '@mui/material/MenuList';
import classNames from 'classnames/bind';

import styles from './Menu.module.css';

export type MenuProps = {
  className?: string;
} & Pick<
  MenuPropsMaterial,
  | 'children'
  | 'anchorEl'
  | 'open'
  | 'onClose'
  | 'onBlur'
  | 'id'
  | 'MenuListProps'
  | 'PopoverClasses'
  | 'PaperProps'
  | 'anchorOrigin'
  | 'transformOrigin'
  | 'classes'
  | 'container'
  | 'aria-labelledby'
>;

const cn = classNames.bind(styles);

export function Menu({
  anchorEl,
  children,
  className,
  id,
  MenuListProps,
  onClose,
  open = false,
  PopoverClasses,
  PaperProps,
  container,
  transformOrigin = { horizontal: 'left', vertical: 'top' },
  anchorOrigin = { horizontal: 'left', vertical: 'bottom' },
  ...rest
}: MenuProps) {
  return (
    <MenuMaterial
      {...rest}
      anchorEl={anchorEl}
      className={cn('menu', className)}
      id={id}
      PaperProps={PaperProps}
      // replace default 'ul' component to be able to use something different from 'li' as list items
      MenuListProps={{ component: 'div', ...MenuListProps } as MenuListPropsMaterial}
      onClose={onClose}
      open={open}
      PopoverClasses={{
        paper: cn('menu__popover'),
        ...PopoverClasses,
      }}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
      container={container}
    >
      {children}
    </MenuMaterial>
  );
}
