import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getProvider = createAsyncThunk(
  'Appointment/provider',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: `/Appointment/provider-bio-details/${id}`,
        skipAlert: true,
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
