import React, { forwardRef, Ref } from 'react';

import TypographyMaterial, { TypographyProps as TypographyPropsMaterial } from '@mui/material/Typography';
import classNames from 'classnames/bind';

import styles from './Typography.module.css';

const cn = classNames.bind(styles);

const VARIANT_MAPPING = {
  'internal-error': 'h1',
  hxl: 'h1',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body0: 'p',
  body1: 'p',
  'body1-bold': 'p',
  'body1-underline': 'p',
  body2: 'p',
  'body2-bold': 'p',
  'body2-underline': 'p',
  inherit: 'p',
  avatar: 'p',
  helper: 'p',
};

export type TypographyProps = {
  className?: string;
  ariaLabel?: string;
  component?: 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'legend';
} & Pick<TypographyPropsMaterial, 'children' | 'align' | 'variant' | 'id' | 'tabIndex' | 'title'>;

type Props = TypographyProps & {
  forwardedRef: Ref<HTMLParagraphElement>;
};

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    hxl: true;
    body0: true;
    'body1-bold': true;
    'body1-underline': true;
    'body2-bold': true;
    'body2-underline': true;
    helper: true;
    avatar: true;
    'internal-error': true;
  }
}

export function TypographyComponent({
  children,
  className,
  variant,
  align,
  component,
  id,
  forwardedRef,
  tabIndex,
  title,
  ariaLabel,
}: Props) {
  return (
    <TypographyMaterial
      className={cn('typography', className, {
        [`typography--${variant}`]: !!variant,
      })}
      variantMapping={VARIANT_MAPPING}
      ref={forwardedRef}
      aria-label={ariaLabel}
      {...{ align, variant, component, id, tabIndex, title }}
    >
      {children}
    </TypographyMaterial>
  );
}

export const Typography = forwardRef<HTMLParagraphElement, TypographyProps>((props, ref) => (
  <TypographyComponent {...props} forwardedRef={ref} />
));

Typography.displayName = 'Typography';
