import React, { forwardRef } from 'react';

import classNames from 'classnames/bind';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';

import styles from './Markdown.module.css';

const cn = classNames.bind(styles);

type MarkdownProps = {
  markdown: string;
  className?: string;
};

export const Markdown = forwardRef<HTMLDivElement, MarkdownProps>(({ markdown, className }, ref) => (
  <div ref={ref}>
    <ReactMarkdown
      className={cn('markdown', className)}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw, rehypeSanitize]}
    >
      {markdown}
    </ReactMarkdown>
  </div>
));

Markdown.displayName = 'Markdown';
