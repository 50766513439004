import { createSelector } from 'reselect';

import { currentEligibilitySelector } from '@modules/userProfileData';

const getSupplementalWaiverSelector = (state: RootState) => state.supplementalWaiver;

export const getAcceptedSupplementalWaiverCurrentEligibilitySelector = createSelector(
  getSupplementalWaiverSelector,
  currentEligibilitySelector,
  (supplementalWaiver, currentEligibility) => {
    if (currentEligibility && currentEligibility.id) {
      return supplementalWaiver.supplementalWaiverSigned[currentEligibility.id];
    }

    return null;
  },
);

export const getSupplementalWaiverDataStatusSelector = createSelector(
  getSupplementalWaiverSelector,
  (supplementalWaiver) => supplementalWaiver.statusData,
);

export const getSupplementalWaiverSignStatusSelector = createSelector(
  getSupplementalWaiverSelector,
  (supplementalWaiver) => supplementalWaiver.statusSign,
);

export const getSupplementalWaiverTextSelector = createSelector(
  getSupplementalWaiverSelector,
  (supplementalWaiver) => ({
    body: supplementalWaiver.supplementalWaiverData?.body,
    title: supplementalWaiver.supplementalWaiverData?.title,
    isSignatureRequired: supplementalWaiver.supplementalWaiverData?.is_signature_required,
  }),
);
