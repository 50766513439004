/* eslint-disable camelcase */
import React, { useCallback, useEffect } from 'react';

import classNames from 'classnames/bind';
import { FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { SignModal, TitleWithProgress } from '@components/SignModals';
import { logout } from '@modules/Login';
import { GlobalWaiverPurposes } from '@modules/globalWeaver';
import { GLOBAL_WAIVER_PURPOSES, JOINED_ToU_PP, WAIWERS_MODAL_TITLE_ID } from '@modules/globalWeaver/constants';
import { useGlobalWaiversForm } from '@modules/globalWeaver/hooks/useGlobalWaiversForm';

import { locale } from './locale';

import styles from './GlobalWaivers.module.css';

export type Waiver = {
  title?: string;
  body?: string;
  signature_required?: boolean;
  purpose?: GlobalWaiverPurposes | typeof JOINED_ToU_PP;
  waiverId?: number | number[];
};

const cn = classNames.bind(styles);

export const GlobalWaivers = () => {
  const dispatch = useDispatch();

  const { activeStep, activeWaiver, totalSteps, onSubmit, form, signature, required } =
    useGlobalWaiversForm(GLOBAL_WAIVER_PURPOSES);
  const { control, handleSubmit } = form;

  const { accept, cancel, progress_title } = locale;
  const { body, title, signature_required } = activeWaiver;

  useEffect(() => {
    if (document) {
      document?.getElementById(WAIWERS_MODAL_TITLE_ID)?.parentElement?.scrollIntoView();
    }
  }, [activeStep]);

  const onClose = useCallback(() => {
    dispatch(logout({ inactivityLogout: false }));
  }, [dispatch]);

  return (
    <FormProvider {...form}>
      <SignModal
        open={required}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
        title={
          <TitleWithProgress
            activeStep={activeStep + 1}
            totalSteps={totalSteps}
            progressTitle={progress_title}
            title={title ?? ''}
            id={WAIWERS_MODAL_TITLE_ID}
          />
        }
        body={body}
        cancelButtonTitle={cancel}
        control={control}
        signButtonTitle={accept}
        disabled={signature_required && !signature}
        signatureRequired={signature_required}
        modalContentClassName={cn('modal-content')}
        withoutBorders
      />
    </FormProvider>
  );
};
