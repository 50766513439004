type ValidatorWithMessageType<Args extends Array<unknown>> = (...args: Args) => true | string;

export const combineValidatorsWithMessage =
  <Args extends Array<unknown>>(validators: Array<ValidatorWithMessageType<Args>>): ValidatorWithMessageType<Args> =>
  (...args: Args) =>
    validators.reduce<true | string>((prev, validator) => {
      if (typeof prev === 'string') {
        return prev;
      }

      return validator(...args);
    }, true);
