import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { emailLatestCovidScreening, getLatestCovidScreening, saveCovidScreening } from './actions';
import { EmailLatestCovidScreeningResponseType, LatestCovidScreeningType } from './types';

interface Covid19ScreeningState {
  covidScreeningResult: {
    status: StatusEnum;
    data: Nullable<LatestCovidScreeningType>;
  };
  emailLatestCovidScreeningStatus: StatusEnum;
  emailLatestCovidScreeningResponse: EmailLatestCovidScreeningResponseType;
}

const initialState: Covid19ScreeningState = {
  covidScreeningResult: {
    status: StatusEnum.Uninitialized,
    data: null,
  },
  emailLatestCovidScreeningStatus: StatusEnum.Uninitialized,
  emailLatestCovidScreeningResponse: {} as EmailLatestCovidScreeningResponseType,
};

const setScreeningResultPending = (state: Covid19ScreeningState) => {
  state.covidScreeningResult.status = StatusEnum.Pending;
};

const setScreeningResultRejected = (state: Covid19ScreeningState) => {
  state.covidScreeningResult.status = StatusEnum.Rejected;
};

const setScreeningResultFulfilled = (state: Covid19ScreeningState, action: PayloadAction<LatestCovidScreeningType>) => {
  state.covidScreeningResult.status = StatusEnum.Fulfilled;
  state.covidScreeningResult.data = action.payload;
};

const covid19ScreeningSlice = createSlice({
  name: 'Covid19Screening',
  initialState,
  reducers: {
    clearCovidScreening: () => initialState,
  },
  extraReducers: {
    [getLatestCovidScreening.pending.type]: setScreeningResultPending,
    [getLatestCovidScreening.fulfilled.type]: setScreeningResultFulfilled,
    [getLatestCovidScreening.rejected.type]: setScreeningResultRejected,
    [saveCovidScreening.pending.type]: setScreeningResultPending,
    [saveCovidScreening.fulfilled.type]: setScreeningResultFulfilled,
    [saveCovidScreening.rejected.type]: setScreeningResultRejected,
    [emailLatestCovidScreening.pending.type]: (state: Covid19ScreeningState) => {
      state.emailLatestCovidScreeningStatus = StatusEnum.Pending;
    },
    [emailLatestCovidScreening.fulfilled.type]: (
      state: Covid19ScreeningState,
      action: PayloadAction<EmailLatestCovidScreeningResponseType>,
    ) => {
      state.emailLatestCovidScreeningStatus = StatusEnum.Fulfilled;
      state.emailLatestCovidScreeningResponse = action.payload;
    },
    [emailLatestCovidScreening.rejected.type]: (state: Covid19ScreeningState) => {
      state.emailLatestCovidScreeningStatus = StatusEnum.Rejected;
    },
  },
  ...resetStoreActions(() => initialState),
});

export const covid19ScreeningReducer = covid19ScreeningSlice.reducer;
export const { clearCovidScreening } = covid19ScreeningSlice.actions;
