import React from 'react';

import { useSelector } from 'react-redux';

import { NumberCircle, SizesEnum } from '@components/NumberCircle';

import { unreadCountFamilyMembersSelector } from '../../store';

type Props = {
  memberId: number;
  size?: SizesEnum;
  className?: string;
};

export const FamilyMemberMessageCount = ({ memberId, size = SizesEnum.Small, className }: Props) => {
  const familyMembersMessageCount = useSelector(unreadCountFamilyMembersSelector);

  if (!familyMembersMessageCount?.[memberId]) {
    return null;
  }

  return <NumberCircle count={familyMembersMessageCount?.[memberId]} size={size} className={className} />;
};
