import React from 'react';

import classNames from 'classnames/bind';

import { Typography } from '@components/Typography';

import { locale } from './locale';

import styles from './TitleWithProgress.module.css';

export type TitleWithProgressProps = {
  activeStep: number;
  totalSteps: number;
  progressTitle: string;
  title: string;
  id?: string;
};

const cn = classNames.bind(styles);

export const TitleWithProgress = ({ activeStep, totalSteps, progressTitle, title, id }: TitleWithProgressProps) => (
  <>
    <div className={cn('progress-title')} id={id}>
      <Typography component="span" variant="body1">
        {progressTitle}
      </Typography>
      <Typography component="span" variant="body1">{`${activeStep} ${locale.of} ${totalSteps}`}</Typography>
    </div>
    <Typography component="h2" variant="h2">
      {title}
    </Typography>
  </>
);
