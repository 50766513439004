import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { FILTER_OPTIONS } from '../constants';
import { getCategories, getDetails, completeContent, resetCategory } from './actions';
import { WorkshopsState, CategoryType, DetailsType, FilterType, CompleteType, GetDetailsType } from './types';
import { isInProgress } from './utils';

const initialState: WorkshopsState = {
  categories: [],
  categoriesStatus: StatusEnum.Uninitialized,
  filter: FILTER_OPTIONS[0],
  details: {} as DetailsType,
  detailsStatus: StatusEnum.Uninitialized,
  completionStatus: {},
  selectedWeek: null,
};

export const workshopsSlice = createSlice({
  name: 'workshops',
  initialState,
  reducers: {
    setFilter(state: WorkshopsState, action: PayloadAction<FilterType>) {
      state.filter = action.payload;
    },

    selectWeek(state: WorkshopsState, action: PayloadAction<number | null>) {
      state.selectedWeek = action.payload;
    },
  },
  extraReducers: {
    [getCategories.pending.type]: (state: WorkshopsState) => {
      state.categoriesStatus = StatusEnum.Pending;
    },
    [getCategories.fulfilled.type]: (state: WorkshopsState, action: PayloadAction<CategoryType[]>) => {
      state.categoriesStatus = StatusEnum.Fulfilled;
      const someInProgress = action.payload.some((category) => isInProgress(category));
      state.filter = someInProgress ? FILTER_OPTIONS[2] : FILTER_OPTIONS[0];
      state.categories = action.payload;
    },
    [getCategories.rejected.type]: (state: WorkshopsState) => {
      state.categoriesStatus = StatusEnum.Rejected;
    },

    [getDetails.pending.type]: (
      state: WorkshopsState,
      action: PayloadAction<DetailsType, string, { arg: GetDetailsType }>,
    ) => {
      if (!action.meta.arg.silent) {
        state.detailsStatus = StatusEnum.Pending;
      }
    },
    [getDetails.fulfilled.type]: (
      state: WorkshopsState,
      action: PayloadAction<DetailsType, string, { arg: GetDetailsType }>,
    ) => {
      if (!action.meta.arg.silent) {
        state.detailsStatus = StatusEnum.Fulfilled;
      }

      state.details = action.payload;
    },
    [getDetails.rejected.type]: (
      state: WorkshopsState,
      action: PayloadAction<DetailsType, string, { arg: GetDetailsType }>,
    ) => {
      if (!action.meta.arg.silent) {
        state.detailsStatus = StatusEnum.Rejected;
      }
    },

    [completeContent.pending.type]: (
      state: WorkshopsState,
      action: PayloadAction<DetailsType, string, { arg: CompleteType }>,
    ) => {
      state.completionStatus[action.meta.arg.id] = StatusEnum.Pending;
    },
    [completeContent.fulfilled.type]: (
      state: WorkshopsState,
      action: PayloadAction<DetailsType, string, { arg: CompleteType }>,
    ) => {
      state.completionStatus[action.meta.arg.id] = StatusEnum.Fulfilled;
    },
    [completeContent.rejected.type]: (
      state: WorkshopsState,
      action: PayloadAction<DetailsType, string, { arg: CompleteType }>,
    ) => {
      state.completionStatus[action.meta.arg.id] = StatusEnum.Rejected;
    },

    [resetCategory.pending.type]: (state: WorkshopsState) => {
      state.detailsStatus = StatusEnum.Pending;
      state.categoriesStatus = StatusEnum.Pending;
    },
    [resetCategory.fulfilled.type]: (state: WorkshopsState) => {
      state.detailsStatus = StatusEnum.Fulfilled;
      state.categoriesStatus = StatusEnum.Fulfilled;
    },
    [resetCategory.rejected.type]: (state: WorkshopsState) => {
      state.detailsStatus = StatusEnum.Rejected;
      state.categoriesStatus = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const { selectWeek, setFilter } = workshopsSlice.actions;
export const workshopsReducer = workshopsSlice.reducer;
