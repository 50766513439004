import React from 'react';

import classNames from 'classnames/bind';

import { Skeleton } from '@components/Skeleton';

import { DEFAULT_ITEMS_ON_PAGE } from '../../constants';

import styles from './ConversationListItem.module.css';

const cn = classNames.bind(styles);

export const ConversationsListSkeleton = () => (
  <ul className={cn('root')}>
    {Array(DEFAULT_ITEMS_ON_PAGE)
      .fill('')
      .map((_, index) => (
        <div
          className={cn('item')}
          key={index} // eslint-disable-line react/no-array-index-key
        >
          <div className={cn('container')}>
            <div className={cn('sender-name')}>
              <Skeleton className={cn('skeleton')} />
            </div>
            <div className={cn('recent-date-container')}>
              <Skeleton className={cn('skeleton')} />
            </div>
          </div>
          <div className={cn('subject-name')}>
            <Skeleton className={cn('skeleton')} />
          </div>
        </div>
      ))}
  </ul>
);
