import React, { useEffect, useRef, useState } from 'react';

import { StatusEnum } from '@common/constants';
import { DEFAULT_OPTIONS, numberFormatter } from '@common/utils/numberFormatter';
import { FieldRow } from '@components/FieldRow';
import { NumberFieldWithControl, useForm } from '@components/Form';
import { Grid } from '@components/Grid';
import { RecordActivityForm } from '@components/RecordActivityForm';

import { WellnessTypesEnum } from '../../../constants';
import { StepsItemType } from '../../../types';
import { useResetOnSuccess } from '../../../useResetFormOnSuccess';
import { DateTimePicker } from '../../DateTimePicker';
import { FormValuesType } from '../types';
import { FIELD_NAMES } from './constants';
import { locale } from './locale';

type FormProps = {
  onClose?: VoidFunction;
  showTitle?: boolean;
  initialValues?: StepsItemType | null;
  saveFormStatus: StatusEnum;
  className?: string;
  onSave: (formValues: FormValuesType, recordAnotherEntry?: boolean) => unknown;
};

const getDefaultInitialValues = () => ({
  id: 0,
  date: new Date(),
  time: new Date(),
  steps: 0,
});

const makeInitialValues = (initialValues: StepsItemType) => ({
  id: initialValues.id,
  date: new Date(initialValues.recorded_at),
  time: new Date(initialValues.recorded_at),
  steps: initialValues.steps,
});

const stepsFormatter = numberFormatter({ ...DEFAULT_OPTIONS, precision: 0, min: 1, max: 999999 });

export const StepsForm = ({ initialValues, onClose, onSave, saveFormStatus, showTitle, className }: FormProps) => {
  const { handleSubmit, control, reset } = useForm<FormValuesType>({
    defaultValues: initialValues ? makeInitialValues(initialValues) : getDefaultInitialValues(),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    firstInputRef?.current?.focus();
  });

  const [edit] = useState(initialValues?.can_edit_or_delete);

  const isLoading = saveFormStatus === StatusEnum.Pending;

  useResetOnSuccess(saveFormStatus, reset, getDefaultInitialValues(), WellnessTypesEnum.Steps);

  return (
    <RecordActivityForm
      cancelText={locale.cancel}
      headerText={locale.header}
      isLoading={isLoading}
      showTitle={showTitle}
      onClose={onClose}
      onSubmitRepeatForm={!edit ? handleSubmit((formValues) => onSave(formValues, true)) : undefined}
      onSubmit={handleSubmit((formValues) => onSave(formValues, false))}
      repeatFormText={locale.record_another_entry}
      submitText={locale.submit}
      className={className}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DateTimePicker<FormValuesType>
            control={control}
            dateLabel={locale.field_labels.date}
            isLoading={isLoading}
            timeLabel={locale.field_labels.time}
            inputRef={firstInputRef}
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={{ xs: 1, sm: 2, md: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} sm={6}>
          <FieldRow size="small">
            <NumberFieldWithControl
              name={FIELD_NAMES.STEPS}
              control={control}
              fullWidth
              label={locale.field_labels.steps}
              min={{ value: 1, message: locale.errors.steps }}
              max={999999}
              formatter={stepsFormatter}
              id="steps_form_steps"
              required
            />
          </FieldRow>
        </Grid>

        <Grid item xs={12} sm={6} />
      </Grid>
    </RecordActivityForm>
  );
};
