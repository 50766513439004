import React, { ReactNode } from 'react';

import { Radio, RadioGroup, RadioProps, RadioGroupProps } from '@components/Radio';

import { useController, UseControllerProps, FieldValues } from '../hooks';

export type RadioItemType = {
  id: string | number;
  label: ReactNode;
  disabled?: boolean;
  subLabel?: string;
};
export type RadioListType = Array<RadioItemType>;

type RadioGroupWithControlProps<FormValues extends FieldValues> = {
  name: string;
  required?: boolean;
  options: RadioListType;
  itemClassName?: string;
  row?: boolean;
  ariaLabelledby?: string;
  onChange?: RadioGroupProps['onChange'];
} & UseControllerProps<FormValues> &
  Omit<RadioProps, 'onChange'>;

export function RadioGroupWithControl<FormValues extends FieldValues>({
  control,
  name,
  required,
  options,
  className,
  itemClassName,
  row,
  onChange,
  disabled,
  ariaLabelledby,
  ...restProps
}: RadioGroupWithControlProps<FormValues>) {
  const {
    field: { value = '', onChange: fieldOnChange },
  } = useController<FormValues>({
    name,
    control,
    rules: { required },
  });

  const handleChange: RadioGroupProps['onChange'] = (event, value) => {
    fieldOnChange(event, value);
    onChange?.(event, value);
  };

  return (
    <RadioGroup
      name={name}
      onChange={handleChange}
      value={value}
      className={className}
      row={row}
      aria-labelledby={ariaLabelledby}
    >
      {options.map((option) => (
        <Radio
          key={option.id}
          value={option.id}
          label={option.label}
          checked={value === option.id}
          className={itemClassName}
          disabled={disabled || option.disabled}
          subLabel={option.subLabel}
          disableRipple
          {...restProps}
        />
      ))}
    </RadioGroup>
  );
}
