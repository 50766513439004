import { createSelector } from 'reselect';

import { flippersSelector, HideFlippersEnum, ShowFlippersEnum } from '@modules/flippers';

import { MAP_VISIT_TYPE_FLIPPERS, MAP_VISIT_TYPES_FLAGS, VisitTypesEnum } from '../../../constants';
import { convertToCollapsedVisitType, isVisitTypeInPerson } from '../../../utils';
import { ModalsEnum } from '../constants';

export const getScheduleAppointmentDataSelector = (state: RootState) =>
  state.scheduleNewAppointment.scheduleAppointmentData;
export const getVisitReasonSearchSelector = (state: RootState) => state.scheduleNewAppointment.visitReasonSearch;
export const getModalStateSelector = (state: RootState) => state.scheduleNewAppointment.modalsState;
export const getEmergencyReasonsOpenSelector = createSelector(
  getModalStateSelector,
  (modalsState) => modalsState[ModalsEnum.EmergencyReasons],
);

export const getUserIdSelector = createSelector(getScheduleAppointmentDataSelector, (data) => data?.userId);
export const getVisitStateSelector = createSelector(getScheduleAppointmentDataSelector, (data) => data?.visitState);
export const getVisitTypeSelector = (state: RootState) =>
  state.scheduleNewAppointment.scheduleAppointmentData?.visitType;

export const getAdditionalNotesSelector = (state: RootState) =>
  state.scheduleNewAppointment.scheduleAppointmentData?.additionalNotes;
export const getReasonForBookingSelector = (state: RootState) =>
  state.scheduleNewAppointment.scheduleAppointmentData?.reasonForBooking;
export const getFirstVisitReasonSelector = (state: RootState) =>
  state.scheduleNewAppointment.scheduleAppointmentData?.visitReason?.[0];
export const getVisitReasonSelector = (state: RootState) =>
  state.scheduleNewAppointment.scheduleAppointmentData?.visitReason;
export const getVisitReasonCategorySelector = createSelector(
  getScheduleAppointmentDataSelector,
  (data) => data?.visitReasonCategory,
);

export const getSchedulingForProviderSelector = (state: RootState) =>
  state.scheduleNewAppointment.schedulingForProvider;

export const getVisitReasonCategoryNameSelector = createSelector(
  getVisitReasonCategorySelector,
  (visitReasonCategory) => visitReasonCategory?.display_name,
);

export const getJoinedVisitReasonsSelector = createSelector(getVisitReasonSelector, (visitReasons) => {
  if (Array.isArray(visitReasons)) {
    return visitReasons.map(({ name }) => name).join(', ');
  }

  return visitReasons;
});

export const getAvailableVisitTypesMapSelector = createSelector(flippersSelector, (flippers) => {
  // TODO: replace with util from flippers module
  const getFlipperValue = (flipper: ShowFlippersEnum | HideFlippersEnum): boolean => flippers[flipper] ?? false;
  const covidPhaseRed = getFlipperValue(ShowFlippersEnum.covid_phase_red);

  return Object.values(VisitTypesEnum).reduce((acc, visitType) => {
    const isPersonInCovid = isVisitTypeInPerson(visitType) && covidPhaseRed;
    const visitTypeFlipper = MAP_VISIT_TYPE_FLIPPERS[visitType];
    const allowedByFlipper = visitTypeFlipper ? !getFlipperValue(visitTypeFlipper) : true;

    acc[visitType] = isPersonInCovid ? false : allowedByFlipper;
    return acc;
  }, {} as Record<VisitTypesEnum, boolean>);
});

export const getAvailableVisitTypesListSelector = createSelector(
  getFirstVisitReasonSelector,
  getAvailableVisitTypesMapSelector,
  getSchedulingForProviderSelector,
  (visitReason, availableVisitTypes, providerRoute) => {
    const visitTypeArray = [VisitTypesEnum.Virtual, VisitTypesEnum.Telephonic];
    if (!providerRoute?.provider?.virtual_only) visitTypeArray.unshift(VisitTypesEnum.InPerson);

    return visitTypeArray.filter((visitTypeId) => {
      const flagKey = MAP_VISIT_TYPES_FLAGS[visitTypeId];

      return visitReason?.[flagKey] && availableVisitTypes[visitTypeId];
    });
  },
);

export const getIsCollapsableVisitTypesSelector = createSelector(getAvailableVisitTypesListSelector, (visitTypes) => (
    visitTypes.filter((visitType) => visitType === VisitTypesEnum.Virtual || visitType === VisitTypesEnum.Telephonic)
      .length === 2
  ));

export const getAvailableCollapsedVisitTypesSelector = createSelector(
  getAvailableVisitTypesListSelector,
  getIsCollapsableVisitTypesSelector,
  (visitTypes, isCollapsable) => {
    if (isCollapsable) {
      return Array.from(new Set(visitTypes.map(convertToCollapsedVisitType)));
    }

    return visitTypes;
  },
);
