import { locale } from './locale';

export enum MedicationTypeEnum {
  Employee = 'Employee',
  Other = 'Other',
}

export const OTHER_PHARMACY_ID = 0;

export const OTHER_PHARMACY = {
  clinical_provider_id: OTHER_PHARMACY_ID,
  pharmacy_name: '',
  label: locale.other_pharmacy_label,
  address: '',
  city: '',
  state: '',
  zip: '',
  is_homemed_pharmacy: false,
};

export const OTHER_MEDICATION = {
  medication_id: '0',
  label: locale.other_medication_label,
  selected: false,
  medication_name: '',
  medication_dosage: '',
  medication_notes: '',
  type: MedicationTypeEnum.Other,
};

export const FIELD_NAMES = {
  NOTES: 'notes',
  MEMBER: 'member',
  MEDICATIONS: 'medications',
  ADHOC_REFILLS: 'adhoc_refills',
} as const;
