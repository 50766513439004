import React from 'react';

import { useIgnoreEffectDeps } from '@common/hooks';
import { getLocale } from '@common/utils/locale';
import { numberFormatter, DEFAULT_OPTIONS } from '@common/utils/numberFormatter';
import { FieldRow } from '@components/FieldRow';
import { NumberFieldWithControl, UseFormReturn, FieldValues, Path } from '@components/Form';
import { Grid } from '@components/Grid';
import { Typography } from '@components/Typography';

export const FIELD_NAMES = {
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
} as const;

type FormProps<FormValuesType extends FieldValues> = {
  disabled: boolean;
  hideSeconds?: boolean;
  required?: boolean;
  errorMessage?: string;
  additionalFieldValue?: number;
  locale: {
    errors: {
      duration: string;
    };
    field_row_labels: {
      duration: string;
    };
    field_labels: {
      hours: string;
      hours_aria_label?: string;
      minutes_aria_label?: string;
      seconds_aria_label?: string;
      minutes: string;
      seconds?: string;
    };
  };
} & Pick<UseFormReturn<FormValuesType>, 'control' | 'watch' | 'clearErrors' | 'setError' | 'formState'>;

const hoursFormatter = numberFormatter({ ...DEFAULT_OPTIONS, precision: 0, min: 1, max: 23 });
const minutesFormatter = numberFormatter({ ...DEFAULT_OPTIONS, precision: 0, min: 1, max: 59 });

export function Duration<FormValuesType extends FieldValues>({
  control,
  watch,
  setError,
  formState,
  clearErrors,
  disabled,
  hideSeconds = false,
  locale,
  required = false,
  errorMessage,
  additionalFieldValue,
}: FormProps<FormValuesType>) {
  const error = {
    type: 'required',
    message: errorMessage || locale.errors.duration,
  };

  const hours = watch(FIELD_NAMES.HOURS as Path<FormValuesType>);
  const minutes = watch(FIELD_NAMES.MINUTES as Path<FormValuesType>);
  const seconds = watch(FIELD_NAMES.SECONDS as Path<FormValuesType>);
  const { errors, isSubmitted } = formState;

  useIgnoreEffectDeps(() => {
    if (Number(minutes) || Number(seconds) || Number(hours) || Number(additionalFieldValue)) {
      clearErrors('duration' as Path<FormValuesType>);
    } else {
      setError('duration' as Path<FormValuesType>, error);
    }
  }, [minutes, seconds, hours, isSubmitted, additionalFieldValue]);

  const hasError = isSubmitted && !!errors?.duration;

  const numberProps = {
    control,
    fullWidth: true,
    error: hasError,
    disabled,
  };

  return (
    <FieldRow
      label={
        <Typography variant="h5" component="h3">
          {locale.field_row_labels.duration}
        </Typography>
      }
      required={required}
      error={hasError}
      helperText={isSubmitted ? (errors?.duration?.message as string) : ''}
      size="small"
    >
      <Grid container spacing={1}>
        <Grid item xs>
          <NumberFieldWithControl
            name={FIELD_NAMES.HOURS as Path<FormValuesType>}
            label={locale.field_labels.hours}
            min={0}
            max={23}
            formatter={hoursFormatter}
            id="hours"
            ariaLabel={getLocale(locale.field_labels.hours_aria_label, { type: locale.field_row_labels.duration })}
            {...numberProps}
          />
        </Grid>
        <Grid item xs>
          <NumberFieldWithControl
            name={FIELD_NAMES.MINUTES as Path<FormValuesType>}
            label={locale.field_labels.minutes}
            min={0}
            max={59}
            formatter={minutesFormatter}
            id="minutes"
            ariaLabel={getLocale(locale.field_labels.minutes_aria_label, { type: locale.field_row_labels.duration })}
            {...numberProps}
          />
        </Grid>
        {!hideSeconds && (
          <Grid item xs>
            <NumberFieldWithControl
              name={FIELD_NAMES.SECONDS as Path<FormValuesType>}
              min={0}
              max={59}
              label={locale.field_labels.seconds}
              formatter={minutesFormatter}
              id="seconds"
              ariaLabel={getLocale(locale.field_labels.seconds_aria_label, { type: locale.field_row_labels.duration })}
              {...numberProps}
            />
          </Grid>
        )}
      </Grid>
    </FieldRow>
  );
}
