import React, { ReactNode } from 'react';

import classNames from 'classnames/bind';

import { Modal } from '@components/Modal';

import styles from './RecordActivityModal.module.css';

const cn = classNames.bind(styles);

type RecordActivityModalProps = {
  open: boolean;
  className?: string;
  modalContentClassName?: string;
  children: ReactNode;
  onClose: VoidFunction;
};

const MODAL_ID = 'record_activity_modal';
const TITLE_ID = 'record_activity_modal_title';

export const RecordActivityModal = ({
  open,
  onClose,
  children,
  className,
  modalContentClassName,
}: RecordActivityModalProps) => (
  <Modal
    className={cn('record-activity-modal', className)}
    paperClassName={cn('record-activity-modal__paper')}
    modalContentClassName={cn('record-activity-modal__content', modalContentClassName)}
    open={open}
    onClose={onClose}
    showCrossButton={false}
    id={MODAL_ID}
    ariaLabelledby={TITLE_ID}
  >
    {children}
  </Modal>
);
