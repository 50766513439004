import { useRef, useState, useContext } from 'react';

import { useIgnoreEffectDeps } from '@common/hooks';
import { noop } from '@common/utils';

import { FullContentLoadedContext } from './FullContentLoadedContextProvider';
import { usePageLocationString } from './usePageLocationString';

type Params = { loadingStarted?: boolean; loadingFinished: boolean };

export const useFullContentLoaded = ({ loadingStarted = true, loadingFinished }: Params) => {
  const [startedOnce, setStartedOnce] = useState(false);
  const [finishedOnce, setFinishedOnce] = useState(false);

  const locationString = usePageLocationString();

  useIgnoreEffectDeps(() => {
    setStartedOnce(false);
    setFinishedOnce(false);
  }, [locationString]);

  useIgnoreEffectDeps(() => {
    if (loadingStarted) {
      setStartedOnce(true);
    }
  }, [loadingStarted]);

  useIgnoreEffectDeps(() => {
    if (startedOnce && loadingFinished) {
      setFinishedOnce(true);
    }
  }, [startedOnce, loadingFinished]);

  const registerLoadingStart = useContext(FullContentLoadedContext);
  const finishLoading = useRef<() => void>(noop);

  useIgnoreEffectDeps(() => {
    if (startedOnce) {
      finishLoading.current = registerLoadingStart();
    }
  }, [startedOnce]);

  useIgnoreEffectDeps(() => {
    if (finishedOnce) {
      finishLoading.current();
    }
  }, [finishedOnce]);
};
