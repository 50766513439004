import React from 'react';

import { useSelector } from 'react-redux';

import { Locker } from '@components/Locker';
import { SrA11YAnnouncer } from '@components/SrA11YAnnouncer';

import { locale } from './locale';
import { globalLockerSelector } from './store';

export const GlobalLocker = () => {
  const { title, showLocker, ariaLabel, hideLogo } = useSelector(globalLockerSelector);

  if (!showLocker) return null;

  return (
    <>
      <SrA11YAnnouncer message={ariaLabel || title || locale.loading} />
      <Locker fullScreen title={title} animated hideLogo={hideLogo ?? false} />
    </>
  );
};
