import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

import { SaveCovidScreeningPayloadType, EmailLatestCovidScreeningPayloadType } from './types';

export const getLatestCovidScreening = createAsyncThunk(
  'get-latest-covid19-screening',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: '/Screening/get-latest-covid19-screening' });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveCovidScreening = createAsyncThunk(
  'save-covid19-screening',
  async (data: SaveCovidScreeningPayloadType, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: '/Screening/save-covid19-screening',
        data,
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const emailLatestCovidScreening = createAsyncThunk(
  'email-latest-covid19-screening',
  async (data: EmailLatestCovidScreeningPayloadType, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: '/Screening/email-latest-covid19-screening',
        data,
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
