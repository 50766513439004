import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { fetchWebinarLibrary } from './actions';
import { WebinarLibraryState, WebinarLibraryType } from './types';

const initialState: WebinarLibraryState = {
  data: [],
  status: StatusEnum.Uninitialized,
  error: {},
};

export const webinarLibrarySlice = createSlice({
  name: 'webinarLibrary',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchWebinarLibrary.pending.type]: (state: WebinarLibraryState) => {
      state.status = StatusEnum.Pending;
    },
    [fetchWebinarLibrary.fulfilled.type]: (state: WebinarLibraryState, action: PayloadAction<WebinarLibraryType>) => {
      state.status = StatusEnum.Fulfilled;
      state.data = action.payload;
    },
    [fetchWebinarLibrary.rejected.type]: (state: WebinarLibraryState) => {
      /** TODO-Samanth Accurate Error-Handling */
      state.status = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const webinarLibraryReducer = webinarLibrarySlice.reducer;
