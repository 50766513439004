import { MeasurementEnum } from '../../constants';

export const locale = {
  title: 'Weight',
  dashboard_title: 'Weight',
  history_title: 'Recently Recorded Weight',
  record_activity: 'Record Weight',
  measurements: {
    [MeasurementEnum.Weight]: 'Weight Changes Over Time',
  },
  measurementsShort: {
    [MeasurementEnum.Weight]: 'lbs',
  },
  measurementsYScaleTitle: {
    [MeasurementEnum.Weight]: 'Pounds',
  },
};
