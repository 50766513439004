import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { login, logout, logoutWithoutRevoke } from '@modules/Login';
import { changeCurrentEligibility, ChangeEligibilityResponseType } from '@modules/userProfileData/store';

import { FlippersState } from './types';

const initialState: FlippersState = {
  flippers: {},
  status: StatusEnum.Uninitialized,
};

export const resetStoreActions = (resetAction: (state: FlippersState) => void) => ({
  [logout.fulfilled.type]: resetAction,
  [logoutWithoutRevoke.fulfilled.type]: resetAction,
});

const makeFlippers = (enabledFeatures = [] as Array<string>) =>
  enabledFeatures.reduce((acc, flipper) => {
    acc[flipper] = true;

    return acc;
  }, {} as Record<string, boolean>);

export const flippersSlice = createSlice({
  name: 'flippers',
  initialState,
  reducers: {
    setFlippers(state: FlippersState, action: PayloadAction<Array<string>>) {
      state.flippers = makeFlippers(action.payload);
    },
  },
  extraReducers: {
    [login.fulfilled.type]: (state: FlippersState, action) => {
      state.flippers = makeFlippers(action.payload.user_auth?.enabled_features);
    },

    [changeCurrentEligibility.fulfilled.type]: (
      state: FlippersState,
      action: PayloadAction<ChangeEligibilityResponseType>,
    ) => {
      state.flippers = makeFlippers(action.payload.user_auth.enabled_features);
    },

    ...resetStoreActions(() => initialState),
  },
});

export const { setFlippers } = flippersSlice.actions;
export const flippersReducer = flippersSlice.reducer;
