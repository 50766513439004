import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useIgnoreEffectDeps } from '@common/hooks';
import { usePrevious } from '@common/hooks/usePrevious';

import { setRouteDetails } from '../store';

export function useRouteDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const previousLocation = usePrevious(location);
  useIgnoreEffectDeps(() => {
    dispatch(
      setRouteDetails({
        from: previousLocation,
        to: location,
      }),
    );
  }, [location]);
}
