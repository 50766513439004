import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { DEFAULT_ITEMS_ON_PAGE } from '@components/Pagination/constants';

import { ParamsWithDependentUserId } from '../../../types';
import { DEFAULT_PAGE, DEFAULT_SORT_ID, DEFAULT_SORT_ORDER } from '../constants';
import { EducationItemsResponse, GetDocumentsParamsType, GetPatientEducationRequestPayloadType } from './types';

export const getPatientEducationRequest = async ({
  dependentUserId,
  data,
}: {
  dependentUserId?: number;
  data: GetPatientEducationRequestPayloadType;
}) => {
  const result = fetch.post({
    url: '/Document/educational-documents',
    params: {
      dependentUserId,
    },
    data,
  });

  const res = await result.response;

  return res?.data;
};

export const getPatientEducation = createAsyncThunk<
  EducationItemsResponse,
  ParamsWithDependentUserId<GetDocumentsParamsType>
>('Document/get-patient-education', async (params, { rejectWithValue }) => {
  const { dependentUserId, orderBy, order, search, currentPage } = params ?? {};

  const dataForRequest: GetPatientEducationRequestPayloadType = {
    page_size: DEFAULT_ITEMS_ON_PAGE,
    page_number: currentPage ?? DEFAULT_PAGE,
  };

  if (orderBy || order) {
    dataForRequest.sorts = [
      {
        is_transient: false,
        property_name: orderBy ?? DEFAULT_SORT_ID,
        direction: order ?? DEFAULT_SORT_ORDER,
      },
    ];
  }

  if (search) {
    dataForRequest.filters = [
      {
        is_transient: false,
        property_name: DEFAULT_SORT_ID,
        filter_value: search,
        filter_operator: 'contains',
      },
    ];
  }

  try {
    return await getPatientEducationRequest({
      dependentUserId,
      data: dataForRequest,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});
