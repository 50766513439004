import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { Languages, LOCALIZATION_ENABLED } from '@common/i18n';

const detectionOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator'], // we use localeStorage for saving languageValue and also get it from navigator,
  // but there a lot of other options how this value cold be extended
  // https://github.com/i18next/i18next-browser-languageDetector
  lookupLocalStorage: 'i18nextLng', // localStorage key for language
};

i18n // https://www.i18next.com/ - please look at documentation if additional questions appear
  .use(initReactI18next) // https://react.i18next.com/
  .use(LanguageDetector)
  .init({
    detection: detectionOptions,
    debug: false,
    saveMissing: true,
    returnObjects: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // Not needed for React
      formatSeparator: ',',
      format(value: string, format: string) {
        return format === 'uppercase' ? value.toUpperCase() : value;
      },
    },
    // parseMissingKeyHandler: () => '<text wasn't found>', // Waiting for approved phrase in future
  });

if (!LOCALIZATION_ENABLED) {
  i18n.changeLanguage(Languages.EN); // set default language
}

export default i18n;
