import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const requestSamlAuth = createAsyncThunk(
  'SamlRedirect/saml-auth',
  async (samlRequest: string, { rejectWithValue }) => {
    try {
      const responseData = fetch.get({
        url: '/Authentication/saml-auth',
        params: {
          SAMLRequest: samlRequest,
        },
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
