import { formatDate } from '@common/utils';
import { SortOrderEnum } from '@common/utils/tableUtils';

export enum COLUMN_IDS {
  DocumentName = 'document_description',
  Date = 'date',
  Provider = 'provider_name',
}

export interface ColumnType {
  id: COLUMN_IDS;
  label: string;
  minWidth?: number;
  align?: 'right';
  primaryColumn?: boolean;
  format?: (value: string | number) => string;
}

export const COLUMNS: ColumnType[] = [
  {
    id: COLUMN_IDS.DocumentName,
    label: 'Description',
  },
  {
    id: COLUMN_IDS.Date,
    label: 'Date',
    format: (value) => (value ? formatDate(new Date(value), 'MM/dd/yyyy') : ''),
  },
  { id: COLUMN_IDS.Provider, label: 'Provider' },
];

export const DEFAULT_SORT_ORDER = SortOrderEnum.Asc;
export const DEFAULT_SORT_ID = COLUMN_IDS.DocumentName;
export const DEFAULT_PAGE = 0;

export const DOCUMENT_CONTENT_TYPE = 'application/pdf';
