import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import { Modal } from '@components/Modal';
import { logout } from '@modules/Login';

import { UserActivityModalContent } from './components/UserActivityModalContent';
import { useUserActiveTimeout } from './hooks/useUserActiveTimeout';

import styles from './UserActivityModal.module.css';

const cn = classNames.bind(styles);

export const UserActivityModal = () => {
  const dispatch = useDispatch();
  const { userActivityExpired, clearExpired } = useUserActiveTimeout();

  const handleSignout = useCallback(
    (inactivityLogout: boolean) => {
      dispatch(logout({ inactivityLogout }));
    },
    [dispatch],
  );

  const handleExtend = useCallback(() => {
    clearExpired();
  }, [clearExpired]);

  return (
    <Modal
      className={cn('user-activity-modal')}
      paperClassName={cn('user-activity-modal__paper')}
      open={userActivityExpired}
      showCrossButton={false}
      ignoreFullScreen
    >
      <UserActivityModalContent handleSignout={handleSignout} handleExtend={handleExtend} />
    </Modal>
  );
};
