import { locale } from './locale';

export enum MeasurementsTypes {
  Weights = 'weights',
  Bmis = 'bmis',
  A1cs = 'a1cs',
  Systolics = 'systolics',
  Diastolics = 'diastolics',
  Ldls = 'ldls',
  Hdls = 'hdls',
  CurrentTotalCholesterol = 'total_cholesterols',
  TotalCholesterolRatios = 'total_cholesterol_ratios',
  Waists = 'waists',
  BloodGlucoses = 'blood_glucoses',
}

export enum MeasurementsTypeNames {
  Weights = 'Weight',
  Bmis = 'BMI',
  A1cs = 'Hemoglobin A1c',
  Systolics = 'Systolic',
  Diastolics = 'Diastolic',
  Ldls = 'LDL Cholesterol',
  Hdls = 'HDL Cholesterol',
  CurrentTotalCholesterol = 'Total Cholesterol',
  TotalCholesterolRatios = 'Total / HDL Cholesterol Ratio',
  Waists = 'Waist Size',
  BloodGlucoses = 'Blood Glucose',
}

export enum MeasurementsCurrentTypes {
  Weights = 'current_weight',
  Bmis = 'current_bmi',
  A1cs = 'current_a1c',
  Systolics = 'current_systolic',
  Diastolics = 'current_diastolic',
  Ldls = 'current_ldl',
  Hdls = 'current_hdl',
  CurrentTotalCholesterol = 'current_total_cholesterol',
  TotalCholesterolRatios = 'current_total_cholesterol_ratio',
  Waists = 'current_waist',
  BloodGlucoses = 'current_blood_glucose',
  BloodPressures = 'blood_pressure',
}

export const MEASUREMENT_CARDS = [
  {
    type: MeasurementsTypes.Weights,
    typeName: MeasurementsTypeNames.Weights,
    currentType: MeasurementsCurrentTypes.Weights,
  },
  {
    type: MeasurementsTypes.Bmis,
    typeName: MeasurementsTypeNames.Bmis,
    currentType: MeasurementsCurrentTypes.Bmis,
  },
  {
    type: MeasurementsTypes.Waists,
    typeName: MeasurementsTypeNames.Waists,
    currentType: MeasurementsCurrentTypes.Waists,
  },
  {
    type: MeasurementsTypes.Ldls,
    typeName: MeasurementsTypeNames.Ldls,
    currentType: MeasurementsCurrentTypes.Ldls,
  },
  {
    type: MeasurementsTypes.Hdls,
    typeName: MeasurementsTypeNames.Hdls,
    currentType: MeasurementsCurrentTypes.Hdls,
  },
  {
    type: MeasurementsTypes.CurrentTotalCholesterol,
    typeName: MeasurementsTypeNames.CurrentTotalCholesterol,
    currentType: MeasurementsCurrentTypes.CurrentTotalCholesterol,
  },
  {
    type: MeasurementsTypes.TotalCholesterolRatios,
    typeName: MeasurementsTypeNames.TotalCholesterolRatios,
    currentType: MeasurementsCurrentTypes.TotalCholesterolRatios,
  },
  {
    type: MeasurementsTypes.A1cs,
    typeName: MeasurementsTypeNames.A1cs,
    currentType: MeasurementsCurrentTypes.A1cs,
  },
  {
    title: locale.blood_pressure,
    type: MeasurementsTypes.Systolics,
    typeName: MeasurementsTypeNames.Systolics,
    subType: MeasurementsTypes.Diastolics,
    subTypeName: MeasurementsTypeNames.Diastolics,
    currentType: MeasurementsCurrentTypes.Systolics,
    currentSubType: MeasurementsCurrentTypes.Diastolics,
  },
  {
    type: MeasurementsTypes.BloodGlucoses,
    typeName: MeasurementsTypeNames.BloodGlucoses,
    currentType: MeasurementsCurrentTypes.BloodGlucoses,
  },
];

export const CUSTOMIZE_MODAL_ID = 'customize_dashboard_modal';

export enum DownloadsTypeNames {
  Csv = 'csv',
  Xlsx = 'xlsx',
}
