import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getNetworkForAllMembers = createAsyncThunk(
  'Appointment/networks-for-all-members',
  async (userId: number, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: `/Appointment/networks-for-all-members/${userId}`,
      });
      const res = await data.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
