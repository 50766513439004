export const formatPhoneNumber = (phone: string) => {
  const PHONE_NUMBER_PATTERN = /^(1|)?(\d{3})(\d{3})(\d+)$/;

  const cleaned = `${phone}`.replace(/\D/g, '');

  const match = cleaned.match(PHONE_NUMBER_PATTERN);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
  }

  return null;
};
