export const locale = {
  links: {
    home: 'Home',
    appointments: {
      root: 'Appointments',
      view_appointment: 'View Appointments',
      schedule_appointment: 'Schedule and View Appointments',
      health_center_locations: 'Health Center Locations',
      our_providers: 'Our Providers',
      health_center_services: 'Health Services',
    },
    medication_refills: 'Medication Refills',
    my_health: {
      root: 'MyHealth',
      my_health_dashboard: 'My Health Dashboard',
      test_results: 'Test Results',
      risk_profile: 'Risk Profile',
      health_summary: 'Health Summary',
      questionnaires: 'Questionnaires',
      goals: 'Goals',
      documents: 'Documents',
      care_guidelines: 'Care Guidelines',
    },
    wellness: {
      root: 'Incentives & Wellness',
      root_condensed: 'Wellness',
      incentives: 'Incentives',
      challenges: 'Challenges',
      wellness_logs: 'Wellness Logs',
    },
    resources: {
      root: 'Resources',
      all_resources: 'View All Resources',
      healthwise: 'Healthwise',
      wellness_workshops: 'Wellness Workshops',
      bluebook: 'Healthcare Bluebook',
      community: 'Find Community Resources Near You',
    },
    help: {
      root: 'Help',
      contact_us: 'Contact Us',
      faq: 'FAQ',
      spanish_language_assistance_sp: 'Asistencia de traducción al español',
      spanish_language_assistance_en: 'Spanish Language Assisstance',
      feedback: 'Portal Feedback Form',
    },
    prescriptions: 'Prescriptions',
  },
};
