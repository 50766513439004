import { generatePathWithQuery } from '@common/router';
import { PATHS } from '@common/routes';

import { WellnessTypesEnum } from '../../../WellnessLog';

export const generateWellnessLogPath = (type: WellnessTypesEnum) => (detailsId: string) =>
  generatePathWithQuery(PATHS.WELLNESS.WELLNESS_LOGS, {
    type,
    redirect: `${PATHS.RESOURCES.WELLNESS_WORKSHOPS}/${detailsId}`,
  });
