import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { useFullContentLoaded } from '@modules/gtmFulContentLoaded';

import { getUserProfile, userProfileStatusSelector } from '../store';

export function useLoadUserProfile() {
  const userProfileStatus = useSelector(userProfileStatusSelector);
  const dispatch = useDispatch();

  useFullContentLoaded({
    loadingStarted: userProfileStatus === StatusEnum.Pending,
    loadingFinished: [StatusEnum.Fulfilled, StatusEnum.Rejected].includes(userProfileStatus),
  });

  useIgnoreEffectDeps(() => {
    if (userProfileStatus !== StatusEnum.Pending) {
      dispatch(getUserProfile());
    }
  }, []);
}
