import React, { useRef, useCallback, ChangeEventHandler } from 'react';

import { ClickAwayListener } from '@mui/base';
import classNames from 'classnames/bind';

import { formatDate, isAmPmTimeFormat } from '@common/utils/dateTimeUtil';
import { IconButton } from '@components/IconButton';
import { SvgIcon } from '@components/SvgIcon';

import { InputAdornment, TextField } from '../TextField';
import { ClockPicker } from './components/ClockPicker';
import { useOpenClockPicker } from './hooks/useOpenClockPicker';
import { locale } from './locale';

import styles from './TimePicker.module.css';

const cn = classNames.bind(styles);

type DateType = string | number | Date | null;

export type TimePickerProps = {
  className?: string;
  label?: string;
  name?: string;
  helperText?: string;
  placeholder?: string;
  value: DateType;
  required?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  openOnFocus?: boolean;
  disabled?: boolean;
  onChange: (date: DateType) => void | undefined;
  variant?: 'outlined' | 'standard' | 'filled';
};

export function TimePicker({
  className,
  disabled = false,
  error = false,
  helperText = '',
  label,
  name = '',
  placeholder = '',
  onChange,
  required = false,
  fullWidth = false,
  openOnFocus = false,
  value = '',
  variant,
}: TimePickerProps) {
  const timePickerRef = useRef<HTMLDivElement>(null);
  const clockToggleRef = useRef<HTMLButtonElement>(null);
  const { onCloseModal, onOpenModal, modalOpen, closeModal } = useOpenClockPicker(
    timePickerRef.current,
    clockToggleRef.current,
  );
  const dateValue = value ? new Date(value) : new Date();
  const date = formatDate(dateValue, 'HH:mm');
  const [hours, minutes] = date.split(':');
  const isAmPm = isAmPmTimeFormat(dateValue);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const newDate = value ? new Date(value) : new Date();
      const [newHours, newMinutes] = event.target.value.split(':');

      if (newHours) {
        newDate.setHours(Number(newHours));
      }

      if (newMinutes) {
        newDate.setMinutes(Number(newMinutes));
      }

      if (newHours || newMinutes) {
        onChange(newDate);
      }
    },
    [onChange, value],
  );

  return (
    <ClickAwayListener onClickAway={onCloseModal}>
      <div ref={timePickerRef}>
        <TextField
          value={value}
          disabled={disabled}
          variant={variant}
          label={label}
          className={cn('time-picker', className)}
          onChange={handleChange}
          onFocus={openOnFocus ? onOpenModal : undefined}
          inputProps={{
            type: 'time',
            value: `${hours}:${minutes}`,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disableRipple={false}
                  onClick={modalOpen ? onCloseModal : onOpenModal}
                  tabIndex={0}
                  className={cn('icon-button')}
                  ref={clockToggleRef}
                  aria-label={locale.clock}
                  aria-expanded={modalOpen}
                >
                  <SvgIcon fontSize="inherit" icon="clock" className={cn('clock-icon')} inheritViewBox />
                </IconButton>
              </InputAdornment>
            ),
          }}
          id={name}
          name={name}
          placeholder={placeholder}
          required={required}
          helperText={helperText}
          error={error}
          fullWidth={fullWidth}
        />

        <ClockPicker
          anchorEl={timePickerRef.current}
          value={value}
          ampm={isAmPm}
          onChange={onChange}
          open={modalOpen}
          handleClose={closeModal}
        />
      </div>
    </ClickAwayListener>
  );
}
