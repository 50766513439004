import React from 'react';

import ListMaterial, { ListProps as ListPropsMaterial } from '@mui/material/List';

export type ListProps = Pick<
  ListPropsMaterial,
  'children' | 'style' | 'className' | 'aria-label' | 'aria-labelledby' | 'aria-describedby'
>;

export function List({
  children,
  style,
  className,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
}: ListProps) {
  return (
    <ListMaterial
      style={style}
      className={className}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
    >
      {children}
    </ListMaterial>
  );
}
