type OptionsType = {
  allowMinus?: boolean;
  precision: number;
  preserveLeadingZeros?: boolean;
  min?: number | null;
  max?: number | null;
};

type ValueType = number | string | null | undefined;

export type FormatterType = (value?: ValueType) => string;

export const DEFAULT_OPTIONS: OptionsType = {
  allowMinus: false,
  precision: 2,
  preserveLeadingZeros: true,
  min: null,
  max: null,
};

type ValueFormatter = {
  predicate: (value: string, options?: OptionsType) => boolean;
  format: (value: string) => string;
};

export const valuesFormatters: ValueFormatter[] = [
  /** Empty */
  {
    predicate: (value) => value === '',
    format: (value) => value,
  },
  /** Dot */
  {
    predicate: (value) => value === '.',
    format: () => '',
  },
  /** Minus allowed */
  {
    predicate: (value, options) => value === '-' && !!(options?.allowMinus ?? DEFAULT_OPTIONS.allowMinus),
    format: () => '-',
  },
  /** Minus not allowed */
  {
    predicate: (value, options) => value === '-' && !(options?.allowMinus ?? DEFAULT_OPTIONS.allowMinus),
    format: () => '',
  },
  /** Includes ,- */
  {
    predicate: (value) => value.includes(',-'),
    format: (value) => value.split(',-').join('.'),
  },
];

function roundTo(value: number, decimalCount = 2) {
  const grade = 10 ** decimalCount;
  const parsedValue = parseFloat(value.toString());

  return Math.round((parsedValue + Math.sign(parsedValue) * Number.EPSILON) * grade) / grade;
}

function round(value: string | number, analysis: ReturnType<typeof analyze>, options: OptionsType): string {
  let newValue = value;
  const { precision } = options;

  newValue = parseFloat(newValue.toString());

  if (analysis.decimalPlacesCount > precision) {
    newValue = roundTo(Math.abs(newValue), precision);
  }

  newValue = Math.abs(newValue);

  newValue = newValue.toString();

  // Add back the decimal separator possible removed during rounding
  if (analysis.dotWas && !newValue.includes('.')) {
    newValue += '.';
  }

  return newValue;
}

function countDecimals(value: string) {
  const parts = value.split('.');

  return parts.length > 1 ? parts[1].length || 0 : 0;
}

function analyze(value: string, options: OptionsType) {
  const { allowMinus = options.allowMinus } = options;

  return {
    sign: allowMinus && value.charAt(0) === '-' ? '-' : '',
    decimalPlacesCount: countDecimals(value),
    dotWas: value.includes('.'),
  };
}

function normalize(value: ValueType, options: OptionsType): string {
  let newValue = value;
  const { precision = DEFAULT_OPTIONS.precision } = options;
  /* Undefined & null */
  if (newValue === undefined || newValue === null) {
    newValue = '';
  }

  /* Force it to be a String */
  newValue = `${newValue}`.trim();

  /* Left only dots */
  newValue = newValue.replace(',', '.');

  /* Replace long minus to simple minus sign */
  newValue = newValue.replace('–', '-');

  /* Remove all restricted symbols (except digits, minus sign and (in some cases) decimal separator */
  if (precision === 0) {
    newValue = `${newValue}`.replace(/[^0-9\\-]/g, '');
  } else {
    newValue = `${newValue}`.replace(/[^0-9.\\-]/g, '');
  }

  /* Collapse multiple dots */
  newValue = newValue.replace(/\.\.+/g, '.');

  return newValue;
}

export function numberFormatter(options = DEFAULT_OPTIONS): FormatterType {
  return function format(val?: number | string | null | undefined): string {
    const {
      precision = DEFAULT_OPTIONS.precision,
      max = DEFAULT_OPTIONS.max,
      preserveLeadingZeros = DEFAULT_OPTIONS.preserveLeadingZeros,
    } = options;
    let value = normalize(val, options);

    const valueFormatter = valuesFormatters.find((formatter) => formatter.predicate(value, options));
    if (valueFormatter) return valueFormatter?.format(value);

    /** Preserve Leading Zeros */
    if (
      (value === '00' || (!!value.charAt(1) && value.charAt(1) !== '0' && value.charAt(0) === '0')) &&
      !preserveLeadingZeros
    ) {
      value = value.replace(/^0+/, '');
    }

    const analysis = analyze(value, options);

    value = round(value, analysis, options);

    if (precision === 0) {
      value = Math.floor(Number(value)).toString().replace('.', '') || '0';
    } else if (analysis.dotWas && value.includes('.') && analysis.decimalPlacesCount > 0) {
      // Appending by zeros if were
      const currentDecimals = value.length - 1 - value.indexOf('.');
      value += '0'.repeat(Math.min(analysis.decimalPlacesCount - currentDecimals, precision - currentDecimals));
    }

    // Return max if value bigger than max
    if (max && value) {
      value = Number(value) > Number(max) ? `${max}` : `${value}`;
    }

    if (analysis.sign) {
      value = `${analysis.sign}${value}`;
    }

    return value;
  };
}
