import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { downloadFileFromUrl } from '@common/utils/downloadFileFromUrl';

export const downloadSharedDocument = createAsyncThunk<string, string | number>(
  'sharedDocuments/get-shared-document-url',
  async (sharedDocumentId: string | number, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/Article/get-shared-document-url',
        params: {
          sharedDocumentId,
        },
      });

      const res = await data.response;
      downloadFileFromUrl(res?.data);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
