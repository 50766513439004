import { fetch } from '@common/fetch';

export const isRecaptchaDisabled = () => !process.env.RECAPTCHA_API_KEY;

export const grecaptchaCall = (callback: (grecaptcha: ReCaptchaV2.ReCaptcha) => unknown) => {
  recaptchaOnload?.().then(callback);
};

export const validateRecaptcha = (token: string) => {
  if (isRecaptchaDisabled()) return Promise.resolve();

  const url = `/Authentication/validate-recaptcha/${token}`;

  return fetch.get({ url }).response;
};
