import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@common/fetch/axios';
import { CONFIG } from '@common/fetch/constants';

import { FormFields, MemberProfile } from '../types';

export const validationApi = createApi({
  reducerPath: 'validationApi',
  baseQuery: axiosBaseQuery(),

  endpoints: (build) => ({
    getPortalDirection: build.query<MemberProfile[], FormFields>({
      query: (params) => ({
        url: 'authentication/portal-validation',
        headers: CONFIG.headers,
        method: 'GET',
        keepUnusedDataFor: 0,
        params,
      }),
    }),
  }),
});

export const { useLazyGetPortalDirectionQuery } = validationApi;
