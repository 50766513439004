import React from 'react';

import classNames from 'classnames/bind';

import { FaIcon } from '@components/FaIcon';
import { IconButton } from '@components/IconButton';
import { TextFieldProps, TextFieldDebounced, InputAdornment } from '@components/TextField';

import { locale } from './locale';

import styles from './SearchField.module.css';

const cn = classNames.bind(styles);

type Props = TextFieldProps & {
  onSearch: TextFieldProps['onChange'];
  debounceDelay?: number;
  leftIcon?: boolean;
  onClear?: VoidFunction;
  inputRootClassName?: string;
  inputClassName?: string;
};

export const SearchField = ({
  value,
  placeholder,
  onSearch,
  leftIcon,
  debounceDelay = 500,
  className,
  onClear,
  inputRootClassName,
  inputClassName,
  ...props
}: Props) => (
  <div className={cn('root', className)}>
    <TextFieldDebounced
      className={cn('search-input')}
      inputClassName={cn('input', inputClassName, { 'input--left-icon': leftIcon })}
      inputRootClassName={cn(inputRootClassName)}
      variant="outlined"
      placeholder={placeholder || locale.search}
      value={value}
      onChange={onSearch}
      name={placeholder || locale.search}
      InputProps={{
        classes: {
          root: cn('input-root'),
        },
        startAdornment: leftIcon && (
          <InputAdornment position="start">
            <FaIcon iconName="magnifying-glass" className={cn('search-icon', 'search-icon--left')} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start">
            {!leftIcon && <FaIcon iconName="magnifying-glass" className={cn('search-icon')} />}

            {!!onClear && !!value && (
              <IconButton className={cn('clear-icon')} disableRipple onClick={onClear}>
                <FaIcon iconName="xmark" size="small" />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      debounceDelay={debounceDelay}
      fullWidth
      {...props}
    />
  </div>
);
