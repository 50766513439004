// Cycle deps
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { cancelPreviousLogin, logout, logoutWithoutRevoke, reset } from '@modules/Login/store';
import { changeCurrentEligibility } from '@modules/userProfileData/store';

export const resetStoreActions = <S>(resetAction: () => S) => ({
  [logout.fulfilled.type]: resetAction,
  [logoutWithoutRevoke.fulfilled.type]: resetAction,
  [cancelPreviousLogin.type]: resetAction,
  [changeCurrentEligibility.fulfilled.type]: resetAction,
  [reset.fulfilled.type]: resetAction,
});

export const resetStoreActionsUsingBuilder = <S>(builder: ActionReducerMapBuilder<S>, resetAction: () => S) => {
  builder.addCase(logout.fulfilled, resetAction);
  builder.addCase(logoutWithoutRevoke.fulfilled, resetAction);
  builder.addCase(cancelPreviousLogin, resetAction);
  builder.addCase(changeCurrentEligibility.fulfilled, resetAction);
  builder.addCase(reset.fulfilled, resetAction);
};
