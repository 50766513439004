/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { useQueryParamsObject } from '@common/router';
import { MenuList } from '@components/MenuList';
import { useFullContentLoaded } from '@modules/gtmFulContentLoaded';

import {
  getConversationMessagesSelector,
  getConversationMessagesStatusSelector,
  getConversationMessagesNoDraftSelector,
  getMailboxStatusSelector,
  pageChangingSelector,
} from '../../store';
import { Message } from './components/Message';
import { MessageSkeleton } from './components/MessageSkeleton';

import styles from './MessageBox.module.css';

const cn = classNames.bind(styles);

type Props = {
  showActions?: Nullable<boolean>;
  className?: string;
  noDraft?: boolean;
};

type LocationState = {
  from?: number;
};

export const MessageBox = ({ showActions, className, noDraft = false }: Props) => {
  const location = useLocation();
  const { from } = (location.state as LocationState) || {};
  const { conversationId } = useQueryParamsObject();
  const conversationMessagesFull = useSelector(getConversationMessagesSelector);
  const conversationMessagesFilter = useSelector(getConversationMessagesNoDraftSelector);

  const conversationMessages = noDraft ? conversationMessagesFilter : conversationMessagesFull;

  const conversationMessagesStatus = useSelector(getConversationMessagesStatusSelector);
  const mailboxStatus = useSelector(getMailboxStatusSelector);
  const pageChanging = useSelector(pageChangingSelector);

  const isLoading =
    conversationMessagesStatus === StatusEnum.Pending ||
    (!pageChanging && (mailboxStatus === StatusEnum.Pending || mailboxStatus === StatusEnum.Uninitialized));

  const [debouncedIsLoading, setDebouncedIsLoading] = useState<Nullable<boolean>>(null);

  useIgnoreEffectDeps(() => {
    const timeout = setTimeout(() => {
      setDebouncedIsLoading(isLoading);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  useFullContentLoaded({
    loadingStarted: isLoading,
    loadingFinished: !isLoading,
  });

  const renderMessage = useMemo(
    () => (
      <MenuList className={cn('message-box__message-list')} component="div">
        {conversationMessages?.map((message, index) => (
          <Message
            message={message}
            key={message.id}
            showActions={showActions ?? index === 0}
            topMessage={index === 0}
            autoFocus={index === 0 && conversationId === from?.toString()}
          />
        ))}
      </MenuList>
    ),
    [conversationId, conversationMessages, from, showActions],
  );

  if (!conversationId && !isLoading) {
    return null;
  }

  return (
    <div className={cn('message-box', className)}>
      {debouncedIsLoading ? <MessageSkeleton isLoading={debouncedIsLoading} /> : renderMessage}
    </div>
  );
};
