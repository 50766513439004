import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { UseFormReset, FieldValues } from '@components/Form';

import { WellnessTypesEnum } from './constants';
import { resetSaveFormStatus } from './store';

export const useResetOnSuccess = <T extends FieldValues>(
  saveFormStatus: StatusEnum,
  reset: UseFormReset<T>,
  initialValues: T,
  type: WellnessTypesEnum,
) => {
  useIgnoreEffectDeps(() => {
    if (saveFormStatus === StatusEnum.Fulfilled) {
      reset(initialValues);
      resetSaveFormStatus({ type });
    }
  }, [saveFormStatus]);
};
