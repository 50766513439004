import { createStructuredSelector } from 'reselect';

const showLockerSelector = (state: RootState) => state.globalLocker.showLocker;
const lockerTitleSelector = (state: RootState) => state.globalLocker.title ?? '';
const lockerAriaLabelSelector = (state: RootState) => state.globalLocker.ariaLabel ?? '';
const hideLogoSelector = (state: RootState) => state.globalLocker.hideLogo;

export const globalLockerSelector = createStructuredSelector({
  showLocker: showLockerSelector,
  title: lockerTitleSelector,
  ariaLabel: lockerAriaLabelSelector,
  hideLogo: hideLogoSelector,
});
