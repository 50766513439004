import React, { ChangeEvent, useCallback, useState, useMemo, useEffect } from 'react';

import debounce from 'lodash.debounce';

import { TextField, TextFieldProps } from '../TextField';

export type TextFieldDebouncedProps = TextFieldProps & { debounceDelay: number };

export function TextFieldDebounced({ value, onChange, debounceDelay = 300, ...restProps }: TextFieldDebouncedProps) {
  const [viewValue, setViewValue] = useState(value);

  const debouncedOnChange = useMemo(
    () => debounce((event: ChangeEvent<HTMLInputElement>) => onChange?.(event), debounceDelay),
    [onChange, debounceDelay],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setViewValue(event.target.value);
      debouncedOnChange(event);
    },
    [debouncedOnChange],
  );

  useEffect(() => {
    setViewValue(value);
  }, [value]);

  return <TextField onChange={handleChange} value={viewValue} {...restProps} />;
}
