import { MailboxTypeEnum } from './constants';

export const locale = {
  header: 'Messages',
  new_message: 'New Message',
  messages_types: {
    [MailboxTypeEnum.Inbox]: 'Inbox',
    [MailboxTypeEnum.Sent]: 'Sent',
    [MailboxTypeEnum.Draft]: 'Draft',
    [MailboxTypeEnum.Archive]: 'Archive',
  },
  archive_warning:
    'We are required to not permanently delete messages, so an archived message is just removed from the inbox.',
  conversation_archived: 'Conversation archived',
  conversation_restored: 'Conversation restored',
  draft_deleted: 'Draft deleted',
};
