import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { saveLogItem } from '@modules/WellnessLog';
import { resetStoreActions } from '@modules/reset';

import {
  deleteCustomRecord,
  editConsent,
  getActiveChallengesList,
  getChallenge,
  getPastChallengesList,
  getRecordsList,
  getTeamsList,
  personalSignUp,
  saveCustomRecord,
  teamSignUp,
  unregister,
} from './actions';
import {
  ActiveChallengesType,
  ChallengesState,
  ChallengeType,
  EditConsentVisibilityPayloadType,
  Record,
  RecordModalVisibilityPayloadType,
  RecordsListModalVisibilityPayloadType,
  SignUpModalVisibilityPayloadType,
  Team,
  UnregisterModalVisibilityPayloadType,
} from './types';

const initialState: ChallengesState = {
  activeChallengesList: null,
  activeChallengesStatus: StatusEnum.Uninitialized,
  pastChallengesList: null,
  pastChallengesStatus: StatusEnum.Uninitialized,
  recordActivityModalOpened: false,
  activitySaveStatus: StatusEnum.Uninitialized,
  challengeId: 0,
  recordId: 0,
  recordDate: null,
  recordAmount: null,

  recordsListModalOpened: false,
  recordsListLoadStatus: StatusEnum.Uninitialized,
  recordsList: null,

  activityDeleteStatus: StatusEnum.Uninitialized,

  signUpModalOpened: false,
  signUpSaveStatus: StatusEnum.Uninitialized,
  signUpType: null,

  teamsListLoadStatus: StatusEnum.Uninitialized,
  teamsList: undefined,

  unregisterModalOpened: false,
  unregisterSaveStatus: StatusEnum.Uninitialized,

  editConsentModalOpened: false,
  editConsentSaveStatus: StatusEnum.Uninitialized,

  wellnessLogSaveStatus: StatusEnum.Uninitialized,
};

const challengesSlice = createSlice({
  name: 'challenges',
  initialState,
  reducers: {
    setChallengeId(state: ChallengesState, action: PayloadAction<{ challengeId?: number }>) {
      state.challengeId = action.payload.challengeId ?? initialState.challengeId;
    },
    setRecordModalVisibility(state: ChallengesState, action: PayloadAction<RecordModalVisibilityPayloadType>) {
      state.recordActivityModalOpened = action.payload.visibility;
      state.activitySaveStatus = initialState.activitySaveStatus;
      state.activityDeleteStatus = initialState.activityDeleteStatus;

      state.recordId = action.payload.recordId ?? initialState.recordId;
      state.recordDate = action.payload.initialValues?.date ?? initialState.recordDate;
      state.recordAmount = action.payload.initialValues?.amount ?? initialState.recordAmount;

      if (action.payload.challengeId) {
        state.challengeId = action.payload.challengeId;
      }
    },
    setRecordsListModalVisibility(
      state: ChallengesState,
      action: PayloadAction<RecordsListModalVisibilityPayloadType>,
    ) {
      state.recordsListModalOpened = action.payload.visibility;
      state.activitySaveStatus = initialState.activitySaveStatus;
      state.activityDeleteStatus = initialState.activityDeleteStatus;

      state.challengeId = action.payload.challengeId ?? initialState.challengeId;
    },
    setSignUpModalVisibility(state: ChallengesState, action: PayloadAction<SignUpModalVisibilityPayloadType>) {
      state.signUpModalOpened = action.payload.visibility;
      state.signUpSaveStatus = initialState.activitySaveStatus;
      state.signUpType = action.payload.signUpType;
      state.teamsListLoadStatus = initialState.teamsListLoadStatus;
      state.teamsList = initialState.teamsList;

      state.challengeId = action.payload.challengeId ?? initialState.challengeId;
    },
    setUnregisterModalVisibility(state: ChallengesState, action: PayloadAction<UnregisterModalVisibilityPayloadType>) {
      state.unregisterModalOpened = action.payload.visibility;
      state.unregisterSaveStatus = initialState.unregisterSaveStatus;

      state.challengeId = action.payload.challengeId ?? initialState.challengeId;
    },
    setEditConsentModalVisibility(state: ChallengesState, action: PayloadAction<EditConsentVisibilityPayloadType>) {
      state.editConsentModalOpened = action.payload.visibility;
      state.editConsentSaveStatus = initialState.editConsentSaveStatus;

      state.challengeId = action.payload.challengeId ?? initialState.challengeId;
    },
  },
  extraReducers: {
    [getActiveChallengesList.pending.type]: (state: ChallengesState) => {
      state.activeChallengesStatus = StatusEnum.Pending;
    },
    [getActiveChallengesList.fulfilled.type]: (state: ChallengesState, action: PayloadAction<ActiveChallengesType>) => {
      state.activeChallengesStatus = StatusEnum.Fulfilled;
      state.activeChallengesList = action.payload;
    },
    [getActiveChallengesList.rejected.type]: (state: ChallengesState) => {
      state.activeChallengesStatus = StatusEnum.Rejected;
    },
    [getChallenge.fulfilled.type]: (state: ChallengesState, action: PayloadAction<ChallengeType>) => {
      if (state.activeChallengesList) {
        const keys = Object.keys(state.activeChallengesList) as Array<keyof ActiveChallengesType>;
        keys.forEach((key) => {
          // TS asks for this check
          if (state.activeChallengesList) {
            state.activeChallengesList[key] = state.activeChallengesList[key].map((challenge) => {
              if (challenge.id === action.payload.id) {
                return action.payload;
              }

              return challenge;
            });
          }
        });
      }
    },

    [getPastChallengesList.pending.type]: (state: ChallengesState) => {
      state.pastChallengesStatus = StatusEnum.Pending;
    },
    [getPastChallengesList.fulfilled.type]: (state: ChallengesState, action: PayloadAction<ChallengeType[]>) => {
      state.pastChallengesStatus = StatusEnum.Fulfilled;
      state.pastChallengesList = action.payload;
    },
    [getPastChallengesList.rejected.type]: (state: ChallengesState) => {
      state.pastChallengesStatus = StatusEnum.Rejected;
    },
    [getRecordsList.pending.type]: (state: ChallengesState) => {
      state.recordsListLoadStatus = StatusEnum.Pending;
    },
    [getRecordsList.fulfilled.type]: (state: ChallengesState, action: PayloadAction<Record[]>) => {
      state.recordsListLoadStatus = StatusEnum.Fulfilled;
      state.recordsList = action.payload;
    },
    [getRecordsList.rejected.type]: (state: ChallengesState) => {
      state.recordsListLoadStatus = StatusEnum.Rejected;
    },
    [saveCustomRecord.pending.type]: (state: ChallengesState) => {
      state.activitySaveStatus = StatusEnum.Pending;
    },
    [saveCustomRecord.fulfilled.type]: (state: ChallengesState) => {
      state.activitySaveStatus = StatusEnum.Fulfilled;
    },
    [saveCustomRecord.rejected.type]: (state: ChallengesState) => {
      state.activitySaveStatus = StatusEnum.Rejected;
    },
    [deleteCustomRecord.pending.type]: (state: ChallengesState) => {
      state.activityDeleteStatus = StatusEnum.Pending;
    },
    [deleteCustomRecord.fulfilled.type]: (state: ChallengesState) => {
      state.activityDeleteStatus = StatusEnum.Fulfilled;
    },
    [deleteCustomRecord.rejected.type]: (state: ChallengesState) => {
      state.activityDeleteStatus = StatusEnum.Rejected;
    },
    [personalSignUp.pending.type]: (state: ChallengesState) => {
      state.signUpSaveStatus = StatusEnum.Pending;
    },
    [personalSignUp.fulfilled.type]: (state: ChallengesState) => {
      state.signUpSaveStatus = StatusEnum.Fulfilled;
    },
    [personalSignUp.rejected.type]: (state: ChallengesState) => {
      state.signUpSaveStatus = StatusEnum.Rejected;
    },
    [teamSignUp.pending.type]: (state: ChallengesState) => {
      state.signUpSaveStatus = StatusEnum.Pending;
    },
    [teamSignUp.fulfilled.type]: (state: ChallengesState) => {
      state.signUpSaveStatus = StatusEnum.Fulfilled;
    },
    [teamSignUp.rejected.type]: (state: ChallengesState) => {
      state.signUpSaveStatus = StatusEnum.Rejected;
    },
    [getTeamsList.pending.type]: (state: ChallengesState) => {
      state.teamsListLoadStatus = StatusEnum.Pending;
    },
    [getTeamsList.fulfilled.type]: (state: ChallengesState, action: PayloadAction<Team[]>) => {
      state.teamsListLoadStatus = StatusEnum.Fulfilled;
      state.teamsList = action.payload;
    },
    [getTeamsList.rejected.type]: (state: ChallengesState) => {
      state.teamsListLoadStatus = StatusEnum.Rejected;
    },
    [unregister.pending.type]: (state: ChallengesState) => {
      state.unregisterSaveStatus = StatusEnum.Pending;
    },
    [unregister.fulfilled.type]: (state: ChallengesState) => {
      state.unregisterSaveStatus = StatusEnum.Fulfilled;
    },
    [unregister.rejected.type]: (state: ChallengesState) => {
      state.unregisterSaveStatus = StatusEnum.Rejected;
    },
    [editConsent.pending.type]: (state: ChallengesState) => {
      state.editConsentSaveStatus = StatusEnum.Pending;
    },
    [editConsent.fulfilled.type]: (state: ChallengesState) => {
      state.editConsentSaveStatus = StatusEnum.Fulfilled;
    },
    [editConsent.rejected.type]: (state: ChallengesState) => {
      state.editConsentSaveStatus = StatusEnum.Rejected;
    },
    [saveLogItem.pending.type]: (state: ChallengesState) => {
      state.wellnessLogSaveStatus = StatusEnum.Pending;
    },
    [saveLogItem.fulfilled.type]: (state: ChallengesState) => {
      state.wellnessLogSaveStatus = StatusEnum.Fulfilled;
    },
    [saveLogItem.rejected.type]: (state: ChallengesState) => {
      state.wellnessLogSaveStatus = StatusEnum.Rejected;
    },
    ...resetStoreActions(() => initialState),
  },
});

export const challengesReducer = challengesSlice.reducer;
export const {
  setChallengeId,
  setRecordModalVisibility,
  setRecordsListModalVisibility,
  setSignUpModalVisibility,
  setUnregisterModalVisibility,
  setEditConsentModalVisibility,
} = challengesSlice.actions;
