export const downLoadFromBlob = (blob: Blob, type: string, fileName: string) => {
  const file = new Blob([blob], { type });
  const url = window.URL || window.webkitURL;
  const link = url.createObjectURL(file);
  const a = document.createElement('a');
  a.setAttribute('download', fileName);
  a.setAttribute('href', link);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
