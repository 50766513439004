import { ReactElement } from 'react';

import { useFlippers } from './hooks/useFlippers';
import { FlipperType } from './types';
import { isHideFlipperKey } from './utils';

type FlipperProps = {
  flipper?: Nullable<FlipperType>;
  children: ReactElement;
};

export function Flipper({ flipper, children }: FlipperProps) {
  const getFlipperValue = useFlippers();

  if (!flipper) {
    return children;
  }

  if (isHideFlipperKey(flipper)) {
    const hide = getFlipperValue(flipper);

    return hide ? null : children;
  }

  const show = getFlipperValue(flipper);

  return show ? children : null;
}
