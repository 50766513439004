import React from 'react';

import { DatePicker, DatePickerProps } from '@components/DatePicker';

import { useController, UseControllerProps, FieldValues } from '../hooks';
import { locale } from '../locale';

type DatePickerWithControlProps<FormValues extends FieldValues> = {
  name: string;
  required?: string | boolean;
  placeholder?: string;
  onChange?: DatePickerProps['onChange'];
  validate?: (value: Nullable<Date | string>, format?: string) => boolean | string | undefined;
} & UseControllerProps<FormValues> &
  Omit<DatePickerProps, 'value' | 'onChange' | 'required'>;

export function DatePickerWithControl<FormValues extends FieldValues>({
  control,
  name,
  required,
  onChange,
  validate,
  inputRef,
  ...restProps
}: DatePickerWithControlProps<FormValues>) {
  const requiredMessage = typeof required === 'boolean' && required ? locale.required_message : required;
  const customValidate = (value: Nullable<Date | string>) => validate?.(value, restProps.format);

  const {
    field: { onChange: controllerOnChange, value, ref },
    fieldState,
  } = useController({
    name,
    control,
    rules: { required: requiredMessage, validate: customValidate },
  });

  return (
    <DatePicker
      {...restProps}
      inputRef={inputRef || ref}
      required={!!required}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      onChange={onChange || controllerOnChange}
      value={value}
      name={name}
    />
  );
}
