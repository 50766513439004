import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const fetchFAQS = createAsyncThunk(
  'categories/fetchFAQ',
  async ({ employerId, articleCategoryId }: { employerId: number; articleCategoryId: number }, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/Article/get-articles',
        params: {
          employerId,
          articleCategoryId,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
