import { formatDate } from '@common/utils';

import { FIELD_NAMES, ContactMethodsEnum } from '../constants';
import { FormValuesTypes } from '../types';
import { BasicInfo, ValidateUserPreregistrationUserPayload } from './types';

const DEFAULT_CONTACT_METHODS = `${ContactMethodsEnum.Email}, ${ContactMethodsEnum.Home}, ${ContactMethodsEnum.Cell}`;
const withDefaultNullValue = (value: Nullable<string | string[] | number>) => value || null;

export const convertFormToPotentialSignUpData = (data: FormValuesTypes, campaign?: Nullable<string>) => ({
  potential: {
    id: 0,
    email: withDefaultNullValue(data[FIELD_NAMES.EMAIL]),
    first_name: withDefaultNullValue(data[FIELD_NAMES.FIRST_NAME]),
    last_name: withDefaultNullValue(data[FIELD_NAMES.LAST_NAME]),
    born_on: data[FIELD_NAMES.BIRTH_DATE]
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore nullable value checked
        formatDate(data[FIELD_NAMES.BIRTH_DATE], 'yyyy-MM-dd')
      : null,
    home_phone_number: withDefaultNullValue(data[FIELD_NAMES.HOME_PHONE_NUMBER]),
    cell_phone_number: withDefaultNullValue(data[FIELD_NAMES.CELL_PHONE_NUMBER]),
    proposed_employer: withDefaultNullValue(data[FIELD_NAMES.PROPOSED_EMPLOYER]),
    proposed_employee_first_name: withDefaultNullValue(data[FIELD_NAMES.PROPOSED_EMPLOYEE_FIRST_NAME]),
    proposed_employee_ssn: withDefaultNullValue(data[FIELD_NAMES.PROPOSED_EMPLOYEE_SOCIAL_SECURITY_NUMBER]),
    social_security_number: withDefaultNullValue(data[FIELD_NAMES.SOCIAL_SECURITY_NUMBER]),
    proposed_employee_last_name: withDefaultNullValue(data[FIELD_NAMES.PROPOSED_EMPLOYEE_LAST_NAME]),
    proposed_employee_born_on: data?.[FIELD_NAMES.PROPOSED_EMPLOYEE_BIRTH_DATE]
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore nullable value checked
        formatDate(data[FIELD_NAMES.PROPOSED_EMPLOYEE_BIRTH_DATE], 'yyyy-MM-dd')
      : null,
    proposed_eligibility_type_id: withDefaultNullValue(Number(data[FIELD_NAMES.PROPOSED_ELIGIBILITY])),
    campaign_slug: campaign ?? null,
  },
  home_address: {
    id: 0,
    street_address1: withDefaultNullValue(data[FIELD_NAMES.STREET_ADDRESS1]),
    street_address2: withDefaultNullValue(data[FIELD_NAMES.STREET_ADDRESS2]),
    city: withDefaultNullValue(data[FIELD_NAMES.CITY]),
    state_abbr: withDefaultNullValue(data[FIELD_NAMES.STATE_ABBR]),
    zip_code: withDefaultNullValue(data[FIELD_NAMES.ZIP]),
  },
});

const trasnformToPreferredOrderOfContact = (data: FormValuesTypes) => {
  const contactMethods = [
    data[FIELD_NAMES.PREFERRED_CONTACT_METHOD_1],
    data[FIELD_NAMES.PREFERRED_CONTACT_METHOD_2],
    data[FIELD_NAMES.PREFERRED_CONTACT_METHOD_3],
  ].filter(Boolean);

  return contactMethods.length === 0 ? DEFAULT_CONTACT_METHODS : contactMethods.join(',');
};

export const convertFormToSignUpData = (data: FormValuesTypes, basicInfo: Nullable<BasicInfo>) => {
  const bornOn = data[FIELD_NAMES.BIRTH_DATE] || '';

  return {
    id: basicInfo?.id ?? null,
    username: withDefaultNullValue(data[FIELD_NAMES.USERNAME] || basicInfo?.username),
    first_name: withDefaultNullValue(data[FIELD_NAMES.FIRST_NAME]),
    last_name: withDefaultNullValue(data[FIELD_NAMES.LAST_NAME]),
    born_on: typeof bornOn === 'string' ? bornOn : formatDate(bornOn, 'yyyy-MM-dd'),
    gender: withDefaultNullValue(data[FIELD_NAMES.GENDER]),
    social_security_number: withDefaultNullValue(data[FIELD_NAMES.SOCIAL_SECURITY_NUMBER]),
    home_phone_number: withDefaultNullValue(data[FIELD_NAMES.HOME_PHONE_NUMBER]),
    cell_phone_number: withDefaultNullValue(data[FIELD_NAMES.CELL_PHONE_NUMBER]),
    email: withDefaultNullValue(data[FIELD_NAMES.EMAIL]),
    home_address: {
      id: withDefaultNullValue(basicInfo?.home_address.id),
      street_address1: withDefaultNullValue(data[FIELD_NAMES.STREET_ADDRESS1]),
      street_address2: withDefaultNullValue(data[FIELD_NAMES.STREET_ADDRESS2]),
      city: withDefaultNullValue(data[FIELD_NAMES.CITY]),
      state_abbr: withDefaultNullValue(data[FIELD_NAMES.STATE_ABBR]),
      zip_code: withDefaultNullValue(data[FIELD_NAMES.ZIP]),
    },
    password: withDefaultNullValue(data[FIELD_NAMES.PASSWORD]),
    password_confirm: withDefaultNullValue(data[FIELD_NAMES.PASSWORD_CONFIRM]),
    primary_language: withDefaultNullValue(data[FIELD_NAMES.PRIMARY_LANGUAGE]),
    available_user_names: withDefaultNullValue(basicInfo?.available_user_names),
    preferred_order_of_contact: trasnformToPreferredOrderOfContact(data),
    registration_notice_token: withDefaultNullValue(basicInfo?.registration_notice_token),
  };
};

export const convertFormToValidateUserPreregistration = (data: ValidateUserPreregistrationUserPayload) => ({
  registration_notice_token: data.registration_notice_token,
  zip_code: data[FIELD_NAMES.ZIP],
  social_security_number: data[FIELD_NAMES.SOCIAL_SECURITY_NUMBER],
  born_on: formatDate(data[FIELD_NAMES.BIRTH_DATE], 'yyyy-MM-dd'),
});
