import React from 'react';

import classNames from 'classnames/bind';

import { Markdown } from '@components/Markdown';
import { MenuItem } from '@components/Menu';
import { MessageType } from '@modules/Messages/store';

import { MessageActions } from './MessageActions';
import { MessageHeader } from './MessageHeader';

import styles from '../MessageBox.module.css';

const cn = classNames.bind(styles);
type Props = {
  message: MessageType;
  showActions: boolean;
  topMessage: boolean;
  autoFocus?: boolean;
};

const MESSAGE_HEADER_ID = 'message_header';
const MESSAGE_BODY_ID = 'message_body';

export const Message = ({ message, showActions, topMessage, autoFocus }: Props) => {
  const messageBodyId = `${MESSAGE_BODY_ID}_${message.id}`;
  const messageHeaderId = `${MESSAGE_HEADER_ID}_${message.id}`;

  return (
    <MenuItem
      noHover
      disableRipple
      className={cn('message-box__message')}
      aria-labelledby={messageHeaderId}
      aria-describedby={messageBodyId}
      autoFocus={autoFocus}
    >
      <div className={cn('message-box__content')} role="document">
        <div className={cn('message-box__header-container')}>
          <MessageHeader message={message} topMessage={topMessage} id={messageHeaderId} />
          {showActions && <MessageActions />}
        </div>
        <div id={messageBodyId}>
          <Markdown className={cn('message-box__body')} markdown={message.body} />
        </div>
      </div>
    </MenuItem>
  );
};
