import React from 'react';

import GridMaterial, { GridProps as GridPropsMaterial } from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { THEME_BREAK_POINTS } from '@common/constants/breackpoints';

export type GridProps = {
  className?: string;
} & Pick<
  GridPropsMaterial,
  | 'container'
  | 'spacing'
  | 'item'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'columnSpacing'
  | 'direction'
  | 'children'
  | 'rowSpacing'
  | 'style'
>;

const theme = createTheme({
  breakpoints: {
    values: {
      xs: THEME_BREAK_POINTS.screenMobileMin, // extra-small
      sm: THEME_BREAK_POINTS.screenMobileMax, // small
      md: THEME_BREAK_POINTS.screenTabletMin, // medium
      lg: THEME_BREAK_POINTS.screenLaptopMin, // large
      xl: THEME_BREAK_POINTS.screenDesktopMin, // extra-large
    },
  },
});

export function Grid({ children, ...restProps }: GridProps) {
  return (
    <ThemeProvider theme={theme}>
      <GridMaterial {...restProps}>{children}</GridMaterial>
    </ThemeProvider>
  );
}
