import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { downLoadFromBlob } from '@common/utils/downloadFileFromBlob';
import { downloadFileFromUrl } from '@common/utils/downloadFileFromUrl';
import { enqueueSnackbar } from '@modules/Snackbar';
import { uploadDocument, SubmitUploadDocumentParams } from '@modules/UploadDocumentModal';
import { getDocumentsRequest, GetDocumentsRequestPayloadType, DocumentsResponse } from '@modules/getDocuments';

import { ParamsWithDependentUserId } from '../../../types';
import { DEFAULT_ITEMS_ON_PAGE, DEFAULT_PAGE, DEFAULT_SORT_ID, DEFAULT_SORT_ORDER } from '../constants';
import { locale } from '../locale';
import { DownloadEmrDocumentType, GetDocumentsParamsType, DownloadEmrDocumentsListPayload } from './types';

export const getDocuments = createAsyncThunk<
  DocumentsResponse,
  ParamsWithDependentUserId<GetDocumentsParamsType> | undefined
>('Document/documents', async (params, { rejectWithValue }) => {
  const { dependentUserId, orderBy, order, search, currentPage } = params ?? {};

  const dataForRequest: GetDocumentsRequestPayloadType = {
    page_size: DEFAULT_ITEMS_ON_PAGE,
    page_number: currentPage ?? DEFAULT_PAGE,
  };

  if (orderBy || order) {
    dataForRequest.sorts = [
      {
        is_transient: false,
        property_name: orderBy ?? DEFAULT_SORT_ID,
        direction: order ?? DEFAULT_SORT_ORDER,
      },
    ];
  }

  if (search) {
    dataForRequest.filters = [
      {
        is_transient: false,
        property_name: DEFAULT_SORT_ID,
        filter_value: search,
        filter_operator: 'contains',
      },
    ];
  }

  try {
    return await getDocumentsRequest({
      dependentUserId,
      data: dataForRequest,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const downloadDocument = createAsyncThunk<unknown, number>(
  'Document/download-document',
  async (documentId: number, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/Document/get-document-url',
        params: {
          documentId,
        },
      });

      const res = await data.response;
      downloadFileFromUrl(res?.data);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getEmrDocumentsList = createAsyncThunk<DownloadEmrDocumentType[], DownloadEmrDocumentsListPayload>(
  'Document/get-emr-document-urls',
  async (params, { rejectWithValue }) => {
    try {
      const data = fetch.post({
        url: '/Document/get-emr-document-urls',
        data: params,
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadEmrDocument = createAsyncThunk<unknown, DownloadEmrDocumentType>(
  'Document/get-emr-document-content',
  async (data: DownloadEmrDocumentType, { rejectWithValue }) => {
    const { fileName, ...restDate } = data;
    try {
      const responseData = fetch.post({
        url: '/Document/get-emr-document-content',
        responseType: 'blob',
        data: restDate,
      });

      const res = await responseData.response;

      downLoadFromBlob(res?.data, data.content_type, fileName);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const removeDocument = createAsyncThunk<unknown, number>(
  'Document/remove-document',
  async (documentId: number, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.post({
        url: '/Document/remove-document',
        data: {
          document_id: documentId,
        },
      });

      const res = await data.response;

      dispatch(
        enqueueSnackbar({
          message: locale.successfully_deleted,
          options: {
            variant: 'success',
          },
        }),
      );

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addDocument = createAsyncThunk<unknown, SubmitUploadDocumentParams>(
  'Document/add-document',
  async (
    { attachment, document, orderBy, order, search }: SubmitUploadDocumentParams & GetDocumentsParamsType,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const documentId = await uploadDocument({ attachment, document });

      dispatch(
        enqueueSnackbar({
          message: locale.successfully_uploaded,
          options: {
            variant: 'success',
          },
        }),
      );

      dispatch(getDocuments({ dependentUserId: document.UserId, orderBy, order, search }));

      return documentId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
