const getStorageArray = (storageKey: string) => {
  const items = sessionStorage.getItem(storageKey);

  return items?.split(',') ?? [];
};

export const addToStorageArray = (item: string, storageKey: string) => {
  const itemsArray = getStorageArray(storageKey);

  sessionStorage.setItem(storageKey, [...itemsArray, item].join(','));
};

export const getLastStorageArrayItem = (key: string) => {
  const array = getStorageArray(key);

  return array[array.length - 1];
};

export const addToStorageObjectKeyValue = (value: string, key: string, storageKey: string) => {
  const storageValue = sessionStorage.getItem(storageKey);
  const parsedValue = storageValue ? JSON.parse(storageValue) : {};
  const newValue = {
    ...parsedValue,
    [key]: value,
  };

  sessionStorage.setItem(storageKey, JSON.stringify(newValue));
};
