import qs from 'qs';

export type TabItemProp = {
  id?: number | string;
  to?: string;
  subMenuItems?: TabItemProp[];
};

const checkPathInclusion = (pathOne = '', pathTwo = ''): boolean => {
  const arrayOne = pathOne.split('/').filter(Boolean);
  const arrayTwo = pathTwo.split('/').filter(Boolean);

  const shorterArray = arrayTwo.length >= arrayOne.length ? arrayOne : arrayTwo;
  const longerArray = arrayTwo.length >= arrayOne.length ? arrayTwo : arrayOne;

  if (shorterArray.length === 0 && longerArray.length === 0) {
    // home page path case
    return true;
  }

  return shorterArray.length !== 0 && shorterArray.every((item, index) => item === longerArray[index]);
};
export const matchPath = (pathname = '', to = ''): boolean => {
  if (to && pathname && checkPathInclusion(pathname, to)) {
    return true;
  }

  const splittedPathName = pathname.split('/').filter(Boolean);

  return splittedPathName.includes(to.replace('/', ''));
};

export const getTabById = <TableItem extends TabItemProp>(
  tabId: number | string | undefined,
  tabList: Array<TableItem>,
) => tabList.findIndex(({ id }) => `${id}` === `${tabId}`);

export const getMenuId = <ID>(id: ID) => `sub-menu-${id}`;

export const getTab = <TableItem extends TabItemProp>(
  pathname: string,
  tabList: Array<TableItem>,
): TableItem | undefined =>
  tabList.find(({ to, subMenuItems }) =>
    subMenuItems ? matchPath(pathname, to) || getTab(pathname, subMenuItems) : matchPath(pathname, to),
  );

export const getInitialTabByPath = <TableItem extends TabItemProp>(pathname: string, tabList: Array<TableItem>) => {
  const tab = getTab(pathname, tabList);

  return tab?.id;
};

export const getInitialTabByQuery = <TableItem extends TabItemProp>(
  query: string,
  tabList: Array<TableItem>,
  key = 'id',
) => {
  const param = qs.parse(query?.slice(1));
  const id = param[key];

  const tab = tabList[getTabById(id as string, tabList)];

  return tab?.id;
};
