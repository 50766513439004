import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getProviders = createAsyncThunk(
  'Appointment/providers-for-current-user',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/Appointment/providers-for-current-user',
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
