import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { enqueueSnackbar } from '@modules/Snackbar';
import { SubmitUploadDocumentParams, uploadDocument } from '@modules/UploadDocumentModal';
import {
  DocumentFilterFieldsEnum,
  DocumentsResponse,
  getDocumentsRequest,
  GetDocumentsRequestPayloadType,
  DocumentSourceEnum,
  DocumentTypeIdsEnum,
} from '@modules/getDocuments';

import { locale } from '../locale';
import { SelfRecordedGoalType } from './types';

export const getCurrentEarningSummary = createAsyncThunk(
  'Incentive/current-earning-summary',
  async (programId: number, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/Incentive/earning-summary',
        params: { programId, calcPending: true },
      });
      const res = await data.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getShortCurrentEarningSummary = createAsyncThunk(
  'Incentive/short-earning-summary',
  async (programId: number, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/Incentive/short-earning-summary',
        params: { programId },
      });
      const res = await data.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getEarningSummary = createAsyncThunk(
  'Incentive/earning-summary',
  async (programId: number, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/Incentive/earning-summary',
        params: { programId },
      });

      const res = await data.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getHistoricalPrograms = createAsyncThunk(
  'Incentive/historical-programs',
  async (_, { rejectWithValue }) => {
    try {
      const data = await fetch.get({ url: '/Incentive/historical-programs' });
      const res = await data.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCurrentGoal = createAsyncThunk(
  'Incentive/get-current-goal',
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await fetch.get({ url: `/Incentive/get-current-goal?goalId=${id}` });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveSelfReportedEntry = createAsyncThunk(
  'Incentive/incentive-achievement',
  async (goal: SelfRecordedGoalType, { rejectWithValue, dispatch }) => {
    try {
      const data = await fetch.post({ url: '/Incentive/incentive-achievement', data: goal });
      const res = await data.response;

      dispatch(
        enqueueSnackbar({
          message: goal.id ? locale.goal_updated : locale.goal_recorded,
          options: {
            variant: 'success',
          },
        }),
      );

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSelfReportedEntry = createAsyncThunk(
  'Incentive/get-incentive-achievement',
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await fetch.get({ url: `/Incentive/incentive-achievement/${id}` });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteSelfReportedEntry = createAsyncThunk(
  'Incentive/delete-incentive-achievement',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const data = await fetch.delete({ url: `/Incentive/incentive-achievement/${id}` });
      const res = await data.response;

      dispatch(
        enqueueSnackbar({
          message: locale.goal_deleted,
          options: {
            variant: 'success',
          },
        }),
      );

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getListOfDocuments = createAsyncThunk<DocumentsResponse, { userId?: number; search?: string }>(
  'Inscentive/list-of-documents',
  async ({ userId, search }, { rejectWithValue }) => {
    const dataForRequest: GetDocumentsRequestPayloadType = {
      filters: [
        {
          is_transient: false,
          property_name: DocumentFilterFieldsEnum.DocumentTypeKey,
          filter_value: DocumentTypeIdsEnum.incentive,
          filter_operator: 'equals',
        },
        {
          is_transient: false,
          property_name: DocumentFilterFieldsEnum.Source,
          filter_value: DocumentSourceEnum.Portal,
          filter_operator: 'equals',
        },
        {
          is_transient: false,
          property_name: DocumentFilterFieldsEnum.DisplayName,
          filter_value: search ?? '',
          filter_operator: 'contains',
        },
      ],
    };

    try {
      return await getDocumentsRequest({
        dependentUserId: userId,
        data: dataForRequest,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const uploadIncentivesForm = createAsyncThunk<
  unknown,
  {
    goalId: number;
    documentParams?: SubmitUploadDocumentParams;
    existingDocumentId?: number;
    comment?: Nullable<string>;
  }
>(
  'Incentive/upload-incentives-form',
  async ({ goalId, documentParams, existingDocumentId, comment }, { rejectWithValue }) => {
    try {
      let documentId;
      if (existingDocumentId) {
        documentId = existingDocumentId;
      } else if (documentParams) {
        documentId = await uploadDocument(documentParams);
      }

      const data = await fetch.post({
        url: '/Incentive/incentive-document/',
        data: {
          document_id: documentId,
          incentive_goal_id: goalId,
          comment: comment || documentParams?.document?.Notes,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getIncentiveDocument = createAsyncThunk<unknown, number>(
  'Incentive/incentive-document',
  async (incentiveDocumentId: number, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: `/Incentive/incentive-document/${incentiveDocumentId}` });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const removeIncentiveDocument = createAsyncThunk<unknown, { incentiveDocumentId: number }>(
  'Incentive/remove-incentive-document',
  async ({ incentiveDocumentId }, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.delete({ url: `/Incentive/incentive-document/${incentiveDocumentId}` });
      const res = await data.response;

      dispatch(
        enqueueSnackbar({
          message: locale.incentive_document_removed,
          options: {
            variant: 'success',
          },
        }),
      );

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getActivePrograms = createAsyncThunk<unknown>(
  'Incentive/active-programs',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: '/Incentive/active-programs' });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const checkProgramCompletionAcknowledged = createAsyncThunk<boolean, { userId: number; programId: number }>(
  'Incentive/get-acknowledged-completed-program',
  async ({ userId, programId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: `/Incentive/acknowledged_completed_program/${userId}/${programId}` });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setProgramCompletionAcknowledged = createAsyncThunk<boolean, { userId: number; programId: number }>(
  'Incentive/put-acknowledge-completed-program',
  async ({ userId, programId }, { rejectWithValue }) => {
    try {
      const data = fetch.put({ url: `/Incentive/acknowledge_completed_program/${userId}/${programId}` });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
