import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { useFullContentLoaded } from '@modules/gtmFulContentLoaded';
import { currentEligibilityEmployerIdSelector } from '@modules/userProfileData';

import { categoriesSelector, getUserCategories } from '../store';

export function useFetchCategories() {
  const { status } = useSelector(categoriesSelector);
  const currentEmployerId = useSelector(currentEligibilityEmployerIdSelector);
  const dispatch = useDispatch();

  useFullContentLoaded({
    loadingStarted: status === StatusEnum.Pending,
    loadingFinished: status === StatusEnum.Fulfilled,
  });

  useIgnoreEffectDeps(() => {
    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(status) && currentEmployerId) {
      dispatch(getUserCategories(currentEmployerId));
    }
  }, [currentEmployerId]);
}
