import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { downLoadFromBlob } from '@common/utils';
import { enqueueSnackbar, EnqueueSnackbarType } from '@modules/Snackbar';

import { locale } from '../locale';
import { downloadCompletedQuestionnairesURLMap, sendHealthRecordURLMap } from './constants';
import { GetSectionPayload, SaveHRAPayload, SendHealthReacordPayload } from './types';

export const getInProgressQuestionnaries = createAsyncThunk(
  'questionnaries/fetchInProgress',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/Questionnaire/get-in-progress-questionnaires',
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCompletedQuestionnaries = createAsyncThunk(
  'questionnaries/fetchCompleted',

  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/Questionnaire/get-completed-questionnaires',
      });

      const res = await data.response;

      return res?.data?.completed_questionnaires;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const sendToHealthRecord = createAsyncThunk(
  'questionnaries/sendHealthRecord',
  async ({ id, viewText }: SendHealthReacordPayload, { rejectWithValue, dispatch }) => {
    const URL = sendHealthRecordURLMap[viewText];
    try {
      const data = fetch.post({
        url: URL,
        data: {
          questionnaire_id: id,
        },
      });

      const res = await data.response;
      const successMessage: EnqueueSnackbarType = {
        message: locale.message,
        options: {
          variant: 'success',
        },
      };

      if (res?.data?.success) {
        dispatch(enqueueSnackbar(successMessage));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchQuestionnaireSection = createAsyncThunk(
  'questionnaries/fetchSection',
  async ({ id, url }: GetSectionPayload, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url,
        params: {
          questionnaireId: id,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveHRA = createAsyncThunk(
  'questionnaries/saveHRA',
  async ({ url, postData }: SaveHRAPayload, { rejectWithValue }) => {
    if (url) {
      try {
        const data = fetch.post({
          url,
          data: postData,
        });

        const res = await data.response;

        return res?.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  },
);

export const completeHRA = createAsyncThunk('questionnaries/completeHRA', async (_, { rejectWithValue, dispatch }) => {
  try {
    const data = fetch.post({
      url: '/QuestionnaireHra/complete-in-progress-hra',
    });

    const res = await data.response;
    dispatch(getCompletedQuestionnaries());
    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getWhereIBelong = createAsyncThunk(
  'questionnaries/whereIBelong',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.get({
        url: '/QuestionnaireHra/get-where-i-belong-hra',
      });

      const res = await data.response;

      dispatch(getInProgressQuestionnaries());

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteInProgressHRA = createAsyncThunk(
  'questionnaries/deleteHRA',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.post({
        url: '/QuestionnaireHra/delete-in-progress-hra',
      });

      const res = await data.response;

      dispatch(getInProgressQuestionnaries());
      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadCompletedQuestionnaires = createAsyncThunk(
  'questionnaries/downloadQuestionnaires',
  async ({ id, viewText }: SendHealthReacordPayload, { rejectWithValue }) => {
    const URL = downloadCompletedQuestionnairesURLMap[viewText];
    try {
      const data = fetch.get({
        url: URL,
        responseType: 'blob',
        params: {
          questionnaireId: id,
        },
      });

      const res = await data.response;
      const blob = res?.data;
      downLoadFromBlob(blob, 'application/pdf', viewText);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
