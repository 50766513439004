import { createAsyncThunk } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';
import { downLoadFromBlob } from '@common/utils';

import { ParamsWithDependentUserId } from '../../../types';
import { dryStatusSelector } from './selectors';
import { TestResultsItem } from './types';

export const getTestResults = createAsyncThunk<TestResultsItem[], ParamsWithDependentUserId>(
  'test-results',
  async ({ dependentUserId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/lab-results',
        params: {
          dependentUserId,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => dryStatusSelector(getState() as RootState) !== StatusEnum.Pending,
  },
);

export const downloadLabResultsReport = createAsyncThunk<unknown, ParamsWithDependentUserId<{ labResultId: number | null }>>(
  'HealthSummary/lab-results-report',
  async ({ labResultId, dependentUserId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/reports/lab-results',
        responseType: 'blob',
        params: {
          dependentUserId,
          labResultId,
        },
      });

      const res = await data.response;
      const blob = res?.data;
      downLoadFromBlob(blob, 'application/pdf', 'lab-results-report');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
