import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { logout, logoutWithoutRevoke } from '@modules/Login';
import { changeCurrentEligibility } from '@modules/userProfileData';

import { getSupplementalWaiverData, acceptSupplementalWaiver } from './actions';
import { SupplementWaiverState } from './types';

const initialState: SupplementWaiverState = {
  statusData: StatusEnum.Uninitialized,
  statusSign: StatusEnum.Uninitialized,
  supplementalWaiverData: null,
  supplementalWaiverSigned: {},
};

export const resetStoreActions = () => ({
  [logout.fulfilled.type]: () => initialState,
  [logoutWithoutRevoke.fulfilled.type]: () => initialState,
  [changeCurrentEligibility.fulfilled.type]: (state: SupplementWaiverState) => {
    state.statusSign = StatusEnum.Uninitialized;
    state.statusData = StatusEnum.Uninitialized;
    state.supplementalWaiverData = null;
  },
});

const supplementalWaiverSlice = createSlice({
  name: 'supplementalWaiver',
  initialState,
  reducers: {
    setSupplementalWaiverFromStorage: (state: SupplementWaiverState, action) => {
      state.supplementalWaiverSigned = action.payload;
    },
  },
  extraReducers: {
    [getSupplementalWaiverData.pending.type]: (state: SupplementWaiverState) => {
      state.statusData = StatusEnum.Pending;
      state.supplementalWaiverData = null;
    },
    [getSupplementalWaiverData.fulfilled.type]: (state: SupplementWaiverState, action) => {
      state.statusData = StatusEnum.Fulfilled;

      if (action.payload) {
        state.supplementalWaiverData = action.payload;
      }
    },
    [getSupplementalWaiverData.rejected.type]: (state: SupplementWaiverState) => {
      state.statusData = StatusEnum.Rejected;
    },
    [acceptSupplementalWaiver.pending.type]: (state: SupplementWaiverState) => {
      state.statusSign = StatusEnum.Pending;
    },
    [acceptSupplementalWaiver.fulfilled.type]: (state: SupplementWaiverState, action) => {
      state.statusSign = StatusEnum.Fulfilled;

      if (action?.payload) {
        state.supplementalWaiverSigned = action.payload;
      }
    },
    [acceptSupplementalWaiver.rejected.type]: (state: SupplementWaiverState) => {
      state.statusSign = StatusEnum.Rejected;
    },
    ...resetStoreActions(),
  },
});

export const supplementalWaiverReducer = supplementalWaiverSlice.reducer;
export const { setSupplementalWaiverFromStorage } = supplementalWaiverSlice.actions;
