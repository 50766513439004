import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { inviteMember } from './actions';
import { InviteMemberPayload } from './types';

type InviteMemberState = {
  memberId: Nullable<number>;
  status: Record<number, StatusEnum>;
};

const initialState: InviteMemberState = {
  memberId: null,
  status: {},
};

export const inviteMemberSlice = createSlice({
  name: 'inviteMember',
  initialState,
  reducers: {
    setModal(state: InviteMemberState, action: PayloadAction<Nullable<number>>) {
      state.memberId = action.payload;
    },
  },
  extraReducers: {
    [inviteMember.pending.type]: (
      state: InviteMemberState,
      action: PayloadAction<unknown, string, Meta<InviteMemberPayload>>,
    ) => {
      const { userId } = action.meta.arg;
      state.status[userId] = StatusEnum.Pending;
    },
    [inviteMember.fulfilled.type]: (
      state: InviteMemberState,
      action: PayloadAction<unknown, string, Meta<InviteMemberPayload>>,
    ) => {
      const { userId } = action.meta.arg;
      state.status[userId] = StatusEnum.Fulfilled;
    },
    [inviteMember.rejected.type]: (
      state: InviteMemberState,
      action: PayloadAction<unknown, string, Meta<InviteMemberPayload>>,
    ) => {
      const { userId } = action.meta.arg;
      state.status[userId] = StatusEnum.Rejected;
    },
    ...resetStoreActions(() => initialState),
  },
});

export const { setModal } = inviteMemberSlice.actions;
export const inviteMemberReducer = inviteMemberSlice.reducer;
