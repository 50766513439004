import React, { Fragment } from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Accordion, AccordionSummary, AccordionDetails } from '@components/Accordion';
import { List } from '@components/List';
import { MenuItem } from '@components/Menu';
import { SideMenu } from '@components/SideMenu';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography/Typography';
import { HideFlippersEnum, useFlippers } from '@modules/flippers';

import { BURGER_MENU_ID } from '../../constants';
import { getVisibleMenuItems } from '../../store';
import { MenuConfigItem } from '../../types';
import { gtmClickLink } from './utils';

import styles from './BurgerMenu.module.css';

export enum AnchorEnum {
  left = 'left',
  right = 'right',
}

const cn = classNames.bind(styles);

export interface MainHeaderProps {
  mainMenuToggleHandler: VoidFunction;
  mainMenuOpen: boolean;
}

export function BurgerMenu({ mainMenuToggleHandler, mainMenuOpen }: MainHeaderProps) {
  const navigate = useNavigate();
  const getFlipperValue = useFlippers();
  const hideLogo = getFlipperValue(HideFlippersEnum.hide_mh_logo);

  const visibleMenuItems = useSelector(getVisibleMenuItems);

  const handleLinkClick = (item: MenuConfigItem) => () => {
    gtmClickLink(item.gtmType);
    navigate(item.to);
    mainMenuToggleHandler();
  };

  const handleSubMenuOpen = (tab: MenuConfigItem) => () => {
    gtmClickLink(tab.gtmType);
  };

  const renderSubMenu = (tab: MenuConfigItem) => (
    <Accordion className={cn('burger-menu__accordion')} square disableGutters>
      <AccordionSummary
        className={cn('burger-menu__accordion-summary')}
        contentClassName={cn('burger-menu__accordion-summary-content')}
        expandIcon={<SvgIcon icon="chevronDown" />}
        focusVisibleClassName={cn('burger-menu__accordion-summary--focus-visible')}
      >
        {tab.label}
      </AccordionSummary>

      <AccordionDetails className={cn('burger-menu__sub-menu-details')}>
        <List className={cn('burger-menu__sub-menu')}>
          {tab.subMenuItems &&
            tab.subMenuItems.map((subMenuItem) => (
              <li key={subMenuItem.to || subMenuItem.label}>
                <MenuItem onClick={handleLinkClick(subMenuItem)} to={subMenuItem.to} noHover>
                  {subMenuItem.label}
                  <Typography variant="body1" className={cn('burger-menu__sub-menu-sublabel')}>
                    {subMenuItem.subLabel}
                  </Typography>
                </MenuItem>
              </li>
            ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <SideMenu
      anchor={AnchorEnum.left}
      open={mainMenuOpen}
      onClose={mainMenuToggleHandler}
      className={cn('burger-menu')}
      component="div"
      role="menu"
      id={BURGER_MENU_ID}
      hideLogo={hideLogo}
    >
      {visibleMenuItems.map((tab) => (
        <Fragment key={tab.to || tab.label}>
          <MenuItem
            className={cn('burger-menu__item', 'burger-menu__item--bold')}
            noHover
            to={tab?.subMenuItems ? undefined : tab.to}
            onClick={tab?.subMenuItems ? handleSubMenuOpen(tab) : handleLinkClick(tab)}
            tabIndex={tab?.subMenuItems ? -1 : 0}
            disableFocusVisible={!!tab?.subMenuItems}
            focusVisibleClassName={cn('burger-menu__item--focus-visible')}
            divider
          >
            {tab?.subMenuItems ? renderSubMenu(tab) : tab.label}
          </MenuItem>
        </Fragment>
      ))}
    </SideMenu>
  );
}
