import React from 'react';

import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';

import { ITEM_IDS } from './constants';

type Params = {
  handleHoursArrowDownClick: VoidFunction;
  handleMinutesArrowDownClick: VoidFunction;
  handleHoursArrowUpClick: VoidFunction;
  handleMinutesArrowUpClick: VoidFunction;
  handleClose: VoidFunction;
};

export const useKeyboardClick = ({
  handleHoursArrowDownClick,
  handleMinutesArrowDownClick,
  handleHoursArrowUpClick,
  handleMinutesArrowUpClick,
  handleClose,
}: Params) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useIgnoreEffectDeps(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      const activeElementId = document.activeElement?.getAttribute?.('id');
      const isHours = activeElementId === ITEM_IDS.HOURS;
      const isMinutes = activeElementId === ITEM_IDS.MINUTES;
      if (event.key === 'ArrowDown') {
        if (isHours) {
          handleHoursArrowDownClick();
        }

        if (isMinutes) {
          handleMinutesArrowDownClick();
        }
      }

      if (event.key === 'ArrowUp') {
        if (isHours) {
          handleHoursArrowUpClick();
        }

        if (isMinutes) {
          handleMinutesArrowUpClick();
        }
      }

      if (['Enter', ' ', 'Escape'].includes(event.key) && (isHours || isMinutes)) {
        handleClose();
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [
    handleHoursArrowDownClick,
    handleHoursArrowUpClick,
    handleMinutesArrowDownClick,
    handleMinutesArrowUpClick,
    handleClose,
  ]);
};
