import React, { ReactElement, ReactNode } from 'react';

import classNames from 'classnames/bind';

import styles from './InnerLayout.module.css';

const cn = classNames.bind(styles);

type InnerLayoutProps = {
  children: ReactNode;
  leftSidebar?: Nullable<ReactElement>;
  rightSidebar?: Nullable<ReactElement>;
  className?: string;
  contentClassName?: string;
  leftSidebarClassName?: string;
  role?: string | undefined;
};

export function InnerLayout({
  leftSidebar,
  rightSidebar,
  children,
  className,
  contentClassName,
  leftSidebarClassName,
  role,
}: InnerLayoutProps) {
  return (
    <div className={cn('inner-layout', className)}>
      {leftSidebar && (
        <div role={role} className={cn('inner-layout__left-sidebar', leftSidebarClassName)}>
          {leftSidebar}
        </div>
      )}
      <div className={cn('inner-layout__content', contentClassName)}>{children}</div>
      {rightSidebar && <div className={cn('inner-layout__right-sidebar')}>{rightSidebar}</div>}
    </div>
  );
}
