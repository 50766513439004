// TODO-Vladislav add tests
import { StatusEnum } from '@common/constants';

import { MedicationType, PharmacyRelativeType, PharmacyType, PharmaciesInStoreType } from './types';

export const getPharmaciesKey = ({ userId, medicationId }: { userId: number; medicationId?: Nullable<string> }) => {
  if (userId && medicationId) {
    return `${userId}_${medicationId}`;
  }

  return medicationId || userId;
};

export const getPharmacies = (pharmacies: PharmaciesInStoreType, medicationId: Nullable<string>, userId: number) =>
  pharmacies[getPharmaciesKey({ medicationId, userId })] || [];

export const getPharmaciesByMedicationId = (
  medications: Pick<MedicationType, 'pharmacy_ids' | 'medication_id'>[],
  pharmacies: PharmacyType[],
  userId: number,
) =>
  medications.reduce<PharmaciesInStoreType>((acc, medication) => {
    acc[`${userId}_${medication.medication_id}`] = pharmacies.filter((pharmacy) =>
      medication.pharmacy_ids.includes(pharmacy.clinical_provider_id),
    );

    return acc;
  }, {});

export const getPharmaciesByMedicationIdStatus = (
  medications: Pick<MedicationType, 'medication_id'>[],
  status: StatusEnum,
  userId: number,
) =>
  medications.reduce<Record<string, StatusEnum>>((acc, medication) => {
    acc[`${userId}_${medication.medication_id}`] = status;

    return acc;
  }, {});

export const statusesByKey = (statuses: Record<string, StatusEnum>, status: StatusEnum) =>
  Object.keys(statuses).reduce<Record<string, StatusEnum>>((acc, key) => {
    acc[key] = status;

    return acc;
  }, {});

export const pharmaciesRelativeToPharmacies = (relativePharmacies: PharmacyRelativeType[]): PharmacyType[] =>
  relativePharmacies.map((relativePharmacy) => ({
    default_pharmacy: false,
    clinical_provider_id: relativePharmacy.id,
    pharmacy_name: relativePharmacy.name,
    address: `${relativePharmacy.address.street_address1} ${relativePharmacy.address.street_address2}`,
    city: relativePharmacy.address.city,
    state: relativePharmacy.address.state_abbr,
    zip: relativePharmacy.address.zip_code,
    phone_number: relativePharmacy.phone,
  }));
