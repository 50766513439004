import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { getDefaultClinicPhone } from './actions';

type DefaultClinicPhoneState = {
  defaultClinicPhoneStatus: StatusEnum;
  defaultClinicPhone: string;
  defaultClinicName: string;
  showDefaultClinicPhoneModal: boolean;
  anywhereNumber: string;
  anywhereNumberStatus: StatusEnum;
};
const initialState: DefaultClinicPhoneState = {
  defaultClinicPhoneStatus: StatusEnum.Uninitialized,
  defaultClinicPhone: '',
  defaultClinicName: '',
  showDefaultClinicPhoneModal: false,
  anywhereNumber: '',
  anywhereNumberStatus: StatusEnum.Uninitialized,
};

export const defaultClinicPhoneSlice = createSlice({
  name: 'defaultClinicPhone',
  initialState,
  reducers: {
    showDefaultClinicPhoneModal: (state: DefaultClinicPhoneState, action: PayloadAction<boolean>) => {
      state.showDefaultClinicPhoneModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDefaultClinicPhone.pending, (state) => {
      state.defaultClinicPhoneStatus = StatusEnum.Pending;
    });
    builder.addCase(getDefaultClinicPhone.fulfilled, (state, action) => {
      state.defaultClinicPhoneStatus = StatusEnum.Fulfilled;
      state.defaultClinicPhone = action.payload.default_clinic_phone;
      state.defaultClinicName = action.payload.default_clinic_name;
      state.anywhereNumber = action.payload.anywhere_phone;
    });
    builder.addCase(getDefaultClinicPhone.rejected, (state) => {
      state.defaultClinicPhoneStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const { showDefaultClinicPhoneModal } = defaultClinicPhoneSlice.actions;
export const defaultClinicPhoneReducer = defaultClinicPhoneSlice.reducer;
