import React from 'react';

import MenuListMaterial, { MenuListProps as MenuListPropsMaterial } from '@mui/material/MenuList';

export type MenuListProps = {
  className?: string;
  component?: 'div' | 'ul' | null;
} & Pick<MenuListPropsMaterial, 'aria-label' | 'id' | 'children' | 'style'>;

export function MenuList({ children, className, 'aria-label': ariaLabel, style, component }: MenuListProps) {
  return (
    <MenuListMaterial className={className} aria-label={ariaLabel} style={style} component={component || 'ul'}>
      {children}
    </MenuListMaterial>
  );
}
