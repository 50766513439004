import { PATHS } from '@common/routes';
import { HideFlippersEnum, ShowFlippersEnum } from '@modules/flippers';
import { GtmClickTypesEnum } from '@modules/gtm';

import { locale } from './locale';
import { MenuConfigItem } from './types';

export const VIEW_APPOINTMENTS: MenuConfigItem = {
  label: locale.links.appointments.view_appointment,
  to: PATHS.APPOINTMENTS.SCHEDULE_APPOINTMENT,
};

export const SCHEDULE_AND_VIEW_APPOINTMENTS: MenuConfigItem = {
  label: locale.links.appointments.schedule_appointment,
  to: PATHS.APPOINTMENTS.SCHEDULE_APPOINTMENT,
  gtmType: GtmClickTypesEnum.scheduleViewAppointments,
};

const APPOINTMENTS_SUB_MENU_ITEMS: MenuConfigItem[] = [
  {
    label: locale.links.appointments.health_center_locations,
    to: PATHS.APPOINTMENTS.HEALTH_CENTER_LOCATIONS,
  },
  {
    label: locale.links.appointments.our_providers,
    to: PATHS.APPOINTMENTS.PROVIDERS.ROOT,
  },
  {
    label: locale.links.appointments.health_center_services,
    to: PATHS.APPOINTMENTS.HEALTH_CENTER_SERVICES,
  },
];

const MY_HEALTH_SUB_MENU_ITEMS: MenuConfigItem[] = [
  {
    label: locale.links.my_health.my_health_dashboard,
    to: PATHS.MY_HEALTH.MY_HEALTH_DASHBOARD,
  },
  {
    label: locale.links.my_health.test_results,
    to: PATHS.MY_HEALTH.TEST_RESULTS,
    flipper: ShowFlippersEnum.my_clinical_results,
  },
  {
    label: locale.links.my_health.health_summary,
    to: PATHS.MY_HEALTH.HEALTH_SUMMARY.ROOT,
  },
  {
    label: locale.links.my_health.risk_profile,
    to: PATHS.MY_HEALTH.RISK_PROFILE,
    flipper: ShowFlippersEnum.my_risk_profile,
  },
  {
    label: locale.links.my_health.goals,
    to: PATHS.MY_HEALTH.GOALS,
    flipper: ShowFlippersEnum.view_goals,
  },
  {
    label: locale.links.my_health.care_guidelines,
    to: PATHS.MY_HEALTH.CARE_GUIDELINES.ROOT,
    flipper: ShowFlippersEnum.my_quality_measures,
  },
  {
    label: locale.links.my_health.questionnaires,
    to: PATHS.MY_HEALTH.QUESTIONNAIRES,
    flipper: ShowFlippersEnum.questionnaires,
  },
];

const WELLNESS_SUB_MENU_ITEMS: MenuConfigItem[] = [
  {
    label: locale.links.wellness.incentives,
    to: PATHS.WELLNESS.INCENTIVES,
  },
  {
    label: locale.links.wellness.challenges,
    to: PATHS.WELLNESS.CHALLENGES,
  },
  {
    label: locale.links.wellness.wellness_logs,
    to: PATHS.WELLNESS.WELLNESS_LOGS,
  },
];

const RESOURCES_SUB_MENU_ITEMS: MenuConfigItem[] = [
  {
    label: locale.links.resources.all_resources,
    to: PATHS.RESOURCES.ALL,
  },
  {
    label: locale.links.resources.healthwise,
    to: PATHS.RESOURCES.HEALTHWISE,
    flipper: HideFlippersEnum.hide_healthwise_resource,
  },
  {
    label: locale.links.resources.wellness_workshops,
    to: PATHS.RESOURCES.WELLNESS_WORKSHOPS,
    flipper: HideFlippersEnum.hide_wellness_workshops,
  },
  {
    label: locale.links.resources.bluebook,
    to: PATHS.RESOURCES.BLUEBOOK,
  },
  {
    label: locale.links.resources.community,
    to: PATHS.RESOURCES.COMMUNITY,
    flipper: HideFlippersEnum.hide_findhelp_org,
  },
];

const HELP_SUB_MENU_ITEMS: MenuConfigItem[] = [
  {
    label: locale.links.help.contact_us,
    to: PATHS.MESSAGES.EDIT,
    flipper: HideFlippersEnum.hide_secure_messaging,
  },
  {
    label: locale.links.help.faq,
    to: PATHS.HELP.FAQ,
  },
  {
    label: locale.links.help.spanish_language_assistance_sp,
    subLabel: locale.links.help.spanish_language_assistance_en,
    to: PATHS.HELP.SPANISH_HELP,
  },
];

export const TAB_LIST: (MenuConfigItem & { id: number })[] = [
  {
    label: locale.links.home,
    to: PATHS.MAIN_PAGE,
  },
  {
    label: locale.links.appointments.root,
    to: PATHS.APPOINTMENTS.ROOT,
    subMenuItems: APPOINTMENTS_SUB_MENU_ITEMS,
    gtmType: GtmClickTypesEnum.appointmentsDropdown,
  } as const,
  {
    label: locale.links.medication_refills,
    to: PATHS.MEDICATION_REFILLS.ROOT,
    flipper: HideFlippersEnum.hide_med_refills,
  },
  {
    label: locale.links.my_health.root,
    to: PATHS.MY_HEALTH.ROOT,
    subMenuItems: MY_HEALTH_SUB_MENU_ITEMS,
  },
  {
    label: locale.links.wellness.root,
    to: PATHS.WELLNESS.ROOT,
    subMenuItems: WELLNESS_SUB_MENU_ITEMS,
  },
  {
    label: locale.links.resources.root,
    to: PATHS.RESOURCES.ROOT,
    subMenuItems: RESOURCES_SUB_MENU_ITEMS,
  },
  {
    label: locale.links.help.root,
    to: PATHS.HELP.ROOT,
    subMenuItems: HELP_SUB_MENU_ITEMS,
  },
].map((item, index) => ({
  ...item,
  id: index,
}));

export const BURGER_MENU_ID = 'burger_menu';
