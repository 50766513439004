import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';

import { GlobalWaiverPurposes } from '../constants';
import {
  acceptWaiverAcknowledgment,
  contentByPurposeSelector,
  forceStop,
  getWaiverAcknowledgment,
  idByPurposeSelector,
  requiredByPurposeSelector,
  statusByPurposeSelector,
} from '../store';

export const useGlobalWaiverOnboarding = (purpose: GlobalWaiverPurposes, gtmCallback?: () => void) => {
  const [runState, setRunState] = useState(false);
  const dispatch = useDispatch();

  const { body, title } = useSelector((state: RootState) => contentByPurposeSelector(state, { purpose }));

  const status = useSelector((state: RootState) => statusByPurposeSelector(state, { purpose }));
  const onboardingRequired = useSelector((state: RootState) => requiredByPurposeSelector(state, { purpose }));
  const waiverId = useSelector((state: RootState) => idByPurposeSelector(state, { purpose }));

  const handleGetWaiver = useCallback(() => {
    if (status !== StatusEnum.Pending) {
      dispatch(getWaiverAcknowledgment(purpose));
    }
  }, [dispatch, purpose, status]);

  const handleFinalizeWaiver = useCallback(() => {
    gtmCallback?.();

    if (waiverId) {
      dispatch(
        acceptWaiverAcknowledgment({
          purpose,
          waiverId,
        }),
      );
    } else {
      dispatch(forceStop(purpose));
    }
  }, [dispatch, gtmCallback, purpose, waiverId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRunState(onboardingRequired);
    }, 200);

    return () => clearTimeout(timeout);
  }, [onboardingRequired]);

  return {
    body,
    handleFinalizeWaiver,
    handleGetWaiver,
    run: runState,
    status,
    title,
  };
};
