import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { generatePathWithQuery } from '@common/router';
import { PATHS } from '@common/routes';
import { ColorEnum, Link } from '@components/Link';
import { NumberCircle, SizesEnum } from '@components/NumberCircle';
import { Typography } from '@components/Typography';
import { getUserIdSelector } from '@modules/userProfileData';

import { isOnlyCurrentUserUnreadMessagesSelector, unreadCountSelector, unreadMessagesSelector } from '../../store';
import { locale } from './locale';

import styles from './UnreadMessages.module.css';

const cn = classNames.bind(styles);

type Props = {
  className?: string;
  size?: SizesEnum;
};
export function UnreadMessages({ size = SizesEnum.Medium, className }: Props) {
  const userId = useSelector(getUserIdSelector);
  const unreadMessagesCount = useSelector(unreadCountSelector);
  const unreadMessages = useSelector(unreadMessagesSelector);
  const isOnlyCurrentUserUnreadMessages = useSelector(isOnlyCurrentUserUnreadMessagesSelector);

  const renderUnreadMessages = useMemo(() => {
    if (!unreadMessages.length) {
      return null;
    }

    return (
      <div
        className={cn('root', className, {
          'root--only-current': isOnlyCurrentUserUnreadMessages,
        })}
      >
        {isOnlyCurrentUserUnreadMessages ? (
          <NumberCircle count={unreadMessages[0].count} size={size} />
        ) : (
          unreadMessages.map((unreadMessage) => (
            <Typography variant="body1" component="div" key={unreadMessage.userId} className={cn('item')}>
              <Link
                color={ColorEnum.GRAY_20}
                to={generatePathWithQuery(PATHS.MESSAGES.ROOT, { memberId: unreadMessage.userId })}
                reverseUnderline
              >
                {unreadMessage.userId === userId ? locale.my_messages : unreadMessage.name}
              </Link>
              <NumberCircle count={unreadMessage.count} size={size} />
            </Typography>
          ))
        )}
      </div>
    );
  }, [className, size, unreadMessages, userId, isOnlyCurrentUserUnreadMessages]);

  if (!unreadMessagesCount) {
    return null;
  }

  return renderUnreadMessages;
}
