import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getUserCategories = createAsyncThunk(
  'categories/fetchUserCategories',
  async (id: number, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/article/get-article-categories',
        params: {
          employerId: id,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
