import { useSelector } from 'react-redux';

import { EligibilityTypesEnum, FamilyMemberRegisterStatusEnum } from '../constants';
import { FamilyMemberTypeWithName } from '../types';

export const userProfileDataSelector = (state: RootState) => state.userProfile.userProfileData;

type SortableObject = {
  [key: string]: Nullable<string | boolean | number | object>;
};

export const sortByOrder = <T extends SortableObject>(objects: T[], order: string[], field: string): T[] =>
  objects.sort((a, b) => {
    const indexA = order.indexOf(a[field] as string);
    const indexB = order.indexOf(b[field] as string);

    if (indexA === -1) return 1; // a[field] is not in order array, move to the end
    if (indexB === -1) return -1; // b[field] is not in order array, move to the front

    return indexA - indexB;
  });

export function useOrderFamilyMembersSelect(
  familyMembers: Array<FamilyMemberTypeWithName>,
): Array<FamilyMemberTypeWithName> {
  const orderedFamilyMembers: Array<FamilyMemberTypeWithName> = [];
  const partnersEnums = [
    EligibilityTypesEnum.Spouse,
    EligibilityTypesEnum.SameSexPartner,
    EligibilityTypesEnum.DomesticPartner,
    EligibilityTypesEnum.EmployeeSpouse,
    EligibilityTypesEnum.RetireeSpouse,
    EligibilityTypesEnum.Contractor,
    EligibilityTypesEnum.Retiree,
    EligibilityTypesEnum.COBRA,
    EligibilityTypesEnum.OnLeave,
  ] as string[];

  const userData = useSelector(userProfileDataSelector);
  const currentUserData = userData as unknown as FamilyMemberTypeWithName;

  const dependentEnums = [EligibilityTypesEnum.Child, EligibilityTypesEnum.Dependent] as string[];

  const employee = familyMembers.filter((member) => member.eligibility_type_name === EligibilityTypesEnum.Employee);
  const partners = familyMembers.filter((member) => partnersEnums.includes(member.eligibility_type_name));

  const partnersSortedByOrder = sortByOrder(partners, partnersEnums, 'eligibility_type_name');
  const eligibleDependents = familyMembers.filter(
    (member) =>
      dependentEnums.includes(member.eligibility_type_name) &&
      member.registration_status !== FamilyMemberRegisterStatusEnum.Uneligible,
  );
  const uneligibleDependents = familyMembers.filter(
    (member) =>
      dependentEnums.includes(member.eligibility_type_name) &&
      member.registration_status === FamilyMemberRegisterStatusEnum.Uneligible,
  );

  if (currentUserData && dependentEnums.includes(currentUserData.eligibility_type_name)) {
    orderedFamilyMembers.push(currentUserData);
    return orderedFamilyMembers;
  }

  orderedFamilyMembers.push(currentUserData);
  orderedFamilyMembers.push(...employee);

  orderedFamilyMembers.push(...partnersSortedByOrder);
  orderedFamilyMembers.push(...eligibleDependents);
  orderedFamilyMembers.push(...uneligibleDependents);

  return orderedFamilyMembers;
}
