import { createAsyncThunk } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';
import { downLoadFromBlob } from '@common/utils';

import { ParamsWithDependentUserId } from '../../../types';
import { medicationsStatusSelector } from './selectors';
import { MedicationsItem } from './types';

export const getMedications = createAsyncThunk<MedicationsItem[], ParamsWithDependentUserId>(
  'HealthSummary/get-medications',
  async ({ dependentUserId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/medications',
        params: {
          dependentUserId,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadMedicationsReport = createAsyncThunk<
  unknown,
  ParamsWithDependentUserId<{ displayHistorical: boolean }>
>(
  'HealthSummary/medications-report',
  async ({ dependentUserId, displayHistorical }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/reports/medications',
        responseType: 'blob',
        params: {
          dependentUserId,
          displayHistorical,
        },
      });

      const res = await data.response;
      const blob = res?.data;
      downLoadFromBlob(blob, 'application/pdf', 'medications-report');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => medicationsStatusSelector(getState() as RootState) !== StatusEnum.Pending,
  },
);
