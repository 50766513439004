export const locale = {
  other_medication_label: 'Other',
  other_pharmacy_label: 'Other',
  tips: {
    title: 'Over-the-Counter Medication Changes',
    first_paragraph:
      'To better align with the needs of our patients and partners, we are making changes to our formulary starting January 1, 2022.',
    second_paragraph:
      'There will be changes to the quantity dispensed for the medications below. If you are on any of these medications, please ask your health center team how you might be impacted.',
    list: [
      'Calcium + Vitamin D',
      'Vitamin D (cholecalciferol, ergocalciferol)',
      'Iron (ferrous sulfate, ferrous gluconate)',
      'Carbamide Peroxide (ear wax removal)',
      'Mucinex',
      'Fluticansone Nasal Spray (Flonase)',
      'Loratadine',
      'Cetirizine',
      'Docusate Sodium',
      'Omeprazole',
      'Famotidine',
      'Randitidine',
      'Hyrdocortisone Cream',
    ],
  },
  submitting: 'Sending refill request...',
};
