import { InternalErrorType } from '@modules/InternalError/constants';

export const locale = {
  [InternalErrorType.ServiceError]: {
    heading: 'Sorry, we are having a problem with our system.',
    subText: 'We are working hard to fix it and should have it resolved soon.',
    subHeading: 'Here are some helpful links instead:',
    gratitude: '',
    error: 'ERROR',
    errorCode: '500',
  },
  [InternalErrorType.NotFound]: {
    heading: 'Whoops! That page is missing.',
    subText: '',
    subHeading: 'Here are some helpful links instead:',
    gratitude: '',
    error: 'ERROR',
    errorCode: '404',
  },
  [InternalErrorType.Maintenance]: {
    heading: 'Don’t worry, everything is fine.',
    subText: 'The site isn’t broken, we’re just undergoing maintenance.',
    subHeading: 'Please come back later. We should be up and running again soon.',
    gratitude: 'Thanks for your patience!',
    error: '',
    errorCode: '',
  },
  refreshPage: 'Refresh This Page',
  goBack: 'Go Back',
  goHome: 'Portal Homepage',
};
