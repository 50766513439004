import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@components/Button';
import { FieldRow } from '@components/FieldRow';
import { SelectWithControl, useForm } from '@components/Form';
import { Typography } from '@components/Typography';
import { getAllLanguagesSelector, getLanguagesLoadingSelector, useFetchLanguages } from '@modules/StaticList';
import { saveCustomSettingsInformation, userProfileDataSelector } from '@modules/userProfileData';

import { locale } from './locale';

import styles from './Content.module.css';

type FormValuesType = {
  primary_language: string;
};

type Props = {
  onClose: VoidFunction;
};

const cn = classNames.bind(styles);
export const Content = ({ onClose }: Props) => {
  useFetchLanguages();
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm<FormValuesType>();
  const languages = useSelector(getAllLanguagesSelector);
  const languagesLoading = useSelector(getLanguagesLoadingSelector);
  const { id, time_zone: timeZone } = useSelector(userProfileDataSelector);

  const handleSaveProfile = useCallback(
    (data: FormValuesType) => {
      dispatch(
        saveCustomSettingsInformation({
          id,
          time_zone: timeZone,
          ...data,
        }),
      );
      onClose();
    },
    [dispatch, id, onClose, timeZone],
  );

  return (
    <div>
      <Typography variant="h1" className={cn('title')}>
        {locale.title}
      </Typography>
      <FieldRow size="small" loading={languagesLoading}>
        <SelectWithControl<FormValuesType>
          name="primary_language"
          labelId="select_custom_settings_primary_language"
          label={locale.placeholder}
          placeholder={locale.placeholder}
          required
          options={languages}
          loading={languagesLoading}
          SelectDisplayProps={{
            role: 'listbox',
          }}
          control={control}
          fullWidth
          disabled={languagesLoading}
        />
      </FieldRow>
      <Typography variant="body1" className={cn('description')}>
        {locale.description}
      </Typography>
      <Button onClick={handleSubmit(handleSaveProfile)} variant="contained" fullWidth>
        {locale.save}
      </Button>
    </div>
  );
};
