import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { PATHS } from '@common/routes';
import { getLocale } from '@common/utils/locale';
import { Button } from '@components/Button';
import { Link } from '@components/Link';
import { Spinner } from '@components/Spinner';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';

import { locale } from '../locale';
import { defaultClinicPhoneSelector, showDefaultClinicPhoneModal } from '../store';
import { useGetDefaultPhone } from '../useGetDefaultPhone';

import styles from '../DefaultClinicPhone.module.css';

const cn = classNames.bind(styles);

const TITLE_ID = 'default_clinic_phone_title';

export const Content = () => {
  const dispatch = useDispatch();
  const { phone, name, status, anywhereNumber } = useSelector(defaultClinicPhoneSelector);
  const isLoading = status !== StatusEnum.Fulfilled;
  const showAdditionalContent = anywhereNumber && phone && phone !== anywhereNumber;
  const showDefaultNumberOnly = phone && !anywhereNumber;

  const handlePhoneClick = useCallback(() => {
    document.location.href = `tel://${phone}`;
  }, [phone]);

  const handleClose = useCallback(() => {
    dispatch(showDefaultClinicPhoneModal(false));
  }, [dispatch]);

  useGetDefaultPhone();

  return (
    <div className={cn('default-clinic-phone__content')}>
      {isLoading ? (
        <Spinner className={cn('default-clinic-phone__spinner')} />
      ) : (
        <>
          <Typography className={cn('default-clinic-phone__title')} variant="h4" component="p" id={TITLE_ID}>
            <SvgIcon className={cn('default-clinic-phone__icon')} icon="phone" inheritViewBox />
            {locale.contact_us}
          </Typography>
          {(showAdditionalContent || showDefaultNumberOnly) && (
            <>
              <Typography className={cn('default-clinic-phone__clinic-name')} variant="h5" component="p">
                {getLocale(locale.default_clinic_name, { name })}
              </Typography>
              <Button
                className={cn('default-clinic-phone__button')}
                onClick={handlePhoneClick}
                variant="contained"
                aria-label={getLocale(locale.call_aria_label, { name, phone })}
              >
                {getLocale(locale.call, { phone })}
              </Button>
            </>
          )}

          {anywhereNumber && (
            <>
              <Typography className={cn('default-clinic-phone__anywhere-name')} variant="h5" component="p">
                {locale.anywhere_clinic_name}
              </Typography>
              <Button
                className={cn('default-clinic-phone__button')}
                onClick={handlePhoneClick}
                variant="contained"
                aria-label={getLocale(locale.call_aria_label, {
                  name: locale.anywhere_clinic_name,
                  phone: anywhereNumber,
                })}
              >
                {getLocale(locale.call, { phone: anywhereNumber })}
              </Button>
            </>
          )}

          {showAdditionalContent && (
            <div className={cn('default-clinic-phone__other-section')}>
              <p>{locale.looking_for_other}</p>
              <p>
                {locale.other_health_center_before_link}
                <Link to={PATHS.APPOINTMENTS.HEALTH_CENTER_LOCATIONS} onClick={handleClose}>
                  {locale.health_center_locations_link}
                </Link>

                {locale.other_health_center_after_link}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
