export const locale = {
  cancel: 'Cancel',
  submit: 'Record Steps',
  record_another_entry: 'Save & Record Another Entry',
  header: 'Record Steps',
  field_labels: {
    date: 'Date',
    time: 'Time',
    steps: 'Steps',
  },
  errors: {
    date: 'Date is required',
    steps: 'Steps data is required',
  },
};
