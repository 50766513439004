export const createLinkToOpen = (href: string) => {
  const linkTag = document.createElement('a');

  linkTag.href = href;

  linkTag.setAttribute('target', '_blank');

  document.body.appendChild(linkTag);

  linkTag.click();

  document.body.removeChild(linkTag);
};
