import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { signUpAndLogin } from '@modules/Login/store';

import { FlowEnum, StageEnum, ValidationUserPreregistrationResultEnum } from '../constants';
import { signUp, validateToken, validateUserPreregistrationUser, getEmployer } from './actions';
import { BasicInfo, RegistrationState, ValidateUserPreregistrationUserMetaType } from './types';

const initialState: RegistrationState = {
  stage: StageEnum.Identification,
  signUpStatus: StatusEnum.Uninitialized,
  flow: null,
  validateTokenStatus: StatusEnum.Uninitialized,
  validateUserPreregistrationUserStatus: StatusEnum.Uninitialized,
  basicInfo: null,
  registrationUserName: null,
  validateUserPreregistrationResult: null,
  userPrevalidatedWithToken: false,
  validationError: null,
  employer: null,
  employerStatus: StatusEnum.Uninitialized,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setFlow(state: RegistrationState, action: PayloadAction<FlowEnum>) {
      state.flow = action.payload;
    },
    setStage(state: RegistrationState, action: PayloadAction<Nullable<StageEnum>>) {
      if (action.payload) {
        state.stage = action.payload;
      }
    },

    setAlreadyHavAccountVisible(
      state: RegistrationState,
      action: PayloadAction<Nullable<ValidationUserPreregistrationResultEnum>>,
    ) {
      state.validateUserPreregistrationResult = action.payload;
    },

    clearValidationError(state: RegistrationState) {
      state.validationError = null;
    },

    reset() {
      return initialState;
    },
  },
  extraReducers: {
    [signUp.pending.type]: (state: RegistrationState) => {
      state.signUpStatus = StatusEnum.Pending;
    },
    [signUp.fulfilled.type]: (state: RegistrationState) => {
      state.signUpStatus = StatusEnum.Fulfilled;
    },
    [signUp.rejected.type]: (state: RegistrationState, error) => {
      const errorCode = error.payload?.response?.data?.error_code;
      state.validateUserPreregistrationResult = errorCode;
      if (errorCode === ValidationUserPreregistrationResultEnum.EmailNotUnique) {
        state.validationError = true;
      }
      state.signUpStatus = StatusEnum.Rejected;
    },

    [validateToken.pending.type]: (state: RegistrationState) => {
      state.validateTokenStatus = StatusEnum.Pending;
    },
    [validateToken.fulfilled.type]: (state: RegistrationState, action: PayloadAction<string>) => {
      state.validateTokenStatus = StatusEnum.Fulfilled;
      state.registrationUserName = action.payload;
    },
    [validateToken.rejected.type]: (state: RegistrationState) => {
      state.validateTokenStatus = StatusEnum.Rejected;
    },

    [getEmployer.pending.type]: (state: RegistrationState) => {
      state.employerStatus = StatusEnum.Pending;
    },
    [getEmployer.fulfilled.type]: (state: RegistrationState, action: PayloadAction<string>) => {
      state.employerStatus = StatusEnum.Fulfilled;
      state.employer = action.payload;
    },
    [getEmployer.rejected.type]: (state: RegistrationState) => {
      state.employerStatus = StatusEnum.Rejected;
    },

    [validateUserPreregistrationUser.pending.type]: (state: RegistrationState) => {
      state.validateUserPreregistrationUserStatus = StatusEnum.Pending;
      state.validateUserPreregistrationResult = null;
      state.validationError = null;
    },
    [validateUserPreregistrationUser.fulfilled.type]: (
      state: RegistrationState,
      action: PayloadAction<BasicInfo, string, ValidateUserPreregistrationUserMetaType>,
    ) => {
      state.validateUserPreregistrationUserStatus = StatusEnum.Fulfilled;
      state.basicInfo = action.payload;
      if (action.meta.arg.registration_notice_token) {
        state.userPrevalidatedWithToken = true;
      }

      state.validateUserPreregistrationResult = action.payload.status;
    },
    [validateUserPreregistrationUser.rejected.type]: (state: RegistrationState, error) => {
      const errorCode = error.payload?.response?.data?.error_code;
      state.validateUserPreregistrationUserStatus = StatusEnum.Rejected;
      state.validateUserPreregistrationResult = errorCode;
      if (errorCode === ValidationUserPreregistrationResultEnum.ValidationError) {
        state.validationError = true;
      }
    },
    [signUpAndLogin.rejected.type]: (state: RegistrationState, error) => {
      const errorCode = error.payload?.response?.data?.error_code;

      if (errorCode === ValidationUserPreregistrationResultEnum.ValidationError) {
        state.validationError = true;
      }
    },
    [signUpAndLogin.pending.type]: (state: RegistrationState) => {
      state.validationError = null;
    },
  },
});

export const registrationReducer = registrationSlice.reducer;
export const { setStage, setFlow, reset, setAlreadyHavAccountVisible, clearValidationError } =
  registrationSlice.actions;
