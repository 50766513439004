export const FIELD_NAMES = {
  ACTIVITY_TYPE: 'type',
  DATE: 'date',
  TIME: 'time',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  MILES: 'distance',
  NAME: 'name',
} as const;
