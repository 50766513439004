import React, { useState, useCallback } from 'react';

import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';
import { Checkbox, CheckboxProps } from '@components/Checkbox';

import { useController, UseControllerProps, FieldValues } from '../hooks';

export type CheckboxType = string | { id: any; label: string }; // eslint-disable-line @typescript-eslint/no-explicit-any
export type ValueType = Array<string | number>;
export type CheckboxesType = Array<CheckboxType>;

type CheckboxWithControlProps<FormValues extends FieldValues, N extends string> = {
  name: N;
  required?: boolean;
  checkboxes: CheckboxesType;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, values: FormValues[N]) => void;
} & UseControllerProps<FormValues> &
  Omit<CheckboxProps, 'checked' | 'onChange'>;

export function CheckboxesWithControl<FormValues extends FieldValues, N extends string>({
  control,
  name,
  required,
  checkboxes,
  onChange,
  ...restProps
}: CheckboxWithControlProps<FormValues, N>) {
  const {
    field: { value = [] as FormValues[N], onChange: fieldOnChange },
  } = useController<FormValues>({
    name,
    control,
    rules: { required },
  });
  const [newValue, setValue] = useState<FormValues[N]>(value as FormValues[N]);

  useIgnoreEffectDeps(() => {
    if (value !== newValue) {
      setValue(value);
    }
  }, [value]);

  const handleChange = useCallback<NonNullable<CheckboxProps['onChange']>>(
    (event) => {
      const valueCopy = [...newValue] as FormValues[N];
      const eventValue = Number.isNaN(Number(event.target.value)) ? event.target.value : Number(event.target.value);

      const index = valueCopy.findIndex((value: unknown) => value === eventValue);

      // update checkbox value
      if (index === -1) {
        valueCopy.push(eventValue);
      } else {
        valueCopy.splice(index, 1);
      }

      // send data to react hook form
      fieldOnChange(valueCopy.filter(Boolean));
      onChange?.(event, valueCopy.filter(Boolean));

      // update local state
      setValue(valueCopy);
    },
    [onChange, newValue, fieldOnChange],
  );

  return (
    <>
      {checkboxes.map((option) => {
        const isObject = typeof option === 'object';
        const id = isObject ? option.id : option;

        return (
          <Checkbox
            onChange={handleChange}
            key={isObject ? option.id : option}
            value={id}
            label={isObject ? option.label : option}
            checked={(value as ValueType).includes(id)}
            {...restProps}
          />
        );
      })}
    </>
  );
}
