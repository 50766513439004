import React, { useMemo } from 'react';

import { matchPath, useLocation } from 'react-router';

import { PageHeader as PageHeaderComponent } from '@components/PageHeader';
import { SrA11YPageAnnouncer } from '@components/SrA11YAnnouncer';

import { locale } from './locale';

export const PageHeader = () => {
  const { pathname } = useLocation();
  const pathLocale = useMemo(
    () => Object.keys(locale).find((pattern) => matchPath({ path: pattern, end: false }, pathname)) ?? '',
    [pathname],
  );
  const titleLocale = useMemo(() => locale[pathLocale] as { title?: string; description?: string }, [pathLocale]);

  return (
    <>
      <PageHeaderComponent title={titleLocale?.title} description={titleLocale?.description} />
      <SrA11YPageAnnouncer />
    </>
  );
};
