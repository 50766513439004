import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { addUserSpecificBuilderCases, USER_SPECIFIC_INITIAL_STATE } from '../../../utils/userIdSpecificStoreData';
import { downloadRiskProfileReport, getRiskProfile } from './actions';
import { RiskProfileState } from './types';

const initialState: RiskProfileState = {
  riskProfileRequest: USER_SPECIFIC_INITIAL_STATE,
  downloadStatus: StatusEnum.Uninitialized,
};

export const riskProfileSlice = createSlice({
  name: 'riskProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getRiskProfile,
      updateState: (state, updates) => {
        state.riskProfileRequest = {
          ...state.riskProfileRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.riskProfileRequest.userId,
    });

    builder.addCase(downloadRiskProfileReport.pending, (state) => {
      state.downloadStatus = StatusEnum.Pending;
    });
    builder.addCase(downloadRiskProfileReport.fulfilled, (state) => {
      state.downloadStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadRiskProfileReport.rejected, (state) => {
      state.downloadStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const riskProfileReducer = riskProfileSlice.reducer;
