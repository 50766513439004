import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { SnackbarKey, useSnackbar } from 'notistack';

import { SvgIcon } from '@components/SvgIcon';

import styles from './CloseButton.module.css';

const cn = classNames.bind(styles);

export function CloseButton(snackbarKey: SnackbarKey) {
  const { closeSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    closeSnackbar(snackbarKey);
  }, [snackbarKey, closeSnackbar]);

  return <SvgIcon className={cn('close-button')} onClick={handleClose} icon="closeAlert" />;
}
