import { Languages } from '@common/i18n';
import i18n from 'src/i18n';

export const LOCALE_NAMESPACE = 'change-language';
const locale = {
  eng: 'Eng',
  esp: 'Esp',
  rus: 'Rus',
};

i18n.addResourceBundle(Languages.EN, LOCALE_NAMESPACE, locale);
i18n.addResourceBundle(Languages.ES, LOCALE_NAMESPACE, locale);
