import { createSelector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';

const { dryStatusSelector, dryDataSelector, currentUserStatusSelector } = createUserIdSpecificSelectors(
  (state) => state.testResults.testResultsRequest,
);

export { dryStatusSelector };
export const testResultsSelector = createSelector(dryDataSelector, (data) => data ?? []);
export const testResultsStatusSelector = currentUserStatusSelector;

export const downloadLabResultsReportStatusSelector = (state: RootState) => state.testResults.downloadStatus;
