import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { requestCallbackForAppointment } from './actions';
import { RequestCallbackStoreType } from './types';

const initialState: RequestCallbackStoreType = {
  lastRequestId: null,
  submitStatus: StatusEnum.Uninitialized,
};

export const requestCallbackSlice = createSlice({
  name: 'requestCallback',
  initialState,
  reducers: {},
  extraReducers: {
    [requestCallbackForAppointment.pending.type]: (state: RequestCallbackStoreType, action) => {
      state.lastRequestId = action.meta.requestId;
      state.submitStatus = StatusEnum.Pending;
    },
    [requestCallbackForAppointment.fulfilled.type]: (state: RequestCallbackStoreType, action) => {
      if (state.lastRequestId === action.meta.requestId) {
        state.submitStatus = StatusEnum.Fulfilled;
      }
    },
    [requestCallbackForAppointment.rejected.type]: (state: RequestCallbackStoreType, action) => {
      if (state.lastRequestId === action.meta.requestId) {
        state.submitStatus = StatusEnum.Rejected;
      }
    },
    ...resetStoreActions(() => initialState),
  },
});
export const requestCallbackReducer = requestCallbackSlice.reducer;
