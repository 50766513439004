import { createSelector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';

const { dryDataSelector, currentUserStatusSelector } = createUserIdSpecificSelectors(
  (state) => state.immunizations.immunizationsRequest,
);

export const immunizationsStatusSelector = currentUserStatusSelector;
export const immunizationsSelector = createSelector(dryDataSelector, (data) => data ?? []);

export const downloadImmunizationsReportStatusSelector = (state: RootState) => state.immunizations.downloadStatus;
