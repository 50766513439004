import { formatDate } from '@common/utils';
import { SortOrderEnum } from '@common/utils/tableUtils';
import { DocumentFilterFieldsEnum } from '@modules/getDocuments';

import { locale } from './locale';

export type ColumnType = {
  id: DocumentFilterFieldsEnum;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: string | number) => string;
};

export const COLUMNS: ColumnType[] = [
  {
    id: DocumentFilterFieldsEnum.Date,
    label: locale.fields.date,
    format: (value) => (value ? formatDate(new Date(value), 'MM/dd/yy') : ''),
  },
  {
    id: DocumentFilterFieldsEnum.Source,
    label: locale.fields.source,
    format: (value) => (locale.sources as PartialRecord<string, string>)[value] || String(value),
  },
];

export const DEFAULT_SORT_ORDER = SortOrderEnum.Asc;
export const DEFAULT_SORT_ID = DocumentFilterFieldsEnum.DisplayName;

export const DEFAULT_ITEMS_ON_PAGE = 10;
export const DEFAULT_PAGE = 1;
