export const THEME_BREAK_POINTS = {
  screenMobileMin: 320,
  screenMobileMax: 767,
  screenTabletMin: 768,
  screenTabletMax: 1024,
  screenLaptopMin: 1025,
  screenLaptopMax: 1279,
  screenDesktopMin: 1280,
  screenDesktopMax: 1281,
  screenHuge: 1920,
};

export const BREAK_POINTS = {
  screenMobileMin: `${THEME_BREAK_POINTS.screenMobileMin}px`,
  screenMobileMax: `${THEME_BREAK_POINTS.screenMobileMax}px`,
  screenTabletMin: `${THEME_BREAK_POINTS.screenTabletMin}px`,
  screenTabletMax: `${THEME_BREAK_POINTS.screenTabletMax}px`,
  screenLaptopMin: `${THEME_BREAK_POINTS.screenLaptopMin}px`,
  screenLaptopMax: `${THEME_BREAK_POINTS.screenLaptopMax}px`,
  screenDesktopMin: `${THEME_BREAK_POINTS.screenDesktopMin}px`,
  screenDesktopMax: `${THEME_BREAK_POINTS.screenDesktopMax}px`,
  screenHuge: `${THEME_BREAK_POINTS.screenHuge}px`,
};
