export const mainSkipLinks = [
  {
    id: 'main',
    label: 'Skip to main content',
  },
];

export const skipLinkSupportedRoutes = [
  {
    path: '/',
    equalityCheck: true,
  },
  {
    path: '/messages',
    equalityCheck: false,
  },
  {
    path: '/appointments',
    equalityCheck: false,
  },
  {
    path: '/medication_refills',
    equalityCheck: false,
  },
  {
    path: '/my_health',
    equalityCheck: false,
  },
  {
    path: '/wellness',
    equalityCheck: false,
  },
  {
    path: '/profile',
    equalityCheck: false,
  },
  {
    path: '/help',
    equalityCheck: false,
  },
  {
    path: '/covid_screenings',
    equalityCheck: false,
  },
];
