import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames/bind';

import styles from './Spinner.module.css';

const cn = classNames.bind(styles);

type Props = {
  className?: string;
  size?: string;
};

export function Spinner({ className, size }: Props) {
  return <CircularProgress className={cn('spinner', className)} size={size} />;
}
