import { locale } from './locale';

export enum StageEnum {
  Identification = 'identification',
  IdentificationComplete = 'identification-complete',
  IdentificationUserValidated = 'identification-validated',
  BasicInfo = 'basic-info',
  ContactInfo = 'contact-info',
  Credentials = 'credentials',
  Complete = 'complete',
}

export enum FlowEnum {
  Initial = 'initial',
  Complete = 'complete',
}

export enum NextButtonTitleEnum {
  Next = 'next',
  Verify = 'verify',
  Back = 'back',
  Finish = 'finish',
  Submit = 'submit',
}

export const FIELD_NAMES = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  BIRTH_DATE: 'born_on',
  SOCIAL_SECURITY_NUMBER: 'social_security_number',
  GENDER: 'gender',

  PROPOSED_EMPLOYER: 'proposed_employer',
  PROPOSED_ELIGIBILITY: 'proposed_eligibility_type_id',
  PROPOSED_EMPLOYEE_FIRST_NAME: 'proposed_employee_first_name',
  PROPOSED_EMPLOYEE_LAST_NAME: 'proposed_employee_last_name',
  PROPOSED_EMPLOYEE_BIRTH_DATE: 'proposed_employee_born_on',
  PROPOSED_EMPLOYEE_SOCIAL_SECURITY_NUMBER: 'proposed_employee_social_security_number',

  STREET_ADDRESS1: 'street_address1',
  STREET_ADDRESS2: 'street_address2',
  CITY: 'city',
  ZIP: 'zip',
  STATE_ABBR: 'state_abr',
  HOME_PHONE_NUMBER: 'home_phone_number',
  CELL_PHONE_NUMBER: 'cell_phone_number',

  PREFERRED_CONTACT_METHOD_1: 'preferred_contact_method_1',
  PREFERRED_CONTACT_METHOD_2: 'preferred_contact_method_2',
  PREFERRED_CONTACT_METHOD_3: 'preferred_contact_method_3',

  PRIMARY_LANGUAGE: 'primary_language',

  USERNAME: 'username',
  PASSWORD: 'password',
  PASSWORD_CONFIRM: 'password_confirm',

  AGREEMENT: 'agreement',
} as const;

const localeWithTypes = locale as Record<StageEnum, string>;

type StepParamsType = {
  index: number;
  prev: StageEnum | null;
  next: StageEnum | null;
  title?: string;
  nextButtonTitle?: string;
};

type StepsObjectType = PartialRecord<StageEnum, StepParamsType>;

export const STANDARD_FLOW_STEPS: StepsObjectType = {
  [StageEnum.Identification]: {
    prev: null,
    next: StageEnum.Complete,
    nextButtonTitle: NextButtonTitleEnum.Submit,
    index: 0,
    title: localeWithTypes[StageEnum.Identification],
  },
  [StageEnum.Complete]: {
    prev: null,
    next: null,
    index: 1,
  },
};

export const COMPLETE_FLOW_STEPS: StepsObjectType = {
  [StageEnum.Identification]: {
    prev: null,
    next: StageEnum.BasicInfo,
    nextButtonTitle: NextButtonTitleEnum.Verify,
    index: 0,
    title: localeWithTypes[StageEnum.Identification],
  },
  [StageEnum.IdentificationUserValidated]: {
    prev: null,
    next: StageEnum.BasicInfo,
    nextButtonTitle: NextButtonTitleEnum.Next,
    index: 0,
    title: localeWithTypes[StageEnum.Identification],
  },
  [StageEnum.BasicInfo]: {
    prev: StageEnum.IdentificationUserValidated,
    next: StageEnum.Credentials,
    nextButtonTitle: NextButtonTitleEnum.Next,
    index: 1,
    title: localeWithTypes[StageEnum.BasicInfo],
  },
  [StageEnum.Credentials]: {
    prev: StageEnum.BasicInfo,
    next: null,
    nextButtonTitle: NextButtonTitleEnum.Finish,
    index: 2,
    title: localeWithTypes[StageEnum.Credentials],
  },
};

export enum ContactMethodsEnum {
  Email = 'email',
  Cell = 'cell',
  Home = 'home',
}

export enum ValidationUserPreregistrationResultEnum {
  Registered = 'registered',
  Pending = 'pending',
  UnregisteredUser = 'error_unregistered_user',
  PotentialUser = 'error_potential_user',
  InvalidToken = 'error_invalid_token',
  AlreadyRegistered = 'already_registered',
  EmailNotUnique = 'error_email_not_unique',
  RegistrationNotificationResent = 'registration_notification_resent',
  ValidationError = 'validation_error',
}

export const SSN_LENGTH = 9;
