import { MIN_YEAR } from '@common/constants';
import { isValid, getYear, isAfter } from '@common/utils/dateTimeUtil/dateTimeUtil';
import { combineValidatorsWithMessage } from '@common/utils/validatorUtils';

import { locale } from './locale';

type DateValidatorArgs = [value: unknown, format?: string];

export const dateValidator = (value?: unknown, format?: string): boolean =>
  !!value && !!isValid(value, format) && Number(getYear(new Date(value as Date))) >= MIN_YEAR;

export const fullDateValidatorWithMessage = (value?: unknown) =>
  dateValidator(value) || locale.full_date_validation_message;

export const dateNoFutureValidator = (value?: unknown) => !isAfter(new Date(value as Date), new Date());

const dateNoFutureValidatorWithMessage = (value?: unknown) =>
  dateNoFutureValidator(value) || locale.no_future_validation_message;

export const dateIsValidAndNoFutureValidatorWithMessage = combineValidatorsWithMessage<DateValidatorArgs>([
  fullDateValidatorWithMessage,
  dateNoFutureValidatorWithMessage,
]);

export const notRequiredDateIsValidAndNoFutureValidatorWithMessage = (value?: unknown, format?: string) =>
  value ? dateIsValidAndNoFutureValidatorWithMessage(value, format) : true;

export const notRequiredShortDateValidatorWithMessage = (value?: unknown, format?: string) => {
  const message = dateValidator(value, format) || locale.short_date_validation_message;

  return value ? message : true;
};
