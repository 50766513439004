import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useIgnoreEffectDeps } from '@common/hooks';
import { generatePathWithParamsAndQuery, useQueryParamsObject } from '@common/router';
import { PATHS } from '@common/routes';

import { reset } from '../store';

export const useSalesforceImpersonationRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkAppDispatch>();
  const { wellness_id: wellnessId } = useQueryParamsObject<{ wellness_id: string }>();

  useIgnoreEffectDeps(() => {
    if (wellnessId) {
      dispatch(reset())
        .unwrap()
        .then(() => {
          navigate(
            generatePathWithParamsAndQuery(
              PATHS.IMPERSONATION,
              { userId: wellnessId },
              { salesForceImpersonation: true },
            ),
          );
        });
    }
  }, [wellnessId]);
};
