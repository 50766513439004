import React, { ReactNode } from 'react';

import { DropDownMenuItem } from '@components/DropDownMenuItem';
import { IconType } from '@components/SvgIcon';
import { ShowCategory } from '@modules/categories';
import { FlipperType } from '@modules/flippers';

export type MenuItemType = {
  id?: string;
  to?: string;
  icon?: IconType;
  label?: string;
  component?: ReactNode;
  noHover?: boolean;
  flipper?: FlipperType;
  badge?: ReactNode;
  action?: VoidFunction;
};

export interface SubMenuProps {
  menuItems: Array<MenuItemType>;
  onSubMenuItemClick?: (menuItem: MenuItemType, to?: string) => void;
  to?: string;
  handleClose: () => void;
}

export function SubMenu({ menuItems, onSubMenuItemClick, to, handleClose }: SubMenuProps) {
  const handleCloseAndChoose = (menuItem: MenuItemType) => {
    onSubMenuItemClick?.(menuItem, to);
    handleClose();
  };

  const renderMenuItem = (menuItem: MenuItemType, index: number) => {
    const key = menuItem.label || menuItem.to || index;
    const autoFocus = index === 0;

    if (menuItem.label && onSubMenuItemClick && menuItem.to) {
      return (
        <ShowCategory key={key} category={menuItem.label || ''}>
          <DropDownMenuItem autoFocus={autoFocus} to={menuItem.to} onClick={() => handleCloseAndChoose(menuItem)}>
            {menuItem.label || menuItem.component}
          </DropDownMenuItem>
        </ShowCategory>
      );
    }

    if (menuItem.to) {
      return (
        <ShowCategory key={key} category={menuItem.label || ''}>
          <DropDownMenuItem autoFocus={autoFocus} to={menuItem.to}>
            {menuItem.label}
          </DropDownMenuItem>
        </ShowCategory>
      );
    }
  };

  return <>{menuItems.map(renderMenuItem)}</>;
}
