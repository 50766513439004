import React, { ReactNode } from 'react';

import InputLabelMaterial, { InputLabelProps as InputLabelPropsMaterial } from '@mui/material/InputLabel';
import classNames from 'classnames/bind';

import styles from './InputLabel.module.css';

export type InputLabelProps = {
  className?: string;
  error?: boolean;
  required?: boolean;
  htmlFor?: string;
  id?: string;
  children?: ReactNode;
  sx?: InputLabelPropsMaterial['sx'];
};

const cn = classNames.bind(styles);

export function InputLabel({ className, error, required, children, htmlFor, id, sx }: InputLabelProps) {
  return (
    <InputLabelMaterial
      shrink
      sx={sx}
      className={cn('label', className)}
      classes={{
        focused: cn('label--focused'),
        outlined: cn('label--outlined'),
        asterisk: cn('label-asterisk'),
        disabled: cn('label--disabled'),
      }}
      required={required}
      id={id}
      htmlFor={htmlFor}
      error={error}
    >
      {children}
    </InputLabelMaterial>
  );
}
