import { createSelector, Selector } from 'reselect';

import { StatusEnum } from '@common/constants';

import { selectedFamilyMemberIdSelector } from '../../store';
import { RequestWithUserId } from './types';

type Result<D> = {
  dryDataSelector: Selector<RootState, D | null>;
  currentUserDataSelector: Selector<RootState, D | null>;
  dryStatusSelector: Selector<RootState, StatusEnum>;
  currentUserStatusSelector: Selector<RootState, StatusEnum>;
};

export const createUserIdSpecificSelectors = <D>(
  storeSelector: Selector<RootState, RequestWithUserId<D>>,
): Result<D> => {
  const dryDataSelector = createSelector(storeSelector, ({ data }) => data);
  const dryStatusSelector = createSelector(storeSelector, ({ status }) => status);

  const requestUserIdSelector = createSelector(storeSelector, ({ userId }) => userId);

  const currentUserDataSelector = createSelector(
    selectedFamilyMemberIdSelector,
    requestUserIdSelector,
    dryDataSelector,
    (selectedId, requestId, dryData) => (selectedId === requestId ? dryData : null),
  );

  const currentUserStatusSelector = createSelector(
    selectedFamilyMemberIdSelector,
    requestUserIdSelector,
    dryStatusSelector,
    (selectedId, requestId, dryStatus) => (selectedId === requestId ? dryStatus : StatusEnum.Uninitialized),
  );

  return {
    dryDataSelector,
    currentUserDataSelector,
    dryStatusSelector,
    currentUserStatusSelector,
  };
};
