import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { downLoadFromBlob } from '@common/utils';

import { DownloadScreeningReportPayloadType } from './types';

export const getScreenings = createAsyncThunk('screenings', async (_, { rejectWithValue }) => {
  try {
    const data = fetch.get({
      url: '/HealthSummary/screenings',
    });

    const res = await data.response;

    return res?.data.screenings;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const downloadScreeningsReport = createAsyncThunk<unknown, DownloadScreeningReportPayloadType>(
  'HealthSummary/screenings-report',
  async ({ dependentUserId, screeningId, displayQuestions }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/reports/screenings',
        responseType: 'blob',
        params: {
          screeningId,
          displayQuestions,
          dependentUserId,
        },
      });

      const res = await data.response;
      const blob = res?.data;
      downLoadFromBlob(blob, 'application/pdf', `screenings-report-${screeningId}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
