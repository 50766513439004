import { createAsyncThunk } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';

import { getDefaultClinicPhoneStatusSelector } from './selectors';
import { DefaultClinicPhoneType } from './types';

export const getDefaultClinicPhone = createAsyncThunk<DefaultClinicPhoneType, void, { state: RootState }>(
  'DefaultClinicPhone/default-clinic-phone',
  async (_, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/User/default-clinic-phone',
      });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => getDefaultClinicPhoneStatusSelector(getState()) !== StatusEnum.Pending,
  },
);
