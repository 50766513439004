/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { PayloadAction } from '@reduxjs/toolkit';

import { userProfileService } from '@common/UserProfileService';
import { StatusEnum } from '@common/constants';

import { CustomSettingsInformationType, SaveMetaType } from '../types';
import { FamilyMemberProfileType, UserProfileState } from './types';

export const getIanaTimezone = (state: UserProfileState, time_zone?: Nullable<string>) =>
  time_zone && state.timeZones.find((tz) => tz.rails_time_zone === time_zone)?.iana_time_zone;

export const saveUserDataPending = (state: UserProfileState) => {
  state.saveStatus = StatusEnum.Pending;
};

export const saveUserDataRejected = (state: UserProfileState) => {
  state.saveStatus = StatusEnum.Rejected;
};

export const saveUserDataFulfilled = (
  state: UserProfileState,
  action: PayloadAction<unknown, string, SaveMetaType>,
) => {
  const { id } = action.meta.arg;
  const iana_time_zone = getIanaTimezone(state, (action.meta.arg as CustomSettingsInformationType).time_zone);
  const userProfileIanaTimeZone = iana_time_zone || state.userProfileData.iana_time_zone;

  if (id !== state.userProfileData.id) {
    const familyMemberState = state.familyMembersProfiles[id] ?? ({} as FamilyMemberProfileType);
    state.familyMembersProfiles[id] = {
      ...familyMemberState,
      data: {
        ...familyMemberState.data,
        ...action.meta.arg,
      },
    };
  } else {
    state.userProfileData = {
      ...state.userProfileData,
      ...action.meta.arg,
      iana_time_zone: userProfileIanaTimeZone,
      password: '',
      confirm_password: '',
    };
  }
  state.ianaTimeZone = iana_time_zone || state.ianaTimeZone;
  const userProfile = userProfileService.getUserProfile();
  const wellnessId = state?.userProfileData?.wellness_id ?? '';

  userProfileService.saveUserProfile(
    {
      ...userProfile,
      user: { ...userProfile.user, ...action.meta.arg, iana_time_zone: userProfileIanaTimeZone },
    },
    wellnessId ?? '',
  );

  state.saveStatus = StatusEnum.Fulfilled;
};
