import React, { useRef, useMemo, useCallback } from 'react';

import { useIgnoreEffectDeps } from '@common/hooks';

import { grecaptchaCall, isRecaptchaDisabled } from './utils';

type Params = {
  submitCallback: (recaptchaToken: string) => unknown;
  flowInitiated: boolean;
};

export const useRecaptcha = ({ submitCallback, flowInitiated }: Params) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useIgnoreEffectDeps(() => {
    if (isRecaptchaDisabled() || !flowInitiated) {
      return;
    }
    grecaptchaCall((grecaptcha) => {
      grecaptcha.render(containerRef.current ?? '', {
        sitekey: process.env.RECAPTCHA_API_KEY,
        size: 'invisible',
        callback: () => {
          submitCallback(grecaptcha.getResponse());
          grecaptcha.reset();
        },
      });
    });
  }, [flowInitiated]);

  const recaptchaContainer = useMemo(() => <div ref={containerRef} />, []);

  const executeRecaptcha = useCallback(() => {
    if (isRecaptchaDisabled()) {
      return submitCallback('');
    }

    grecaptchaCall((grecaptcha) => grecaptcha.execute());
  }, [submitCallback]);

  return { recaptchaContainer, executeRecaptcha };
};
