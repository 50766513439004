export const locale = {
  header: 'Marathon Health, LLC',
  sub_header: 'MEMBER PORTAL ACCESSIBILITY STATEMENT',
  item1: 'General',
  p_item1_1:
    'Marathon Health, LLC (“Marathon”) is committed to facilitating and improving the accessibility and usability of our <a href="https://member.ourhealth.org/">Member Portal</a>. Marathon strives to ensure that our Member Portal is accessible to persons with disabilities, including users of screen reader technology. To accomplish this, Marathon has engaged UsableNet Inc. to help test, remediate and maintain our Member Portal in line with the Web Content Accessibility Guidelines (WCAG 2.1 AA).',
  item2: 'Disclaimer',
  p_item2_1:
    'We are continually evaluating the accessibility of our Member Portal, and our efforts to improve our Member Portal’s accessibility and usability are ongoing. We may make changes to our Member Portal for the purpose of improving its accessibility from time to time.',
  item3: 'Contact Us',
  p_item3_1:
    'We welcome your feedback on the accessibility of the Member Portal. Please let us know if you encounter any barriers to accessibility by contacting us at: <a href="mailto:member@marathon-health.com">member@marathon-health.com</a> or 866-434-3255.',
  p_item3_2:
    'If you do encounter an accessibility issue, please specify the nature of the issue in your email or phone call, and we will make all reasonable efforts to make the Member Portal service or information that you attempted to access available to you.',
  p_item3_3: 'Last Updated: January 25, 2022',
};
