import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';

import { requestSamlAuth } from './actions';
import { SamlAuthState } from './types';

const initialState: SamlAuthState = {
  samlAuthStatus: StatusEnum.Uninitialized,
};

const samlAuthSlice = createSlice({
  name: 'samlAuth',
  initialState,
  reducers: {},
  extraReducers: {
    [requestSamlAuth.pending.type]: (state: SamlAuthState) => {
      state.samlAuthStatus = StatusEnum.Pending;
    },
    [requestSamlAuth.fulfilled.type]: (state: SamlAuthState) => {
      state.samlAuthStatus = StatusEnum.Fulfilled;
    },
    [requestSamlAuth.rejected.type]: (state: SamlAuthState) => {
      state.samlAuthStatus = StatusEnum.Rejected;
    },
  },
});

export const samlAuthReducer = samlAuthSlice.reducer;
