import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import { getData, downloadVisitSummaries } from './actions';
import { StateType } from './types';

const initialState: StateType = {
  summaryRequest: USER_SPECIFIC_INITIAL_STATE,
  downloadStatus: StatusEnum.Uninitialized,
};

export const visitSummariesSlice = createSlice({
  name: 'visitSummaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getData,
      updateState: (state, updates) => {
        state.summaryRequest = {
          ...state.summaryRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.summaryRequest.userId,
    });

    builder.addCase(downloadVisitSummaries.pending, (state) => {
      state.downloadStatus = StatusEnum.Pending;
    });
    builder.addCase(downloadVisitSummaries.fulfilled, (state) => {
      state.downloadStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadVisitSummaries.rejected, (state) => {
      state.downloadStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const visitSummariesReducer = visitSummariesSlice.reducer;
