import { createSelector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';

const { dryDataSelector, currentUserStatusSelector } = createUserIdSpecificSelectors(
  (state) => state.medications.medicationsRequest,
);

export const medicationsStatusSelector = currentUserStatusSelector;
export const medicationsSelector = createSelector(dryDataSelector, (data) => data ?? []);

export const downloadMedicationsReportStatusSelector = (state: RootState) => state.medications.downloadStatus;
