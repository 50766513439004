import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { datePlusTime, formatDateForServer } from '@common/utils';
import { RecordActivityModal } from '@components/RecordActivityModal';

import { WellnessTypesEnum } from '../../../constants';
import { hideEditModal, saveLogItem, SaveWaterRequestType, waterRecordActivitySelector } from '../../../store';
import { WaterItemType } from '../../../types';
import { useResetEntryCount } from '../../../useReserEntryCount';
import { FormValuesType } from '../types';
import { WaterForm } from './WaterForm';

export function WaterRecordActivityModal() {
  const { editModalVisible, selectedItem, saveFormStatus } = useSelector(waterRecordActivitySelector);
  const dispatch = useDispatch();
  const [entryCount, setEntryCount] = useState(0);

  const handleSave = useCallback(
    (formValues: FormValuesType, recordAnotherEntry: boolean) => {
      const requestData: SaveWaterRequestType['data'] = {
        id: formValues.id,
        ounces: Number(formValues.ounces),
        recorded_at: formatDateForServer(datePlusTime(formValues.date, formValues.time)),
      };

      dispatch(saveLogItem({ data: requestData, type: WellnessTypesEnum.Water, recordAnotherEntry, entryCount }));
      setEntryCount(entryCount + 1);
    },
    [dispatch, entryCount],
  );

  const handleClose = useCallback(() => {
    dispatch(hideEditModal({ type: WellnessTypesEnum.Water }));
  }, [dispatch]);

  useResetEntryCount(editModalVisible, setEntryCount);

  return (
    <RecordActivityModal open={editModalVisible} onClose={handleClose}>
      <WaterForm
        onClose={handleClose}
        onSave={handleSave}
        initialValues={selectedItem as Nullable<WaterItemType>}
        saveFormStatus={saveFormStatus}
      />
    </RecordActivityModal>
  );
}
