import { useCallback, useEffect, useRef, useState } from 'react';

import throttle from 'lodash.throttle';

import { USER_ACTIVITY_TIMEOUT } from '../constants';

export const useUserActiveTimeout = () => {
  const [userActivityExpired, setUserActivity] = useState(false);
  const timerId = useRef<ReturnType<typeof setTimeout>>();
  const timerState = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setTimer = useCallback(
    throttle(() => {
      if (timerState.current) {
        return;
      }

      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      timerId.current = setTimeout(() => {
        timerState.current = true;
        setUserActivity(true);
      }, USER_ACTIVITY_TIMEOUT);
    }, 1000),
    [],
  );

  const clearExpired = useCallback(() => {
    timerState.current = false;
    setTimer();
    setUserActivity(false);
  }, [setTimer]);

  useEffect(() => {
    timerState.current = false;
    setTimer();

    document.addEventListener('mousemove', setTimer);
    document.addEventListener('keydown', setTimer);

    return () => {
      document.removeEventListener('mousemove', setTimer);
      document.removeEventListener('keydown', setTimer);
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, [setTimer]);

  return { userActivityExpired, clearExpired };
};
