import { MeasurementEnum } from '../../constants';

export const locale = {
  title: 'Steps',
  dashboard_title: 'Steps',
  history_title: 'Recently Recorded Steps',
  record_activity: 'Record Steps',
  measurements: {
    [MeasurementEnum.Steps]: 'Steps Per Month',
  },
  measurementsShort: {
    [MeasurementEnum.Steps]: 'steps',
  },
  measurementsYScaleTitle: {
    [MeasurementEnum.Steps]: 'Steps',
  },
};
