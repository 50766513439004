import { createSlice } from '@reduxjs/toolkit';

import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { addUserSpecificBuilderCases, USER_SPECIFIC_INITIAL_STATE } from '../../../utils/userIdSpecificStoreData';
import { getGoals } from './actions';
import { GoalsState } from './types';

const initialState: GoalsState = {
  goalsRequest: USER_SPECIFIC_INITIAL_STATE,
};

export const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getGoals,
      updateState: (state, updates) => {
        state.goalsRequest = {
          ...state.goalsRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.goalsRequest.userId,
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const goalsReducer = goalsSlice.reducer;
