import React from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { useScreenSize } from '@common/hooks/useScreenSize';
import { HorizontalMenu } from '@components/HorizontalMenu';

import { getVisibleMenuItems } from '../../store';

import styles from './MainMenu.module.css';

const cn = classNames.bind(styles);

export function MainMenu() {
  const { isDesktopView } = useScreenSize();

  const visibleMenuItems = useSelector(getVisibleMenuItems);

  if (!isDesktopView) {
    return null;
  }

  return <HorizontalMenu className={cn('main-menu')} menuItemList={visibleMenuItems} />;
}
