import { extendConfigByApiUrl, extendConfigByProxyHeader } from '@common/fetch/utils';

export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  OPTIONS: 'OPTIONS',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
} as const;

export const REFRESH_TOKEN_URL = '/Authentication/refresh-token';

const BASE_CONFIG = {
  withCredentials: true,
};

export const STATUSES = {
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  NETWORK_CONNECT_TIMEOUT: 599,
};

export const SKIP_ALERT_STATUSES = [STATUSES.NOT_FOUND];

export const CONFIG = extendConfigByProxyHeader(extendConfigByApiUrl(BASE_CONFIG));

export const ERROR_MESSAGES = {
  CANCELED: 'canceled',
};

export const ERROR_CODES = {
  EXTERNAL_API_ERROR: 'external_api_error',
};
