export const INCENTIVE_FOR_PRELOADER = {
  incentive_program_id: 100,
  name: 'Test Mobile Incentive Program 2022',
  summary: 'Test Mobile Incentive Program 2022',
  reward_designation: '      ',
  days_in_program: 0,
  days_remaining_in_program: 0,
  starts_on: '04/04/2022',
  ends_on: '12/04/2022',
  individual_max: 100,
  family_max: 100,
  possible_amount: 100,
  confirmed_toward_possible_amount: 0,
  contingent_on_family_member_id: null,
  contact_info: null,
  family_member_earnings: {
    total_family_confirmed_toward_possible_amount: 100,
    total_family_possible_amount: 0,
    family_member_earning_summaries: [
      {
        id: 1217094,
        first_name: '    ',
        last_name: '    ',
        middle_name: '    ',
        preferred_first_name: '    ',
        eligibility_type_name: '    ',
        individual_max: 400.0,
        family_max: 800.0,
        possible_amount: 400.0,
        confirmed_toward_possible_amount: 0,
      },
    ],
  },
};

export const GRID_SPACING = { xs: 1, sm: 1, md: 2, lg: 2 };
export const GRID_ROW_SPACING = { xs: 1, sm: 1, md: 0, lg: 0 };

export const FITNESS_ACTIVITY_TYPES = [
  'DistancePerDayIncentiveGoal',
  'MinutesPerDayIncentiveGoal',
  'MinutesPerWeekIncentiveGoal',
  'TotalDistanceIncentiveGoal',
  'TotalMinutesIncentiveGoal',
];

export const STEPS_ACTIVITY_TYPES = [
  'StepsPerDayIncentiveGoal',
  'StepsPerWeekIncentiveGoal',
  'TotalStepsIncentiveGoal',
];

export const HRA_ACTIVITY_TYPES = ['HraIncentiveGoal'];

export const SCHEDULE_APPOINTMENTS_TYPES = [
  'AnnualPhysicalIncentiveGoal',
  'BiometricsIncentiveGoal',
  'BiometricsWithA1cIncentiveGoal',
  'BiometricsWithHdlIncentiveGoal',
  'BiometricsWithWaistIncentiveGoal',
  'AnyAppointmentIncentiveGoal',
  'ChrIncentiveGoal',
  'FirstClinicVisitIncentiveGoal',
  'FlushotIncentiveGoal',
  'HealthCoachIncentiveGoal',
  'HealthCoachSecondIncentiveGoal',
  'HealthCoachThirdIncentiveGoal',
  'HealthCoachFourthIncentiveGoal',
  'HealthCoachFifthIncentiveGoal',
  'HealthCoachSixthIncentiveGoal',
  'HealthCoachSixthIncentiveGoal',
  'HealthCoachIncludeChrIncentiveGoal',
  'HealthCoachIncludeChrSecondIncentiveGoal',
  'HealthCoachIncludeChrThirdIncentiveGoal',
  'HealthCoachIncludeChrFourthIncentiveGoal',
  'HealthCoachIncludeChrFifthIncentiveGoal',
  'HealthCoachIncludeChrSixthIncentiveGoal',
  'InBodyIncentiveGoal',
  'TobaccoIncentiveGoal',
  'TobaccoSecondIncentiveGoal',
  'TobaccoThirdIncentiveGoal',
  'TobaccoFourthIncentiveGoal',
  'TobaccoFifthIncentiveGoal',
  'TobaccoSixthIncentiveGoal',
  'WellnessScreenIncentiveGoal',
  'BloodPressureIncentiveGoal',
  'BehavioralHealthIncentiveGoal',
  'BehavioralHealthSecondIncentiveGoal',
  'BehavioralHealthThirdIncentiveGoal',
  'BehavioralHealthFourthIncentiveGoal',
  'BehavioralHealthFifthIncentiveGoal',
  'BehavioralHealthSixthIncentiveGoal',
  'BehavioralHealthIncludeCoachingIncentiveGoal',
  'BehavioralHealthIncludeCoachingSecondIncentiveGoal',
  'BehavioralHealthIncludeCoachingThirdIncentiveGoal',
  'BehavioralHealthIncludeCoachingFourthIncentiveGoal',
  'BehavioralHealthIncludeCoachingFifthIncentiveGoal',
  'BehavioralHealthIncludeCoachingSixthIncentiveGoal',
  'HealthCoachCompletedIncentiveGoal',
  'AcuteProviderIncentiveGoal',
  'PhysicalTherapyIncentiveGoal',
  'BiometricsStandard1IncentiveGoal',
  'BiometricsStandard2IncentiveGoal',
  'BiometricsStandard3IncentiveGoal',
  'BiometricsStandard4IncentiveGoal',
];

export const FITNESS_AND_STEPS_ACTIVITY_TYPES = ['StepsOrMinutesPerDayIncentiveGoal'];
export const WORKSHOPS_TYPES = ['WellnessProgramIncentiveGoal'];
export const CHALLENGES_TYPES = ['Challenge50IncentiveGoal', 'Challenge75IncentiveGoal', 'Challenge100IncentiveGoal'];

export const GROUP_TYPES = ['GroupIncentiveGoal'];
export const INCENTIVE_DOCUMENT_FOR_PRELOADER = {
  id: 0,
  document_id: 0,
};

export const INCENTIVE_PROGRAM_SELECTOR_ID = 'incentive-program-id-selector';
export const INCENTIVE_PROGRAM_SELECTOR_SELECT_ID = 'select-id_select_program_id';
