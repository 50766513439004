import { useDispatch, useSelector } from 'react-redux';

import { userProfileService } from '@common/UserProfileService';
import { useIgnoreEffectDeps } from '@common/hooks';

import { wellnessIdSelector } from '../../../userProfileData/store';
import { setHideChallenges, setHideIncentives } from '../../store';

export function useLoadHeaderFlags() {
  const dispatch = useDispatch();
  const wellnessId = useSelector(wellnessIdSelector);
  const userProfileDataFromStorage = userProfileService.getUserProfile(wellnessId);

  useIgnoreEffectDeps(() => {
    if (userProfileDataFromStorage) {
      dispatch(setHideChallenges(userProfileDataFromStorage.user_auth?.hide_challenges));
      dispatch(setHideIncentives(userProfileDataFromStorage.user_auth?.hide_incentives));
    }
  }, [userProfileDataFromStorage.user_auth?.hide_challenges, userProfileDataFromStorage.user_auth?.hide_incentives]);
}
