export const locale = {
  cancel: 'Cancel',
  record_another_entry: 'Save & Record Another Entry',
  submit: 'Record Fitness Activity',
  header: 'Record Fitness Activity',
  field_labels: {
    activity_type: 'Activity Type',
    date: 'Date',
    time: 'Time',
    hours: 'Hours',
    hours_aria_label: '{{type}} - Hours',
    minutes_aria_label: '{{type}} - Minutes',
    seconds_aria_label: '{{type}} - Seconds',
    minutes: 'Minutes',
    seconds: 'Seconds',
    miles: 'Miles',
    miles_aria: 'Distance - Miles',
    name: 'Description',
  },
  field_row_labels: {
    started: 'Started',
    duration: 'Duration',
    distance: 'Distance',
  },
  placeholders: {
    description: 'Activity Description',
    activity_type: 'Fitness Activity',
  },
  errors: {
    activity_type: 'Activity Type is required',
    description: 'Description is required',
    date: 'Date is required',
    duration: 'Duration is required',
    duration_or_distance: 'Must include duration and/or distance',
  },
};
