import React from 'react';

import { Helmet } from 'react-helmet';

import { locale } from './locale';

type Props = {
  title?: Nullable<string>;
  description?: Nullable<string>;
};

export const PageHeader = ({ title, description }: Props) => (
  <Helmet>
    <title>{title ? `${title} - ${locale.marathon_health}` : locale.marathon_health}</title>
    {description && <meta name="description" content={description} />}
  </Helmet>
);
