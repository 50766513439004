import { useMediaQuery } from '@mui/material';

import { BREAK_POINTS } from '@common/constants/breackpoints';

export const useScreenSize = () => {
  const mobileMax = useMediaQuery(`(max-width: ${BREAK_POINTS.screenMobileMax})`);
  const isMobileView = mobileMax;

  const tabletMax = useMediaQuery(`(max-width: ${BREAK_POINTS.screenTabletMax})`);
  const tabletMin = useMediaQuery(`(min-width: ${BREAK_POINTS.screenTabletMin})`);
  const isTabletView = tabletMax && tabletMin;

  const isDesktopMin = useMediaQuery(`(min-width: ${BREAK_POINTS.screenLaptopMin})`);
  const isDesktopView = isDesktopMin;

  return { isMobileView, isTabletView, isDesktopView };
};
