import { createSelector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';

const { dryDataSelector, currentUserStatusSelector } = createUserIdSpecificSelectors(
  (state: RootState) => state.riskProfile.riskProfileRequest,
);

export const riskProfileStatusSelector = currentUserStatusSelector;

export const riskProfileSelector = createSelector(dryDataSelector, (data) => data?.rows ?? []);
export const riskProfileAllowDownloadSelector = createSelector(
  dryDataSelector,
  (data) => data?.allow_my_health_report_download,
);

export const riskProfileDownloadStatusSelector = (state: RootState) => state.riskProfile.downloadStatus;
