import React, { useCallback, useState, ReactNode } from 'react';

import classNames from 'classnames/bind';

import { Accordion, AccordionDetails, AccordionSummary } from '@components/Accordion';
import { Typography } from '@components/Typography';

import { locale } from './locale';

import styles from './ControlledAccordion.module.css';

const cn = classNames.bind(styles);

type Props = {
  summary: ReactNode;
  children: ReactNode;
  showTitle?: string;
  className?: string;
  hideTitle?: string;
  noPadding?: boolean;
  defaultExpanded?: boolean;
};

export function ControlledAccordion({
  summary,
  children,
  showTitle = locale.show,
  hideTitle = locale.hide,
  className,
  noPadding,
  defaultExpanded = false,
}: Props) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = useCallback((_: unknown, isExpanded: boolean) => {
    setExpanded(isExpanded);
  }, []);

  return (
    <Accordion className={cn('accordion', className)} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        className={cn('accordion__accordion-summary', { 'accordion__accordion-summary--no-padding': noPadding })}
      >
        <div className={cn('accordion__summary-container')}>
          <Typography variant="h2" className={cn('accordion__title')}>
            {summary}
          </Typography>

          <Typography className={cn('accordion__accordion-summary-right')}>
            {expanded ? hideTitle : showTitle}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={cn('accordion__accordion-details', { 'accordion__accordion-details--no-padding': noPadding })}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
