import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import { downloadLabResultsReport, getTestResults } from './actions';
import { TestResultsState } from './types';

const initialState: TestResultsState = {
  testResultsRequest: USER_SPECIFIC_INITIAL_STATE,
  downloadStatus: StatusEnum.Uninitialized,
};

export const testResultsSlice = createSlice({
  name: 'testResults',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getTestResults,
      updateState: (state, updates) => {
        state.testResultsRequest = {
          ...state.testResultsRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.testResultsRequest.userId,
    });

    builder.addCase(downloadLabResultsReport.pending, (state) => {
      state.downloadStatus = StatusEnum.Pending;
    });
    builder.addCase(downloadLabResultsReport.fulfilled, (state) => {
      state.downloadStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadLabResultsReport.rejected, (state) => {
      state.downloadStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const testResultsReducer = testResultsSlice.reducer;
