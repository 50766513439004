import { Signed } from './types';

export interface ISignServiceService {
  getSign: () => Signed | null;
  saveSign: (userProfile: Signed) => void;
  removeSign: () => void;
}

export class SignService implements ISignServiceService {
  private readonly localStorageKey: string;

  constructor(accessKey: string) {
    this.localStorageKey = accessKey;
  }

  getSign() {
    const sign = sessionStorage.getItem(this.localStorageKey);

    return sign ? JSON.parse(sign) : null;
  }

  saveSign(sign: Signed) {
    sessionStorage.setItem(this.localStorageKey, JSON.stringify(sign));
  }

  removeSign() {
    sessionStorage.removeItem(this.localStorageKey);
  }
}
