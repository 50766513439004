import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarKey } from 'notistack';

import { SnackbarState, EnqueueSnackbarType } from './types';

const initialState: SnackbarState = {
  notifications: [],
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    enqueueSnackbar(state: SnackbarState, action: PayloadAction<EnqueueSnackbarType>) {
      const key = new Date().getTime() + Math.random();
      state.notifications = [
        ...state.notifications,
        {
          key,
          ...action.payload,
          options: {
            key,
            ...action.payload.options,
          },
        },
      ];
    },
    removeSnackbar(state: SnackbarState, action: PayloadAction<SnackbarKey>) {
      state.notifications = state.notifications.filter((notification) => notification.key !== action.payload);
    },
  },
});

export const { removeSnackbar, enqueueSnackbar } = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;
