import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { logout, logoutWithoutRevoke } from '@modules/Login';

import { getClinics, getClinicDetails } from './actions';
import { HealthCenterLocationsState, ClinicType, ClinicDetailsType } from './types';

const initialState: HealthCenterLocationsState = {
  clinics: {
    data: [],
    status: StatusEnum.Uninitialized,
    error: {},
  },
  clinic: {
    data: {
      id: 0,
      name: '',
      phone: '',
      fax: '',
      email: '',
      address: {
        street_address1: '',
        street_address2: '',
        city: '',
        state_abbr: '',
        zip_code: '',
        latitude: null,
        longitude: null,
      },
      is_virtual: false,
      hours: '',
      latitude: null,
      longitude: null,
      providers: [],
      nurses: [],
      health_coaches: [],
    },
    status: StatusEnum.Uninitialized,
    error: {},
  },
};

export const healthCenterLocationsSlice = createSlice({
  name: 'healthCenterLocations',
  initialState,
  reducers: {
    resetClinic: (state: HealthCenterLocationsState) => {
      state.clinic = initialState.clinic;
    },
    resetClinics: (state: HealthCenterLocationsState) => {
      state.clinics = initialState.clinics;
    },
  },
  extraReducers: {
    [getClinics.pending.type]: (state: HealthCenterLocationsState) => {
      state.clinics.status = StatusEnum.Pending;
    },
    [getClinics.fulfilled.type]: (state: HealthCenterLocationsState, action: PayloadAction<ClinicType[]>) => {
      state.clinics.status = StatusEnum.Fulfilled;
      state.clinics.data = action.payload;
    },
    [getClinics.rejected.type]: (state: HealthCenterLocationsState) => {
      state.clinics.status = StatusEnum.Rejected;
    },
    [getClinicDetails.pending.type]: (state: HealthCenterLocationsState) => {
      state.clinic.status = StatusEnum.Pending;
    },
    [getClinicDetails.fulfilled.type]: (
      state: HealthCenterLocationsState,
      action: PayloadAction<ClinicDetailsType>,
    ) => {
      state.clinic.status = StatusEnum.Fulfilled;
      state.clinic.data = action.payload;
    },
    [getClinicDetails.rejected.type]: (state: HealthCenterLocationsState) => {
      state.clinic.status = StatusEnum.Rejected;
    },

    [logout.fulfilled.type]: () => initialState,
    [logoutWithoutRevoke.fulfilled.type]: () => initialState,
  },
});

export const { resetClinic, resetClinics } = healthCenterLocationsSlice.actions;

export const healthCenterLocationsReducer = healthCenterLocationsSlice.reducer;
