import React, { ReactNode } from 'react';

import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';

import { CapturePrimaryLanguage } from '@modules/CapturePrimaryLanguage';
import { DefaultClinicPhone } from '@modules/DefaultClinicPhone';
import { Footer } from '@modules/Footer';
import { GlobalWaivers } from '@modules/GlobalWaivers';
import { Header } from '@modules/Header';
import { useLoadHeaderFlags } from '@modules/Header/hooks/useLoadHeaderFlags/useLoadHeaderFlags';
import { ImpersonationBanner } from '@modules/ImpersonationBanner';
import { useGetFamilyMembers } from '@modules/LinkedFamilyMembers';
import { usePersistImpersonation } from '@modules/Login';
import { useUnreadMessages } from '@modules/Messages/hooks/useUnreadMessages';
import { useLoadNetworks } from '@modules/Networks/hooks/useLoadNetworks';
import { ScrollToTop } from '@modules/ScrollToTop';
import { SupplementalWaiver, usePersistSupplementWaiver } from '@modules/SuplementalWaiver';
import { useLoadFlippers } from '@modules/flippers/hooks/useLoadFlippers';
import { useLoadActiveEligibilities, usePersistUserProfile } from '@modules/userProfileData';

import styles from './BaseLayout.module.css';

const cn = classNames.bind(styles);

export function BaseLayout({ children }: { children?: ReactNode }) {
  usePersistImpersonation();
  useLoadActiveEligibilities();
  usePersistSupplementWaiver();
  usePersistUserProfile();
  useGetFamilyMembers();
  useUnreadMessages();
  useLoadNetworks();
  useLoadFlippers();
  useLoadHeaderFlags();

  return (
    <div className={cn('base-layout')}>
      <div className={cn('base-layout__header')}>
        <Header />
      </div>
      <ImpersonationBanner />
      <main className={cn('base-layout__main-content')} id="main">
        {children ?? <Outlet />}
      </main>
      <div className={cn('base-layout__footer')}>
        <Footer />
      </div>
      <SupplementalWaiver />
      <DefaultClinicPhone />
      <ScrollToTop />
      <GlobalWaivers />
      <CapturePrimaryLanguage />
    </div>
  );
}
