import { isValid } from '@common/utils';

const valueComparator = (valueA: string | number | Date, valueB: string | number | Date) => {
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }

  return 0;
};

export function descendingComparator(valueA: unknown, valueB: unknown) {
  if ((typeof valueA === 'string' && isValid(valueA)) || (typeof valueB === 'string' && isValid(valueB))) {
    const dateA = new Date(String(valueA)).getTime();
    const dateB = new Date(String(valueB)).getTime();

    return valueComparator(dateA, dateB);
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    const stringA = valueA.toLowerCase();
    const stringB = valueB.toLowerCase();

    return valueComparator(stringA, stringB);
  }

  return valueComparator(valueA as number, valueB as number);
}
