import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { enqueueSnackbar } from '@modules/Snackbar';

import { DEFAULT_ITEMS_ON_PAGE, DEFAULT_PAGE } from '../constants';
import { locale } from '../locale';
import { ActionPayloadType, GetConversationPayloadType, MailboxConversationPayloadType } from './types';

export const getUnreadMessages = createAsyncThunk('Messages/get-unread', async (_, { rejectWithValue }) => {
  try {
    const data = await fetch.get({ url: '/Messaging/unread-family-members' });
    const res = await data.response;

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getConversationMessages = createAsyncThunk(
  'Messages/get-conversation-message',
  async ({ conversationId, showDraft = false, memberId }: GetConversationPayloadType, { rejectWithValue }) => {
    try {
      const url = '/Messaging/conversation-messages';
      const data = fetch.get({ url, params: { conversationId, showDraft, dependentUserId: memberId } });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMailboxConversations = createAsyncThunk(
  'Messages/get-mailbox-conversations',
  async ({ mailboxType, currentPage, search, memberId }: MailboxConversationPayloadType, { rejectWithValue }) => {
    try {
      const url = '/Messaging/mailbox-conversations';
      const data = fetch.post({
        url,
        data: {
          mailbox_type: mailboxType,
          page_size: DEFAULT_ITEMS_ON_PAGE,
          page_number: Number(currentPage) || DEFAULT_PAGE,
          ...(search ? { search_value: search } : {}),
          dependent_user_id: Number(memberId),
        },
      });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteDraft = createAsyncThunk(
  'Messages/delete-draft',
  async ({ messageId, memberId }: { messageId: number; memberId: string }, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.post({
        url: '/Messaging/delete-draft',
        params: {
          messageId,
          dependentUserId: Number(memberId),
        },
      });

      dispatch(
        enqueueSnackbar({
          message: locale.draft_deleted,
          options: {
            variant: 'success',
          },
        }),
      );

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const archiveMessage = createAsyncThunk(
  'Messages/archive-message',
  async ({ conversationId, memberId }: ActionPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.post({
        url: '/Messaging/archive',
        params: {
          conversationId: Number(conversationId),
          dependentUserId: Number(memberId),
        },
      });
      dispatch(
        enqueueSnackbar({
          message: locale.conversation_archived,
          options: {
            variant: 'success',
          },
        }),
      );
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const restoreMessage = createAsyncThunk(
  'Messages/restore',
  async ({ conversationId, memberId }: ActionPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.post({
        url: '/Messaging/unarchive',
        params: {
          conversationId: Number(conversationId),
          dependentUserId: Number(memberId),
        },
      });
      dispatch(
        enqueueSnackbar({
          message: locale.conversation_restored,
          options: {
            variant: 'success',
          },
        }),
      );
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
