import { useRef, useCallback } from 'react';

import { useLocation } from 'react-router-dom';

import { useBlocker, Blocker } from './useBlocker';
import { useIgnoreEffectDeps } from './useIgnoreEffectDeps';

type UseAbortType = {
  abortOnLocationChange?: boolean;
  abortOnUnmount?: boolean;
};

type AbortablePromise = Promise<unknown> & { abort?: () => void };

export const useAbort = ({ abortOnLocationChange = false, abortOnUnmount = false }: UseAbortType = {}) => {
  const ref = useRef<AbortablePromise>();
  const location = useLocation();

  const abort = useCallback(() => {
    ref.current?.abort?.();
  }, []);

  const onBlock = useCallback<Blocker>(
    (transition, unblock) => {
      if (transition.location.pathname !== location.pathname) {
        abort();
      }

      unblock();
      transition.retry();
    },
    [location.pathname, abort],
  );

  useBlocker(onBlock, !!(abortOnLocationChange && ref.current));

  const setPromise = useCallback((promise: AbortablePromise) => {
    ref.current = promise;
  }, []);

  useIgnoreEffectDeps(
    () => () => {
      if (abortOnUnmount && ref.current) {
        abort();
      }
    },
    [],
  );

  return { setPromise, abort };
};
