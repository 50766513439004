import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { MailboxTypeEnum } from '../constants';
import {
  archiveMessage,
  deleteDraft,
  getConversationMessages,
  getMailboxConversations,
  getUnreadMessages,
  restoreMessage,
} from './actions';
import {
  FamilyMemberUnreadMessages,
  MailboxConversationPayloadType,
  MailboxConversationResponseType,
  MessagesState,
  MessageType,
} from './types';

const initialState: MessagesState = {
  mailbox: {
    [MailboxTypeEnum.Inbox]: {
      items: [],
      total: 0,
    },
    [MailboxTypeEnum.Draft]: {
      items: [],
      total: 0,
    },
    [MailboxTypeEnum.Sent]: {
      items: [],
      total: 0,
    },
    [MailboxTypeEnum.Archive]: {
      items: [],
      total: 0,
    },
  },
  mailboxStatus: StatusEnum.Uninitialized,
  conversationMessages: null,
  conversationMessagesStatus: StatusEnum.Uninitialized,
  changeStatus: StatusEnum.Uninitialized,
  unreadMessages: {
    total: 0,
    users: {},
  },
  unreadMessagesStatus: StatusEnum.Uninitialized,
  pageChanging: null,
};

const changeActionSuccessHandle = (state: MessagesState) => {
  state.changeStatus = StatusEnum.Fulfilled;
  state.conversationMessages = initialState.conversationMessages;
  state.conversationMessagesStatus = initialState.conversationMessagesStatus;
};

const changeActionPendingHandle = (state: MessagesState) => {
  state.changeStatus = StatusEnum.Pending;
};

const changeActionErrorHandle = (state: MessagesState) => {
  state.changeStatus = StatusEnum.Rejected;
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {},
  extraReducers: {
    [getUnreadMessages.pending.type]: (state: MessagesState) => {
      state.unreadMessagesStatus = StatusEnum.Pending;
    },
    [getUnreadMessages.fulfilled.type]: (state: MessagesState, action: PayloadAction<FamilyMemberUnreadMessages>) => {
      state.unreadMessagesStatus = StatusEnum.Fulfilled;
      state.unreadMessages = action.payload.reduce<MessagesState['unreadMessages']>(
        (acc, item) => {
          acc.total += item.new_message_count.unread_messages;
          acc.users[String(item.user_id)] = item.new_message_count.unread_messages;

          return acc;
        },
        { total: 0, users: {} },
      );
    },
    [getUnreadMessages.rejected.type]: (state: MessagesState) => {
      state.unreadMessagesStatus = StatusEnum.Rejected;
    },
    [getConversationMessages.pending.type]: (state: MessagesState) => {
      state.conversationMessagesStatus = StatusEnum.Pending;
    },
    [getConversationMessages.rejected.type]: (state: MessagesState) => {
      state.conversationMessagesStatus = StatusEnum.Rejected;
    },
    [getConversationMessages.fulfilled.type]: (
      state: MessagesState,
      action: PayloadAction<Array<MessageType>, string, Meta<{ conversationId: number; memberId: number }>>,
    ) => {
      state.conversationMessagesStatus = StatusEnum.Fulfilled;
      state.conversationMessages = action.payload;

      state.mailbox[MailboxTypeEnum.Inbox].items = state.mailbox[MailboxTypeEnum.Inbox].items.map((message) => {
        if (message.id === Number(action.meta.arg.conversationId) && message.has_unread_message) {
          message.has_unread_message = false;

          if (state.unreadMessages.users[action.meta.arg.memberId] && state.unreadMessages.total) {
            state.unreadMessages.users[action.meta.arg.memberId] -= 1;
            state.unreadMessages.total -= 1;
          }
        }

        return message;
      });
    },
    [getMailboxConversations.pending.type]: (state: MessagesState, action) => {
      state.mailboxStatus = StatusEnum.Pending;
      state.pageChanging = action.meta.arg.pageChanging;
    },
    [getMailboxConversations.rejected.type]: (state: MessagesState) => {
      state.mailboxStatus = StatusEnum.Rejected;
      state.pageChanging = initialState.pageChanging;
    },
    [getMailboxConversations.fulfilled.type]: (
      state: MessagesState,
      action: PayloadAction<MailboxConversationResponseType, string, Meta<MailboxConversationPayloadType>>,
    ) => {
      state.mailboxStatus = StatusEnum.Fulfilled;
      if (action.meta.arg.mailboxType) {
        state.mailbox[action.meta.arg.mailboxType] = {
          total: action.payload.total_record_count,
          items: action.payload.models,
        };
      }
      state.pageChanging = initialState.pageChanging;
    },
    [deleteDraft.pending.type]: changeActionPendingHandle,
    [deleteDraft.rejected.type]: changeActionErrorHandle,
    [deleteDraft.fulfilled.type]: changeActionSuccessHandle,
    [archiveMessage.pending.type]: changeActionPendingHandle,
    [archiveMessage.rejected.type]: changeActionErrorHandle,
    [archiveMessage.fulfilled.type]: changeActionSuccessHandle,
    [restoreMessage.pending.type]: changeActionPendingHandle,
    [restoreMessage.rejected.type]: changeActionErrorHandle,
    [restoreMessage.fulfilled.type]: changeActionSuccessHandle,
    ...resetStoreActions(() => initialState),
  },
});

export const messagesReducer = messagesSlice.reducer;
