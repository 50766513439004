import { createAsyncThunk } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';
import { downLoadFromBlob } from '@common/utils';

import { ParamsWithDependentUserId } from '../../../types';
import { riskProfileStatusSelector } from './selectors';
import { GetRiskProfilePayload } from './types';

export const getRiskProfile = createAsyncThunk<GetRiskProfilePayload, ParamsWithDependentUserId<{ date: string }>>(
  'HealthSummary/get-risk-profile',
  async ({ date, dependentUserId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/risk-profile',
        params: {
          viewForDate: date,
          dependentUserId,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => riskProfileStatusSelector(getState() as RootState) !== StatusEnum.Pending,
  },
);

export const downloadRiskProfileReport = createAsyncThunk<unknown, ParamsWithDependentUserId<{ viewForDate: string }>>(
  'HealthSummary/risk-profile-report',
  async ({ dependentUserId, viewForDate }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/reports/my-health',
        responseType: 'blob',
        params: {
          viewForDate,
          dependentUserId,
        },
      });

      const res = await data.response;
      const blob = res?.data;
      downLoadFromBlob(blob, 'application/pdf', `risk-profile-report-${viewForDate}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
