import { useEffect, useRef } from 'react';

export const useTimer = (timeout: number, callback: () => unknown) => {
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('final timer is set', new Date());
    timeoutId.current = setTimeout(() => {
      callback();
    }, timeout);

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [timeout, callback]);
};
