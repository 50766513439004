import React from 'react';
import { ChangeEvent, useCallback } from 'react';

import isNil from 'lodash.isnil';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { generatePathWithQuery, useQueryParamsObject } from '@common/router';
import { SearchField } from '@components/SearchField';

import { DEFAULT_PAGE } from '../../constants';
import { getMessagesByTypeSelector } from '../../store';
import { locale } from './locale';

type Props = {
  className?: string;
};

export const Search = ({ className }: Props) => {
  const navigate = useNavigate();
  const params = useQueryParamsObject();
  const { search, mailboxType } = params;
  const list = useSelector((state: RootState) => getMessagesByTypeSelector(state, { type: mailboxType }));

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      navigate(
        generatePathWithQuery('', {
          ...params,
          currentPage: DEFAULT_PAGE,
          ...(!isNil(event.target.value) ? { search: event.target.value } : {}),
        }),
      );
    },
    [navigate, params],
  );

  if (list.length === 0 && !search) {
    return null;
  }

  return (
    <SearchField
      className={className}
      onSearch={handleSearch}
      value={search ?? ''}
      placeholder={(locale as UnknownObject<string>)[mailboxType]}
    />
  );
};
