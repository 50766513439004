import React, { ReactNode } from 'react';

import classNames from 'classnames/bind';
import { SnackbarContent } from 'notistack';

import { Typography } from '@components/Typography';

import styles from './Message.module.css';

const cn = classNames.bind(styles);

interface MessageProps {
  message: ReactNode;
  subtitle?: ReactNode;
}

export const Message = React.forwardRef<HTMLDivElement, MessageProps>((props, ref) => (
  <SnackbarContent ref={ref} role="alert" className={cn('content')}>
    <Typography variant="body1-bold">{props.message}</Typography>

    {!!props.subtitle && <div className={cn('title')}>{props.subtitle}</div>}
  </SnackbarContent>
));

Message.displayName = 'SnackbarMessage';
