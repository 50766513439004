import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';

import { getStates, getStatesStatusSelector } from '../store';

export function useFetchStates() {
  const status = useSelector(getStatesStatusSelector);
  const dispatch = useDispatch();

  useIgnoreEffectDeps(() => {
    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(status)) {
      dispatch(getStates());
    }
  }, []);
}
