import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getAttachmentsFormats = createAsyncThunk<string[]>(
  '/Document/get-attachments-formats',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: `/Document/allowed-attachment-extensions` });
      const res = await data.response;

      return res?.data?.allowed_file_extensions;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
