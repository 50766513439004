import React from 'react';

import { useSelector } from 'react-redux';

import { Locker } from '@components/Locker';
import { globalLockerSelector } from '@modules/GlobalLocker/store';

export const LoadingLocker = () => {
  const { hideLogo } = useSelector(globalLockerSelector);

  return <Locker fullScreen animated hideLogo={hideLogo ?? false} />;
};
