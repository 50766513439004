import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { toggleLocker } from './store';

export const useGlobalLocker = (showLocker: boolean, options?: { title?: string; ariaLabel?: string }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLocker({ showLocker, title: options?.title, ariaLabel: options?.ariaLabel }));

    return () => {
      dispatch(toggleLocker({ showLocker: false }));
    };
  }, [dispatch, showLocker, options]);
};
