export enum DocumentSourceEnum {
  MedicalRecord = 'medicalRecord',
  Portal = 'portal',
}

export enum DocumentFilterFieldsEnum {
  Date = 'date',
  Provider = 'provider',
  Source = 'source',
  DisplayName = 'display_name',
  DocumentTypeKey = 'document_type_key',
}

export enum DocumentTypeIdsEnum {
  incentive = 1,
  covid = 2,
  other = 3,
}
