import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { login } from '@modules/Login';

export interface HeaderState {
  mainMenuOpen: boolean;
  hideIncentives: boolean;
  hideChallenges: boolean;
}

const initialState: HeaderState = {
  mainMenuOpen: false,
  hideIncentives: false,
  hideChallenges: false,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    mainMenuToggle: (state) => ({
      ...state,
      mainMenuOpen: !state.mainMenuOpen,
    }),
    setHideChallenges: (state: HeaderState, action: PayloadAction<boolean>) => ({
      ...state,
      hideChallenges: action.payload,
    }),
    setHideIncentives: (state: HeaderState, action: PayloadAction<boolean>) => ({
      ...state,
      hideIncentives: action.payload,
    }),
  },
  extraReducers: {
    [login.fulfilled.type]: (state: HeaderState, action) => {
      state.hideChallenges = action.payload.user_auth?.hide_challenges;
      state.hideIncentives = action.payload.user_auth?.hide_incentives;
    },
  },
});

export const { mainMenuToggle, setHideChallenges, setHideIncentives } = headerSlice.actions;
export const headerReducer = headerSlice.reducer;
