import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { gtmEvent, GtmEventsEnum } from '@modules/gtm';

import { ReasonIdType } from '../types';
import { AttachmentsFormatsType, SendMessagePayloadType } from './types';

export const getMessageReasons = createAsyncThunk('EditMessage/get-message-reasons', async (_, { rejectWithValue }) => {
  try {
    const data = fetch.get({ url: '/Messaging/messaging-reasons' });
    const res = await data.response;

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadAttachments = createAsyncThunk(
  'FileAttachment/file-attachment',
  async ({ files, reasonId }: { files: File[]; reasonId: number }, { rejectWithValue }) => {
    const formData = new FormData();

    files.forEach((attachment) => formData.append('attachment', attachment));

    try {
      const data = fetch.post({
        url: `/FileAttachment/file-attachment/${reasonId}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const sendMessage = createAsyncThunk(
  'EditMessage/send-message',
  async (
    { data, id = 0, draft = false, conversationId = 0, attachments }: SendMessagePayloadType,
    { rejectWithValue },
  ) => {
    const dataToSend = {
      draft,
      conversation_id: Number(conversationId),
      message_id: Number(id),
      reason_id: data.message_reasons,
      body: data.body,
      athena_department_id: data.health_center ?? null,
      athena_provider_id: data.provider ?? null,
      file_attachment_ids: attachments.map((attachment) => attachment.id),
      dependent_user_id: data.member_id ? Number(data.member_id) : null,
    };

    try {
      const data = fetch.post({
        url: '/Messaging/new-message',
        data: dataToSend,
      });

      const res = await data.response;

      gtmEvent(GtmEventsEnum.newMessage);

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const replyMessage = createAsyncThunk(
  'EditMessage/reply',
  async (
    { data, id = 0, draft = false, conversationId = 0, attachments }: SendMessagePayloadType,
    { rejectWithValue },
  ) => {
    const dataToSend = {
      message_id: Number(id),
      conversation_id: Number(conversationId),
      draft,
      body: data.body,
      file_attachment_ids: attachments.map((attachment) => attachment.id),
      reason_id: data.message_reasons,
      dependent_user_id: data.member_id ? Number(data.member_id) : null,
    };

    try {
      const data = fetch.post({
        url: '/Messaging/reply',
        data: dataToSend,
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAttachmentsFormatsByReasonId = createAsyncThunk<AttachmentsFormatsType, ReasonIdType>(
  'EditMessage/get-attachments-formats-by-reason-id',
  async (reasonId, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: `/FileAttachment/allowed-attachment-extensions/${reasonId}` });
      const res = await data.response;

      return res?.data?.allowed_file_extensions;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
