export const locale = {
  labels: {
    previous: 'previous',
    next: 'next',
    first: 'first',
    last: 'last',
  } as PartialRecord<string, string>,
  types: {
    previous: 'BACK',
    next: 'NEXT',
    first: 'FIRST',
    last: 'LAST',
    page: 'page',
  } as PartialRecord<string, string>,
  got_to: 'Go to',
  go_to_page: 'Go to page {{page}}',
};
