export enum GuidelineTypeEnum {
  Upcoming = 'upcoming',
  PastDue = 'past_due',
  Other = 'other',
}

export const GUIDELINE_TABS_LIST = [GuidelineTypeEnum.PastDue, GuidelineTypeEnum.Upcoming, GuidelineTypeEnum.Other];

export const GRID_SPACING = { xs: 1, sm: 1, md: 2, lg: 2 };
export const GRID_ROW_SPACING = { xs: 1, sm: 1, md: 0, lg: 0 };

// deprecated
export enum GoalStatusEnum {
  Completed = 'completed',
  Incomplete = 'incomplete',
}

export enum TaskStatusEnum {}
