import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getArticlesByCategory = createAsyncThunk(
  'articles/fetchArticles',
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/Article/get-articles',
        params: {
          articleCategoryId: id,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
