import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { tokenService, ACCESS_TOKEN_KEY } from '@common/TokenService';
import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';
import { PATHS } from '@common/routes';
import { cancelPreviousLogin } from '@modules/Login';

export const useIsAuthorized = () => {
  const wellnessId = useSelector((state: RootState) => state.userProfile.userProfileData.wellness_id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleStorageChange = ({ key }: StorageEvent) => {
    if (key === ACCESS_TOKEN_KEY && !tokenService.getTokenByWellnessId(wellnessId)) {
      dispatch(cancelPreviousLogin());
      navigate(PATHS.LOGIN);
    }
  };

  useIgnoreEffectDeps(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return !!tokenService.getToken();
};
