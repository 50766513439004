import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import { getCustomizationDefaultValues } from '../utils';
import { downloadMeasurements, getDashboardData } from './actions';
import { DEFAULT_DATA } from './constants';
import { HealthDashboardState } from './types';

const initialState: HealthDashboardState = {
  dashboardRequest: {
    ...USER_SPECIFIC_INITIAL_STATE,
    data: DEFAULT_DATA,
  },
  filter: {
    showAll: true,
    visibleGraphs: [],
  },
  downloadingStatus: StatusEnum.Uninitialized,
};

export const healthDashboardSlice = createSlice({
  name: 'healthDashboard',
  initialState,
  reducers: {
    setFilter(state: HealthDashboardState, action: PayloadAction<HealthDashboardState['filter']>) {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getDashboardData,
      updateState: (state, updates) => {
        state.dashboardRequest = {
          ...state.dashboardRequest,
          ...updates,
        };

        if (updates.status === StatusEnum.Fulfilled) {
          const data = updates.data || DEFAULT_DATA;
          state.dashboardRequest.data = data;
          state.filter = {
            showAll: true,
            visibleGraphs: data.configuration || getCustomizationDefaultValues(data),
          };
        }
      },
      getUserId: (state) => state.dashboardRequest.userId,
    });

    builder.addCase(downloadMeasurements.pending, (state) => {
      state.downloadingStatus = StatusEnum.Pending;
    });
    builder.addCase(downloadMeasurements.fulfilled, (state) => {
      state.downloadingStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadMeasurements.rejected, (state) => {
      state.downloadingStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const { setFilter } = healthDashboardSlice.actions;
export const healthDashboardReducer = healthDashboardSlice.reducer;
