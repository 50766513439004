import { createSelector, Selector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';
import { GoalsTypeEnum } from '../constants';
import { GoalType } from './types';

const { dryDataSelector, currentUserStatusSelector } = createUserIdSpecificSelectors(
  (state) => state.goals.goalsRequest,
);

const goalsTypeSelector = (_: unknown, { type }: { type: GoalsTypeEnum }) => type;
const goalNameSelector = (_: unknown, { goalName }: { goalName: string }) => goalName;
const goalIndexSelector = (_: unknown, { goalIndex }: { goalIndex: number }) => goalIndex;

export const statusSelector = currentUserStatusSelector;

export const goalsByTypeSelector: Selector<RootState, GoalType[]> = createSelector(
  dryDataSelector,
  goalsTypeSelector,
  (goals, type) => goals?.[type] ?? [],
);

export const goalByTypeNameIndexSelector: Selector<RootState, Nullable<GoalType>> = createSelector(
  goalsByTypeSelector,
  goalNameSelector,
  goalIndexSelector,
  (goals, goalName, goalIndex) => goals.find((goal, index) => goal.name === goalName && index === goalIndex),
);
