export const ACTIONS_BUTTON_ID = 'actions_button_id';
export const INCENTIVE_BLOCK_ID = 'incentive_block_id';
export const MAIN_HEADER_RIGHT_ID = 'main_header_right_id';
export const MAIN_MENU_ID = 'main_menu_id';
export const SIDEBAR_ID = 'sidebar_id';

export const MOBILE_MENU_ID = 'mobile_menu_id';
export const PROFILE_SIDE_MENU_ID = 'profile_side_menu_id';
export const WHATS_NEW_ID = 'whats_new_id';
export const FILTER_ID = 'filter_id';
