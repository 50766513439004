import React, { useState } from 'react';

import SlideMaterial, { SlideProps as Props } from '@mui/material/Slide';

import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';

export type SlideProps = {
  delay?: number;
} & Pick<
  Props,
  'children' | 'className' | 'direction' | 'in' | 'style' | 'mountOnEnter' | 'unmountOnExit' | 'appear' | 'timeout'
>;

export function Slide({
  children,
  className,
  in: inProp,
  direction,
  style,
  mountOnEnter,
  unmountOnExit,
  appear = false,
  timeout = 300,
  delay = 0,
}: SlideProps) {
  const [isIn, setIsIn] = useState(inProp && delay === 0);

  // TODO-Vladislav write tests
  useIgnoreEffectDeps(() => {
    if (delay > 0 && inProp && !isIn) {
      setTimeout(() => setIsIn(true), delay);
    }

    if (inProp && !isIn) {
      setIsIn(true);
    }

    if (!inProp && isIn) {
      setIsIn(false);
    }
  }, [inProp]);

  return (
    <SlideMaterial
      className={className}
      direction={direction}
      in={isIn}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      appear={appear}
      timeout={timeout}
      style={style}
    >
      {children}
    </SlideMaterial>
  );
}
