import React from 'react';

import ListItemButtonMaterial, {
  ListItemButtonProps as ListItemButtonPropsMaterial,
} from '@mui/material/ListItemButton';

export type ListItemButtonProps = Pick<
  ListItemButtonPropsMaterial,
  'children' | 'className' | 'onClick' | 'aria-label' | 'disableRipple' | 'aria-describedby' | 'role'
>;

export function ListItemButton({
  className,
  children,
  onClick,
  disableRipple,
  'aria-label': ariaLabel,
  'aria-describedby': ariaDescribedby,
  role,
}: ListItemButtonProps) {
  return (
    <ListItemButtonMaterial
      className={className}
      onClick={onClick}
      disableRipple={disableRipple}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
      role={role}
    >
      {children}
    </ListItemButtonMaterial>
  );
}
