import { createSlice } from '@reduxjs/toolkit';

import { resetStoreActions } from '@modules/reset';

import { ModalTypeEnum } from '../constants';

type FooterState = {
  modalType: Nullable<ModalTypeEnum>;
};
const initialState: FooterState = {
  modalType: null,
};

export const globalModalSlice = createSlice({
  name: 'globalModal',
  initialState,
  reducers: {
    setModal(state: FooterState, action) {
      state.modalType = action.payload;
    },
  },
  extraReducers: {
    ...resetStoreActions(() => initialState),
  },
});

export const { setModal } = globalModalSlice.actions;
export const globalModalReducer = globalModalSlice.reducer;
