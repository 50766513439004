import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

import { OTHER_PHARMACY_ID } from '../constants';
import { FormValuesType, GetPersonalMedicationsType, GetPersonalPharmaciesType } from '../types';

export const getMedRefill = createAsyncThunk(
  'MedicationRefills/personal_medications',
  async ({ userId }: GetPersonalMedicationsType, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/med-refills',
        params: { userId },
      });
      const res = await data.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getRelativeAdhocPharmacies = createAsyncThunk(
  'MedicationRefills/relative-adhoc-pharmacies',
  async (_: GetPersonalPharmaciesType, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: `/Clinic/get-clinics`,
        params: {
          forMedRefill: true,
        },
      });

      const res = await data.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const sendMedicationRefills = createAsyncThunk(
  'MedicationRefills/send-medication-refill',
  async (
    { data, isCurrentUserSelected }: { data: FormValuesType; isCurrentUserSelected: boolean },
    { rejectWithValue },
  ) => {
    const adhocMedications = data.adhoc_refills
      .filter(({ selected }) => selected)
      .map((adhoc) => ({
        is_other_medication: true,
        medication_name: adhoc.medication_name,
        medication_dosage: adhoc.medication_dosage,
        is_other_location: adhoc.pharmacy?.clinical_provider_id === OTHER_PHARMACY_ID || !isCurrentUserSelected,
        location_name: adhoc.pharmacy?.pharmacy_name,
        location_address: adhoc.pharmacy?.address,
        medication_id: Number(adhoc.medication_id) ? adhoc.medication_id : null,
        clinical_provider_id: adhoc.pharmacy?.clinical_provider_id || null,
        medication_notes: adhoc.medication_notes,
      }));

    const medications = data.medications
      .filter(({ selected }) => selected)
      .map((medication) => ({
        is_other_medication: false,
        medication_name: medication.medication_name,
        medication_dosage: medication.dosage_instruction,
        is_other_location: medication.pharmacy?.clinical_provider_id === OTHER_PHARMACY_ID || !isCurrentUserSelected,
        location_name: medication.pharmacy?.pharmacy_name,
        location_address: medication.pharmacy?.address,
        medication_id: medication.medication_id,
        clinical_provider_id: medication.pharmacy?.clinical_provider_id || null,
      }));

    const dataToSend = {
      id: 0,
      user_id: data.member.id,
      patient_notes: data.notes ? data.notes : null,
      medications: [...adhocMedications, ...medications],
    };

    try {
      const responseData = fetch.post({
        url: `/Messaging/medication-refill`,
        data: dataToSend,
      });

      const res = await responseData.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
