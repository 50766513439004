/* eslint-disable camelcase */
import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { useScreenSize } from '@common/hooks/useScreenSize';
import { generatePathWithQuery, useQueryParamsObject } from '@common/router';
import { MenuList } from '@components/MenuList';
import { useFullContentLoaded } from '@modules/gtmFulContentLoaded';

import { MailboxTypeEnum } from '../../constants';
import { getMailboxStatusSelector, getMessagesByTypeSelector } from '../../store';
import { ConversationListItem, ConversationsListSkeleton } from '../ConversationListItem';
import { ConversationNoMessages } from './components/ConversationNoMessages';
import { locale } from './locale';

import styles from './ConversationsList.module.css';

const cn = classNames.bind(styles);

type Props = {
  className?: string;
};

export const ConversationsList = ({ className }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useQueryParamsObject();
  const { conversationId: conversationIdString, mailboxType } = params;
  const conversationId = Number(conversationIdString);
  const list = useSelector((state: RootState) => getMessagesByTypeSelector(state, { type: mailboxType }));
  const mailboxStatus = useSelector(getMailboxStatusSelector);
  const isLoading = mailboxStatus === StatusEnum.Pending || mailboxStatus === StatusEnum.Uninitialized;
  const { isDesktopView } = useScreenSize();
  const noMessages = list.length === 0;
  const isDraft = mailboxType === MailboxTypeEnum.Draft;

  const handleMessageClick = useCallback(
    (id: number) => {
      navigate(generatePathWithQuery(pathname, { ...params, conversationId: id }), {
        state: { from: id },
      });
    },
    [navigate, pathname, params],
  );

  useIgnoreEffectDeps(() => {
    if (mailboxStatus === StatusEnum.Fulfilled && list.length > 0 && isDesktopView && !conversationId) {
      navigate(generatePathWithQuery(pathname, { ...params, conversationId: list[0].id }), {
        replace: true,
      });
    }
  }, [mailboxStatus]);

  useFullContentLoaded({
    loadingStarted: isLoading,
    loadingFinished: !isLoading,
  });

  if (isLoading) return <ConversationsListSkeleton />;

  return noMessages ? (
    <ConversationNoMessages />
  ) : (
    <MenuList
      className={cn('conversations-list', className, {
        'conversations-list--no-messages': noMessages,
      })}
      aria-label={locale.conversations}
      component="div"
    >
      {list?.map(
        ({
          id,
          sender,
          subject,
          recent_message_at,
          recent_message_has_attachments,
          has_unread_message,
          athena_provider_id,
          athena_department_id,
        }) => (
          <ConversationListItem
            key={id}
            id={id}
            sender={sender}
            subject={subject}
            recentMessageAt={recent_message_at}
            recentMessageHasAttachments={recent_message_has_attachments}
            hasUnreadMessage={has_unread_message}
            athenaProviderId={athena_provider_id}
            athenaDepartmentId={athena_department_id}
            isDraft={isDraft}
            conversationId={conversationId}
            handleMessageClick={() => handleMessageClick(id)}
          />
        ),
      )}
    </MenuList>
  );
};
