import { createSelector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';
import { MEASUREMENT_CARDS } from '../constants';
import { getDashboardItemValue } from '../utils';
import { DEFAULT_DATA } from './constants';

export const downloadingStatusSelector = (state: RootState) => state.healthDashboard.downloadingStatus;
export const filterSelector = (state: RootState) => state.healthDashboard.filter;

const { dryDataSelector, currentUserStatusSelector } = createUserIdSpecificSelectors(
  (state) => state.healthDashboard.dashboardRequest,
);
export const dashboardDataSelector = createSelector(dryDataSelector, (data) => data ?? DEFAULT_DATA);

export const dashboardStatusSelector = currentUserStatusSelector;

export const getGraphsSelector = createSelector(dashboardDataSelector, filterSelector, (dashboardData, filter) =>
  MEASUREMENT_CARDS.filter(
    (card) =>
      !!getDashboardItemValue(dashboardData, card.currentType, card.currentSubType) &&
      (filter.visibleGraphs ? filter.visibleGraphs.includes(card.typeName) : true),
  ),
);
