import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import { downloadAllergiesReport, getAllergies } from './actions';
import { AllergiesState } from './types';

const initialState: AllergiesState = {
  allergiesRequest: USER_SPECIFIC_INITIAL_STATE,
  downloadStatus: StatusEnum.Uninitialized,
};

export const allergiesSlice = createSlice({
  name: 'allergies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getAllergies,
      updateState: (state, updates) => {
        state.allergiesRequest = {
          ...state.allergiesRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.allergiesRequest.userId,
    });

    builder.addCase(downloadAllergiesReport.pending, (state) => {
      state.downloadStatus = StatusEnum.Pending;
    });
    builder.addCase(downloadAllergiesReport.fulfilled, (state) => {
      state.downloadStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadAllergiesReport.rejected, (state) => {
      state.downloadStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const allergiesReducer = allergiesSlice.reducer;
