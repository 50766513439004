import React, { useCallback } from 'react';

import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: {
    target: { name: string; value: string };
    currentTarget: { name: string; value: string };
  }) => void;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mask: any;
  unmask?: boolean;
}

export const MaskedTextField = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
  const { onChange, mask, ...other } = props;

  const handleChange = useCallback(
    (value: string) => {
      const params = { name: props.name, value };
      onChange({ target: params, currentTarget: params });
    },
    [onChange, props.name],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <IMaskInput {...other} mask={mask} inputRef={ref as any} onAccept={handleChange} overwrite />;
});

MaskedTextField.displayName = 'MaskedTextField';
