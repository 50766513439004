import React from 'react';

import { StyledEngineProvider } from '@mui/material';
import 'chartjs-adapter-date-fns';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

// eslint-disable-next-line import/order
import './styles';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { SkipLinks } from '@components/SkipLinks';
import { GlobalLocker } from '@modules/GlobalLocker';
import { GlobalModal } from '@modules/GlobalModal';
import { PageHeader } from '@modules/PageHeader/PageHeader';
import { SnackbarProvider } from '@modules/Snackbar';
import { FullContentLoadedContextProvider } from '@modules/gtmFulContentLoaded';

import App from './App';
import i18n from './i18n';
import InternalServerErrorPage from './pages/InternalServerErrorPage';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <StyledEngineProvider injectFirst>
        <Router>
          <ErrorBoundary fallBackComponent={InternalServerErrorPage}>
            <FullContentLoadedContextProvider>
              <SnackbarProvider>
                <SkipLinks />
                <PageHeader />
                <App />
                <GlobalLocker />
                <GlobalModal />
              </SnackbarProvider>
            </FullContentLoadedContextProvider>
          </ErrorBoundary>
        </Router>
      </StyledEngineProvider>
    </I18nextProvider>
  </Provider>,
);

reportWebVitals();
