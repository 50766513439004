import { createSelector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';

const { dryDataSelector, currentUserStatusSelector } = createUserIdSpecificSelectors(
  (state) => state.allergies.allergiesRequest,
);

export const allergiesStatusSelector = currentUserStatusSelector;
export const allergiesSelector = createSelector(dryDataSelector, (data) => data ?? []);

export const downloadAllergiesReportStatusSelector = (state: RootState) => state.allergies.downloadStatus;
