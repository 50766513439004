import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

import { CompleteType, GetDetailsType } from './types';

export const getCategories = createAsyncThunk('Resource/categories', async (_, { rejectWithValue }) => {
  try {
    const data = fetch.get({
      url: '/Resource/categories',
    });

    const res = await data.response;

    return res?.data || [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetails = createAsyncThunk(
  'Resource/category-detail',
  async ({ id }: GetDetailsType, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: `/Resource/category-detail/${id}`,
      });

      const res = await data.response;

      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const completeContent = createAsyncThunk(
  'Resource/complete-content',
  async ({ id, detailsId }: CompleteType, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.post({
        url: '/Resource/complete-content',
        data: {
          id,
        },
      });

      const res = await data.response;

      dispatch(getCategories());

      await dispatch(getDetails({ id: detailsId, silent: true }));

      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resetCategory = createAsyncThunk(
  'Resource/reset-category',
  async ({ id, reloadDetails = false }: { id: number; reloadDetails?: boolean }, { rejectWithValue, dispatch }) => {
    try {
      const data = fetch.post({
        url: '/Resource/reset-category',
        data: {
          id,
        },
      });

      const res = await data.response;

      dispatch(getCategories());

      if (reloadDetails) {
        await dispatch(getDetails({ id }));
      }

      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
