export const locale = {
  title: 'Incentives',
  historical_title: 'Historical Incentives',
  view_historical_programs: 'View Historical Programs',
  close_historical_programs: 'Exit Historical program',
  show: 'Show',
  hide: 'Hide',
  goal_deleted: 'Goal deleted',
  goal_recorded: 'Goal recorded',
  goal_updated: 'Goal updated',
  possible: 'Possible',
  alert: 'The incentive period has ended for “{{name}}”. This page is for historical incentive viewing purposes only.',

  incentive_document_removed: 'Your document has been deleted.',
};
