import React from 'react';

import classNames from 'classnames/bind';

import { Skeleton } from '@components/Skeleton';

import styles from './WellnessCardSkeleton.module.css';

const cn = classNames.bind(styles);

type WellnessCardSkeletonProps = {
  className?: string;
};

export function WellnessCardSkeleton({ className }: WellnessCardSkeletonProps) {
  return <Skeleton className={cn('wellness-card-skeleton', className)} variant="rectangular" />;
}
