import { objectValueComparator } from './objectValueComparator';

export enum SortOrderEnum {
  Asc = 'ascending',
  Desc = 'descending',
}

export function getComparator<Key extends string>(
  order: SortOrderEnum,
  orderBy: Key,
): (a: { [key in Key]: unknown }, b: { [key in Key]: unknown }) => number {
  return order === SortOrderEnum.Desc
    ? (a, b) => objectValueComparator(a, b, orderBy)
    : (a, b) => -objectValueComparator(a, b, orderBy);
}

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = Array.isArray(array) ? array.map((el, index) => [el, index] as [T, number]) : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function filterRows<T>(value: string, rows: T[], field: keyof T) {
  if (value === '') {
    return rows;
  }

  return rows.filter((row) => `${row[field]}`.toLowerCase().includes(value.toLowerCase()));
}
