import React, { ReactNode } from 'react';

import classNames from 'classnames/bind';

import { SafeHtml } from '@components/SafeHtml';
import { Typography } from '@components/Typography';

import styles from './ModalTypography.module.css';

const cn = classNames.bind(styles);

type HeaderProps = {
  text: string;
  className?: string;
  id?: string;
};

export const Header = ({ text, className, id }: HeaderProps) => (
  <Typography variant="h1" className={cn('header', className)} id={id}>
    {text}
  </Typography>
);

export const SubHeader = ({ text, className, id }: HeaderProps) => (
  <Typography variant="h2" className={cn('sub-header', className)} id={id}>
    {text}
  </Typography>
);

export const ThirdHeader = ({ text, className, id }: HeaderProps) => (
  <Typography variant="h3" className={cn('third-header', className)} id={id}>
    {text}
  </Typography>
);

export const Paragraph = ({ html }: { html: string | Node }) => <SafeHtml className={cn('paragraph')} html={html} />;

export const Container = ({ children }: { children: ReactNode }) => <div className={cn('container')}>{children}</div>;
