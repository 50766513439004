import React, { useCallback, useRef } from 'react';

import { useIgnoreEffectDeps, useIsAuthorized } from '@common/hooks';
import { gtmEvent, GtmEventsEnum } from '@modules/gtm';

import { usePageLocationString } from './usePageLocationString';

export const FullContentLoadedContext = React.createContext<() => () => unknown>(() => () => undefined);

type Props = {
  children: React.ReactNode;
};

const makeMinimalPromise = (timeout = 50) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

export function FullContentLoadedContextProvider({ children }: Props) {
  const visitedPages = useRef(new Set());
  const isFirstVisit = useRef(false);
  const allLoadingPromise = useRef<Promise<unknown> | null>(null);
  const startTime = useRef<number>(NaN);
  const currentLocation = useRef('');

  const resetLoading = useCallback(() => {
    allLoadingPromise.current = null;
    startTime.current = NaN;
  }, []);

  const updateAllLoading = useCallback((promise: Promise<unknown>) => {
    if (allLoadingPromise.current === null) {
      startTime.current = Date.now();
    }

    const updatedAllLoadingPromise = Promise.all([allLoadingPromise.current, promise]);
    allLoadingPromise.current = updatedAllLoadingPromise;

    updatedAllLoadingPromise
      .then(() => {
        if (allLoadingPromise.current === updatedAllLoadingPromise) {
          const loadingTime = Date.now() - startTime.current;

          gtmEvent(GtmEventsEnum.fullContentLoaded, {
            first_loading_time: isFirstVisit.current ? loadingTime : undefined,
            loading_time: isFirstVisit.current ? undefined : loadingTime,
          });

          // prevent repeated sending until page change
          updateAllLoading(Promise.reject());
        }
      })
      .catch(() => {
        /* rejection means that event from current page is already sent */
      });
  }, []);

  const locationString = usePageLocationString();
  useIgnoreEffectDeps(() => {
    resetLoading();

    currentLocation.current = locationString;
    isFirstVisit.current = !visitedPages.current.has(locationString);
    visitedPages.current.add(locationString);
    // track every page even with no registered api calls
    updateAllLoading(makeMinimalPromise());
  }, [locationString]);

  const authorized = useIsAuthorized();

  useIgnoreEffectDeps(() => {
    if (!authorized) {
      visitedPages.current.clear();
    }
  }, [authorized]);

  const registerLoadingRequest = useCallback(() => {
    let finishLoading;
    const promise = new Promise<void>((resolve) => {
      finishLoading = resolve;
    });

    updateAllLoading(promise);

    return finishLoading as unknown as () => void;
  }, [updateAllLoading]);

  return (
    <FullContentLoadedContext.Provider value={registerLoadingRequest}>{children}</FullContentLoadedContext.Provider>
  );
}
