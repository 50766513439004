import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { supplementalWaiverService } from '@common/servicesInstances/suplementalWeaver';

export const getSupplementalWaiverData = createAsyncThunk(
  'getSupplementalWaiverData',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/User/get-supplemental-waiver',
      });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const acceptSupplementalWaiver = createAsyncThunk(
  'acceptSupplementalWaiver',
  async ({ id, signature }: { id: string | number; signature?: string }, { rejectWithValue }) => {
    try {
      const data = fetch.post({
        url: '/User/sign-supplemental-waiver',
        data: { signature },
      });
      await data.response;
      const newSupplementalWaiver = {
        ...supplementalWaiverService.getSign(),
        [id]: true,
      };
      supplementalWaiverService.saveSign(newSupplementalWaiver);

      return newSupplementalWaiver;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
