export const locale = {
  heading: 'Questionnaires',
  health_screening: 'Health Screening Questionnaires',
  hra: 'Health Risk Assessment (HRA)',
  inProgress: 'In Progress Questionnaires',
  completed: 'Completed Questionnaires',
  noInProgress: 'No in progress questionnaires.',
  noCompleted: 'No completed questionnaires.',
  message: 'Your Health Risk Assessment has been submitted.',
  subHeading:
    'Health questionnaires collect various medical and lifestyle risk history, which provides a clinician with information about your current and past health, symptoms, and risks. It is recommended that you schedule an appointment with your clinician to review the results of these questionnaires.',

  to_appointments: {
    suggested_next_steps: 'Suggested Next Steps: ',
    description:
      'Schedule a preventative visit to review your results and create a plan to achieve your personal health goals.',
    button: 'Schedule an Appointment',
  },
  commonGeneralOptionsLabels: {
    excellent: 'Excellent',
    very_good: 'Very good',
    good: 'Good',
    fair: 'Fair',
    poor: 'Poor',
  },

  commonOptionsLabels: {
    strongly_agree: 'Strongly Agree',
    agree: 'Agree',
    unsure: 'Unsure',
    disagree: 'Disagree',
    strongly_disagree: 'Strongly Disagree',
  },

  commonAgreeOptionsLabels: {
    strongly_agree: 'Strongly Agree',
    agree: 'Agree',
    slightly_agree: 'Slightly Agree',
    neither_agree_nor: 'Neither Agree Nor Disagree',
    slightly_disagree: 'Slightly Disagree',
    disagree: 'Disagree',
    strongly_disagree: 'Strongly Disagree',
  },

  commonStressOptionsLabels: {
    never: 'Never',
    almost_never: 'Almost Never',
    sometimes: 'Sometimes',
    fairly_often: 'Fairly Often',
    very_often: 'Very Often',
  },

  commonNutritionOptionsLabels: {
    zero_one: '0-1 days a week',
    two_three: '2-3 days a week',
    four_five: '4-5 days a week',
    six_seven: '6-7 days a week',
  },

  commonPhysicalActivityLabels: {
    one: '1 or less days',
    two: '2 days',
    one_two: '1-2 days',
    three: '3 days',
    four_five: '4-5 days',
    six_seven: '6-7 days',
    do_not: 'I do not regularly exercise',
    do_not_strength: 'I do not regularly engage in strength training',
    do_not_stretching: 'I do not typically do any stretching training exercises',
  },

  commonPhysicalActivityLabelsPerWeek: {
    one_two: '1-2 days per week',
    three: '3 days per week',
    four_five: '4-5 days per week',
    six_seven: '6-7 days per week',
  },

  commonRiskOptionsLabels: {
    always: 'Always',
    sometimes: 'Sometimes',
    rarely_never: 'Rarely or Never',
  },

  commonRiskBooleanOptionLabels: {
    true: 'True',
    false: 'False',
  },

  commonFinancialWellnessOptionsLabels: {
    completely: 'Completely',
    very_well: 'Very Well',
    somewhat: 'Somewhat',
    very_little: 'Very Little',
    not_at_all: 'Not at All',
  },
  stepOne: {
    title: 'General Health',
    q1: 'In general, I describe my health as',
    q2: 'Currently, how would you rate your quality of life?',
  },
  stepTwo: {
    title: 'Purpose',
    q1: 'For the most part, I am satisfied with the balance between my work life and personal life',
    q2: 'In most ways my life is close to my ideal.',
    q3: 'The conditions of my life are excellent.',
    q4: 'I am satisfied with my life.',
    q5: 'So far I have gotten the important things I want in life.',
    q6: 'If I could live my life over, I would change almost nothing.',
  },
  stepThree: {
    title: 'Stress',
    q1: 'In the last month, how often have you been upset because of something that happened unexpectedly?',
    q2: 'In the last month, how often have you felt that you were unable to control the important things in your life?',
    q3: 'In the last month, how often have you felt nervous and stressed?',
    q4: 'In the last month, how often have you felt confident about your ability to handle your personal problems?',
    q5: 'In the last month, how often have you felt that things were going your way?',
    q6: 'In the last month, how often have you found that you could not cope with all the things that you had to do?',
    q7: 'In the last month, how often have you been able to control irritations in your life?',
    q8: 'In the last month, how often have you felt that you were on top of things?',
    q9: 'In the last month, how often have you been angered because of things that happened that were outside of your control?',
    q10: 'In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?',
  },
  stepFour: {
    title: 'Sleep',
    q1: 'Select all that apply regarding your sleep',
    q2: 'How many hours of sleep do you get on an average each night?',
    q3: 'I usually wake up feeling rested.',
    q1OptionsLabels: {
      hard_time: 'I have a hard time falling asleep or staying asleep',
      restless_sleep: 'I have restless sleep',
      easily_doze_off: 'I have a tendency to easily doze off or fall asleep during the day',
      snore: 'I have been told or know that I snore',
      no_issues: 'I have no issues with my sleep',
    },
    q2OptionsLabels: {
      seven: '7 hours or more',
      six: '6 hours',
      five: '5 hours or less',
    },
  },
  stepFive: {
    title: 'Nutrition',
    q1: 'What is the average number of times per week you dine out including in restaurant, carry out, or food delivery?',
    q2: 'On average, how many 8 oz. glasses of water do you drink each day?',
    q3: 'How often do you consume sugary food / drinks ? Examples are dessert, candy or sweetened drinks (juice, sweetened coffee, soda)',
    q4: 'How often do you eat 5 or more fruits / vegetable servings a day?',

    q1OptionsLabels: {
      four: '4 or more',
      two_three: '2 to 3',
      one: '1 or less/None',
    },
    q2OptionsLabels: {
      six: '6 or more',
      three_five: '3 to 5',
      one_two: '1 to 2',
      none: 'None',
    },
  },

  stepSix: {
    title: 'Physical Activity',
    q1: 'How often do you exercise?',
    q2: 'On average, how many minutes do you spend doing aerobic exercise weekly(walking, running, biking, and other aerobic activities)?',
    q3: 'On average, how many times a week do you do resistance or strengthening exercises?',
    q4: 'How many days a week do you do stretching exercises?',

    q2OptionsLabels: {
      thirty_mins: 'Less than 30 minutes a week',
      thirty_seventyfour: '30-74 minutes a week',
      seventyfive_onetwenty: '75-120 minutes per week',
      onefifty: '150+ minutes a week',
      no: 'I do not regularly exercise',
    },
  },

  stepSeven: {
    title: 'Tobacco',
    q1: 'Please indicate the statement that fits your current use of tobacco(cigarettes, ecigarettes / vaping, smokeless tobacco, cigars, pipes, light cigarettes)?',
    q2: 'If you currently use tobacco, on average; how many cigarettes, cigars, etc. per day?',
    q3: 'If you use tobacco, how long have you been using tobacco? (in years)',
    q1OptionsLabels: {
      tobacco_use: 'I currently use tobacco',
      tobacco_social: 'I currently use tobacco, only under certain circumstances like social occasions',
      tobacco_six: 'I quit using tobacco less than 6 months ago',
      tobacco_eleven: 'I quit using tobacco 6-12 months ago',
      tobacco_twelve: 'I quit using tobacco more than 12 months ago',
      tobacco_never: 'I have never used tobacco products',
    },
  },

  stepEight: {
    title: 'Risk Behavior',
    q1: 'How often do you wear a seat belt in a motor vehicle?',
    q2: 'How often do you wear a helmet when necessary, such as; riding a motorcycle, bicycle, or recreation vehicle or while participating in high - impact sports?',
    q3: 'Do you have a smoke detector in your home?',
    q4: 'Do you have a carbon monoxide detector in your home?',
    q5: 'How often do you protect your skin from sun exposure when outside(for example, sunscreen with a SPF 15 or higher and / or protective clothing)?',
    q6: 'How often do you have a drink containing alcohol?',
    q7: 'How many drinks containing alcohol do you have on a typical day when you are drinking?',
    q7_5: 'How often do you have six or more drinks on one occasion?',
    q8: 'In the past year, how often have you used an illegal drug or a prescription drug for a non-medical reason?',

    q2OptionsLabel: {
      never: 'I don`t engage in activities that would require/need helmet',
    },

    q5OptionsLabel: {
      all: 'All of the time',
      most: 'Most of the time',
      some: 'Some of the time',
      rarely: 'Rarely or Never',
    },

    q6OptionsLabels: {
      never: 'Never',
      monthly: 'Monthly or less',
      two_four: '2 to 4 times a month',
      two_three: '2 to 3 times a week',
      four: '4 or more times a week',
    },

    q7OptionsLabels: {
      one_two: '1 or 2',
      three_four: '3 or 4',
      five_six: '5 or 6',
      seven_eight_nine: '7, 8 or 9',
      ten: '10 or more',
    },

    q75optionsLabels: {
      never: 'Never',
      less_then_monthly: 'Less then monthly',
      monthly: 'Monthly',
      weekly: 'Weekly',
    },

    q8OptionsLabels: {
      never: 'Never',
      few: 'A few times',
      monthly: 'Monthly',
      weekly: 'Weekly',
      daily: 'Daily',
    },
  },

  stepNine: {
    title: 'Readiness to Change',
    q1: 'Improve my overall health',
    q2: 'If there was one thing you could work on to improve the way you feel and function what would you choose?',
    q1OptionsLabels: {
      no_interest: 'No current interest in making a change',
      making_change: 'Thinking about making a change',
      planning_change: 'Planning a change in the next 6 months',
      started_change: 'Recently started this change',
      six_months: 'I have been doing this for 6+ months',
    },
  },

  stepTen: {
    title: 'Financial Wellness',
    q1: 'I could handle a major unexpected expense',
    q2: 'I am securing my financial future',
    q3: 'I have money left over at the end of the month',
  },

  stepEleven: {
    title: 'Social Determinants Of Health',
    q1: 'I have a close friend, family member or support system I can talk to about important issues.',
    q2: 'How often do you feel lonely?',
    q3: 'During the past year, have you worried about the following? Check all that apply.',

    q2OptionsLabels: {
      always: 'Always',
      often: 'Often',
      sometimes: 'Sometimes',
      rarely: 'Rarely',
      never: 'Never',
    },

    q3OptionsLabels: {
      stable_housing: 'Stable housing',
      reliable_transport: 'Reliable transportation',
      money: 'Money/finances',
      access_food: 'Access to food',
      afford: 'Ability to afford medication',
      none: 'No worries',
    },
  },

  choose_answer: 'Please choose an answer and resubmit.',
  conflicting_answer: 'Conflcting answers. Please fix and resubmit.',
  error_heading: 'Please fix the following issues and resubmit.',
  blank: 'cannot be blank, please choose an answer',
  conflict: 'has conflicting answers.',
  no_worries: 'cannot have both some worries, and no worries',
  per_day: 'must be a whole number (integer), must be >=1 and < 1000',
  years_smoked: 'must be a whole number (integer), must be >=1 and < 200',
  issues_submit: 'Please fix issues and resubmit.',
  max_length: 'only 255 characters are allowed',

  questionCodeNames: {
    overall_health: 'Overall Health',
    quality_of_life: 'Quality Of Life',
    satisfied_with_balance: ' Satisfied With Balance',
    close_to_ideal: 'Close To Ideal',
    conditions_of_life_excellent: 'Conditions Of Life Excellent',
    satisfied_with_life: 'Satisfied With Life',
    gotten_important_things: 'Gotten Important Things',
    change_almost_nothing: 'Change Almost Nothing',
    upset_unexpected: 'Upset Unexpected',
    unable_to_control: 'Unable To Control',
    nervous_and_stressed: 'Nervous And Stressed',
    confident_handle_problems: 'Confident Handle Problems',
    things_go_your_way: 'Things Go Your Way',
    not_cope_with_everything: 'Not Cope With Everything',
    control_irritations: 'Control Irritations',
    on_top_of_things: 'On Top Of Things',
    angered_outside_of_control: 'Angered Outside Of Control',
    not_overcome_difficulties: 'Not OverCome Difficulties',
    issues_with_sleep: 'Issues With Sleep',
    hard_time_falling_asleep: 'Hard Time Falling Asleep',
    restless_sleep: 'Restless Sleep',
    easily_doze_off: 'Easily Doze Off',
    snore: 'Snore',
    no_issues_with_sleep: 'No Issues With Sleep',
    hours_of_sleep: 'Hours Of Sleep',
    wake_up_rested: 'Wake Up Rested',
    average_dine_out: 'Average Dine Out',
    how_much_water: 'How Much Water',
    sugary_drinks: 'Sugary Drinks',
    fruits_vegetables_per_day: 'Fruits Vegetables Per Day',
    how_often_exercise: 'How Often Excercise',
    aerobic_exercise_minutes: 'Aerobic Exercise Minutes',
    resistance_strength_exercises: 'Resistance Strength Exercises',
    stretching_exercises: 'Stretching Exercises',
    tobacco_use: 'Tobacco Use',
    tobacco_per_day: 'Tobacco Per Day',
    years_smoked: 'Years Smoked',
    seat_belt_usage: 'Seat Belt Usage',
    helmet_usage: 'Hemlet Usage',
    smoke_detector: 'Smoke Detector',
    carbon_monoxide_detector: 'Carbon Monoxide Detector',
    sunscreen_usage: 'Sunscreen Usage',
    alcohol_usage: 'Alcohol Usage',
    alcohol_amount: 'Alcohol Amount',
    alcohol_six_or_more: 'Alcohol Six Or More',
    drug_use: 'Drug Use',
    improve_overall_health: 'Improve Overall Health',
    thing_to_work_on: 'Thing To Work On',
    handle_unexpected_expense: 'Handle Unexpected Expense',
    securing_financial_future: 'Securing Financial Future',
    money_left_over: 'Money Left Over',
    have_support_system: 'Have Support System',
    how_often_lonely: 'How Often Lonely',
    worried_stable_housing: 'Worried Stable Housing',
    worried_reliable_transportation: 'Worried Reliable Transportation',
    worried_money: 'Worried Money',
    worried_access_to_food: 'Worried Access To Food',
    worried_afford_medication: 'Worried Afford Medication',
    worried_other: 'Worried Other',
    worried_other_detail: 'Worried Other Detail',
    no_worries: 'No Worries',
  },

  purpose_source:
    'Source: Diener, E., Ph.D. (n.d.). The Satisfaction with Life Scale. Retrieved November 6, 2020, from ',
  stress_source:
    'Source: Cohen, S., Kamarck, T., & Mermelstein, R. (1983). A global measure of perceived stress. Journal of Health and Social Behavior, 24, 385-396.',

  nutrition_source:
    'Source: Powell, H. S., & Greenberg, D. L. (2019). Screening for unhealthy diet and exercise habits: The electronic health record and a healthier population. Preventive Medicine Reports, 14, 100816. doi:10.1016/j.pmedr.2019.01.020',

  financial_wellness_source: 'Source: Find out your financial well-being. (n.d.). Retrieved November 06, 2020, from',
};
