import React from 'react';

import classNames from 'classnames/bind';

import { Languages, LOCALIZATION_ENABLED, useTranslation } from '@common/i18n';
import { LinkButton } from '@components/Link';

import { LOCALE_NAMESPACE } from './locale';

import styles from './ChangeLanguage.module.css';

const cn = classNames.bind(styles);

type Props = {
  className?: string;
};

// this is only example component, you are free to delete it
export const ChangeLanguage = ({ className }: Props) => {
  const { t, i18n } = useTranslation(LOCALE_NAMESPACE);
  const { language } = i18n;

  if (!LOCALIZATION_ENABLED) {
    return null;
  }

  return (
    <div className={cn('root', className)}>
      <LinkButton
        className={cn('item')}
        noUnderline={language === Languages.EN}
        onClick={() => i18n.changeLanguage(Languages.EN)}
      >
        {t('eng')}
      </LinkButton>
      <LinkButton
        className={cn('item')}
        noUnderline={language === Languages.ES}
        onClick={() => i18n.changeLanguage(Languages.ES)}
      >
        {t('esp')}
      </LinkButton>
      <LinkButton
        className={cn('item')}
        noUnderline={language === Languages.RU}
        onClick={() => i18n.changeLanguage(Languages.RU)}
      >
        {t('rus')}
      </LinkButton>
    </div>
  );
};
