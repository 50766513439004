import React from 'react';

import classNames from 'classnames/bind';

import { Skeleton } from '@components/Skeleton';

import { ITEMS_ON_PAGE } from '../../../../constants';

import styles from './HistoryListSkeleton.module.css';

const cn = classNames.bind(styles);

type WellnessCardSkeletonProps = {
  className?: string;
};

const ITEMS = Array.from(Array(ITEMS_ON_PAGE).keys());

export function HistoryListSkeleton({ className }: WellnessCardSkeletonProps) {
  return (
    <>
      {ITEMS.map((item) => (
        <Skeleton key={item} className={cn('history-list-skeleton', className)} variant="text" />
      ))}
    </>
  );
}
