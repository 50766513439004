export const locale = {
  header: {
    active: 'Active Challenges',
    completed: 'Completed Challenges',
  },
  change_toggler: {
    active: 'Completed Challenges',
    completed: 'Active Challenges',
  },
  change_toggler_mobile: {
    active: 'Completed',
    completed: 'Active',
  },
  no_challenges: {
    active: 'There are no active Challenges to display.',
    completed: 'There are no completed Challenges to display.',
  },
  custom_entry_recorded: 'Custom entry recorded',
};
