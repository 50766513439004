import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { downloadScreeningsReport, getScreenings } from './actions';
import { ErrorMessage, ScreeningsItem, ScreeningsState } from './types';

const initialState: ScreeningsState = {
  screenings: [],
  status: StatusEnum.Uninitialized,
  error: {} as ErrorMessage,
  downloadStatus: StatusEnum.Uninitialized,
};

export const screeningsSlice = createSlice({
  name: 'screenings',
  initialState,
  reducers: {},
  extraReducers: {
    [getScreenings.pending.type]: (state: ScreeningsState) => {
      state.status = StatusEnum.Pending;
      state.error = initialState.error;
    },
    [getScreenings.fulfilled.type]: (state: ScreeningsState, action: PayloadAction<ScreeningsItem[]>) => {
      state.status = StatusEnum.Fulfilled;
      state.screenings = action.payload;
    },
    [getScreenings.rejected.type]: (state: ScreeningsState, error) => {
      state.status = StatusEnum.Rejected;
      state.error = error.payload?.response?.data;
    },
    [downloadScreeningsReport.pending.type]: (state: ScreeningsState) => {
      state.downloadStatus = StatusEnum.Pending;
    },
    [downloadScreeningsReport.fulfilled.type]: (state: ScreeningsState) => {
      state.downloadStatus = StatusEnum.Fulfilled;
    },
    [downloadScreeningsReport.rejected.type]: (state: ScreeningsState) => {
      state.downloadStatus = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const screeningsReducer = screeningsSlice.reducer;
