import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { enqueueSnackbar } from '@modules/Snackbar';

import { WellnessTypesEnum } from '../constants';
import { locale } from '../locale';
import { URLS_BY_WELLNESS_TYPE } from './constants';
import { DeleteExerciseRequestType, SaveRequestType } from './types';

export const getList = createAsyncThunk('get-list', async (type: WellnessTypesEnum, { rejectWithValue }) => {
  try {
    const url = URLS_BY_WELLNESS_TYPE[type].listUrl;
    const data = await fetch.get({ url });
    const res = await data.response;

    return res?.data ?? [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getExerciseTypes = createAsyncThunk('get-exercise-type', async (_, { rejectWithValue }) => {
  try {
    const data = await fetch.get({ url: '/WellnessLog/get-exercise-types' });
    const res = await data.response;

    return res?.data ?? [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const saveLogItem = createAsyncThunk(
  'save-exercise',
  async (
    {
      data,
      type,
      recordAnotherEntry = false,
      entryCount = 0,
    }: SaveRequestType & {
      recordAnotherEntry?: Nullable<boolean>;
      entryCount?: Nullable<number>;
    },
    { rejectWithValue, dispatch },
  ) => {
    const url = URLS_BY_WELLNESS_TYPE[type].saveUrl;
    try {
      const responseData = await fetch.post({ url, data });
      const res = await responseData.response;

      if (!recordAnotherEntry) {
        dispatch(
          enqueueSnackbar({
            message: entryCount === 0 ? locale.entry_recorder.single[type] : locale.entry_recorder.multiple[type],
            options: {
              variant: 'success',
            },
          }),
        );
      }

      dispatch(getList(type));

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteExercise = createAsyncThunk(
  'delete-exercise',
  async ({ id, type }: DeleteExerciseRequestType, { rejectWithValue, dispatch }) => {
    const url = URLS_BY_WELLNESS_TYPE[type].deleteUrl;
    try {
      const data = await fetch.delete({ url: `${url}/${id}` });
      const res = await data.response;
      dispatch(getList(type));

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
