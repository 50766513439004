import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';
import { datePlusTime, formatDateForServer } from '@common/utils';
import { RecordActivityModal } from '@components/RecordActivityModal';

import { WellnessTypesEnum } from '../../../constants';
import { hideEditModal, saveLogItem, SaveStepsRequestType, stepsRecordActivitySelector } from '../../../store';
import { StepsItemType } from '../../../types';
import { useResetEntryCount } from '../../../useReserEntryCount';
import { FormValuesType } from '../types';
import { StepsForm } from './StepsForm';

export function StepsRecordActivityModal() {
  const { editModalVisible, selectedItem, saveFormStatus } = useSelector(stepsRecordActivitySelector);
  const dispatch = useDispatch();
  const [entryCount, setEntryCount] = useState(0);

  const handleSave = useCallback(
    (formValues: FormValuesType, recordAnotherEntry = false) => {
      const requestData: SaveStepsRequestType['data'] = {
        id: formValues.id,
        steps: Number(formValues.steps),
        recorded_at: formatDateForServer(datePlusTime(formValues.date, formValues.time)),
      };

      dispatch(saveLogItem({ data: requestData, type: WellnessTypesEnum.Steps, recordAnotherEntry, entryCount }));
      setEntryCount(entryCount + 1);
    },
    [dispatch, entryCount],
  );

  const handleClose = useCallback(() => {
    dispatch(hideEditModal({ type: WellnessTypesEnum.Steps }));
  }, [dispatch]);

  useIgnoreEffectDeps(
    () => () => {
      handleClose();
    },
    [],
  );

  useResetEntryCount(editModalVisible, setEntryCount);

  return (
    <RecordActivityModal open={editModalVisible} onClose={handleClose}>
      <StepsForm
        onSave={handleSave}
        onClose={handleClose}
        initialValues={selectedItem as Nullable<StepsItemType>}
        saveFormStatus={saveFormStatus}
      />
    </RecordActivityModal>
  );
}
