import React from 'react';

import RadioGroupMaterial, { RadioGroupProps as RadioGroupMaterialProps } from '@mui/material/RadioGroup';

export type RadioGroupProps = {
  name?: string;
  children: React.ReactNode;
  className?: string;
} & Pick<RadioGroupMaterialProps, 'value' | 'onChange' | 'aria-labelledby' | 'row'>;

export function RadioGroup({ className, name, children, value, onChange, ...rest }: RadioGroupProps) {
  return (
    <RadioGroupMaterial {...rest} className={className} name={name} value={value} onChange={onChange}>
      {children}
    </RadioGroupMaterial>
  );
}
