import { HideFlippersEnum } from '@modules/flippers';

import { locale } from './locale';

export enum AppointmentTypeEnum {
  Upcoming = 'Upcoming',
  Past = 'Past',
}

export enum CalledFromEnum {
  Search = 'Search',
  Filter = 'Filter',
}

export enum VisitTypesEnum {
  InPerson = 'in-person',
  Virtual = 'virtual-visit',
  Telephonic = 'telephonic',
}

export enum VirtualAppointmentsStatuses {
  Approved = 'approved',
  Arrived = 'arrived',
  ErrorAppointmentNotFound = 'error_appointment_not_found',
}

export const MAP_VISIT_TYPE_FLIPPERS: PartialRecord<VisitTypesEnum, HideFlippersEnum> = {
  [VisitTypesEnum.InPerson]: HideFlippersEnum.hide_inperson_options,
  [VisitTypesEnum.Telephonic]: HideFlippersEnum.hide_telephonic_options,
  [VisitTypesEnum.Virtual]: HideFlippersEnum.hide_virtual_options,
};

export enum FilterNames {
  CurrentDate = 'currentDate',
  EndDate = 'endDate',
  StartDate = 'startDate',
  VisitType = 'visitType',
  Provider = 'provider',
  Network = 'network',
  NextVisitReasonCategory = 'nextVisitReasonCategory',
  NextVisitReason = 'nextVisitReason',
  VisitState = 'visitState',
  HealthCenter = 'healthCenter',
  SoonestAvailability = 'soonestAvailability',
  TimeZone = 'timeZone',
  DefaultProviderId = 'defaultProviderId',
  AppointmentTypeId = 'appointmentTypeId',
  AppointmentStartDate = 'appointmentStartDate',
  AppointmentId = 'appointmentId',
  ProviderId = 'providerId',
  RescheduleProviderId = 'rescheduleProviderId',
  ProviderSearchValue = 'providerSearchValue',
}

export enum SoonestFilterEnum {
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export const NEXT_DAYS_COUNT = 6;
export const WEEK_DAYS = 7;
export const PHYSICAL_EXAM_ID = 2;
export const ANNUAL_PHYSICAL_ID = 178;
export const ANNUAL_PHYSICAL_PAP_ID = 177;
export const COMPREHENSIVE_HEALTH_REVIEW_ID = 267;
export const BIOMETRIC_WELLNESS_SCREEN_CATEGORY_ID = 3;
export const BIOMETRIC_WELLNESS_SCREEN_REASON_ID = 181;
export const LAB_WORK_ID = 6;
export const RESCHEDULE_VISIT_REASON = 'Reschedule';
export const IMMUNIZATION_VISIT_CATEGORY_ID = 5;
export const SCHOOL_SPORTS_PHYSICAL_ID = 176;
export const EAR_INFECTION_ID = 51;
export const PRE_SURGICAL_PHYSICAL_ID = 263;

export const LAB_WORK_VISIT_REASON_ID = 241;
export const LAB_WORK_VISIT_REASON_CATEGORY_ID = 6;

export const SOONEST_FILTER_OPTIONS = [
  {
    id: SoonestFilterEnum.Morning,
    name: locale.soonest_availability.morning,
    start: '00:00',
    end: '11:59',
  },
  {
    id: SoonestFilterEnum.Afternoon,
    name: locale.soonest_availability.afternoon,
    start: '12:00',
    end: '16:00',
  },
  {
    id: SoonestFilterEnum.Evening,
    name: locale.soonest_availability.evening,
    start: '16:01',
    end: '23:59',
  },
];
export const NOT_RESCHEDULED_PT_VISIT_REASON_IDS = [1062, 1063, 1064, 252];
export const NOT_RESCHEDULED_BH_VISIT_REASON_IDS = [245];
export const BEHAVIORAL_HEALTH_PROVIDER_VISIT_REASON_IDS = [245, 258];
export const MENTAL_HEALTH_PROVIDER_VISIT_REASON_IDS = [194];
export const BEHAVIORAL_VISIT_REASON_IDS = [
  ...BEHAVIORAL_HEALTH_PROVIDER_VISIT_REASON_IDS,
  ...MENTAL_HEALTH_PROVIDER_VISIT_REASON_IDS,
];
export const BEHAVIORAL_VISIT_REASON_PCP_IDS = [8, 44];

export const MAP_VISIT_TYPES_FLAGS = {
  [VisitTypesEnum.InPerson]: 'in_person_allowed',
  [VisitTypesEnum.Virtual]: 'virtual_allowed',
  [VisitTypesEnum.Telephonic]: 'telephonic_allowed',
} as const;

export const DO_NOT_RESET_FORM_KEY = 'doNotResetForm';
export const ANYWHERE_NETWORK = 'Marathon Health - CareAnywhere / Virtual';
export const PROVIDER_SEARCH_LABEL_ID = 'provider-search-title';
