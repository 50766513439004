import { useCallback, useRef } from 'react';

import { Notifier } from '@airbrake/browser';

import { useIgnoreEffectDeps } from '@common/hooks';
import { gtmEvent, GtmEventsEnum } from '@modules/gtm';

export { Notifier } from '@airbrake/browser';

export const useHandleError = () => {
  const airbrake = useRef<Nullable<Notifier>>(null);

  const handleError = useCallback(
    (error: Error, info: { componentStack: string }) => {
      console.log(error, info, 'Message'); // eslint-disable-line no-console
      gtmEvent(GtmEventsEnum.exception, {
        description: [error.message, error.stack].filter(Boolean).join('\n'),
        fatal: true,
      });

      if (airbrake.current) {
        airbrake.current.notify({
          error,
          info,
        });
      }
    },
    [airbrake],
  );

  useIgnoreEffectDeps(() => {
    if (process.env.AIRBRAKE_PROJECT_ID && process.env.AIRBRAKE_PROJECT_KEY) {
      airbrake.current = new Notifier({
        projectId: process.env.AIRBRAKE_PROJECT_ID,
        projectKey: process.env.AIRBRAKE_PROJECT_KEY,
        environment: process.env.ENVIRONMENT,
      });
    }
  }, []);

  return handleError;
};
