import { MeasurementEnum } from '../../constants';

export const locale = {
  title: 'Water',
  dashboard_title: 'Water',
  history_title: 'Recently Recorded Water',
  record_activity: 'Record Water',
  measurements: {
    [MeasurementEnum.Ounces]: 'Ounces Per Month',
  },
  measurementsShort: {
    [MeasurementEnum.Ounces]: 'ounces',
  },
  measurementsYScaleTitle: {
    [MeasurementEnum.Ounces]: 'Ounces',
  },
};
