import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import {
  completeHRA,
  deleteInProgressHRA,
  downloadCompletedQuestionnaires,
  fetchQuestionnaireSection,
  getCompletedQuestionnaries,
  getInProgressQuestionnaries,
  getWhereIBelong,
  saveHRA,
} from './actions';
import {
  CompletedQuestionnarieType,
  InProgressHRAType,
  QuestionnariesState,
  SectionType,
  WhereIBelongType,
} from './types';

const initialState: QuestionnariesState = {
  inProgress: {
    data: {
      incented_hra: false,
      in_progress_hra: {
        id: null,
        updated_at: '',
        user_id: null,
      },
    },
    status: StatusEnum.Uninitialized,
    error: {},
  },
  completed: {
    data: {
      completed_questionnaires: [],
    },
    status: StatusEnum.Uninitialized,
    error: {},
  },
  section: {
    data: {},
    status: StatusEnum.Uninitialized,
    error: {},
  },
  whereIBelong: {
    data: {
      where_i_belong: 'general',
      id: null,
    },
    status: StatusEnum.Uninitialized,
    error: {},
  },
  completeHRAStatus: StatusEnum.Uninitialized,
  deleteHRAStatus: StatusEnum.Uninitialized,
  downloadStatus: StatusEnum.Uninitialized,
  completedId: null,
  showAppointmentModal: false,
};

export const questionnariesSlice = createSlice({
  name: 'questionnaries',
  initialState,
  reducers: {
    resetSection: (state) => {
      state.section.data = {};
    },
    resetInProgress: (state) => {
      state.inProgress = initialState.inProgress;
    },
    resetDeleteHRAStatus: (state) => {
      state.deleteHRAStatus = StatusEnum.Uninitialized;
    },
    setCompletedId: (state, action) => {
      state.completedId = action.payload;
    },
    resetCompletedStatus: (state) => {
      state.completeHRAStatus = initialState.completeHRAStatus;
    },
    setShowAppointmentModal: (state, action) => {
      state.showAppointmentModal = action.payload;
    },
  },
  extraReducers: {
    [getInProgressQuestionnaries.pending.type]: (state: QuestionnariesState) => {
      state.inProgress.status = StatusEnum.Pending;
    },
    [getInProgressQuestionnaries.fulfilled.type]: (
      state: QuestionnariesState,
      action: PayloadAction<InProgressHRAType>,
    ) => {
      state.inProgress.status = StatusEnum.Fulfilled;
      state.inProgress.data = action.payload;
    },
    [getInProgressQuestionnaries.rejected.type]: (state: QuestionnariesState) => {
      state.inProgress.status = StatusEnum.Rejected;
    },

    [getCompletedQuestionnaries.pending.type]: (state: QuestionnariesState) => {
      state.completed.status = StatusEnum.Pending;
    },
    [getCompletedQuestionnaries.fulfilled.type]: (
      state: QuestionnariesState,
      action: PayloadAction<CompletedQuestionnarieType[]>,
    ) => {
      state.completed.status = StatusEnum.Fulfilled;
      state.completed.data.completed_questionnaires = action.payload;
    },
    [getCompletedQuestionnaries.rejected.type]: (state: QuestionnariesState) => {
      state.completed.status = StatusEnum.Rejected;
    },

    [fetchQuestionnaireSection.pending.type]: (state: QuestionnariesState) => {
      state.section.status = StatusEnum.Pending;
    },
    [fetchQuestionnaireSection.fulfilled.type]: (state: QuestionnariesState, action: PayloadAction<SectionType>) => {
      state.section.status = StatusEnum.Fulfilled;
      state.section.data = action.payload;
    },
    [fetchQuestionnaireSection.rejected.type]: (state: QuestionnariesState) => {
      state.section.status = StatusEnum.Rejected;
    },
    [saveHRA.pending.type]: (state: QuestionnariesState) => {
      state.section.status = StatusEnum.Pending;
    },
    [saveHRA.fulfilled.type]: (state: QuestionnariesState) => {
      state.section.status = StatusEnum.Fulfilled;
    },
    [saveHRA.rejected.type]: (state: QuestionnariesState) => {
      state.section.status = StatusEnum.Rejected;
    },
    [getWhereIBelong.pending.type]: (state: QuestionnariesState) => {
      state.whereIBelong.status = StatusEnum.Pending;
    },
    [getWhereIBelong.fulfilled.type]: (state: QuestionnariesState, action: PayloadAction<WhereIBelongType>) => {
      state.whereIBelong.status = StatusEnum.Fulfilled;
      state.whereIBelong.data = action.payload;
    },
    [getWhereIBelong.rejected.type]: (state: QuestionnariesState) => {
      state.section.status = StatusEnum.Rejected;
    },
    [completeHRA.pending.type]: (state: QuestionnariesState) => {
      state.completeHRAStatus = StatusEnum.Pending;
    },
    [completeHRA.fulfilled.type]: (state: QuestionnariesState) => {
      state.completeHRAStatus = StatusEnum.Fulfilled;
      state.showAppointmentModal = true;
    },
    [completeHRA.rejected.type]: (state: QuestionnariesState) => {
      state.completeHRAStatus = StatusEnum.Rejected;
    },
    [deleteInProgressHRA.pending.type]: (state: QuestionnariesState) => {
      state.deleteHRAStatus = StatusEnum.Pending;
    },
    [deleteInProgressHRA.fulfilled.type]: (state: QuestionnariesState) => {
      state.deleteHRAStatus = StatusEnum.Fulfilled;
    },
    [deleteInProgressHRA.rejected.type]: (state: QuestionnariesState) => {
      state.deleteHRAStatus = StatusEnum.Rejected;
    },
    [downloadCompletedQuestionnaires.pending.type]: (state: QuestionnariesState) => {
      state.downloadStatus = StatusEnum.Pending;
    },
    [downloadCompletedQuestionnaires.fulfilled.type]: (state: QuestionnariesState) => {
      state.downloadStatus = StatusEnum.Fulfilled;
    },
    [downloadCompletedQuestionnaires.rejected.type]: (state: QuestionnariesState) => {
      state.downloadStatus = StatusEnum.Rejected;
    },
    ...resetStoreActions(() => initialState),
  },
});

export const questionnariesReducer = questionnariesSlice.reducer;

export const {
  resetSection,
  resetInProgress,
  resetDeleteHRAStatus,
  resetCompletedStatus,
  setCompletedId,
  setShowAppointmentModal,
} = questionnariesSlice.actions;
