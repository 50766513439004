import { ReactNode } from 'react';

import { DialogProps } from '@mui/material';

export enum ModalSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type OnCloseType = (
  event: Parameters<NonNullable<DialogProps['onClose']>>[0],
  reason?: Parameters<NonNullable<DialogProps['onClose']>>[1],
) => void;

export type ModalProps = {
  className?: string;
  paperClassName?: string;
  crossbuttonClassName?: string;
  iconClassName?: string;
  modalContentClassName?: string;
  modalActionsClassName?: string;
  closeButtonClassName?: string;
  children: ReactNode;
  /**
   * Optional: CTA buttons at the end of the modal.
   */
  actions?: ReactNode;
  title?: ReactNode;
  withCloseButton?: boolean;
  showCrossButton?: boolean;
  ignoreFullScreen?: boolean;
  ignoreBackdropClick?: boolean;
  withoutBorders?: boolean;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  role?: string | undefined;
  titleId?: string;
  hasLoadingIndicator?: boolean;
  loading?: boolean;
  spinnerClassName?: string;
  fixedWidth?: boolean;
  size?: ModalSize;
  onClose?: OnCloseType;
} & Pick<DialogProps, 'disableEscapeKeyDown' | 'fullScreen' | 'open' | 'id' | 'transitionDuration'>;
