import React from 'react';

import classNames from 'classnames/bind';

import { useScreenSize } from '@common/hooks/useScreenSize';

import { locale } from './locale';

import styles from './EnvironmentNotification.module.css';

const cn = classNames.bind(styles);

const envLocale = locale.environments[process.env.ENVIRONMENT];

type Props = {
  className?: string;
};

export function EnvironmentNotification({ className }: Props) {
  const { isMobileView } = useScreenSize();
  if (!envLocale) {
    return null;
  }

  return (
    <div className={cn('root', className)}>
      {isMobileView ? (
        envLocale?.mobile
      ) : (
        <>
          {envLocale?.desktop}
          {envLocale?.desktop_sub && (
            <>
              <br />
              <span className={cn('sub')}>{envLocale?.desktop_sub}</span>
            </>
          )}
        </>
      )}
    </div>
  );
}
