export const locale = {
  links: {
    home: 'My Health',
    my_health_dashboard: 'Dashboard',
    test_results: 'Test Results',
    health_summary: {
      root: 'Health Summary',
      medications: 'Medications',
      allergies: 'Allergies',
      health_issues: 'Health Issues',
      immunizations: 'Immunizations',
      screenings: 'Screenings',
      visit_summaries: 'Visit Summaries',
      patient_education: 'Patient Education',
      documents: 'Documents',
    },
    risk_profile: 'Risk Profile',
    questionnaires: 'Questionnaires',
    goals: 'Goals',
    care_guidelines: 'Care Guidelines',
    documents: 'Documents',
  },
};
