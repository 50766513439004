import { Fetch } from './Fetch';
import { CONFIG } from './constants';
import { setAuthorization } from './interceptors';

export { refreshInProcessInterceptor, alertInterceptor, refreshInterceptor } from './interceptors';
export type { ExtendedAxiosError, ExtendedAxiosRequestConfig } from './types';

export const fetch = new Fetch({
  config: CONFIG,
  requestInterceptors: [{ onSuccess: setAuthorization }],
});
