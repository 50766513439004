import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const fetchWebinarLibrary = createAsyncThunk(
  'categories/fetchWebinarLibrary',
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/Article/get-articles-by-name',
        params: {
          employerId: id,
          articleCategoryName: 'Webinar Library',
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
