import { MEASUREMENT_CARDS, MeasurementsCurrentTypes } from './constants';
import { DashboardDataType } from './store/types';

export const getDashboardItemValue = (
  dashboardData: DashboardDataType,
  currentType: MeasurementsCurrentTypes,
  currentSubType?: MeasurementsCurrentTypes,
) => {
  if (!currentSubType) {
    return dashboardData[currentType] ?? '';
  }

  return [dashboardData[currentType]?.split(' ')?.[0], dashboardData[currentSubType]?.split(' ')?.[0]]
    .filter(Boolean)
    .join('/');
};

export const getDashboardCustomizationOptions = (dashboardData: DashboardDataType) =>
  MEASUREMENT_CARDS.filter((card) => !!getDashboardItemValue(dashboardData, card.currentType, card.currentSubType)).map(
    (card) => ({ id: card.typeName, label: card.title ?? dashboardData[card.type]?.type_name ?? '' }),
  );

export const getCustomizationDefaultValues = (dashboardData: DashboardDataType) =>
  getDashboardCustomizationOptions(dashboardData).map(({ id }) => id);
