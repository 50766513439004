import React from 'react';

import classNames from 'classnames/bind';

import { Typography } from '@components/Typography';

import { ReactComponent as Dashes } from './assets/dashes.svg';
import { ReactComponent as LockerLogo } from './assets/locker_logo.svg';

import styles from './Locker.module.css';

export type LockerProps = {
  className?: string;
  title?: string;
  fullScreen?: boolean;
  animated?: boolean;
  hideLogo?: boolean;
};

const cn = classNames.bind(styles);

export const Locker = ({ fullScreen, title, animated, className, hideLogo }: LockerProps) => (
  <div
    className={cn('locker', className, {
      'locker--fullscreen': fullScreen,
    })}
  >
    <div className={cn('locker__logo')}>
      {!hideLogo && <LockerLogo className={cn('locker__marathon-logo')} />}
      <Dashes
        className={cn('locker__dashes', {
          'locker__dashes--animated': animated,
        })}
      />
    </div>

    {title && (
      <Typography variant="h3" className={cn('locker__title')}>
        {title}
      </Typography>
    )}
  </div>
);
