import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { getUserCategories } from './actions';
import { UserCategoriesState, UserCategoryType } from './types';

const initialState: UserCategoriesState = {
  userCategories: {
    categories: [],
    status: StatusEnum.Uninitialized,
    error: {},
  },
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: {
    [getUserCategories.pending.type]: (state: UserCategoriesState) => {
      state.userCategories.status = StatusEnum.Pending;
    },
    [getUserCategories.fulfilled.type]: (state: UserCategoriesState, action: PayloadAction<UserCategoryType[]>) => {
      state.userCategories.status = StatusEnum.Fulfilled;
      state.userCategories.categories = action.payload;
    },
    [getUserCategories.rejected.type]: (state: UserCategoriesState) => {
      state.userCategories.status = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const categoriesReducer = categoriesSlice.reducer;
