import qs from 'qs';

export const extendConfigByApiUrl = <T extends UnknownObject>(config: T): T & { baseUrl?: string } => {
  if (!process.env.API_URL || process.env.MOCK_SERVER === 'true') {
    return config;
  }
  const API_URL = process.env.API_URL.match('^http') ? process.env.API_URL : `https://${process.env.API_URL}`;

  return {
    ...config,
    baseURL: API_URL,
  };
};

type HeadersType = Record<string, string | number | boolean>;

export const extendConfigByProxyHeader = <H extends HeadersType, T extends UnknownObject & { headers?: H }>(
  config: T,
): T & { headers?: HeadersType } => {
  if (!process.env.PROXY_API_URL || process.env.MOCK_SERVER === 'true') {
    return config;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      'api-request': true,
    },
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const paramsSerializer = (params: any) => qs.stringify(params, { indices: false });
