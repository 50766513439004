import { useCallback, useState } from 'react';

import { useIgnoreEffectDeps } from '@common/hooks';
import { usePrevious } from '@common/hooks/usePrevious';

export const useModal = (open?: boolean) => {
  const [modalOpen, setModalVisible] = useState(open || false);
  const prevVisible = usePrevious(open);

  useIgnoreEffectDeps(() => {
    if (prevVisible !== open) {
      setModalVisible(!!open);
    }
  }, [open]);

  const handleOpenModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  return {
    modalOpen,
    onOpenModal: handleOpenModal,
    onCloseModal: handleCloseModal,
  };
};
