export const sendHealthRecordURLMap: UnknownObject<string> = {
  'Health Risk Assessment (HRA)': '/QuestionnaireHra/generate-and-save-hra-pdf',
  'Health history and Risk Assessment (HHRA)': '/QuestionnaireHhra/generate-and-save-hhra-pdf',
  'Health Risk Assessment Annual Update (HRAU)': '/QuestionnaireHrau/generate-and-save-hrau-pdf',
  'Marathon Health Questionnaire (MHQ)': '/QuestionnaireMhq/generate-and-save-mhq-pdf',
};

export const downloadCompletedQuestionnairesURLMap: UnknownObject<string> = {
  'Health Risk Assessment (HRA)': '/QuestionnaireHra/download-completed-hra-questionnaire',
  'Health history and Risk Assessment (HHRA)': '/QuestionnaireHhra/download-completed-hhra-questionnaire',
  'Health Risk Assessment Annual Update (HRAU)': '/QuestionnaireHrau/download-completed-hrau-questionnaire',
  'Marathon Health Questionnaire (MHQ)': '/QuestionnaireMhq/download-completed-mhq-questionnaire',
};
