import React, { ReactElement } from 'react';

import { LISTED_CATEGORIES } from '../constants';
import { useCategories } from '../hooks';
import { locale } from './locale';

type ShowCategoryProps = {
  category?: string;
  children: ReactElement;
  id?: string | number;
};

export function ShowCategory({ category, children, id }: ShowCategoryProps) {
  const getCategory = useCategories();
  const CATEGORY = category === locale.our_providers ? locale.providers : category;
  if (!LISTED_CATEGORIES.includes(CATEGORY as string)) return children;

  const allowUser = getCategory(CATEGORY as string);

  return allowUser ? <React.Fragment key={id}>{children}</React.Fragment> : null;
}
