import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getClinics = createAsyncThunk('locations/fetchClinics', async (_, { rejectWithValue }) => {
  try {
    const data = await fetch.get({
      url: '/clinic/get-clinics',
    });

    const res = await data.response;

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getClinicDetails = createAsyncThunk(
  'locations/fetchClinicDetails',
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/clinic/get-clinic',
        params: {
          clinicId: id,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
