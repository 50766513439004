import React from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { userProfileDataSelector } from '@modules/userProfileData';

import { mainMenuOpenSelector } from '../store';
import { BurgerMenu } from './BurgerMenu';
import { MainHeader } from './MainHeader';
import { MainMenu } from './MainMenu';

import styles from './Header.module.css';

const cn = classNames.bind(styles);

export interface HeaderProps {
  mainMenuToggleHandler: VoidFunction;
}

export function Header({ mainMenuToggleHandler }: HeaderProps) {
  const mainMenuOpen = useSelector(mainMenuOpenSelector);
  const userProfileData = useSelector(userProfileDataSelector);

  return (
    <div className={cn('app-header')}>
      <MainHeader
        mainMenuToggleHandler={mainMenuToggleHandler}
        userProfileData={userProfileData}
        mainMenuOpen={mainMenuOpen}
      />

      <MainMenu />

      <BurgerMenu mainMenuOpen={mainMenuOpen} mainMenuToggleHandler={mainMenuToggleHandler} />
    </div>
  );
}
