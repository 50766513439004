import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getClinicsSelector, getProvidersSelector } from '../store';

export const useGetAthenaValues = (athenaProviderId: Nullable<number>, athenaDepartmentId: Nullable<number>) => {
  const providers = useSelector(getProvidersSelector);
  const clinics = useSelector(getClinicsSelector);

  const athenaProvider = useMemo(
    () => providers.find((provider) => provider.id === athenaProviderId),
    [athenaProviderId, providers],
  );
  const athenaDepartment = useMemo(
    () => clinics.find((clinic) => clinic.id === athenaDepartmentId),
    [clinics, athenaDepartmentId],
  );

  return {
    athenaProvider,
    athenaDepartment,
    athenaProviderName: athenaProvider?.display_name,
    athenaDepartmentName: athenaDepartment?.name,
  };
};
