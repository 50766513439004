import { generatePath } from 'react-router';

import { PATHS } from '@common/routes';
import { TabProps } from '@components/HorizontalMenu';
import { ShowFlippersEnum } from '@modules/flippers';

import { locale } from './locale';

export enum MenuItemsEnum {
  Medications = 'Medications',
  Allergies = 'Allergies',
  HealthIssues = 'Health Issues',
  Immunizations = 'Immunizations',
  Screenings = 'Screenings',
  VisitSummaries = 'VisitSummaries',
  PatientEducation = 'PatientEducation',
  Documents = 'Documents',
}

export enum MyHealthItemsEnum {
  Dashboard = 'Dashboard',
  TestResults = 'TestResults',
  HealthSummary = 'HealthSummary',
  RiskProfile = 'RiskProfile',
  Goals = 'Goals',
  CareGuidelines = 'CareGuidelines',
  Questionnaires = 'Questionnaires',
}
export const HEALTH_SUMMARY_SUB_MENU_ITEMS = [
  {
    id: MenuItemsEnum.Medications,
    label: locale.links.health_summary.medications,
    to: generatePath(PATHS.MY_HEALTH.HEALTH_SUMMARY.MEDICATIONS),
    flipper: ShowFlippersEnum.my_medications,
  },
  {
    id: MenuItemsEnum.Allergies,
    label: locale.links.health_summary.allergies,
    to: generatePath(PATHS.MY_HEALTH.HEALTH_SUMMARY.ALLERGIES),
    flipper: ShowFlippersEnum.my_allergies,
  },
  {
    id: MenuItemsEnum.HealthIssues,
    label: locale.links.health_summary.health_issues,
    to: generatePath(PATHS.MY_HEALTH.HEALTH_SUMMARY.HEALTH_ISSUES),
    flipper: ShowFlippersEnum.my_problems,
  },
  {
    id: MenuItemsEnum.Immunizations,
    label: locale.links.health_summary.immunizations,
    to: generatePath(PATHS.MY_HEALTH.HEALTH_SUMMARY.IMMUNIZATIONS),
    flipper: ShowFlippersEnum.my_immunizations,
  },
  {
    id: MenuItemsEnum.Screenings,
    label: locale.links.health_summary.screenings,
    to: generatePath(PATHS.MY_HEALTH.HEALTH_SUMMARY.SCREENINGS),
    onlySourceUser: true,
  },
  {
    id: MenuItemsEnum.VisitSummaries,
    label: locale.links.health_summary.visit_summaries,
    to: generatePath(PATHS.MY_HEALTH.HEALTH_SUMMARY.VISIT_SUMMARIES),
    flipper: ShowFlippersEnum.my_visit_summaries,
  },
  {
    id: MenuItemsEnum.PatientEducation,
    label: locale.links.health_summary.patient_education,
    to: generatePath(PATHS.MY_HEALTH.HEALTH_SUMMARY.PATIENT_EDUCATION),
  },
  {
    id: MenuItemsEnum.Documents,
    label: locale.links.health_summary.documents,
    to: generatePath(PATHS.MY_HEALTH.HEALTH_SUMMARY.DOCUMENTS),
  },
];

export const TAB_LIST: Array<TabProps> = [
  {
    id: MyHealthItemsEnum.Dashboard,
    label: locale.links.my_health_dashboard,
    to: PATHS.MY_HEALTH.MY_HEALTH_DASHBOARD,
  },
  {
    id: MyHealthItemsEnum.TestResults,
    label: locale.links.test_results,
    to: PATHS.MY_HEALTH.TEST_RESULTS,
    flipper: ShowFlippersEnum.my_clinical_results,
  },
  {
    id: MyHealthItemsEnum.HealthSummary,
    label: locale.links.health_summary.root,
    to: PATHS.MY_HEALTH.HEALTH_SUMMARY.ROOT,
    subMenuItems: HEALTH_SUMMARY_SUB_MENU_ITEMS,
  },
  {
    id: MyHealthItemsEnum.RiskProfile,
    label: locale.links.risk_profile,
    to: PATHS.MY_HEALTH.RISK_PROFILE,
    flipper: ShowFlippersEnum.my_risk_profile,
  },
  {
    id: MyHealthItemsEnum.Goals,
    label: locale.links.goals,
    to: PATHS.MY_HEALTH.GOALS,
    flipper: ShowFlippersEnum.view_goals,
  },
  {
    id: MyHealthItemsEnum.CareGuidelines,
    label: locale.links.care_guidelines,
    to: PATHS.MY_HEALTH.CARE_GUIDELINES.ROOT,
    flipper: ShowFlippersEnum.my_quality_measures,
  },
  {
    id: MyHealthItemsEnum.Questionnaires,
    label: locale.links.questionnaires,
    to: PATHS.MY_HEALTH.QUESTIONNAIRES,
    flipper: ShowFlippersEnum.questionnaires,
    onlySourceUser: true,
  },
];
