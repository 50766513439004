import React, { ReactNode } from 'react';

import classNames from 'classnames/bind';

import { FormButtons } from '@components/FormButtons';
import { Typography } from '@components/Typography';

import { locale } from './locale';

import styles from './RecordActivityForm.module.css';

const cn = classNames.bind(styles);

type FormProps = {
  onClose?: VoidFunction;
  onDelete?: VoidFunction;
  onSubmitRepeatForm?: (e?: React.BaseSyntheticEvent) => Promise<void>;
  onSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void>;
  children: ReactNode;
  isLoading: boolean;
  repeatFormText?: string;
  submitText: string;
  cancelText: string;
  headerText: string;
  titleText?: string;
  className?: string;
  showTitle?: boolean;
};

const TITLE_ID = 'record_activity_modal_title';

export const RecordActivityForm = ({
  onClose,
  onDelete,
  onSubmitRepeatForm,
  onSubmit,
  children,
  isLoading,
  repeatFormText,
  submitText,
  cancelText,
  headerText,
  titleText,
  className,
  showTitle = true,
}: FormProps) => (
  <div className={cn('form', className)}>
    {showTitle && (
      <>
        {!!titleText && (
          <Typography variant="h5" className={cn('form__title')}>
            {titleText}
          </Typography>
        )}

        <Typography variant="h2" className={cn('form__header')} id={TITLE_ID}>
          {headerText}
        </Typography>

        <div className={cn('form__sub-header')}>
          <span className={cn('form__asterisk')}>*</span> {locale.sub_header}
        </div>
      </>
    )}

    <form className={cn('form__fields')} onSubmit={onSubmit}>
      {children}
    </form>

    <FormButtons
      className={cn('form__buttons')}
      onSubmitRepeatForm={onSubmitRepeatForm}
      onSubmit={onSubmit}
      isLoading={isLoading}
      onClose={onClose}
      onDelete={onDelete}
      repeatFormTitle={repeatFormText}
      submitTitle={submitText}
      cancelTitle={cancelText}
    />
  </div>
);
