import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';

import { getNetworkForAllMembers, networksSelector } from '../store';

export function useLoadNetworks() {
  const dispatch = useDispatch();
  const { networkForAllMembersStatus, userId } = useSelector(networksSelector);

  useIgnoreEffectDeps(() => {
    if ([StatusEnum.Uninitialized].includes(networkForAllMembersStatus) && userId) {
      dispatch(getNetworkForAllMembers(userId));
    }
  }, [userId, networkForAllMembersStatus]);
}
