import { useDispatch } from 'react-redux';

import { useIgnoreEffectDeps, useIsAuthorized } from '@common/hooks';
import { supplementalWaiverService } from '@common/servicesInstances/suplementalWeaver';

import { setSupplementalWaiverFromStorage } from './store';

export function usePersistSupplementWaiver() {
  const dispatch = useDispatch();
  const authorized = useIsAuthorized();

  useIgnoreEffectDeps(() => {
    const supplementalWaiverFromStorage = supplementalWaiverService.getSign();

    if (authorized && supplementalWaiverFromStorage) {
      dispatch(setSupplementalWaiverFromStorage(supplementalWaiverFromStorage));
    }
  }, [authorized]);
}
