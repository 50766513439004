import React, { ReactNode } from 'react';

import classNames from 'classnames/bind';

import { Accordion, AccordionDetails, AccordionSummary } from '@components/Accordion';
import { Paper } from '@components/Paper';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';

import styles from './SideBarWidget.module.css';

const cn = classNames.bind(styles);

type SideBarWidgetProps = {
  children: ReactNode;
  header?: ReactNode;
  subHeader?: ReactNode;
  className?: string;
  isAccordion?: boolean;
  defaultExpanded?: boolean;
  id?: string;
  onCloseClick?: VoidFunction;
};

export function SideBarWidget({
  id,
  children,
  className,
  header,
  subHeader,
  isAccordion,
  defaultExpanded = true,
  onCloseClick,
}: SideBarWidgetProps) {
  if (isAccordion) {
    return (
      <Accordion
        square
        defaultExpanded={defaultExpanded}
        className={cn('side-bar-widget', 'side-bar-widget--accordion', className)}
        id={id}
      >
        <AccordionSummary
          expandIcon={<SvgIcon icon="chevronDown" />}
          expandedClassName={cn('side-bar-widget__expanded-summary')}
        >
          {header && (
            <Typography variant="h2" component="h2">
              {header}
            </Typography>
          )}

          {subHeader && (
            <Typography variant="body1" component="p">
              {subHeader}
            </Typography>
          )}
        </AccordionSummary>

        <AccordionDetails className={cn('side-bar-widget__accordion-details')}>{children}</AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Paper className={cn('side-bar-widget', className)} id={id}>
      {header && (
        <Typography className={cn('side-bar-widget__header')} variant="h3" component="h2">
          {header}
        </Typography>
      )}

      {onCloseClick && <SvgIcon onClick={onCloseClick} icon="close" className={cn('side-bar-widget__close-icon')} />}

      {subHeader && (
        <Typography className={cn('side-bar-widget__sub-header')} component="p">
          {subHeader}
        </Typography>
      )}

      {children}
    </Paper>
  );
}
