import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { enqueueSnackbar } from '@modules/Snackbar';

import { locale } from '../locale';
import {
  EditConsentPayloadType,
  PersonalSignUpPayloadType,
  SaveCustomRecordPayloadType,
  TeamSignUpPayloadType,
  UnregisterPayloadType,
  GetChallengeParams,
} from './types';

export const getActiveChallengesList = createAsyncThunk(
  'get-active-challenges-list',
  async (_, { rejectWithValue }) => {
    try {
      const url = '/Challenge/challenges-list';
      const data = await fetch.get({ url });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getChallenge = createAsyncThunk(
  'get-challenge',
  async ({ challengeId }: GetChallengeParams, { rejectWithValue }) => {
    try {
      const data = await fetch.get({
        url: '/Challenge/challenge',
        params: { challengeId },
      });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPastChallengesList = createAsyncThunk('get-past-challenges-list', async (_, { rejectWithValue }) => {
  try {
    const url = '/Challenge/challenges-past';
    const data = await fetch.get({ url });
    const res = await data.response;

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const saveCustomRecord = createAsyncThunk(
  'save-custom-record',
  async ({ data }: SaveCustomRecordPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const url = '/Challenge/challenges-custom';
      const responseData = fetch.post({
        url,
        data,
      });
      const res = await responseData.response;

      dispatch(
        enqueueSnackbar({
          message: locale.custom_entry_recorded,
          options: {
            variant: 'success',
          },
        }),
      );

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteCustomRecord = createAsyncThunk(
  'delete-custom-record',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const url = `/Challenge/challenges-custom/${id}`;
      const responseData = fetch.delete({
        url,
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getRecordsList = createAsyncThunk(
  'get-records-list',
  async ({ challengeId }: { challengeId: number }, { rejectWithValue }) => {
    try {
      const url = `/Challenge/challenges-custom/${challengeId}`;
      const data = await fetch.get({ url });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const personalSignUp = createAsyncThunk(
  'personal-sign-up',
  async (data: PersonalSignUpPayloadType, { rejectWithValue }) => {
    try {
      const url = `/Challenge/challenges-signup-personal`;
      const responseData = fetch.post({
        url,
        data,
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const teamSignUp = createAsyncThunk('team-sign-up', async (data: TeamSignUpPayloadType, { rejectWithValue }) => {
  try {
    const url = `/Challenge/challenges-signup-team`;
    const responseData = fetch.post({
      url,
      data,
    });
    const res = await responseData.response;

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getTeamsList = createAsyncThunk(
  'get-teams-list',
  async ({ challengeId }: { challengeId: number }, { rejectWithValue }) => {
    try {
      const url = `/Challenge/challenges-teams/${challengeId}`;
      const responseData = fetch.get({
        url,
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const unregister = createAsyncThunk('unregister', async (data: UnregisterPayloadType, { rejectWithValue }) => {
  try {
    const url = '/Challenge/challenges-unregister';
    const responseData = fetch.post({
      url,
      data,
    });
    const res = await responseData.response;

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editConsent = createAsyncThunk(
  'edit-consent',
  async (data: EditConsentPayloadType, { rejectWithValue }) => {
    try {
      const url = `/Challenge/challenges-edit-consent`;
      const responseData = fetch.post({
        url,
        data,
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
