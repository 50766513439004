import { EnvironmentsEnum } from '@common/constants';

export const locale = {
  environments: {
    [EnvironmentsEnum.staging]: {
      desktop: 'This is the STAGING environment',
      desktop_sub: 'STAGING uses production data',
      mobile: 'STAGING',
    },
    [EnvironmentsEnum.qa]: {
      desktop: 'This is the QA environment',
      mobile: 'QA',
    },
  } as UnknownObject<UnknownObject<string>>,
};
