import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getStates = createAsyncThunk('StaticList/get-states', async (_, { rejectWithValue }) => {
  try {
    const data = await fetch.get({ url: '/StaticList/get-states' });

    const res = await data.response;

    return res?.data || [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getLanguages = createAsyncThunk('StaticList/get-emr-languages', async (_, { rejectWithValue }) => {
  try {
    const data = await fetch.get({ url: '/StaticList/get-emr-languages' });

    const res = await data.response;

    return res?.data || [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getTimezones = createAsyncThunk('StaticList/get-timezones', async (_, { rejectWithValue }) => {
  try {
    const data = await fetch.get({ url: '/StaticList/get-timezones', params: { region: 'America' } });

    const res = await data.response;

    return res?.data || [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getEligibility = createAsyncThunk('StaticList/eligibilities', async (_, { rejectWithValue }) => {
  try {
    const data = await fetch.get({ url: '/StaticList/eligibilities' });
    const res = await data.response;

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
