import * as React from 'react';

import TabMaterial, { TabProps } from '@mui/material/Tab';
import classNames from 'classnames/bind';
import { Link as RouterLink } from 'react-router-dom';

import styles from './Tab.module.css';

const cn = classNames.bind(styles);

export type NextTabProps = {
  tabRootClass?: string;
  asRouterLink?: boolean;
  selectedBackground?: boolean;
  to?: string;
} & TabProps;

export function NextTab({
  asRouterLink,
  className,
  tabRootClass,
  to,
  selectedBackground = false,
  disableRipple = true,
  ...restProps
}: NextTabProps) {
  return (
    <TabMaterial
      {...restProps}
      disableRipple={disableRipple}
      to={asRouterLink ? to : undefined}
      component={asRouterLink ? RouterLink : 'div'}
      className={cn('tab', className, {
        'tab--selected-background': selectedBackground,
      })}
      classes={{
        root: cn('tab--root', tabRootClass),
        selected: cn('tab--selected'),
      }}
    />
  );
}
