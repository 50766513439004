import { GetFlipperValueType } from '@modules/flippers';

import {
  filterMenuAndSubMenuItems,
  getFiltrationByFlipperFunction,
  getFiltrationByOnlySourceUserFunction,
} from './filtrationFunctions';
import { MenuItemWithSubItems, FlipperItemProps, OnlySourceUserItemProps } from './types';

export function filterMenuItemsByFlippers<TMenuItemType extends MenuItemWithSubItems<FlipperItemProps>>(
  menuItems: TMenuItemType[],
  getFlipperValue: GetFlipperValueType,
): TMenuItemType[] {
  const filterByFlipper = getFiltrationByFlipperFunction(getFlipperValue);

  return filterMenuAndSubMenuItems(menuItems, filterByFlipper);
}

export function filterMenuItemsByUserId<TMenuItemType extends MenuItemWithSubItems<OnlySourceUserItemProps>>(
  menuItems: TMenuItemType[],
  isSourceUserAndSelectedFamilyMemberSimilar: boolean,
) {
  const filterByOnlySourceUser = getFiltrationByOnlySourceUserFunction(isSourceUserAndSelectedFamilyMemberSimilar);

  return filterMenuAndSubMenuItems(menuItems, filterByOnlySourceUser);
}
