export const locale = {
  heading: 'Connect your device',
  subText: 'Connect a device, such as Fitbit or Garmin, with your Marathon Health account.',
  buttonText: 'Connect',
  actionText:
    'To connect Apple Health or Google Fit to your Marathon Health account, please download the Marathon Health app. ',
  footertext:
    'Connecting your device will send data (i.e. steps, sleep) to our portal. Incentives and challenges you are enrolled in will automatically be updated.',
  appStore: 'App Store',
  googlePlay: 'Google Play',
  get_on_app_store: 'Get it on App Store.',
  get_on_google_play: 'Get it on Google Play.',
};
