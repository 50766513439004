import { createAsyncThunk } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';
import { downLoadFromBlob } from '@common/utils';

import { ParamsWithDependentUserId } from '../../../types';
import { statusSelector } from './selectors';
import { SummaryItem, DownloadVisitSummariesParams } from './types';

export const getData = createAsyncThunk<SummaryItem[], ParamsWithDependentUserId>(
  'visit-summaries',
  async ({ dependentUserId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/visit-summaries',
        params: {
          dependentUserId,
        },
      });

      const res = await data.response;

      return res?.data || [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => statusSelector(getState() as RootState) !== StatusEnum.Pending,
  },
);

export const downloadVisitSummaries = createAsyncThunk<unknown, ParamsWithDependentUserId<DownloadVisitSummariesParams>>(
  'HealthSummary/visit-summaries-report',
  async ({ encounterId, dependentUserId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/reports/visit-summaries',
        responseType: 'blob',
        params: {
          dependentUserId,
          encounterId,
        },
      });

      const res = await data.response;
      const blob = res?.data;
      downLoadFromBlob(blob, 'application/pdf', `visit-summary-report-${encounterId}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
