const UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function sizeFormat(x: string | number) {
  let l = 0;
  let n = parseInt(String(x), 10) || 0;

  while (n >= 1024) {
    l += 1;
    n /= 1024;
  }

  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${UNITS[l]}`;
}
