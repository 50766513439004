import React from 'react';

import classNames from 'classnames/bind';

import { locale } from './locale';

import styles from './ViewSwitcherItem.module.css';

const cn = classNames.bind(styles);

type ViewSwitcherItemProps = {
  isCurrent: boolean;
  name: string;
};

export function ViewSwitcherItem({ name, isCurrent }: ViewSwitcherItemProps) {
  const title = `${isCurrent ? locale.viewing_as : locale.view_as}${name}`;

  return (
    <span
      className={cn('root', {
        'root--active': isCurrent,
      })}
    >
      <span className={cn('inner')} title={title}>
        {title}
      </span>
    </span>
  );
}
