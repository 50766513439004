export const locale = {
  call: 'call {{phone}}',
  contact_us: 'Contact Us By Phone',
  call_aria_label: 'Call {{name}} at {{phone}}',
  button_close_text: 'Close',
  default_clinic_name: 'Call {{name}}:',
  anywhere_clinic_name: 'Call Marathon Health - Anywhere:',
  looking_for_other: 'Looking for a different Health Center?',
  other_health_center_before_link: 'View ',
  health_center_locations_link: 'Health Center Locations',
  other_health_center_after_link: ' to contact your preferred Health Center.',
};
