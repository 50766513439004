import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import { getPatientEducation } from './actions';
import { PatientEducationState } from './types';

const initialState: PatientEducationState = {
  patientEducationRequest: USER_SPECIFIC_INITIAL_STATE,
  downloadStatus: StatusEnum.Uninitialized,
};

export const patientEducationSlice = createSlice({
  name: 'patientEducation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getPatientEducation,
      updateState: (state, updates) => {
        state.patientEducationRequest = {
          ...state.patientEducationRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.patientEducationRequest.userId,
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const patientEducationReducer = patientEducationSlice.reducer;
