export const getLoginStatus = (state: RootState) => state.login.loginStatus;
export const getLogoutStatus = (state: RootState) => state.login.logoutStatus;
export const getLoginError = (state: RootState) => state.login.error || '';
export const getLoginInfo = (state: RootState) => state.login.info;
export const getFailedAttempts = (state: RootState) => state.login.failedAttempts;
export const getBlockingTime = (state: RootState) => state.login.blockingTime;
export const getRouteSelector = (state: RootState) => state.route;
export const getImpersonationStatus = (state: RootState) => state.login.impersonation;
export const getLogoutOnceSelector = (state: RootState) => state.login.logoutOnce;
export const getImpersonationRedirectSelector = (state: RootState) => state.login.impersonationRedirect;
export const getImpersonationUserIdSelector = (state: RootState) => state.login.impersonationUserId;
export const getSalesForceImpersonationSelector = (state: RootState) => state.login.salesForceImpersonation;
export const getAdminReturnUrlSelector = (state: RootState) => state.login.adminReturnUrl;
export const samlReturnUrlSelector = (state: RootState) => state.login.samlReturnUrl;
export const samlRegistrationNoticeTokenSelector = (state: RootState) => state.login.samlRegistrationNoticeToken;
export const samlNavigationTypeSelector = (state: RootState) => state.login.samlNavigationType;
export const alreadyRegisteredSelector = (state: RootState) => state.login.alreadyRegistered;
