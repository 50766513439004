import { ChartProps } from 'react-chartjs-2';

import { browserTimeZone, formatInTimeZone } from '@common/utils';
import { getLocale } from '@common/utils/locale';

import { locale } from './locale';

export const formatLineChartAriaLabel = (
  data: ChartProps<'line'>['data'],
  options: ChartProps<'line'>['options'],
  startLabel?: string,
) => {
  const { labels: defaultValues, datasets } = data;
  const { data: activitiesData } = datasets[0];
  const title = (options?.scales?.yAxis?.title?.text || '') as string;

  let labelsWithValues: string[];
  let ariaLabel = startLabel ?? '';
  const values = (activitiesData?.map((activity) => (typeof activity === 'object' ? activity?.y : activity)) ||
    []) as number[];

  const min = Math.min(...values);
  const max = Math.max(...values);
  const hasDefaultValues = Array.isArray(defaultValues) && defaultValues.length > 0;

  ariaLabel += getLocale(locale.title, { title, min, max });

  if (hasDefaultValues) {
    labelsWithValues = defaultValues?.map((label, index) => `${label}: ${values[index]} ${title}`);
    ariaLabel += ` ${labelsWithValues.join(', ')}`;

    return ariaLabel;
  }

  const activitiesDates = (activitiesData?.map((activity) => (typeof activity === 'object' ? activity?.x : activity)) ||
    []) as number[];

  labelsWithValues = activitiesDates?.map((activityDate) => {
    const date = new Date(activityDate);
    const formattedDate = formatInTimeZone(date, browserTimeZone, 'MMMM dd, yyyy h:mm a');

    return `${formattedDate}: ${values[activitiesDates.indexOf(activityDate)]} ${title}`;
  });

  ariaLabel += ` ${labelsWithValues.join(', ')}`;

  return ariaLabel;
};
