import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { flippersSelector } from '../store';
import { FlipperType, GetFlipperValueType } from '../types';

export const useFlippers = (): GetFlipperValueType => {
  const flippers = useSelector(flippersSelector);

  return useCallback((flipper: FlipperType): boolean => flippers[flipper] ?? false, [flippers]);
};
