import { createAsyncThunk } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';

import { ParamsWithDependentUserId } from '../../../types';
import { statusSelector } from './selectors';
import { GoalsType } from './types';

export const getGoals = createAsyncThunk<Nullable<GoalsType>, ParamsWithDependentUserId>(
  'HealthSummary/goals',
  async ({ dependentUserId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/goals',
        params: {
          dependentUserId,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => statusSelector(getState() as RootState) !== StatusEnum.Pending,
  },
);
