import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import { downloadMedicationsReport, getMedications } from './actions';
import { MedicationsState } from './types';

const initialState: MedicationsState = {
  medicationsRequest: USER_SPECIFIC_INITIAL_STATE,
  downloadStatus: StatusEnum.Uninitialized,
};

export const medicationsSlice = createSlice({
  name: 'medications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getMedications,
      updateState: (state, updates) => {
        state.medicationsRequest = {
          ...state.medicationsRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.medicationsRequest.userId,
    });

    builder.addCase(downloadMedicationsReport.pending, (state) => {
      state.downloadStatus = StatusEnum.Pending;
    });
    builder.addCase(downloadMedicationsReport.fulfilled, (state) => {
      state.downloadStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadMedicationsReport.rejected, (state) => {
      state.downloadStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const medicationsReducer = medicationsSlice.reducer;
