/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { formatDate } from '@common/utils/dateTimeUtil';
import { gtmEvent, GtmEventsEnum } from '@modules/gtm';

import { AppointmentItemType } from '../types';
import {
  CancelAppointmentType,
  CheckDuplicateAppointmentsType,
  CheckVirtualProvidersType,
  SearchOpenAppointmentsType,
  ConfirmAndScheduleAppointmentType,
  AbandonAppointmentType,
} from './types';

export const getUpcomingList = createAsyncThunk('Appointment/appointments/upcoming', async (_, { rejectWithValue }) => {
  try {
    const data = fetch.get({ url: '/Appointment/appointments', params: { showPast: false } });
    const res = await data.response;

    return res?.data?.appointments ?? [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPastList = createAsyncThunk('Appointment/appointments/past', async (_, { rejectWithValue }) => {
  try {
    const data = fetch.get({ url: '/Appointment/appointments', params: { showPast: true } });
    const res = await data.response;

    return res?.data?.appointments ?? [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getVisitReasonCategories = createAsyncThunk(
  'Appointment/visit-reason-categories',
  async (
    { userId, providerType, virtualOnly }: { userId: number; providerType?: Nullable<string>; virtualOnly?: boolean },
    { rejectWithValue },
  ) => {
    try {
      let data;

      if (providerType) {
        data = fetch.post({
          url: '/Appointment/visit-reason-categories-by-provider-type',
          data: {
            user_id: userId,
            provider_types: [providerType],
            virtualOnly,
          },
        });
      } else {
        data = fetch.get({
          url: `/Appointment/visit-reason-categories/${userId}`,
          params: {
            virtualOnly: !!virtualOnly,
          },
        });
      }

      const res = await data.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCommonVisitReasons = createAsyncThunk(
  'Appointment/common-visit-reasons',
  async (
    { userId, employerId, providerTypes }: { userId: number; employerId: number; providerTypes?: Nullable<string[]> },
    { rejectWithValue },
  ) => {
    const uriEncodedParams = providerTypes ? `?providerTypes=${encodeURIComponent(JSON.stringify(providerTypes))}` : '';
    try {
      const data = fetch.get({
        url: `/Appointment/common-visit-reasons/${employerId}/${userId}${uriEncodedParams}`,
      });
      const res = await data.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSchedulableStates = createAsyncThunk(
  'Appointment/schedulable-states',
  async ({ userId, isTelephonic }: { userId: number; isTelephonic?: boolean }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: `/Appointment/schedulable-states/${userId}`,
        params: { telephonic: isTelephonic },
      });
      const res = await data.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const checkVirtualProviders = createAsyncThunk(
  '/Appointment/any-virtual-providers',
  async (data: CheckVirtualProvidersType, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: '/Appointment/any-virtual-providers',
        data,
      });
      const res = await responseData.response;

      return Boolean(res?.data?.any_virtual_providers);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const searchOpenAppointments = createAsyncThunk(
  'Appointment/open-appointments',
  async (
    { visitReason, start_date, calledFrom, ...data }: SearchOpenAppointmentsType,
    { rejectWithValue, ...rest },
  ) => {
    try {
      const responseData = fetch.post({
        url: '/Appointment/open-appointments',
        signal: rest.signal,
        data: {
          ...data,
          start_date: formatDate(start_date, 'yyyy-MM-dd'),
          visit_reason_id: Array.isArray(visitReason) ? visitReason[0]?.id : visitReason?.id,
        },
      });

      const res = await responseData.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const cancelAppointment = createAsyncThunk(
  'Appointment/cancel-appointment',
  async (
    data: CancelAppointmentType & { appointment: AppointmentItemType; details?: Nullable<string> },
    { rejectWithValue },
  ) => {
    try {
      const responseData = fetch.post({
        url: '/Appointment/cancel-appointment',
        data: {
          user_id: data.user_id,
          requested_by_user_id: data.requested_by_user_id,
          appointment_id: data.appointment_id,
          reason: data.reason,
          details: data.details,
        },
      });

      const res = await responseData.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const abandonAppointment = createAsyncThunk(
  'Appointment/abandon-appointment',
  async (data: AbandonAppointmentType, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: '/Appointment/abandon-appointment',
        data,
      });

      const res = await responseData.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const rescheduleAppointment = createAsyncThunk(
  'Appointment/reschedule-appointment',
  async (data: CancelAppointmentType, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: `/Appointment/reschedule-appointment`,
        data,
      });
      const res = await responseData.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const checkDuplicateAppointments = createAsyncThunk(
  'Appointment/check-duplicate-appointments',
  async (data: CheckDuplicateAppointmentsType, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: '/Appointment/check-duplicate-appointments',
        data,
      });
      const res = await responseData.response;

      return res?.data?.duplicate_appointments ?? false;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAnywhereNumber = createAsyncThunk(
  'Appointment/anywhere-number',
  async (userId: number, { rejectWithValue }) => {
    try {
      const responseData = fetch.get({
        url: `/Appointment/anywhere-number/${userId}`,
      });
      const res = await responseData.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const confirmAndScheduleAppointment = createAsyncThunk(
  'Appointment/confirm-and-schedule-appointment',
  async (data: ConfirmAndScheduleAppointmentType, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: `/Appointment/confirm-and-schedule-appointment`,
        data,
      });
      const res = await responseData.response;

      gtmEvent(GtmEventsEnum.confirmAndScheduleAppointment);

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getVirtualCheckInLink = createAsyncThunk(
  'Appointment/virtual-check-in-link',
  async ({ appointmentId, userId }: { appointmentId: number; userId?: number }, { rejectWithValue }) => {
    try {
      const responseData = fetch.get({
        url: '/Appointment/virtual-check-in-link',
        params: {
          appointmentId,
          dependentUserId: userId,
        },
      });

      const res = await responseData.response;

      return res?.data ? res.data.link : '';
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getVirtualVideoCallLink = createAsyncThunk(
  'Appointment/virtual-video-call-link',
  async ({ appointmentId, userId }: { appointmentId: number; userId?: number }, { rejectWithValue }) => {
    try {
      const responseData = fetch.get({
        url: '/Appointment/virtual-video-call-link',
        params: {
          appointmentId,
          dependentUserId: userId,
        },
      });

      const res = await responseData.response;

      return res?.data ? res.data.link : '';
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCustomTile = createAsyncThunk('CustomTile/custom-tile', async (_, { rejectWithValue }) => {
  try {
    const responseData = fetch.get({
      url: '/CustomTile/custom-tile',
      params: {
        tileType: 0,
      },
    });

    const res = await responseData.response;

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
