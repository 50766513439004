import { Action, ThunkDispatch, configureStore } from '@reduxjs/toolkit';

import { abandonAppointmentMiddleware } from '@common/middlewares/abandonAppointmentMiddleware';
import { userAlertsReducer } from '@modules/Alerts/store/store';
import { scheduleNewAppointmentReducer } from '@modules/Appointments/components/ScheduleAppointment/store/store';
import { appointmentsReducer } from '@modules/Appointments/store/store';
import { articlesReducer } from '@modules/Articles/store/store';
import { challengesReducer } from '@modules/Challenges/store/store';
import { connectMyDeviceReducer } from '@modules/ConnectMyDevice/store/store';
import { covid19ScreeningReducer } from '@modules/Covid19Screening/store/store';
import { defaultClinicPhoneReducer } from '@modules/DefaultClinicPhone/store/store';
import { editMessageReducer } from '@modules/EditMessage/store/store';
import { faqReducer } from '@modules/FAQ/store/store';
import { feedbackReducer } from '@modules/Feedback/store/store';
import { globalLockerReducer } from '@modules/GlobalLocker/store/store';
import { globalModalReducer } from '@modules/GlobalModal/store/store';
import { headerReducer } from '@modules/Header/store/store';
import { healthCenterLocationsReducer } from '@modules/HealthCenterLocations/store/store';
import { incentivesReducer } from '@modules/Incentives/store/store';
import { inviteMemberReducer } from '@modules/InviteMember/store/store';
import { loginReducer } from '@modules/Login/store';
import { medicationRefillsReducer } from '@modules/MedicationRefills/store/store';
import { messagesReducer } from '@modules/Messages/store/store';
import { allergiesReducer } from '@modules/MyHealth/components/Allergies/store/store';
import { careGuidelinesReducer } from '@modules/MyHealth/components/CareGuidelines/store/store';
import { healthDashboardReducer } from '@modules/MyHealth/components/Dashboard/store/store';
import { documentsReducer } from '@modules/MyHealth/components/Documents/store/store';
import { healthIssuesReducer } from '@modules/MyHealth/components/HealthIssues/store/store';
import { immunizationsReducer } from '@modules/MyHealth/components/Immunizations/store/store';
import { medicationsReducer } from '@modules/MyHealth/components/Medications/store/store';
import { goalsReducer } from '@modules/MyHealth/components/MyGoals/store/store';
import { patientEducationReducer } from '@modules/MyHealth/components/PatientEducation/store/store';
import { riskProfileReducer } from '@modules/MyHealth/components/RiskProfile/store/store';
import { screeningsReducer } from '@modules/MyHealth/components/Screenings/store/store';
import { testResultsReducer } from '@modules/MyHealth/components/TestResults/store/store';
import { visitSummariesReducer } from '@modules/MyHealth/components/VisitSummaries/store/store';
import { myHealthReducer } from '@modules/MyHealth/store/store';
import { networksReducer } from '@modules/Networks/store/store';
import { newsReducer } from '@modules/News/store/store';
import { nextAppointmentReducer } from '@modules/NextUppointment/store/store';
import { passwordRecoveryReducer } from '@modules/PasswordRecovery/store/store';
import { providerBioReducer } from '@modules/ProviderBio/store/store';
import { providersReducer } from '@modules/Providers/store/store';
import { questionnariesReducer } from '@modules/Questionnaries/store/store';
import { registrationReducer } from '@modules/Registration/store/store';
import { requestCallbackReducer } from '@modules/RequestCallbackModal/store/store';
import { workshopsReducer } from '@modules/Resources/components/Workshops/store/store';
import { snackbarReducer } from '@modules/Snackbar/store/store';
import { staticListReducer } from '@modules/StaticList/store/store';
import { supplementalWaiverReducer } from '@modules/SuplementalWaiver/store/store';
import { uploadDocumentsReducer } from '@modules/UploadDocumentModal/store/store';
import { validationApi } from '@modules/Validation/store/actions';
import { webinarLibraryReducer } from '@modules/WebinarLibrary/store/store';
import { WellnessLogReducer } from '@modules/WellnessLog/store/store';
import { categoriesReducer } from '@modules/categories/store/store';
import { flippersReducer } from '@modules/flippers/store/store';
import { globalWaiverReducer } from '@modules/globalWeaver/store/store';
import { routeReducer } from '@modules/route/store/store';
import { samlAuthReducer } from '@modules/samlAuth/store/store';
import { sharedDocumentsReducer } from '@modules/sharedDocuments/store/store';
import { userProfileReducer } from '@modules/userProfileData/store/store';

export const reducers = {
  articles: articlesReducer,
  appointments: appointmentsReducer,
  scheduleNewAppointment: scheduleNewAppointmentReducer,
  nextAppointment: nextAppointmentReducer,
  categories: categoriesReducer,
  challenges: challengesReducer,
  connectMyDevice: connectMyDeviceReducer,
  header: headerReducer,
  healthCenterLocations: healthCenterLocationsReducer,
  messages: messagesReducer,
  editMessage: editMessageReducer,
  flippers: flippersReducer,
  faqs: faqReducer,
  news: newsReducer,
  medicationRefills: medicationRefillsReducer,
  incentives: incentivesReducer,
  login: loginReducer,
  userAlerts: userAlertsReducer,
  userProfile: userProfileReducer,
  webinarLibrary: webinarLibraryReducer,
  wellnessLog: WellnessLogReducer,
  medications: medicationsReducer,
  allergies: allergiesReducer,
  patientEducation: patientEducationReducer,
  immunizations: immunizationsReducer,
  route: routeReducer,
  healthIssues: healthIssuesReducer,
  healthDashboard: healthDashboardReducer,
  screenings: screeningsReducer,
  myHealth: myHealthReducer,
  snackbar: snackbarReducer,
  supplementalWaiver: supplementalWaiverReducer,
  testResults: testResultsReducer,
  questionnaires: questionnariesReducer,
  staticList: staticListReducer,
  riskProfile: riskProfileReducer,
  covid19Screening: covid19ScreeningReducer,
  visitSummaries: visitSummariesReducer,
  documents: documentsReducer,
  workshops: workshopsReducer,
  goals: goalsReducer,
  careGuidelines: careGuidelinesReducer,
  globalLocker: globalLockerReducer,
  defaultClinicPhone: defaultClinicPhoneReducer,
  globalModal: globalModalReducer,
  passwordRecovery: passwordRecoveryReducer,
  networks: networksReducer,
  registration: registrationReducer,
  samlAuth: samlAuthReducer,
  feedback: feedbackReducer,
  providers: providersReducer,
  providerBio: providerBioReducer,
  requestCallback: requestCallbackReducer,
  sharedDocuments: sharedDocumentsReducer,
  uploadDocuments: uploadDocumentsReducer,
  globalWaiver: globalWaiverReducer,
  inviteMember: inviteMemberReducer,
  [validationApi.reducerPath]: validationApi.reducer,
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([abandonAppointmentMiddleware, validationApi.middleware]),
});

declare global {
  type RootState = ReturnType<typeof store.getState>;
}

declare global {
  type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
}
