import React, { useCallback, useEffect, useRef } from 'react';

import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

import { getMenuId, matchPath } from '@common/utils';
import { Menu, MenuItem, MenuProps } from '@components/Menu';
import { Typography } from '@components/Typography';
import { ShowCategory } from '@modules/categories';
import { GtmEventsEnum, gtmEvent } from '@modules/gtm';

import { TabProps, SubMenuItemType } from '../../horizontal-menu-types';
import { KEYBOARD_CLOSE_REASONS } from './constants';

import styles from './SubMenu.module.css';

export type SubMenuProps = {
  closeMenu: VoidFunction;
  menuAnchorEl: HTMLElement | null;
  open: boolean;
  tab: TabProps;
  onCloseByKeyboard: (anchorElement: HTMLElement | null) => unknown;
};

const cn = classNames.bind(styles);

export function SubMenu({ closeMenu, menuAnchorEl, open, tab, onCloseByKeyboard }: SubMenuProps) {
  const location = useLocation();

  const isMenuItemSelected = useCallback(
    (pathName: string): boolean => matchPath(location.pathname, pathName),
    [location.pathname],
  );

  const menuListRef = useRef<HTMLUListElement | null>(null);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const menuListElement = menuListRef.current;
      const eventTarget = event.target;

      if (menuListElement && !menuListElement.contains(eventTarget as Node)) {
        closeMenu();
      }
    };

    setTimeout(() => {
      window.addEventListener('click', handleOutsideClick);
    });

    return () => window.removeEventListener('click', handleOutsideClick);
  }, [closeMenu, open]);

  const cachedAnchorEl = useRef(menuAnchorEl);
  if (menuAnchorEl) {
    cachedAnchorEl.current = menuAnchorEl;
  }

  const handleClose = useCallback<NonNullable<MenuProps['onClose']>>(
    (event, reason) => {
      closeMenu();
      if (KEYBOARD_CLOSE_REASONS.includes(reason)) {
        onCloseByKeyboard(cachedAnchorEl.current);
      }
    },
    [closeMenu, onCloseByKeyboard],
  );

  const handleSubMenuClick = useCallback(
    (subMenuItem: SubMenuItemType) => {
      if (subMenuItem.gtmType) gtmEvent(GtmEventsEnum.click, { type: subMenuItem.gtmType });
      closeMenu();
    },
    [closeMenu],
  );

  if (!tab.subMenuItems || tab.subMenuItems.length === 0) {
    return null;
  }

  return (
    <Menu
      key={tab.id}
      id={getMenuId(tab.id)}
      anchorEl={cachedAnchorEl.current}
      className={cn('sub-menu')}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        className: cn('sub-menu__list'),
        ref: menuListRef,
      }}
    >
      {tab.subMenuItems.map((subMenuItem, i) => {
        const path = subMenuItem.to;
        const selected = isMenuItemSelected(path);

        return (
          <ShowCategory category={subMenuItem.label} key={subMenuItem.to}>
            <MenuItem
              to={path}
              autoFocus={i === 0}
              className={cn('sub-menu__menu-item', {
                'sub-menu__menu-item--select': selected,
              })}
              selected={selected}
              onClick={() => handleSubMenuClick(subMenuItem)}
            >
              <span>{subMenuItem.label}</span>
              {subMenuItem.subLabel && (
                <Typography variant="body1" className={cn('sub-menu__menu-item-sublabel')}>
                  {subMenuItem.subLabel}
                </Typography>
              )}
            </MenuItem>
          </ShowCategory>
        );
      })}
    </Menu>
  );
}
