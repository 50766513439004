export const locale = {
  cancel: 'Cancel',
  submit: 'Record Weight',
  record_another_entry: 'Save & Record Another Entry',
  header: 'Record Weight',

  field_labels: {
    date: 'Date',
    time: 'Time',
    weight: 'Weight',
  },
  adornments: {
    weight: 'lbs',
  },
  errors: {
    date: 'Date is required',
    weight: 'Weight must be greater than or equal to 20.',
  },
};
