import { ExtendedAxiosRequestConfig } from '@common/fetch';
import { matchPath } from '@common/utils';

import { TRACKING_PAGE } from './constants';

export const trackPageRequestsInterceptor = (requestConfig: ExtendedAxiosRequestConfig) => {
  const trackingPair = Object.entries(TRACKING_PAGE)?.find(([_, paths]) =>
    paths.some((path) => matchPath(path, window.location.pathname)),
  );

  if (trackingPair) {
    const [trackingPage] = trackingPair;

    return {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        'MH-Workflow': trackingPage,
      },
    };
  }

  return requestConfig;
};
