/* eslint-disable camelcase */
import { JOINED_ToU_PP } from '../constants';
import { MappedWaiver as Waiver } from '../store/types';

export const buildTermsOfUseAndPrivacyPolicy = (termsOfUse?: Waiver, privacyPolicy?: Waiver) => {
  if (termsOfUse && privacyPolicy) {
    return {
      title: `${termsOfUse.title} & ${privacyPolicy.title}`,
      body: `${termsOfUse?.body ?? ''}${termsOfUse?.body && privacyPolicy?.body ? '\n---\n' : ''}${privacyPolicy.body ?? ''}`,
      signature_required: termsOfUse.signature_required || privacyPolicy.signature_required,
      purpose: JOINED_ToU_PP,
    };
  }
};
