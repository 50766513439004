import { MailboxTypeEnum } from '../../constants';
import { Actions } from './constants';

export const locale = {
  sender: 'From: {{sender}}',
  recipient: 'To: {{recipients}}',
  sent: 'Sent: {{sent}}',
  sent_on: 'Sent on: {{sent}},',
  subject: 'Subject: {{subject}}',
  archived_at: 'Archived: {{archivedAt}}',
  no_messages: 'Choose message',
  back_button_title: 'Back to {{mailboxType}}',
  actions: {
    [Actions.Archive]: 'Archive',
    [Actions.Restore]: 'Restore',
    [Actions.Reply]: 'Reply',
    [Actions.Delete]: 'Delete',
    [Actions.Edit]: 'Edit',
  },
  mailbox_types: {
    [MailboxTypeEnum.Inbox]: 'Inbox',
    [MailboxTypeEnum.Sent]: 'Sent',
    [MailboxTypeEnum.Draft]: 'Draft',
    [MailboxTypeEnum.Archive]: 'Archive',
  },
  member_relation_team: 'Patient Support',
  message_content: 'Message content:',
};
