import { createAsyncThunk } from '@reduxjs/toolkit';

import { tokenService } from '@common/TokenService';
import { userProfileService } from '@common/UserProfileService';
import { fetch } from '@common/fetch';
import { getErrorMessage } from '@common/utils/getErrorMessage';
import { matchPasswordErrors } from '@common/utils/matchPasswordErrors';
import { enqueueSnackbar } from '@modules/Snackbar';
import { validateRecaptcha } from '@modules/recaptcha';

import { ResetErrorCodes } from '../constants';
import { ForgotPasswordFormValuesTypes } from '../types';
import { ResetPasswordPayloadType } from './types';

type ResetLinkData = ForgotPasswordFormValuesTypes & {
  recaptchaToken: string;
};

export const sendResetLink = createAsyncThunk(
  'PasswordRecovery/sendResetLink',
  async ({ recaptchaToken, ...data }: ResetLinkData, { rejectWithValue }) => {
    try {
      await validateRecaptcha(recaptchaToken);

      const responseData = fetch.post({
        url: '/Authentication/send-reset-link',
        skipAlert: true,
        data,
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'PasswordRecovery/resetPassword',
  async (data: ResetPasswordPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const responseData = fetch.post({
        url: '/Authentication/reset-password',
        skipAuth: true,
        skipAlert: true,
        data,
      });
      const res = await responseData.response;
      const wellnessId = res?.data?.user.wellness_id;
      tokenService.saveToken(res?.data.token, wellnessId);
      userProfileService.saveAuthUserData(res?.data, wellnessId);
      return res?.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = matchPasswordErrors(error?.response?.data?.error);

      if (error?.response?.data?.error_code !== ResetErrorCodes.ValidationError) {
        dispatch(
          enqueueSnackbar({
            message: message || getErrorMessage(error),
            options: {
              variant: 'error',
            },
          }),
        );
      }

      return rejectWithValue(error);
    }
  },
);

export const verifyResetToken = createAsyncThunk(
  'Authentication/verify-password-reset-token',
  async (token: string | undefined, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: '/Authentication/verify-password-reset-token',
        skipAuth: true,
        skipAlert: true,
        data: {
          token,
        },
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
