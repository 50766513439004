import { SortOrderEnum } from '@common/utils/tableUtils';

export const PROVIDERS_ON_PAGE = 6;

export enum OrderByIdsEnum {
  ProviderFirstName = 'firstName',
  ProviderLastName = 'lastName',
  HealthCenterName = 'healthCenterName',
  HealthCenterDistance = 'healthCenterDistance',
}

export enum FilterNamesEnum {
  ProviderTypes = 'providerTypes',
  Networks = 'networks',
  HealthCenters = 'healthCenters',
}

export const DEFAULT_SORT_BY = OrderByIdsEnum.ProviderFirstName;
export const DEFAULT_SORT_ORDER = SortOrderEnum.Asc;
