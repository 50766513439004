import { createSlice } from '@reduxjs/toolkit';
import { Location } from 'react-router';

import { resetStoreActions } from '@modules/reset';

type RouteState = {
  from: Nullable<Location>;
  to: Nullable<Location>;
};
const initialState: RouteState = {
  from: null,
  to: null,
};

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setRouteDetails(state: RouteState, action) {
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
  },
  extraReducers: {
    ...resetStoreActions(() => initialState),
  },
});

export const { setRouteDetails } = routeSlice.actions;
export const routeReducer = routeSlice.reducer;
