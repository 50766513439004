import { createSelector, Selector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';
import { GuidelineTypeEnum, GUIDELINE_TABS_LIST } from '../constants';
import { CareGuidelineType, GuidelineType } from './types';

const { dryDataSelector, currentUserStatusSelector } = createUserIdSpecificSelectors(
  (state) => state.careGuidelines.careGuidelinesRequest,
);

export const statusSelector = currentUserStatusSelector;
export const careGuidelinesSelector = createSelector(dryDataSelector, (data) => data ?? []);

const guidelinesTypeSelector = (_: unknown, { type }: { type: GuidelineTypeEnum }) => type;
const careGuidelinesNameSelector = (_: unknown, { name }: { name: string }) => name;
const guidelineNameSelector = (_: unknown, { guidelineName }: { guidelineName: string }) => guidelineName;
const guidelineIndexSelector = (_: unknown, { guidelineIndex }: { guidelineIndex: number }) => guidelineIndex;

export const careGuidelineByNameSelector: Selector<RootState, Nullable<CareGuidelineType>> = createSelector(
  careGuidelinesSelector,
  careGuidelinesNameSelector,
  (careGuidelines, name) => careGuidelines.find((careGuideline) => careGuideline.name === name),
);

export const guidelinesTabSelector: Selector<RootState, GuidelineTypeEnum[]> = createSelector(
  careGuidelineByNameSelector,
  (careGuideLine) => {
    const guidelines = (careGuideLine?.guidelines ?? {}) as UnknownObject<string>;

    return GUIDELINE_TABS_LIST.filter(
      (guideline) => (guidelines[guideline] as string)?.length > 0,
    ) as GuidelineTypeEnum[];
  },
);

export const guidelinesByNameTypeSelector: Selector<RootState, GuidelineType[]> = createSelector(
  careGuidelineByNameSelector,
  guidelinesTypeSelector,
  (careGuideline, type) => (careGuideline?.guidelines as unknown as UnknownObject<GuidelineType[]>)?.[type] ?? [],
);

export const guidelineDetailsSelector: Selector<RootState, Nullable<GuidelineType>> = createSelector(
  guidelinesByNameTypeSelector,
  guidelineNameSelector,
  guidelineIndexSelector,
  (guidelines, guidelineName, guidelineIndex) =>
    guidelines.find((guideline, index) => guideline.name === guidelineName && index === guidelineIndex),
);
