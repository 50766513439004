import { useDispatch } from 'react-redux';

import { userProfileService } from '@common/UserProfileService';
import { useIgnoreEffectDeps } from '@common/hooks';

import { setProfileFromStorage } from '../store';

export function usePersistUserProfile() {
  const dispatch = useDispatch();

  useIgnoreEffectDeps(() => {
    const userProfileDataFromStorage = userProfileService.getUserProfile();

    if (userProfileDataFromStorage) {
      dispatch(setProfileFromStorage(userProfileDataFromStorage));
    }
  }, []);
}
