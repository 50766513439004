import React, { ReactNode } from 'react';

import FormHelperTextMaterial, { FormHelperTextProps } from '@mui/material/FormHelperText';
import classNames from 'classnames/bind';

import { SvgIcon } from '@components/SvgIcon';

import styles from './FormHelperText.module.css';

export type Props = {
  error?: boolean;
  helperText?: ReactNode;
} & Pick<FormHelperTextProps, 'children' | 'className' | 'id'>;

const cn = classNames.bind(styles);

export function FormHelperText({ className, error = false, helperText, id }: Props) {
  if (!helperText) {
    return null;
  }

  return (
    <FormHelperTextMaterial
      className={cn('form-helper-text', className, {
        'form-helper-text--error': error,
      })}
      id={id}
    >
      {error && <SvgIcon icon="warningOutline" className={cn('form-helper-text__icon')} />}

      {helperText}
    </FormHelperTextMaterial>
  );
}
