import { MeasurementEnum } from '../../constants';
import { locale } from './locale';

export const MEASUREMENT_TYPES = [
  {
    title: locale.measurements[MeasurementEnum.Hours],
    shortTitle: locale.measurementsShort[MeasurementEnum.Hours],
    yScaleTitle: locale.measurementsYScaleTitle[MeasurementEnum.Hours],
    type: MeasurementEnum.Hours,
  },
];
