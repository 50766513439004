import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { EmrDocumentDetailType } from '@modules/getDocuments';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import {
  addDocument,
  downloadDocument,
  downloadEmrDocument,
  getDocuments,
  removeDocument,
  getEmrDocumentsList,
} from './actions';
import { DocumentsState } from './types';

const initialState: DocumentsState = {
  documentsRequest: USER_SPECIFIC_INITIAL_STATE,
  documentsRequestTotal: null,
  addStatus: StatusEnum.Uninitialized,
  documentLoadingStatus: StatusEnum.Uninitialized,
  removeStatus: StatusEnum.Uninitialized,
  emrToDownload: null,
  emrDocumentsList: [],
  emrDocumentsListStatus: StatusEnum.Uninitialized,
  showDeleteDocumentModal: {
    show: false,
    documentId: null,
  },
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    clearAddStatus(state) {
      state.addStatus = StatusEnum.Uninitialized;
    },
    setEmrToDownload(state, action: PayloadAction<Nullable<EmrDocumentDetailType>>) {
      state.emrToDownload = action.payload;
    },

    resetEmr(state) {
      state.emrToDownload = initialState.emrToDownload;
      state.emrDocumentsList = initialState.emrDocumentsList;
      state.emrDocumentsListStatus = initialState.emrDocumentsListStatus;
    },

    setShowDeleteModal: (state, action) => {
      state.showDeleteDocumentModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getDocuments,
      updateState: (state, updates) => {
        state.documentsRequest = {
          ...state.documentsRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.documentsRequest.userId,
    });

    builder.addCase(downloadDocument.fulfilled, (state: DocumentsState) => {
      state.documentLoadingStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadDocument.rejected, (state: DocumentsState) => {
      state.documentLoadingStatus = StatusEnum.Rejected;
    });
    builder.addCase(downloadDocument.pending, (state: DocumentsState) => {
      state.documentLoadingStatus = StatusEnum.Pending;
    });

    builder.addCase(downloadEmrDocument.fulfilled, (state: DocumentsState) => {
      state.documentLoadingStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(downloadEmrDocument.rejected, (state: DocumentsState) => {
      state.documentLoadingStatus = StatusEnum.Rejected;
    });
    builder.addCase(downloadEmrDocument.pending, (state: DocumentsState) => {
      state.documentLoadingStatus = StatusEnum.Pending;
    });

    builder.addCase(getEmrDocumentsList.pending, (state: DocumentsState) => {
      state.emrDocumentsListStatus = StatusEnum.Pending;
    });
    builder.addCase(getEmrDocumentsList.fulfilled, (state: DocumentsState, action) => {
      state.emrDocumentsListStatus = StatusEnum.Fulfilled;
      state.emrDocumentsList = action.payload;
    });
    builder.addCase(getEmrDocumentsList.rejected, (state: DocumentsState) => {
      state.emrDocumentsListStatus = StatusEnum.Rejected;
    });

    builder.addCase(addDocument.pending, (state: DocumentsState) => {
      state.addStatus = StatusEnum.Pending;
    });
    builder.addCase(addDocument.fulfilled, (state: DocumentsState) => {
      state.addStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(addDocument.rejected, (state: DocumentsState) => {
      state.addStatus = StatusEnum.Rejected;
    });

    builder.addCase(removeDocument.pending, (state: DocumentsState) => {
      state.removeStatus = StatusEnum.Pending;
    });
    builder.addCase(removeDocument.fulfilled, (state: DocumentsState, action) => {
      const {
        documentsRequest: { data },
      } = current(state);
      const deletedDocumentId = action.meta.arg;

      state.documentsRequest.data = {
        models: data?.models?.filter((document) => document.id !== deletedDocumentId) ?? null,
        total_record_count: Number(data?.total_record_count) - 1,
      };

      state.removeStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(removeDocument.rejected, (state: DocumentsState) => {
      state.removeStatus = StatusEnum.Rejected;
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const documentsReducer = documentsSlice.reducer;
export const { clearAddStatus, setEmrToDownload, resetEmr, setShowDeleteModal } = documentsSlice.actions;
