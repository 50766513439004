export const locale = {
  password_errors: {
    must_have_at_least_8_characters: '- password must have at least 8 characters',
    must_have_upper_and_lower_characters: '- password must have upper and lower characters',
    must_have_at_least_one_number: '- password must have at least one number',
    must_have_no_match_to_email: '- password must have no match to email',
    must_have_no_match_to_username: '- password must have no match to username',
    must_have_no_match_to_previous_password: '- password must have no match to previous password',
    must_have_no_common_password_matches: '- password must have no common password matches',
  } as PartialRecord<string, string>,
};
