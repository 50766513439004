import { createSelector } from 'reselect';

import { PATHS } from '@common/routes';
import { filterMenuItemsByFlippers, filterMenuItemsByUserId } from '@common/utils/filterMenuItems';
import { isSourceUserAndSelectedFamilyMemberSimilarSelector } from '@modules/MyHealth/store';
import { FlipperType, HideFlippersEnum } from '@modules/flippers';
import { getIsRestrictedSelector } from '@modules/userProfileData';

import { TAB_LIST, VIEW_APPOINTMENTS, SCHEDULE_AND_VIEW_APPOINTMENTS } from '../constants';
import { locale } from '../locale';
import { MenuConfigItem } from '../types';

export const mainMenuOpenSelector = (state: RootState) => state.header.mainMenuOpen;
export const getFlippersSelector = (state: RootState) => state.flippers.flippers;
export const getHcbbUrlSelector = (state: RootState) => state.userProfile.hcbbUrl;
export const getHideChallengesSelector = (state: RootState) => state.header.hideChallenges;
export const getHideIncentivesSelector = (state: RootState) => state.header.hideIncentives;

export const getVisibleMenuItems = createSelector(
  getFlippersSelector,
  getHcbbUrlSelector,
  getIsRestrictedSelector,
  isSourceUserAndSelectedFamilyMemberSimilarSelector,
  getHideChallengesSelector,
  getHideIncentivesSelector,
  // eslint-disable-next-line max-params
  (flippers, hcbbUrl, isRestricted, isSourceUserAndSelectedFamilyMemberSimilar, hideChallenges, hideIncentives) => {
    const getFlipperValue = (flipper: FlipperType): boolean => flippers[flipper] ?? false;
    const hideWellnessLogsButton = getFlipperValue(HideFlippersEnum.hide_wellness_logs);
    const hideWellnessAndIncentives = hideChallenges && hideIncentives && hideWellnessLogsButton;
    const filteredMenuItems = filterMenuItemsByFlippers(
      filterMenuItemsByUserId(TAB_LIST, isSourceUserAndSelectedFamilyMemberSimilar),
      getFlipperValue,
    );

    const hideAppointmentButtons = getFlipperValue(HideFlippersEnum.hide_appointment_buttons);

    return filteredMenuItems.reduce((menuItemsAcc: (MenuConfigItem & { id: number })[], menuItem) => {
      const mappedMenuItem = {
        ...menuItem,
        subMenuItems: menuItem.subMenuItems?.map((item) => ({ ...item })),
      };
      const { to, subMenuItems } = mappedMenuItem;
      // Incentives and Wellness Tab mapping
      if (to === PATHS.WELLNESS.ROOT && hideWellnessAndIncentives) return menuItemsAcc;

      if (to === PATHS.WELLNESS.ROOT && subMenuItems) {
        if (hideIncentives) mappedMenuItem.label = locale.links.wellness.root_condensed;

        mappedMenuItem.subMenuItems = subMenuItems.filter((subMenuItem) => {
          switch (subMenuItem.to) {
            case PATHS.WELLNESS.CHALLENGES:
              return !hideChallenges;
            case PATHS.WELLNESS.INCENTIVES:
              return !hideIncentives;
            case PATHS.WELLNESS.WELLNESS_LOGS:
              return !hideWellnessLogsButton;
            default:
              return true;
          }
        });
      }

      // Resource Tab mapping
      if (to === PATHS.RESOURCES.ROOT && subMenuItems) {
        const subMenuFiltered = subMenuItems.filter(
          (subMenuItem: { to: string }) => !(subMenuItem.to === PATHS.RESOURCES.BLUEBOOK && !hcbbUrl),
        );

        if (subMenuFiltered?.length === 1) return menuItemsAcc;

        mappedMenuItem.subMenuItems = subMenuFiltered;
      }

      // Appointments Tab mapping
      if (menuItem.to === PATHS.APPOINTMENTS.ROOT && menuItem.subMenuItems) {
        mappedMenuItem.subMenuItems = [
          ...(hideAppointmentButtons ? [VIEW_APPOINTMENTS] : [SCHEDULE_AND_VIEW_APPOINTMENTS]),
          ...menuItem.subMenuItems.filter((subMenuItem) =>
            subMenuItem.to === PATHS.APPOINTMENTS.SCHEDULE_APPOINTMENT ? !isRestricted : true,
          ),
        ];
      }

      menuItemsAcc.push(mappedMenuItem);
      return menuItemsAcc;
    }, []);
  },
);
