import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { GlobalWaiverPurposes } from '../constants';
import { requiredAcknowledgments } from '../store';
import { MappedWaiver as Waiver } from '../store/types';
import { buildTermsOfUseAndPrivacyPolicy } from '../utils/build-terms-of-use-and-privay-policy';

type Waivers = {
  waivers: Waiver[];
  hasJoinedToU_PP: boolean;
};

export const useGlobalWaivers = (purposes: GlobalWaiverPurposes[]) => {
  const acknowledgments = useSelector((state: RootState) => requiredAcknowledgments(state, purposes));
  const [requiredWaivers, setRequiredWaivers] = useState<Waiver[]>([]);

  useEffect(() => {
    if (requiredWaivers.length === 0 && !!acknowledgments?.length) {
      const termsOfUse = acknowledgments.find((ack) => ack.purpose === GlobalWaiverPurposes.TermsOfUse);
      const privacyPolicy = acknowledgments.find((ack) => ack.purpose === GlobalWaiverPurposes.PrivacyPolicy);
      const joinedToUData = buildTermsOfUseAndPrivacyPolicy(termsOfUse, privacyPolicy);
      const data: Waivers = acknowledgments.reduce(
        (data, waiver) => {
          /** Exclusion for Terms of Use and privacy Popicy: should be displayed together if both are required */
          if ((waiver === termsOfUse || waiver === privacyPolicy) && joinedToUData) {
            return {
              waivers: data.hasJoinedToU_PP ? [...data.waivers] : [...data.waivers, joinedToUData as Waiver],
              hasJoinedToU_PP: true,
            };
          }

          return { waivers: [...data.waivers, waiver], hasJoinedToU_PP: data.hasJoinedToU_PP };
        },
        { waivers: [], hasJoinedToU_PP: false } as Waivers,
      );

      setRequiredWaivers(data.waivers);
    }
  }, [acknowledgments, requiredWaivers]);

  return {
    acknowledgments,
    requiredWaivers,
  };
};
