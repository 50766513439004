import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@common/routes';
import { EligibilitiesType } from '@common/types';
import {
  activeEligibilitiesSelector,
  currentEligibilitySelector,
  changeCurrentEligibilityStart,
} from '@modules/userProfileData';

import { MenuItemType } from '../../types';
import { ViewSwitcherItem } from './ViewSwitcherItem';

type ViewSwitcherParams = {
  onViewSwitch?: VoidFunction;
};

export const useViewSwitcherMenuitems = ({ onViewSwitch }: ViewSwitcherParams = {}): Array<MenuItemType> => {
  const currentEligibility = useSelector(currentEligibilitySelector);
  const activeEligibilities = useSelector(activeEligibilitiesSelector);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const viewChanger = useCallback(
    (activeEligibility: EligibilitiesType) => {
      if (activeEligibility.id !== currentEligibility?.id) {
        navigate(ROUTES.MAIN_PAGE);
        onViewSwitch?.();
        dispatch(changeCurrentEligibilityStart(activeEligibility.id));
      }
    },
    [dispatch, currentEligibility, onViewSwitch, navigate],
  );

  return useMemo(
    () =>
      activeEligibilities.map((activeEligibility: EligibilitiesType) => {
        const isCurrent = activeEligibility.id === currentEligibility?.id;

        return {
          grouped: true,
          action: () => viewChanger(activeEligibility),
          component: <ViewSwitcherItem isCurrent={isCurrent} name={activeEligibility.employer.name} />,
        };
      }),
    [activeEligibilities, currentEligibility, viewChanger],
  );
};
