import React from 'react';

import ListItemSecondaryActionMaterial, {
  ListItemSecondaryActionProps as ListItemSecondaryActionPropsMaterial,
} from '@mui/material/ListItemSecondaryAction';

export type ListItemSecondaryActionProps = Pick<ListItemSecondaryActionPropsMaterial, 'children' | 'className'>;

export function ListItemSecondaryAction({ className, children }: ListItemSecondaryActionProps) {
  return <ListItemSecondaryActionMaterial className={className}>{children}</ListItemSecondaryActionMaterial>;
}
