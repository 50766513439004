import { createSelector } from 'reselect';

import { GlobalWaiverPurposes } from '../constants';

const globalWaiverSelector = (state: RootState) => state.globalWaiver;
const isImpersonationSelector = (state: RootState) => state.login.impersonation;
const purposeSelector = (_: unknown, { purpose }: { purpose: GlobalWaiverPurposes }) => purpose;
export const statusByPurposeSelector = createSelector(
  globalWaiverSelector,
  purposeSelector,
  (globalWaiver, purpose) => globalWaiver[purpose].status,
);
export const signedByPurposeSelector = createSelector(
  globalWaiverSelector,
  purposeSelector,
  (globalWaiver, purpose) => globalWaiver[purpose].signed,
);

export const forceRunByPurposeSelector = createSelector(
  globalWaiverSelector,
  purposeSelector,
  (globalWaiver, purpose) => globalWaiver[purpose].forceRun,
);

export const dataByPurposeSelector = createSelector(
  globalWaiverSelector,
  purposeSelector,
  (globalWaiver, purpose) => globalWaiver[purpose].data,
);

export const contentByPurposeSelector = createSelector(dataByPurposeSelector, (data) => ({
  body: data?.supplemental_waiver?.body,
  title: data?.supplemental_waiver?.title,
}));

export const idByPurposeSelector = createSelector(dataByPurposeSelector, (data) => data?.supplemental_waiver?.id);

export const isAcknowledgeByPurposeSelector = createSelector(dataByPurposeSelector, (data) => data?.is_acknowledged);

export const requiredByPurposeSelector = createSelector(
  signedByPurposeSelector,
  isAcknowledgeByPurposeSelector,
  forceRunByPurposeSelector,
  isImpersonationSelector,
  (signed, isAcknowledge, forceRun, impersonation) =>
    !signed && (isAcknowledge === false || forceRun) && !impersonation,
);

export const globalWaiversSelectorByPurposes = (state: RootState, purposes: GlobalWaiverPurposes[]) =>
  purposes.map((purpose) => state.globalWaiver[purpose]);

export const requiredAcknowledgments = createSelector(
  globalWaiversSelectorByPurposes,
  isImpersonationSelector,
  (globalWaivers, impersonation) =>
    globalWaivers
      .filter(({ signed, data, forceRun }) => !signed && data && (!data.is_acknowledged || forceRun) && !impersonation)
      .map(({ data }) => ({
        title: data?.supplemental_waiver?.title,
        body: data?.supplemental_waiver?.body,
        signature_required: data?.supplemental_waiver?.is_signature_required,
        purpose: data?.supplemental_waiver?.purpose,
        waiverId: data?.supplemental_waiver?.id,
      })),
);
