import { ThunkMiddleware } from '@reduxjs/toolkit';

import { ROUTES, PATHS } from '@common/routes';
import { setAbandonmentOpen, shouldShowAbandonSelector } from '@modules/Appointments/store';
import { logout, logoutStart } from '@modules/Login';
import { changeCurrentEligibility, changeCurrentEligibilityStart } from '@modules/userProfileData';

export const abandonAppointmentMiddleware: ThunkMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const { dispatch, getState } = store;
  const state = getState();
  const shouldShowAbandon = !sessionStorage.getItem('impersonation') && shouldShowAbandonSelector(state);
  const isAppointmentPage = window.location.pathname.includes(ROUTES.APPOINTMENTS.ROOT);
  const isAppointmentScheduledPage = window.location.pathname.includes(PATHS.APPOINTMENTS.SCHEDULED);
  const isProvidersPage = window.location.pathname.includes(PATHS.APPOINTMENTS.PROVIDERS.ROOT);

  // TODO set historyBlocked to store and make this middleware more generic
  // Logout

  if (logoutStart.match(action) && action.payload) {
    if (isAppointmentPage && !isAppointmentScheduledPage && !isProvidersPage && shouldShowAbandon) {
      dispatch(setAbandonmentOpen(true));

      return result;
    }

    dispatch(logout(action.payload));
  }

  // Change Current Eligibility
  if (changeCurrentEligibilityStart.match(action) && action.payload) {
    if (isAppointmentPage && shouldShowAbandon) {
      dispatch(setAbandonmentOpen(true));

      return result;
    }

    dispatch(changeCurrentEligibility(action.payload));
  }

  return result;
};
