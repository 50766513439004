import { createSelector, createStructuredSelector } from 'reselect';

import { MeasurementEnum, WellnessAdditionalTypesEnum, WellnessTypesEnum } from '../constants';
import { WellnessTypesWithAdditional } from '../types';

export const getRequestLogStatus = (type: WellnessTypesWithAdditional) => (state: RootState) =>
  state.wellnessLog[type].requestLogStatus;
export const getRemoveStatus = (type: WellnessTypesWithAdditional) => (state: RootState) =>
  state.wellnessLog[type].removeStatus;
const getSummaryMonth = (type: WellnessTypesWithAdditional) => (state: RootState) =>
  state.wellnessLog[type].summaryMonth;
const getSummaryMinutes = (type: WellnessTypesWithAdditional) => (state: RootState) =>
  state.wellnessLog[type].summaryMinutes;
const getSummaryData = (type: WellnessTypesWithAdditional) => (state: RootState) => state.wellnessLog[type].summaryData;
const getEditModalVisible = (type: WellnessTypesWithAdditional) => (state: RootState) =>
  state.wellnessLog[type].editModalVisible;
const getSelectedItem = (type: WellnessTypesWithAdditional) => (state: RootState) =>
  state.wellnessLog[type].selectedItem;
export const getSaveFormStatus = (type: WellnessTypesWithAdditional) => (state: RootState) =>
  state.wellnessLog[type].saveFormStatus;
export const getItemsSelector = (type: WellnessTypesWithAdditional) => (state: RootState) =>
  state.wellnessLog[type].items;
const getExerciseTypes = (state: RootState) => state.wellnessLog.exerciseTypes;
const getExerciseTypesStatus = (state: RootState) => state.wellnessLog.exerciseTypesStatus;
export const getCurrentPage = (type: WellnessTypesWithAdditional) => (state: RootState) =>
  state.wellnessLog[type].currentPage;

export const getSummaryByMeasurement = createSelector(
  getSummaryMinutes(WellnessTypesEnum.Fitness),
  getSummaryData(WellnessTypesEnum.Fitness),
  (summaryMinutes, summaryData) => ({
    [MeasurementEnum.Minutes]: summaryMinutes.map((minute) => Math.round(minute)),
    [MeasurementEnum.Miles]: summaryData,
  }),
);

export const fitnessDataSelector = createStructuredSelector({
  requestLogStatus: getRequestLogStatus(WellnessTypesEnum.Fitness),
  removeStatus: getRemoveStatus(WellnessTypesEnum.Fitness),
  summaryMonth: getSummaryMonth(WellnessTypesEnum.Fitness),
  exerciseTypesStatus: getExerciseTypesStatus,
  summaryByMeasurement: getSummaryByMeasurement,
});

export const fitnessRecordActivitySelector = createStructuredSelector({
  editModalVisible: getEditModalVisible(WellnessTypesEnum.Fitness),
  selectedItem: getSelectedItem(WellnessTypesEnum.Fitness),
  exerciseTypes: getExerciseTypes,
  exerciseTypesStatus: getExerciseTypesStatus,
  saveFormStatus: getSaveFormStatus(WellnessTypesEnum.Fitness),
});

export const sleepRecordActivitySelector = createStructuredSelector({
  editModalVisible: getEditModalVisible(WellnessTypesEnum.Sleep),
  selectedItem: getSelectedItem(WellnessTypesEnum.Sleep),
  saveFormStatus: getSaveFormStatus(WellnessTypesEnum.Sleep),
});

export const stepsRecordActivitySelector = createStructuredSelector({
  editModalVisible: getEditModalVisible(WellnessTypesEnum.Steps),
  selectedItem: getSelectedItem(WellnessTypesEnum.Steps),
  saveFormStatus: getSaveFormStatus(WellnessTypesEnum.Steps),
});

export const fitnessAndStepsSelector = createStructuredSelector({
  editModalVisible: getEditModalVisible(WellnessAdditionalTypesEnum.FitnessAndSteps),
  saveFormStatus: getSaveFormStatus(WellnessAdditionalTypesEnum.FitnessAndSteps),
});

export const waterRecordActivitySelector = createStructuredSelector({
  editModalVisible: getEditModalVisible(WellnessTypesEnum.Water),
  selectedItem: getSelectedItem(WellnessTypesEnum.Water),
  saveFormStatus: getSaveFormStatus(WellnessTypesEnum.Water),
});

export const weightRecordActivitySelector = createStructuredSelector({
  editModalVisible: getEditModalVisible(WellnessTypesEnum.Weight),
  selectedItem: getSelectedItem(WellnessTypesEnum.Weight),
  saveFormStatus: getSaveFormStatus(WellnessTypesEnum.Weight),
});

export const sleepDataSelector = createStructuredSelector({
  removeStatus: getRemoveStatus(WellnessTypesEnum.Sleep),
  requestLogStatus: getRequestLogStatus(WellnessTypesEnum.Sleep),
  summaryMonth: getSummaryMonth(WellnessTypesEnum.Sleep),
  summaryData: getSummaryData(WellnessTypesEnum.Sleep),
});

export const stepsDataSelector = createStructuredSelector({
  removeStatus: getRemoveStatus(WellnessTypesEnum.Steps),
  requestLogStatus: getRequestLogStatus(WellnessTypesEnum.Steps),
  summaryMonth: getSummaryMonth(WellnessTypesEnum.Steps),
  summaryData: getSummaryData(WellnessTypesEnum.Steps),
});

export const waterDataSelector = createStructuredSelector({
  removeStatus: getRemoveStatus(WellnessTypesEnum.Water),
  requestLogStatus: getRequestLogStatus(WellnessTypesEnum.Water),
  summaryMonth: getSummaryMonth(WellnessTypesEnum.Water),
  summaryData: getSummaryData(WellnessTypesEnum.Water),
});

export const weightDataSelector = createStructuredSelector({
  requestLogStatus: getRequestLogStatus(WellnessTypesEnum.Weight),
  removeStatus: getRemoveStatus(WellnessTypesEnum.Weight),
});

export const mostResentByTypeSelector = (type: WellnessTypesWithAdditional) =>
  createSelector(
    getItemsSelector(type),
    (fitnessItems) => [...fitnessItems].sort((a, b) => Date.parse(b.recorded_at) - Date.parse(a.recorded_at))[0] ?? {},
  );

export const mostResentSelector = createStructuredSelector({
  [WellnessTypesEnum.Fitness]: mostResentByTypeSelector(WellnessTypesEnum.Fitness),
  [WellnessTypesEnum.Steps]: mostResentByTypeSelector(WellnessTypesEnum.Steps),
  [WellnessTypesEnum.Sleep]: mostResentByTypeSelector(WellnessTypesEnum.Sleep),
  [WellnessTypesEnum.Water]: mostResentByTypeSelector(WellnessTypesEnum.Water),
  [WellnessTypesEnum.Weight]: mostResentByTypeSelector(WellnessTypesEnum.Weight),
});
