import { useSelector } from 'react-redux';
import { Location, matchPath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { tokenService } from '@common/TokenService';
import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { ROUTES } from '@common/routes';
import { useGlobalLocker } from '@modules/GlobalLocker';
import { useSamlAuth } from '@modules/samlAuth';

import { IGNORE_TO_REDIRECT_URLS } from '../constants';
import {
  getImpersonationRedirectSelector,
  getImpersonationUserIdSelector,
  getLoginStatus,
  getLogoutOnceSelector,
  getRouteSelector,
} from '../store';

const needToIgnorePreviousPath = (pathname: string) =>
  !!IGNORE_TO_REDIRECT_URLS.find((path) => matchPath({ path }, pathname));

const getRouteToNavigate = (route: { from: Nullable<Location>; to: Nullable<Location> }, logoutOnce: boolean) => {
  if (route?.from?.pathname && !needToIgnorePreviousPath(route?.from?.pathname) && !logoutOnce) {
    return `${route.from.pathname}${route.from.search}`;
  }

  return ROUTES.MAIN_PAGE;
};

export const useLoginRedirect = () => {
  const loginStatus = useSelector(getLoginStatus);
  const logoutOnce = useSelector(getLogoutOnceSelector);

  const impersonationRedirect = useSelector(getImpersonationRedirectSelector);
  const impersonationUserId = useSelector(getImpersonationUserIdSelector);

  const route = useSelector(getRouteSelector);
  const navigate = useNavigate();

  const { isSamlAuthAvailable, callSamlAuth } = useSamlAuth();

  useIgnoreEffectDeps(() => {
    if (loginStatus === StatusEnum.Fulfilled && tokenService.getToken()) {
      if (isSamlAuthAvailable) {
        callSamlAuth();
      } else {
        navigate(getRouteToNavigate(route, logoutOnce));
      }
    }
  }, [loginStatus]);

  useGlobalLocker(loginStatus === StatusEnum.Pending || (!!impersonationUserId && impersonationRedirect));
};
