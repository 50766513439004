import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { ColorEnum, LinkButton } from '@components/Link';
import { getModalTypeSelector, ModalTypeEnum, setModal } from '@modules/GlobalModal';

import { locale } from './locale';

import styles from './Footer.module.css';

const cn = classNames.bind(styles);

type Props = {
  className?: string;
};

const GLOBAL_MODAL_ID = 'global_modal';

export function Footer({ className }: Props) {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalTypeSelector);

  const handleOpenModal = useCallback(
    (type: ModalTypeEnum) => {
      dispatch(setModal(type));
    },
    [dispatch],
  );

  return (
    <footer className={cn('footer', className)}>
      <LinkButton
        color={ColorEnum.GRAY_20}
        className={cn('footer__link')}
        onClick={() => handleOpenModal(ModalTypeEnum.AccessibilityStatement)}
        aria-haspopup="dialog"
        aria-controls={modalType ? GLOBAL_MODAL_ID : undefined}
      >
        {locale.accessibility_statement}
      </LinkButton>
      <LinkButton
        color={ColorEnum.GRAY_20}
        className={cn('footer__link')}
        onClick={() => handleOpenModal(ModalTypeEnum.TermsOfUse)}
        aria-haspopup="dialog"
        aria-controls={modalType ? GLOBAL_MODAL_ID : undefined}
      >
        {locale.terms_of_use}
      </LinkButton>
      <LinkButton
        className={cn('footer__link')}
        onClick={() => handleOpenModal(ModalTypeEnum.PrivacyPolicy)}
        aria-haspopup="dialog"
        aria-controls={modalType ? GLOBAL_MODAL_ID : undefined}
        color={ColorEnum.GRAY_20}
      >
        {locale.privacy_policy}
      </LinkButton>
    </footer>
  );
}
