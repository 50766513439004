import React, { ForwardedRef } from 'react';

import { useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';

import { WellnessTypesEnum } from '../../constants';
import { stepsDataSelector, getItemsSelector } from '../../store';
import { Dashboard, useDashboard } from '../Dashboard';
import { MEASUREMENT_TYPES } from './constants';
import { locale } from './locale';

type StepsDashboardComponentProps = {
  setRef: ForwardedRef<HTMLDivElement>;
};

export function StepsDashboardComponent({ setRef }: StepsDashboardComponentProps) {
  const { requestLogStatus, summaryData, summaryMonth, removeStatus } = useSelector(stepsDataSelector);
  const items = useSelector(getItemsSelector(WellnessTypesEnum.Steps));

  const { chartData, handleHistoryItemClick, handleRecordButtonClick, handleDeleteItemClick } = useDashboard({
    summaryMonth,
    summaryData,
    type: WellnessTypesEnum.Steps,
  });

  return (
    <div ref={setRef}>
      <Dashboard
        type={WellnessTypesEnum.Steps}
        heading={locale.dashboard_title}
        chartTitle={locale.title}
        data={chartData}
        measurements={MEASUREMENT_TYPES}
        selectedMeasurement={MEASUREMENT_TYPES[0]}
        historyItems={items}
        historyTitle={locale.history_title}
        loading={
          [StatusEnum.Pending, StatusEnum.Uninitialized].includes(requestLogStatus) ||
          removeStatus === StatusEnum.Pending
        }
        recordButtonText={locale.record_activity}
        onRecordButtonClick={handleRecordButtonClick}
        onEditItemClick={handleHistoryItemClick}
        onDeleteItemClick={handleDeleteItemClick}
      />
    </div>
  );
}

export const StepsDashboard = React.forwardRef<HTMLDivElement>((props, ref) => (
  <StepsDashboardComponent setRef={ref} {...props} />
));

StepsDashboard.displayName = 'StepsDashboard';
