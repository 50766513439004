import { locale } from './locale';

export const matchPasswordErrors = (error: Nullable<string>) => {
  if (!error) {
    return null;
  }
  const errors = error.split(',');

  return errors
    .map((errorKey) => locale.password_errors[errorKey])
    .filter(Boolean)
    .join('\n');
};
