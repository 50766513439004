import { DocumentTypeIdsEnum } from '@modules/getDocuments';

import { locale } from './locale';

export const DOCUMENT_TYPES = [
  { id: DocumentTypeIdsEnum.covid, name: locale.documentTypes.COVID },
  { id: DocumentTypeIdsEnum.other, name: locale.documentTypes.other },
];

export const DOCUMENT_TYPE_SELECT_ID = 'document-type-select';
