import React, { useEffect, useRef, useState } from 'react';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { minutesToHm } from '@common/utils';
import { useForm } from '@components/Form';
import { Grid } from '@components/Grid';
import { RecordActivityForm } from '@components/RecordActivityForm';

import { GRID_SPACING, GRID_ROW_SPACING, WellnessTypesEnum } from '../../../constants';
import { ExerciseItemType } from '../../../types';
import { useResetOnSuccess } from '../../../useResetFormOnSuccess';
import { DateTimePicker } from '../../DateTimePicker';
import { Duration } from '../../Duration';
import { FormValuesType } from '../types';
import { locale } from './locale';

type FormProps = {
  onClose: VoidFunction;
  onSave: (formValues: FormValuesType, recordAnotherEntry: boolean) => unknown;
  initialValues?: ExerciseItemType | null;
  saveFormStatus: StatusEnum;
};

const getDefaultInitialValues = () => ({
  id: 0,
  date: new Date(),
  time: new Date(),
  hours: 0,
  minutes: 0,
});

const makeInitialValues = (initialValues: ExerciseItemType) => {
  const { hours = 0, minutes = 0 } = minutesToHm(initialValues.minutes);

  return {
    id: initialValues.id,
    date: new Date(initialValues.recorded_at),
    time: new Date(initialValues.recorded_at),
    hours,
    minutes,
  };
};

export const SleepForm = ({ initialValues, onClose, onSave, saveFormStatus }: FormProps) => {
  const { handleSubmit, control, watch, formState, setError, clearErrors, reset } = useForm<FormValuesType>({
    defaultValues: initialValues ? makeInitialValues(initialValues) : getDefaultInitialValues(),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    firstInputRef?.current?.focus();
  }, []);

  const [edit] = useState(initialValues?.can_edit_or_delete);

  useIgnoreEffectDeps(() => {
    if (initialValues === null) reset();
  }, [initialValues]);

  useResetOnSuccess(saveFormStatus, reset, getDefaultInitialValues(), WellnessTypesEnum.Sleep);

  const isLoading = saveFormStatus === StatusEnum.Pending;

  return (
    <RecordActivityForm
      cancelText={locale.cancel}
      headerText={locale.header}
      isLoading={isLoading}
      onClose={onClose}
      repeatFormText={locale.record_another_entry}
      onSubmit={handleSubmit((formData) => onSave(formData, false))}
      onSubmitRepeatForm={!edit ? handleSubmit((formData) => onSave(formData, true)) : undefined}
      submitText={locale.submit}
    >
      <Grid container>
        <Grid item xs={12}>
          <DateTimePicker<FormValuesType>
            control={control}
            dateLabel={locale.field_labels.date}
            isLoading={isLoading}
            timeLabel={locale.field_labels.time}
            inputRef={firstInputRef}
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={GRID_ROW_SPACING} columnSpacing={GRID_SPACING}>
        <Grid item xs={12} md={6}>
          <Duration<FormValuesType>
            locale={locale}
            control={control}
            watch={watch}
            formState={formState}
            clearErrors={clearErrors}
            setError={setError}
            disabled={isLoading}
            hideSeconds
            required
          />
        </Grid>

        <Grid item xs={12} md={6} />
      </Grid>
    </RecordActivityForm>
  );
};
