import React from 'react';

import AccordionSummaryMaterial, {
  AccordionSummaryProps as AccordionSummaryPropsMaterial,
} from '@mui/material/AccordionSummary';

export type AccordionSummaryProps = {
  expandedClassName?: string;
  contentClassName?: string;
  rootClassName?: string;
} & Pick<AccordionSummaryPropsMaterial, 'children' | 'expandIcon' | 'className' | 'focusVisibleClassName'>;

export function AccordionSummary({
  children,
  expandIcon,
  className,
  expandedClassName,
  contentClassName,
  rootClassName,
  focusVisibleClassName,
}: AccordionSummaryProps) {
  return (
    <AccordionSummaryMaterial
      expandIcon={expandIcon}
      className={className}
      classes={{ expanded: expandedClassName, content: contentClassName, root: rootClassName }}
      focusVisibleClassName={focusVisibleClassName}
    >
      {children}
    </AccordionSummaryMaterial>
  );
}
