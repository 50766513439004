import React, { useCallback, useState } from 'react';

import TabsMaterial from '@mui/material/Tabs';
import classNames from 'classnames/bind';

import { Tab } from './components/Tab';
import { TabsProps } from './tabs-types';

import styles from './Tabs.module.css';

const cn = classNames.bind(styles);

export function Tabs({
  tabList = [],
  children,
  className,
  onChange,
  selectionFollowsFocus,
  orientation,
  value = 0,
  id,
  'aria-label': ariaLabel,
}: TabsProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabSelect = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  }, []);

  return (
    <TabsMaterial
      orientation={orientation}
      className={cn('tabs', className)}
      onChange={onChange || handleTabSelect}
      value={value || selectedTab}
      selectionFollowsFocus={selectionFollowsFocus}
      TabIndicatorProps={{ className: cn('tabs__indicator') }}
      aria-label={ariaLabel}
      id={id}
      classes={{
        flexContainer: cn('tabs__container'),
        root: cn('tabs__root'),
      }}
    >
      {!children &&
        tabList.map((tab) => <Tab value={tab.id} key={tab.id} label={tab.label} selected={tab.id === selectedTab} />)}

      {!tabList.length && children}
    </TabsMaterial>
  );
}
