import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { getNextAppointment } from './actions';
import { NextAppointmentState, NextAppointmentType } from './types';

const initialState: NextAppointmentState = {
  data: null,
  status: StatusEnum.Uninitialized,
};

export const nextAppointmentSlice = createSlice({
  name: 'nextAppointment',
  initialState,
  reducers: {},
  extraReducers: {
    [getNextAppointment.pending.type]: (state: NextAppointmentState) => {
      state.status = StatusEnum.Pending;
    },
    [getNextAppointment.fulfilled.type]: (state: NextAppointmentState, action: PayloadAction<NextAppointmentType>) => {
      state.status = StatusEnum.Fulfilled;
      state.data = action.payload;
    },
    [getNextAppointment.rejected.type]: (state: NextAppointmentState) => {
      state.status = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const nextAppointmentReducer = nextAppointmentSlice.reducer;
