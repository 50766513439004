import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { fetchFAQS } from './actions';
import { FAQState, FAQType } from './types';

const initialState: FAQState = {
  data: [],
  status: StatusEnum.Uninitialized,
  error: {},
};

export const faqSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFAQS.pending.type]: (state: FAQState) => {
      state.status = StatusEnum.Pending;
    },
    [fetchFAQS.fulfilled.type]: (state: FAQState, action: PayloadAction<FAQType>) => {
      state.status = StatusEnum.Fulfilled;
      state.data = action.payload;
    },
    [fetchFAQS.rejected.type]: (state: FAQState) => {
      /** TODO-Samanth Accurate Error-Handling */
      state.status = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const faqReducer = faqSlice.reducer;
