/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { tokenService } from '../TokenService';
import { userProfileService } from '../UserProfileService';
import { getErrorMessage } from '../utils/getErrorMessage';
import { Fetch } from './Fetch';
import { REFRESH_TOKEN_URL, SKIP_ALERT_STATUSES, ERROR_MESSAGES } from './constants';
import { ErrorType, ExtendedAxiosError, ExtendedAxiosRequestConfig, ExtendedAxiosResponse } from './types';

export const setAuthorization = (requestConfig: ExtendedAxiosRequestConfig) => {
  if (requestConfig.skipAuth) {
    return requestConfig;
  }

  const accessToken = tokenService.getToken();

  if (accessToken) {
    return {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  return requestConfig;
};

export const refreshInProcessInterceptor =
  (fetch: InstanceType<typeof Fetch>) => async (requestConfig: ExtendedAxiosRequestConfig) => {
    if (fetch.refreshTokenInProcess && !requestConfig.refreshing) {
      try {
        await fetch.refreshTokenInProcess;
        fetch.refreshTokenInProcess = null;
      } catch (e) {
        fetch.refreshTokenInProcess = null;
        return Promise.reject(e);
      }
    }

    return requestConfig;
  };

export const alertInterceptor =
  (enqueueSnackbar: (error: string) => any) => async (error: ExtendedAxiosError<ErrorType>) => {
    const { config: originalRequest } = error;

    if (SKIP_ALERT_STATUSES.includes(Number(error?.response?.status)) || error.message === ERROR_MESSAGES.CANCELED) {
      return Promise.reject(error);
    }

    const message = getErrorMessage(error);

    if (!originalRequest?.skipAlert && message) {
      enqueueSnackbar(message);
    }

    return Promise.reject(error);
  };

export const refreshInterceptor =
  (logoutAction: (error?: ExtendedAxiosError) => void, fetch: InstanceType<typeof Fetch>) =>
  async (error: ExtendedAxiosError) => {
    const { response, config: originalRequest = {} } = error;
    if ([REFRESH_TOKEN_URL].includes(originalRequest.url!)) {
      fetch.refreshTokenInProcess = null;
      logoutAction(error);
      return;
    }

    if (response?.status === 401 && !originalRequest.refreshed && !fetch.refreshTokenInProcess) {
      fetch.refreshTokenInProcess = new Promise((resolve) => {
        fetch
          .post({ url: REFRESH_TOKEN_URL, refreshing: true, skipAlert: true })
          .response.then(async (response: void | ExtendedAxiosResponse<any>) => {
            if (response?.status === 200) {
              const wellnessId = response?.data?.user?.wellness_id;
              tokenService.saveToken(response?.data?.token, wellnessId);
              userProfileService.saveAuthUserData(response?.data, wellnessId);

              originalRequest.headers!.Authorization = `Bearer ${response.data.token}`;

              const originalResponse = await fetch.request({ ...originalRequest, refreshed: true, refreshing: true })
                .response;

              resolve(originalResponse as any);
              return originalResponse;
            }
          })
          .catch((error) => {
            fetch.refreshTokenInProcess = null;
            logoutAction(error);
          });
      });

      return fetch.refreshTokenInProcess;
    }

    if (response?.status === 401 && fetch.refreshTokenInProcess && !originalRequest.refreshing) {
      return fetch.request({ ...originalRequest, refreshed: true }).response;
    }

    return Promise.reject(error);
  };
