import { createAsyncThunk } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';
import { downLoadFromBlob } from '@common/utils';

import { ParamsWithDependentUserId } from '../../../types';
import { healthIssuesStatusSelector } from './selectors';
import { HealthIssuesItem } from './types';

export const getHealthIssues = createAsyncThunk<HealthIssuesItem[], ParamsWithDependentUserId>(
  'HealthSummary/get-health-issues',
  async ({ dependentUserId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/problems',
        params: { dependentUserId },
      });

      const res = await data.response;

      return res?.data.problems;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => healthIssuesStatusSelector(getState() as RootState) !== StatusEnum.Pending,
  },
);

export const downloadProblemsReport = createAsyncThunk<unknown, ParamsWithDependentUserId>(
  'HealthSummary/problems-report',
  async ({ dependentUserId }: ParamsWithDependentUserId, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/HealthSummary/reports/problems',
        responseType: 'blob',
        params: { dependentUserId },
      });

      const res = await data.response;
      const blob = res?.data;
      downLoadFromBlob(blob, 'application/pdf', 'problems-report');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
