import React, { FocusEventHandler, useCallback } from 'react';

import { FormatterType } from '@common/utils/numberFormatter';
import { NumberField, NumberFieldProps } from '@components/NumberField';

import { useController, UseControllerProps, FieldValues } from '../hooks';
import { locale } from '../locale';

type NumberFieldWithControlProps<FormValues extends FieldValues> = {
  name: string;
  required?: string | boolean;
  error?: boolean;
  type?: string;
  inputMode?: string;
  formatter?: FormatterType;
  validate?: () => boolean;
  min?: { value: number; message: string } | number;
  max?: { value: number; message: string } | number;
  maxLength?: { value: number; message: string } | number;
  minLength?: { value: number; message: string } | number;
  pattern?: { value: RegExp; message: string } | RegExp;
  ariaLabel?: string;
} & UseControllerProps<FormValues> &
  Omit<NumberFieldProps, 'maxLength' | 'minLength' | 'pattern' | 'max' | 'min' | 'required'>;

export function NumberFieldWithControl<FormValues extends FieldValues>({
  control,
  name,
  required,
  validate,
  error = false,
  min,
  max,
  maxLength,
  minLength,
  pattern,
  formatter,
  ariaLabel,
  ...restProps
}: NumberFieldWithControlProps<FormValues>) {
  const requiredMessage = typeof required === 'boolean' && required ? locale.required_message : required;

  const {
    field: { onChange: controllerOnChange, onBlur, value, ref },
    fieldState,
  } = useController({
    name,
    control,
    rules: { required: requiredMessage, maxLength, minLength, pattern, min, max, validate },
  });

  const handleChange = useCallback(
    (event: { target: { value: string } }) => {
      let newValue = event.target.value;

      if (formatter) {
        newValue = formatter(newValue);
      }

      controllerOnChange(newValue);
    },
    [controllerOnChange, formatter],
  );

  const handleBlur: FocusEventHandler<HTMLInputElement> = (ev) => {
    onBlur();
    handleChange(ev);
  };

  return (
    <NumberField
      {...restProps}
      inputRef={ref}
      required={!!required}
      min={typeof min === 'number' ? min : min?.value}
      max={typeof max === 'number' ? max : max?.value}
      maxLength={typeof maxLength === 'number' ? maxLength : maxLength?.value}
      minLength={typeof minLength === 'number' ? minLength : minLength?.value}
      pattern={pattern instanceof RegExp ? pattern : pattern?.value}
      error={!!fieldState.error || error}
      helperText={fieldState.error?.message}
      onChange={handleChange}
      onBlur={handleBlur}
      value={(value ?? '') as NumberFieldProps['value']}
      name={name}
      inputProps={{
        'aria-label': ariaLabel,
      }}
    />
  );
}
