import React, { useEffect, useRef, useState } from 'react';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { DEFAULT_OPTIONS, numberFormatter } from '@common/utils/numberFormatter';
import { FieldRow } from '@components/FieldRow';
import { NumberFieldWithControl, useForm } from '@components/Form';
import { Grid } from '@components/Grid';
import { RecordActivityForm } from '@components/RecordActivityForm';
import { InputAdornment } from '@components/TextField';

import { GRID_SPACING, WellnessTypesEnum } from '../../../constants';
import { WeightItemType } from '../../../types';
import { useResetOnSuccess } from '../../../useResetFormOnSuccess';
import { DateTimePicker } from '../../DateTimePicker';
import { FormValuesType } from '../types';
import { FIELD_NAMES } from './constants';
import { locale } from './locale';

type FormProps = {
  onClose: VoidFunction;
  onSave: (formValues: FormValuesType, recordAnotherEntry?: boolean) => unknown;
  initialValues?: WeightItemType | null;
  saveFormStatus: StatusEnum;
};

const getDefaultInitialValues = () => ({
  id: 0,
  date: new Date(),
  time: new Date(),
  result: 0,
});

const makeInitialValues = (initialValues: WeightItemType) => ({
  id: initialValues.id,
  date: new Date(initialValues.recorded_at),
  time: new Date(initialValues.recorded_at),
  result: initialValues.result,
});

const weightFormatter = numberFormatter({ ...DEFAULT_OPTIONS, precision: 3, min: 21, max: 1000 });

export const WeightForm = ({ initialValues, onClose, onSave, saveFormStatus }: FormProps) => {
  const { handleSubmit, control, reset } = useForm<FormValuesType>({
    defaultValues: initialValues ? makeInitialValues(initialValues) : getDefaultInitialValues(),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const [edit] = useState(initialValues?.can_edit_or_delete);
  const isLoading = saveFormStatus === StatusEnum.Pending;
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    firstInputRef?.current?.focus();
  }, []);

  useIgnoreEffectDeps(() => {
    if (initialValues === null) reset();
  }, [initialValues]);

  useResetOnSuccess(saveFormStatus, reset, getDefaultInitialValues(), WellnessTypesEnum.Weight);

  return (
    <RecordActivityForm
      cancelText={locale.cancel}
      headerText={locale.header}
      isLoading={isLoading}
      onClose={onClose}
      onSubmitRepeatForm={!edit ? handleSubmit((formValues) => onSave(formValues, true)) : undefined}
      onSubmit={handleSubmit((formValues) => onSave(formValues, false))}
      repeatFormText={locale.record_another_entry}
      submitText={locale.submit}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DateTimePicker<FormValuesType>
            control={control}
            dateLabel={locale.field_labels.date}
            isLoading={isLoading}
            timeLabel={locale.field_labels.time}
            inputRef={firstInputRef}
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={GRID_SPACING} columnSpacing={GRID_SPACING}>
        <Grid item xs={12} md={6}>
          <FieldRow>
            <NumberFieldWithControl
              name={FIELD_NAMES.RESULT}
              control={control}
              fullWidth
              label={locale.field_labels.weight}
              min={{ value: 20, message: locale.errors.weight }}
              max={1000}
              required={locale.errors.weight}
              InputProps={{
                endAdornment: <InputAdornment position="end">{locale.adornments.weight}</InputAdornment>,
              }}
              formatter={weightFormatter}
              id="weight_form_result"
            />
          </FieldRow>
        </Grid>

        <Grid item xs={12} md={6} />
      </Grid>
    </RecordActivityForm>
  );
};
