import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getUserAlerts = createAsyncThunk('articles/fetchUserAlerts', async (id: number, { rejectWithValue }) => {
  try {
    const data = await fetch.get({
      url: '/article/get-alerts',
      params: {
        employerId: id,
      },
    });

    const res = await data.response;

    return res?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
