import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { generatePathWithQuery, useQueryParamsObject } from '@common/router';
import { Pagination as PaginationComponent } from '@components/Pagination';

import { DEFAULT_ITEMS_ON_PAGE, DEFAULT_PAGE } from '../../constants';
import { messagesByTypeCountSelector } from '../../store';

type Props = {
  className?: string;
};

export const Pagination = ({ className }: Props) => {
  const navigate = useNavigate();
  const params = useQueryParamsObject();
  const location = useLocation();
  const total = useSelector((state: RootState) => messagesByTypeCountSelector(state, { type: params.mailboxType }));

  const handleChangePage = useCallback(
    (_: unknown, page: number) => {
      navigate(generatePathWithQuery(location.pathname, { ...params, currentPage: page }));
    },
    [location.pathname, navigate, params],
  );

  if (total <= DEFAULT_ITEMS_ON_PAGE) {
    return null;
  }

  return (
    <PaginationComponent
      className={className}
      onChange={handleChangePage}
      page={Number(params.currentPage) || DEFAULT_PAGE}
      count={Math.ceil(total / DEFAULT_ITEMS_ON_PAGE)}
      siblingCount={0}
      showFirstButton
      showLastButton
      tiny
    />
  );
};
