/* eslint-disable camelcase */
import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { getLocale } from '@common/utils/locale';
import { Alert } from '@components/Alert';
import { LinkButton } from '@components/Link';
import { Spinner } from '@components/Spinner';
import { getImpersonationStatus, getLogoutStatus, logoutStart } from '@modules/Login';
import { userProfileDataSelector } from '@modules/userProfileData';

import { locale } from './locale';

import styles from './ImpersonationBanner.module.css';

const cn = classNames.bind(styles);

export const ImpersonationBanner = () => {
  const { first_name, last_name } = useSelector(userProfileDataSelector);
  const isImpersonation = useSelector(getImpersonationStatus);
  const status = useSelector(getLogoutStatus);
  const dispatch = useDispatch();

  const handleRedirectToAdmin = useCallback(() => {
    dispatch(logoutStart({ impersonationRedirect: true }));
  }, [dispatch]);

  const action = useMemo(
    () =>
      status === StatusEnum.Pending ? (
        <Spinner size="1.25rem" />
      ) : (
        <LinkButton onClick={handleRedirectToAdmin}>{locale.end}</LinkButton>
      ),
    [handleRedirectToAdmin, status],
  );

  if (!isImpersonation) {
    return null;
  }

  return (
    <Alert
      className={cn('impersonation-banner')}
      type="impersonate"
      messageClassName={cn('impersonation-banner__message')}
      bodyClassName={cn('impersonation-banner__body')}
      action={action}
    >
      <div className={cn('impersonation-banner__container')}>
        {getLocale(locale.admin_impersonation, { first_name, last_name })}
      </div>
    </Alert>
  );
};
