import { PATHS } from '@common/routes';

export { PATHS } from '@common/routes';
export const locale = {
  [PATHS.MEDICATION_REFILLS.ROOT]: {
    title: 'Medication Refill Request',
  },
  [PATHS.MY_HEALTH.MY_HEALTH_DASHBOARD]: {
    title: 'My Health: Dashboard',
  },
  [PATHS.MY_HEALTH.GOALS]: {
    title: 'My Health: Goals',
  },
  [PATHS.MY_HEALTH.CARE_GUIDELINES.ROOT]: {
    title: 'My Health: Care Guidelines',
  },
  [PATHS.MY_HEALTH.CARE_GUIDELINES.ITEM]: {
    title: 'My Health: Care Guidelines',
  },
  [PATHS.MY_HEALTH.HEALTH_SUMMARY.HEALTH_ISSUES]: {
    title: 'MyHealth: Health Summary - Health Issues',
  },
  [PATHS.MY_HEALTH.HEALTH_SUMMARY.ALLERGIES]: {
    title: 'MyHealth: Health Summary - Allergies',
  },
  [PATHS.MY_HEALTH.HEALTH_SUMMARY.VISIT_SUMMARIES]: {
    title: 'MyHealth: Health Summary - Visit Summaries',
  },
  [PATHS.MY_HEALTH.HEALTH_SUMMARY.DOCUMENTS]: {
    title: 'MyHealth: Health Summary - Documents',
  },
  [PATHS.MY_HEALTH.HEALTH_SUMMARY.IMMUNIZATIONS]: {
    title: 'MyHealth: Health Summary - Immunizations',
  },
  [PATHS.MY_HEALTH.HEALTH_SUMMARY.MEDICATIONS]: {
    title: 'MyHealth: Health Summary - Medications',
  },
  [PATHS.MY_HEALTH.HEALTH_SUMMARY.SCREENINGS]: {
    title: 'MyHealth: Health Summary - Screenings',
  },
  [PATHS.MY_HEALTH.HEALTH_SUMMARY.ROOT]: {
    title: 'MyHealth: Health Summary',
  },
  [PATHS.MY_HEALTH.TEST_RESULTS]: {
    title: 'MyHealth: Test Results',
  },
  [PATHS.MY_HEALTH.QUESTIONNAIRES]: {
    title: 'MyHealth: Questionnaires',
  },
  [PATHS.MY_HEALTH.RISK_PROFILE]: {
    title: 'MyHealth: Risk Profile',
  },
  [PATHS.MY_HEALTH.ROOT]: {
    title: 'My Health: Goals',
  },
  [PATHS.MESSAGES.ROOT]: {
    title: 'Messages',
  },
  [PATHS.APPOINTMENTS.SCHEDULE_APPOINTMENT]: {
    title: 'Schedule A New Appointment',
  },
  [PATHS.APPOINTMENTS.CHOOSE_TIME]: {
    title: 'Choose a Time & Provider',
  },
  [PATHS.APPOINTMENTS.CONFIRM]: {
    title: 'Please Review & Confirm',
  },
  [PATHS.APPOINTMENTS.SCHEDULED]: {
    title: 'Scheduled',
  },
  [PATHS.APPOINTMENTS.PROVIDERS.ROOT]: {
    title: 'Providers',
  },
  [PATHS.APPOINTMENTS.HEALTH_CENTER_SERVICES]: {
    title: 'Health Center Services',
  },
  [PATHS.APPOINTMENTS.HEALTH_CENTER_LOCATIONS]: {
    title: 'Health Center Locations',
  },
  [PATHS.APPOINTMENTS.ROOT]: {
    title: 'Appointments',
  },
  [PATHS.LATEST_NEWS]: {
    title: 'Latest News',
  },
  [PATHS.FORMS.ROOT]: {
    title: 'Forms',
  },
  [PATHS.RESOURCES.WEBINARS]: {
    title: 'Webinar Library',
  },
  [PATHS.RESOURCES.WELLNESS_WORKSHOPS]: {
    title: 'Wellness Workshops',
  },
  [PATHS.RESOURCES.WELLNESS_WORKSHOPS_DETAILS]: {
    title: 'Wellness Workshops',
  },
  [PATHS.RESOURCES.HEALTHWISE]: {
    title: 'Healthwise',
  },
  [PATHS.RESOURCES.BLUEBOOK]: {
    title: 'Healthcare Bluebook',
  },
  [PATHS.RESOURCES.ALL]: {
    title: 'Resources',
  },
  [PATHS.HELP.FAQ]: {
    title: 'Frequently Asked Questions',
  },
  [PATHS.HELP.FEEDBACK]: {
    title: 'Portal Feedback Form',
  },
  [PATHS.HELP.ROOT]: {
    title: 'Help',
  },
  [PATHS.WELLNESS.WELLNESS_LOGS]: {
    title: 'Wellness Logs',
  },
  [PATHS.WELLNESS.CHALLENGES]: {
    title: 'Challenges',
  },
  [PATHS.WELLNESS.INCENTIVES]: {
    title: 'Incentives',
  },
  [PATHS.CATEGORIES.ROOT]: {
    title: 'Latest News',
  },
  [PATHS.COVID_SCREENING.RESULT]: {
    title: 'COVID-19 Screening Result',
  },
  [PATHS.COVID_SCREENING.ROOT]: {
    title: 'COVID-19 Screening',
  },
  [PATHS.PROFILE.ROOT]: {
    title: 'Profile & Settings',
  },
  [PATHS.LOGIN]: {
    title: 'Sign In',
  },
  [PATHS.PASSWORD_RECOVERY.RESET_PASSWORD]: {
    title: 'Choose a new password',
  },
  [PATHS.PASSWORD_RECOVERY.ROOT]: {
    title: 'Forgot your password?',
  },
  [PATHS.REGISTER.ROOT]: {
    title: 'Sign Up',
  },
  [PATHS.REGISTER.COMPLETE]: {
    title: 'Sign Up Complete',
  },
  [PATHS.REGISTER.COMPLETE_WITHOUT_TOKEN]: {
    title: 'Sign Up Complete',
  },
  home: {
    title: 'Home',
  },
  page_changed: 'Page changed to',
};
