const urlParser = (to: string) => {
  const parser = document.createElement('a');
  parser.href = to;
  return parser;
};

export const isExternalUrl = (to: string) => {
  // assuming relative paths don't have protocol hence we can know they are internal
  if (to.indexOf('http://') === 0 || to.indexOf('https://') === 0 || to.indexOf('tel:') === 0 || to.includes('#')) {
    return true;
  }

  const toLocation = urlParser(to);

  return window.location.hostname !== toLocation.hostname;
};
