import { AmPmEnum, ChangeValueActionsEnum, MAX_HOURS, AM_PM_MAX_HOURS, INITIAL_HOURS } from './constants';

export const getAmPmHours = (value: number, ampm = true) => {
  const hour = value;

  if (!ampm) {
    return hour;
  }

  if (ampm && (hour === 0 || hour === 12 || hour === 24)) {
    return 12;
  }

  return hour % 12;
};

export const addZeroBefore = (value: number) => (`${value}`.length === 1 ? `0${value}` : value);

export const getMaxHours = (ampm: AmPmEnum | null) => {
  let maxHours = MAX_HOURS;

  if (ampm) {
    maxHours = AM_PM_MAX_HOURS;
  }

  return maxHours;
};

export const getAmPmValue = (hours: number) => (hours >= AM_PM_MAX_HOURS ? AmPmEnum.PM : AmPmEnum.AM);

type ChangeValueType = {
  hours: number;
  amPmValue: AmPmEnum | null;
  maxHours: number;
};

export const addHours = ({ hours, amPmValue, maxHours }: ChangeValueType) => {
  let newHoursValue = getAmPmHours(hours, !!amPmValue) + 1;
  newHoursValue = newHoursValue > maxHours ? INITIAL_HOURS : newHoursValue;

  return newHoursValue;
};

export const subtractHours = ({ hours, amPmValue, maxHours }: ChangeValueType) => {
  let newHoursValue = getAmPmHours(hours, !!amPmValue) - 1;
  // For 24 time format we need to set 23 hours when we do subtract
  const newMaxHours = amPmValue ? maxHours : maxHours - 1;
  newHoursValue = newHoursValue < INITIAL_HOURS ? newMaxHours : newHoursValue;
  return newHoursValue;
};

export const getNewHoursValue = ({
  hours,
  amPmValue,
  maxHours,
  changeValueActions,
}: {
  hours: number;
  amPmValue: AmPmEnum | null;
  maxHours: number;
  changeValueActions: ChangeValueActionsEnum;
}) => {
  let newHoursValue = hours;

  if (changeValueActions === ChangeValueActionsEnum.Add) {
    newHoursValue = addHours({ hours, amPmValue, maxHours });
  }

  if (changeValueActions === ChangeValueActionsEnum.Subtract) {
    newHoursValue = subtractHours({ hours, amPmValue, maxHours });
  }

  if (amPmValue === AmPmEnum.AM) {
    newHoursValue = newHoursValue === AM_PM_MAX_HOURS ? MAX_HOURS : newHoursValue;
  }

  if (amPmValue === AmPmEnum.PM) {
    newHoursValue += 12;
    newHoursValue = newHoursValue === MAX_HOURS ? AM_PM_MAX_HOURS : newHoursValue;
  }

  return newHoursValue;
};
