import * as React from 'react';

import IconButtonMaterial, { IconButtonProps as IconButtonPropsMaterial } from '@mui/material/IconButton';
import classNames from 'classnames/bind';

import styles from './IconButton.module.css';

const cn = classNames.bind(styles);

export type IconButtonProps = {
  className?: string;
} & Pick<
  IconButtonPropsMaterial,
  | 'tabIndex'
  | 'children'
  | 'color'
  | 'disabled'
  | 'disableRipple'
  | 'type'
  | 'onClick'
  | 'title'
  | 'aria-label'
  | 'aria-controls'
  | 'aria-haspopup'
  | 'aria-disabled'
  | 'autoFocus'
  | 'id'
  | 'ref'
  | 'role'
>;

export function IconButton({
  ref,
  children,
  className,
  onClick,
  color,
  title,
  type,
  disableRipple = true,
  disabled,
  autoFocus,
  'aria-label': ariaLabel,
  'aria-haspopup': ariaHasPopup,
  'aria-controls': ariaControls,
  'aria-disabled': ariaDisabled,
  ...rest
}: IconButtonProps) {
  return (
    <IconButtonMaterial
      className={cn('icon-button', className, {
        [`icon-button--custom-focus`]: disableRipple,
      })}
      ref={ref}
      color={color}
      onClick={!ariaDisabled ? onClick : undefined}
      type={type}
      title={title}
      disabled={disabled}
      disableRipple={disableRipple}
      autoFocus={autoFocus}
      aria-label={ariaLabel}
      aria-haspopup={ariaHasPopup}
      aria-controls={ariaControls}
      aria-disabled={ariaDisabled}
      {...rest}
    >
      {children}
    </IconButtonMaterial>
  );
}
