import { enUS } from 'date-fns/locale';

import { eachMonthOfInterval, formatDate } from '@common/utils/dateTimeUtil';
import { DEFAULT_OPTIONS, LineChartProps } from '@components/Charts';

export const DISPLAY_FORMATS = {
  millisecond: 'MMM yy',
  second: 'MMM yy',
  minute: 'MMM yy',
  hour: 'MMM yy',
  day: 'LLL dd',
  week: 'MMM yy',
  month: 'MMM yy',
  quarter: 'MMM yy',
  year: 'yyyy',
};

type Item = {
  recorded_at: string;
  result: number;
};

const MONTHS_IN_ONE_YEAR = 12;
const MONTHS_IN_HALF_YEAR = MONTHS_IN_ONE_YEAR / 2;
const MONTHS_IN_TWO_YEAR = MONTHS_IN_ONE_YEAR * 2;
const MONTHS_IN_FIVE_YEARS = MONTHS_IN_ONE_YEAR * 5;

export const sortItems = <T extends Item[]>(items: T) =>
  [...items].sort((a, b) => Date.parse(a.recorded_at) - Date.parse(b.recorded_at));

export const getDatasetData = <T extends Item[]>(items: T) =>
  items.map((item) => ({
    ...item,
    x: new Date(item.recorded_at).getTime(),
    y: item.result,
  }));

const getMonthsLength = <T extends Item[]>(items: T) => {
  if (!items.length || items.length === 1) {
    return items.length;
  }

  const interval = eachMonthOfInterval({
    start: new Date(items[0].recorded_at),
    end: new Date(items[items.length - 1].recorded_at),
  });

  return interval.length;
};

export const getDataset = <T extends Item[]>(items: T) => {
  const sortedItems = sortItems(items);

  return {
    labels: items.length === 1 ? [formatDate(new Date(items[0].recorded_at), 'LLL dd hh:mm a')] : undefined,
    datasets: [
      {
        data: getDatasetData(sortedItems),
      },
    ],
  };
};

const defaultTitleRender = (value: UnknownObject<UnknownObject<Nullable<string>>>[]) => {
  const title = value[0]?.raw?.x;

  return title ? formatDate(new Date(title), 'LLL dd hh:mm a') : '';
};

export const getOptions = <T extends Item[]>(
  items: T,
  options: LineChartProps['options'] = DEFAULT_OPTIONS,
  titleRender = defaultTitleRender,
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
any => {
  const sortedItems = sortItems(items);
  const monthLength = getMonthsLength(sortedItems);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timeOptions: any = {
    unit: 'day',
  };

  const minUnit = new Date(sortedItems[0]?.recorded_at).getTime();

  if (items.length === 1) {
    return {
      ...options,
      scales: {
        ...options.scales,
        xAxis: {
          ...options?.scales?.xAxis,
          offset: true,
        },
      },
    };
  }

  if (MONTHS_IN_HALF_YEAR >= monthLength) {
    timeOptions = {
      minUnit,
      stepSize: 1,
      unit: 'month',
    };
  }

  if (MONTHS_IN_HALF_YEAR < monthLength && monthLength <= MONTHS_IN_TWO_YEAR) {
    timeOptions = {
      minUnit,
      stepSize: 2,
      unit: 'month',
    };
  }

  if (MONTHS_IN_TWO_YEAR < monthLength && monthLength <= MONTHS_IN_FIVE_YEARS) {
    timeOptions = {
      minUnit,
      stepSize: 6,
      unit: 'month',
    };
  }

  if (MONTHS_IN_FIVE_YEARS < monthLength) {
    timeOptions = {
      minUnit,
      stepSize: 1,
      unit: 'year',
    };
  }

  if (monthLength === 1) {
    timeOptions = {
      unit: 'hour',
    };
  }

  return {
    ...options,
    plugins: {
      ...options.plugins,
      tooltip: {
        ...options?.plugins?.tooltip,
        callbacks: {
          ...options?.plugins?.tooltip?.callbacks,
          title: titleRender,
        },
      },
    },
    scales: {
      ...options?.scales,
      xAxis: {
        ...options?.scales?.xAxis,
        type: 'time',
        time: {
          ...timeOptions,
          displayFormats: DISPLAY_FORMATS,
        },
      },
    },
    adapters: {
      date: {
        locale: enUS,
      },
    },
  };
};
