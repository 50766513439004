/* eslint-disable camelcase */
import { PayloadAction } from '@reduxjs/toolkit';
import unionBy from 'lodash.unionby';

import { formatInTimeZone, dateStringToDateObject } from '@common/utils/dateTimeUtil';

import { SOONEST_FILTER_OPTIONS } from '../constants';
import { locale } from '../locale';
import {
  ClinicAppointment,
  OpenAppointmentsType,
  ScheduleAppointmentDataType,
  TimeZoneType,
  ProviderType,
} from '../types';
import { addZeroBeforeTime, getLocalClinics } from '../utils';
import { FilterType, OpenAppointmentsLoadedDateType, SearchOpenAppointmentsMetaType } from './types';

const mergeClinicAppointments = (
  clinicAppointments: OpenAppointmentsType['appointments'],
  newClinicAppointments: OpenAppointmentsType['appointments'],
) => {
  const mergedClinicAppointments = newClinicAppointments?.map?.((newClinic) => {
    const foundClinicAppointments = clinicAppointments?.find?.((clinic) => clinic.clinic_id === newClinic.clinic_id);
    const oldAppointments = foundClinicAppointments?.appointments || [];

    return {
      clinic_id: newClinic.clinic_id,
      appointments: unionBy(oldAppointments, newClinic.appointments, 'id'),
    };
  });

  return unionBy(mergedClinicAppointments, clinicAppointments, 'clinic_id');
};

export const mergeOpenAppointments = (
  openAppointments: OpenAppointmentsType,
  newOpenAppointments: OpenAppointmentsType,
) => {
  const { appointments, clinics = [], providers = [] } = newOpenAppointments;

  const mergedClinicAppointments = mergeClinicAppointments(openAppointments.appointments || [], appointments || []);

  return {
    ...newOpenAppointments,
    appointments: mergedClinicAppointments,
    clinics: unionBy(clinics, openAppointments.clinics || [], 'id'),
    providers: unionBy(providers, openAppointments.providers || [], 'id'),
  };
};

export const setOpenAppointmentsLoadedDates = (
  openAppointmentsLoadedDates: OpenAppointmentsLoadedDateType[],
  newOpenAppointmentsLoadedDate: OpenAppointmentsLoadedDateType,
) => {
  const newOpenAppointmentsLoadedDates = [...openAppointmentsLoadedDates, newOpenAppointmentsLoadedDate];

  return [...newOpenAppointmentsLoadedDates].sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate));
};

export const getSoonestAvailabilityFilter = (
  openAppointments: Array<ClinicAppointment>,
  timeZone: Nullable<TimeZoneType>,
) => {
  const appointments: ClinicAppointment['appointments'] = openAppointments.reduce(
    (acc, openAppointment) => [...acc, ...openAppointment.appointments],
    [] as ClinicAppointment['appointments'],
  );

  return SOONEST_FILTER_OPTIONS.filter((soonestAvailability) =>
    appointments.some((appointment) => {
      const appointmentDate = timeZone?.iana_time_zone
        ? new Date(formatInTimeZone(appointment.start_time, timeZone.iana_time_zone, 'yyyy/MM/dd HH:mm:ss'))
        : new Date(appointment.start_time);

      const appointmentTime = `${addZeroBeforeTime(appointmentDate.getHours())}:${addZeroBeforeTime(
        appointmentDate.getMinutes(),
      )}`;

      return appointmentTime >= soonestAvailability.start && appointmentTime <= soonestAvailability.end;
    }),
  );
};

export const appointmentResultHasError = (result: Nullable<string>) => result === locale.already_booked_message;

export const removeAppointment = (openAppointments: OpenAppointmentsType, clinicId: number, appointmentId: number) => ({
  ...openAppointments,
  appointments: openAppointments.appointments?.map?.((openAppointment) => {
    if (openAppointment.clinic_id === clinicId) {
      return {
        ...openAppointment,
        appointments: openAppointment.appointments.filter((appointment) => appointment.id !== appointmentId),
      };
    }

    return openAppointment;
  }),
});

export const convertDataForSearchAppointments = (
  filter: FilterType,
  scheduleAppointmentData: ScheduleAppointmentDataType,
) => ({
  visit_type: filter.visitType,
  appointment_virtual_state: filter.visitState,
  // scheduleAppointmentData
  visitReason: scheduleAppointmentData.visitReason?.[0],
  visit_reason_category_id: scheduleAppointmentData.visitReasonCategory?.id,
  visit_reason_category: scheduleAppointmentData.visitReasonCategory?.display_name,
  user_id: scheduleAppointmentData.userId,
  // Reschedule params
  appointment_type_id: filter.appointmentTypeId,
  appointment_start_date: filter.appointmentStartDate,
  appointment_id: filter.appointmentId,
  provider_id: filter.providerId || filter?.rescheduleProviderId,
});

export const checkProviderId = (provider: ProviderType, id: Nullable<number>): boolean =>
  provider.id === id || parseInt(provider.athena1_pun, 10) === id;

type DefaultFilterParams = {
  openAppointments: ReturnType<typeof mergeOpenAppointments>;
  filter: FilterType;
  action: PayloadAction<OpenAppointmentsType, string, SearchOpenAppointmentsMetaType>;
  providers: Nullable<ProviderType[]>;
};

export const getDefaultFilter = ({ openAppointments, filter, action, providers }: DefaultFilterParams) => {
  const { providers: newProviders = [], default_provider_id } = action.payload;
  const { visit_type, start_date, end_date, appointment_virtual_state } = action.meta.arg;

  const defaultProviderId = default_provider_id || filter.defaultProviderId;

  // We pre-select the default Provider as "Last Provider Seen" if the API returns the defaultProviderId
  // In addition if the user has altered the provider filter then we should not override the provider / group of providers they are filtering by
  const defaultProvider =
    defaultProviderId && !(filter.provider && filter.provider.length > 1)
      ? newProviders?.find?.((provider) => checkProviderId(provider, defaultProviderId))
      : undefined;

  const defaultProviderFilter = defaultProvider ? [defaultProvider.display_name] : undefined;
  const providerFromFilter = filter.provider && filter.provider.length > 0 ? filter.provider : undefined;

  let provider = defaultProviderFilter || [];

  if (providers?.length !== providerFromFilter?.length) {
    provider = providerFromFilter || provider;
  }

  const localClinics = getLocalClinics(openAppointments.clinics);
  const defaultHealthCenter = localClinics.length ? localClinics : openAppointments.clinics;

  return {
    ...filter,
    currentDate: dateStringToDateObject(start_date).toLocaleDateString(),
    endDate: end_date,
    healthCenter: filter.healthCenter?.length ? filter.healthCenter : defaultHealthCenter,
    startDate: start_date,
    visitType: filter.visitType || visit_type,
    visitState: appointment_virtual_state,
    network: filter.network || [],
    provider,
    soonestAvailability: filter.soonestAvailability || [],
    defaultProviderId,
  };
};
