import React from 'react';

import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';
import { useModal } from '@components/Modal';

export const useOpenClockPicker = (
  timePickerContainer: HTMLDivElement | null,
  clockToggleButton: HTMLButtonElement | null,
) => {
  const { onOpenModal, onCloseModal, modalOpen } = useModal();

  useIgnoreEffectDeps(() => {
    const handleFocus = (event: FocusEvent) => {
      const timePickerContainerContainsFocusElement = timePickerContainer?.contains?.(event.target as Node);

      if (!timePickerContainerContainsFocusElement) {
        onCloseModal();
      }
    };

    if (modalOpen) {
      document.addEventListener('focus', handleFocus, true);
    }

    return () => {
      document.removeEventListener('focus', handleFocus, true);
    };
  }, [modalOpen]);

  useIgnoreEffectDeps(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        event.preventDefault();
        onCloseModal();
        clockToggleButton?.focus();
      }
    };

    if (modalOpen) {
      document.addEventListener('keydown', handleKeyPress, true);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress, true);
    };
  }, [modalOpen]);

  const closeModal = () => {
    clockToggleButton?.focus();
    onCloseModal();
  };

  return { onOpenModal, onCloseModal, modalOpen, closeModal };
};
