import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useIgnoreEffectDeps } from '@common/hooks';
import { datePlusTime, formatDateForServer, hmsToSeconds } from '@common/utils';
import { RecordActivityModal } from '@components/RecordActivityModal';

import { WellnessTypesEnum } from '../../../constants';
import { fitnessRecordActivitySelector, hideEditModal, SaveExerciseRequestType, saveLogItem } from '../../../store';
import { ExerciseItemType } from '../../../types';
import { useResetEntryCount } from '../../../useReserEntryCount';
import { FormValuesType } from '../types';
import { FitnessForm } from './FitnessForm';

export function FitnessRecordActivityModal() {
  const { editModalVisible, selectedItem, exerciseTypes, saveFormStatus, exerciseTypesStatus } =
    useSelector(fitnessRecordActivitySelector);
  const dispatch = useDispatch();
  const [entryCount, setEntryCount] = useState(0);

  const handleClose = () => {
    dispatch(hideEditModal({ type: WellnessTypesEnum.Fitness }));
  };

  useIgnoreEffectDeps(
    () => () => {
      handleClose();
    },
    [],
  );

  const handleSave = useCallback(
    (formValues: FormValuesType, recordAnotherEntry = false) => {
      let requestData: SaveExerciseRequestType['data'] = {
        id: formValues.id,
        recorded_at: formatDateForServer(datePlusTime(formValues.date, formValues.time)),
        type: formValues.type?.type as string,
      };

      const duration = hmsToSeconds(formValues);
      const distance = Number(formValues.distance);

      if (formValues.type?.label || formValues.name) {
        requestData = {
          ...requestData,
          name: formValues.name || formValues.type?.label,
        };
      }

      if (distance) {
        requestData = {
          ...requestData,
          distance,
        };
      }

      if (duration) {
        requestData = {
          ...requestData,
          duration,
        };
      }

      dispatch(
        saveLogItem({
          data: requestData,
          type: WellnessTypesEnum.Fitness,
          recordAnotherEntry,
          entryCount,
        }),
      );
      setEntryCount(entryCount + 1);
    },
    [entryCount, dispatch],
  );

  useResetEntryCount(editModalVisible, setEntryCount);

  return (
    <RecordActivityModal open={editModalVisible} onClose={handleClose}>
      <FitnessForm
        exerciseTypes={exerciseTypes}
        onClose={handleClose}
        onSave={handleSave}
        initialValues={selectedItem as Nullable<ExerciseItemType>}
        saveFormStatus={saveFormStatus}
        exerciseTypesStatus={exerciseTypesStatus}
      />
    </RecordActivityModal>
  );
}
