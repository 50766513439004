import { useDispatch, useSelector } from 'react-redux';

import { userProfileService } from '@common/UserProfileService';
import { useIgnoreEffectDeps } from '@common/hooks';

import { wellnessIdSelector } from '../../userProfileData/store';
import { setFlippers } from '../store';

export function useLoadFlippers() {
  const dispatch = useDispatch();
  const wellnessId = useSelector(wellnessIdSelector);

  useIgnoreEffectDeps(() => {
    const userProfileDataFromStorage = userProfileService.getUserProfile(wellnessId);

    if (userProfileDataFromStorage) {
      dispatch(setFlippers(userProfileDataFromStorage.user_auth?.enabled_features));
    }
  }, []);
}
