import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { getUserAlerts } from './actions';
import { UserAlertsState, UserAlertType } from './types';

const initialState: UserAlertsState = {
  data: [],
  status: StatusEnum.Uninitialized,
  error: {},
};

export const userAlertsSlice = createSlice({
  name: 'userAlerts',
  initialState,
  reducers: {},
  extraReducers: {
    [getUserAlerts.pending.type]: (state: UserAlertsState) => {
      state.status = StatusEnum.Pending;
    },
    [getUserAlerts.fulfilled.type]: (state: UserAlertsState, action: PayloadAction<UserAlertType[]>) => {
      state.status = StatusEnum.Fulfilled;
      state.data = action.payload;
    },
    [getUserAlerts.rejected.type]: (state: UserAlertsState) => {
      state.status = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const userAlertsReducer = userAlertsSlice.reducer;
