export const FIELD_NAMES = {
  EMAIL: 'email',
  PASSWORD: 'password',
  PASSWORD_CONFIRM: 'password_confirm',
} as const;

export enum ErrorCodes {
  AccountLocked = 'account_locked',
  Default = 'something_went_wrong',
}

export enum ResetErrorCodes {
  ValidationError = 'validation_error',
}
