import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';

import { activeEligibilitiesStatusSelector, getActiveEligibilities } from '../store';

export function useLoadActiveEligibilities() {
  const activeEligibilitiesStatus = useSelector(activeEligibilitiesStatusSelector);
  const dispatch = useDispatch();

  useIgnoreEffectDeps(() => {
    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(activeEligibilitiesStatus)) {
      dispatch(getActiveEligibilities());
    }
  }, []);
}
