import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type GlobalLockerState = {
  showLocker: boolean;
  title: Nullable<string>;
  ariaLabel: Nullable<string>;
  hideLogo: Nullable<boolean>;
};
const initialState: GlobalLockerState = {
  showLocker: false,
  title: null,
  ariaLabel: null,
  hideLogo: false,
};

export const globalLockerSlice = createSlice({
  name: 'globalLocker',
  initialState,
  reducers: {
    toggleLocker(
      state: GlobalLockerState,
      action: PayloadAction<{ showLocker: boolean; title?: string; ariaLabel?: string }>,
    ) {
      state.showLocker = action.payload.showLocker;
      state.title = action.payload.title;
      state.ariaLabel = action.payload.ariaLabel;
    },

    setHideLogo(state: GlobalLockerState, action: PayloadAction<boolean>) {
      state.hideLogo = action.payload;
    },
  },
});

export const globalLockerReducer = globalLockerSlice.reducer;
export const { toggleLocker, setHideLogo } = globalLockerSlice.actions;
