export const locale = {
  title: 'Documents',
  add_new: 'Add Document',
  no_data: 'No documents found.',
  successfully_uploaded: 'Your document has been successfully uploaded.',
  successfully_deleted: 'Your document has been successfully deleted.',
  fields: {
    document_type: 'Document Name',
    date: 'Date Added',
    provider: 'Provider',
    source: 'Source',
    actions: 'Document Actions',
    view_and_print: 'View & Print',
    view_documents_link: 'View Documents List',
    delete: 'Delete',
  },
  sources: {
    medicalRecord: 'Medical Record',
    portal: 'Uploaded by patient',
  },

  sort_by_name: 'Name',
  sort_by_provider: 'Provider',
  sort_by_date: 'Date',

  description_1:
    'Below is a list of your documents added by you or your provider. If you need to send a document to a provider or health center, please ',
  description_2: 'send a message.\n',
  sort_by_aria: 'Sort By: {{by}}',
};
