import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

import { SubmitFeedbackPayloadType } from './types';

export const submitFeedback = createAsyncThunk(
  'Feedback/submit',
  async (data: SubmitFeedbackPayloadType, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: '/Feedback',
        data,
      });

      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
