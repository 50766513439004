import { addMonths } from '@common/utils/dateTimeUtil';

import { MeasurementEnum } from '../../constants';
import { WellnessLogStateItemType } from '../../store';
import { locale } from './locale';

export const MEASUREMENT_TYPES = [
  {
    title: locale.measurements[MeasurementEnum.Weight],
    shortTitle: locale.measurementsShort[MeasurementEnum.Weight],
    yScaleTitle: locale.measurementsYScaleTitle[MeasurementEnum.Weight],
    type: MeasurementEnum.Weight,
  },
];

export const DISPLAY_FORMATS = {
  millisecond: 'MMM yy',
  second: 'MMM yy',
  minute: 'MMM yy',
  hour: 'MMM yy',
  day: 'LLL dd',
  week: 'MMM yy',
  month: 'MMM yy',
  quarter: 'MMM yy',
  year: 'yyyy',
};

const ITEM = {
  result: 202.0,
  result_label: '202 lbs',
  id: 7421211,
  source: 'admin',
  recorded_at: '2021-04-10T19:47:00',
  created_at: '2022-05-10T18:49:02.82241',
  updated_at: '2022-05-10T18:49:02.82241',
};

export const MOCK_ITEMS: WellnessLogStateItemType['items'] = Array.from(Array(62).keys()).map((_, index) => ({
  ...ITEM,
  recorded_at: addMonths(new Date(ITEM.recorded_at), index).toISOString(),
}));
