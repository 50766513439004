import { MeasurementEnum } from '../../constants';

export const locale = {
  title: 'Sleep',
  dashboard_title: 'Sleep',
  history_title: 'Recently Recorded Sleep',
  record_activity: 'Record Sleep',
  measurements: {
    [MeasurementEnum.Hours]: 'Hours Per Month',
  },
  measurementsShort: {
    [MeasurementEnum.Hours]: 'hours',
  },
  measurementsYScaleTitle: {
    [MeasurementEnum.Hours]: 'Hours',
  },
};
