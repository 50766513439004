import { ERROR_CODES, STATUSES } from '@common/fetch/constants';
import { ErrorType, ExtendedAxiosError } from '@common/fetch/types';

import { locale } from './locale';

export const getErrorMessage = (error: ExtendedAxiosError<ErrorType>) => {
  if (error?.response?.data?.error_code === ERROR_CODES.EXTERNAL_API_ERROR) {
    return locale.external_api_error;
  }

  const statusCode = error?.response?.status;

  if (statusCode && statusCode >= STATUSES.SERVER_ERROR && statusCode <= STATUSES.NETWORK_CONNECT_TIMEOUT) {
    return locale.server_error;
  }

  return (
    error?.response?.data?.error ??
    error?.response?.data?.title ??
    error?.response?.data?.message ??
    locale.common_error
  );
};
