import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useIgnoreEffectDeps } from '@common/hooks';

import { SrA11YAnnouncer } from './SrA11YAnnouncer';

export const SrA11YPageAnnouncer = () => {
  const [pageHeading, setPageHeading] = useState<Nullable<string>>('');
  const location = useLocation();

  useIgnoreEffectDeps(() => {
    const heading = document.querySelector('h1');
    if (heading && heading.textContent && heading.textContent !== pageHeading) {
      setPageHeading(heading.textContent);
    }
  }, [location]);

  return <SrA11YAnnouncer message={pageHeading} />;
};
