import React, { ReactNode, memo, useEffect, useState } from 'react';

type SrA11YPageAnnouncerProps = {
  message?: Nullable<string>;
  id?: string;
  children?: ReactNode;
};

const areEqual = (prevProps: SrA11YPageAnnouncerProps, nextProps: SrA11YPageAnnouncerProps) =>
  prevProps.message === nextProps.message;

export const SrA11YAnnouncer = memo(({ message, id, children }: SrA11YPageAnnouncerProps) => {
  const [a11yMessage, setA11yMessage] = useState<Nullable<string>>('');

  useEffect(() => {
    const timeoutAnnouncer = setTimeout(() => {
      setA11yMessage(message);
    }, 0);

    const timeoutClearer = setTimeout(() => {
      setA11yMessage('');
    }, 500);

    return () => {
      clearTimeout(timeoutAnnouncer);
      clearTimeout(timeoutClearer);
    };
  }, [message]);

  return (
    <div aria-live="polite" role="status" id={id} className={message ? 'sr-only' : ''}>
      {children || a11yMessage}
    </div>
  );
}, areEqual);

SrA11YAnnouncer.displayName = 'SrA11YAnnouncer';
