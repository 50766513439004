import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { DocumentsResponse } from '@modules/getDocuments';
import { resetStoreActions } from '@modules/reset';

import { INCENTIVE_DOCUMENT_FOR_PRELOADER, INCENTIVE_FOR_PRELOADER } from '../constants';
import {
  deleteSelfReportedEntry,
  getCurrentEarningSummary,
  getCurrentGoal,
  getEarningSummary,
  getHistoricalPrograms,
  getIncentiveDocument,
  getSelfReportedEntry,
  getShortCurrentEarningSummary,
  removeIncentiveDocument,
  saveSelfReportedEntry,
  uploadIncentivesForm,
  getListOfDocuments,
  getActivePrograms,
  checkProgramCompletionAcknowledged,
  setProgramCompletionAcknowledged,
} from './actions';
import {
  CurrentEarningSummaryMetaType,
  CurrentEarningSummaryType,
  CurrentShortEarningSummaryType,
  HistoricalProgramType,
  SelfRecordedGoalsType,
  SelfRecordedGoalType,
  IncentiveDocumentType,
  ActiveProgramType,
} from './types';

export interface IncentivesState {
  currentEarningSummary: CurrentEarningSummaryType;
  currentEarningSummaryStatus: StatusEnum;
  earningSummaryStatus: StatusEnum;
  earningSummaryById: Record<string, CurrentEarningSummaryType>;

  currentShortEarningSummary: CurrentShortEarningSummaryType;
  currentShortEarningSummaryStatus: StatusEnum;

  historicalPrograms: HistoricalProgramType[];
  historicalProgramsStatus: StatusEnum;

  saveFormStatus: StatusEnum;

  historicalProgramsOpen: boolean;
  selfReportedGoals: SelfRecordedGoalsType;
  selfReportedGoalStatus: StatusEnum;
  selfReportedGoalOpen: boolean;

  uploadingFormStatus: StatusEnum;
  documentsList: DocumentsResponse;
  documentsListStatus: StatusEnum;

  incentiveDocumentStatus: StatusEnum;
  incentiveDocumentRemoveStatus: StatusEnum;
  incentiveDocument: IncentiveDocumentType;

  activeProgramsStatus: StatusEnum;
  activePrograms: ActiveProgramType[];
  activeProgram: ActiveProgramType | null;

  programCompletionAcknowledgedByProgramId: Record<number, boolean | undefined>;
}

const initialState: IncentivesState = {
  currentEarningSummary: INCENTIVE_FOR_PRELOADER,
  earningSummaryById: {},
  currentEarningSummaryStatus: StatusEnum.Uninitialized,
  earningSummaryStatus: StatusEnum.Uninitialized,

  currentShortEarningSummary: INCENTIVE_FOR_PRELOADER,
  currentShortEarningSummaryStatus: StatusEnum.Uninitialized,

  historicalPrograms: [],
  historicalProgramsStatus: StatusEnum.Uninitialized,

  saveFormStatus: StatusEnum.Uninitialized,

  selfReportedGoals: {} as SelfRecordedGoalsType,
  selfReportedGoalStatus: StatusEnum.Uninitialized,
  historicalProgramsOpen: false,
  selfReportedGoalOpen: false,

  uploadingFormStatus: StatusEnum.Uninitialized,
  documentsList: {
    total_record_count: 0,
    models: null,
  },
  documentsListStatus: StatusEnum.Uninitialized,

  incentiveDocument: INCENTIVE_DOCUMENT_FOR_PRELOADER,
  incentiveDocumentStatus: StatusEnum.Uninitialized,

  incentiveDocumentRemoveStatus: StatusEnum.Uninitialized,

  activeProgramsStatus: StatusEnum.Uninitialized,
  activePrograms: [],
  activeProgram: null,

  programCompletionAcknowledgedByProgramId: {},
};

export const incentivesSlice = createSlice({
  name: 'incentives',
  initialState,
  reducers: {
    openHistoricalPrograms(state) {
      state.historicalProgramsOpen = true;
    },

    closeHistoricalPrograms(state) {
      state.historicalProgramsOpen = false;
    },

    openSelfReportedGoal(state) {
      state.selfReportedGoalOpen = true;
    },

    closeSelfReportedGoal(state) {
      state.selfReportedGoalOpen = false;
      state.saveFormStatus = StatusEnum.Uninitialized;
    },

    resetUploadingFormStatus(state) {
      state.uploadingFormStatus = StatusEnum.Uninitialized;
    },

    resetDocumentsList(state) {
      state.documentsListStatus = StatusEnum.Uninitialized;
      state.documentsList = initialState.documentsList;
    },

    setActiveProgram(state, action) {
      state.activeProgram = action.payload;
    },
  },
  extraReducers: {
    [getCurrentEarningSummary.pending.type]: (state: IncentivesState) => {
      state.currentEarningSummary = INCENTIVE_FOR_PRELOADER as CurrentEarningSummaryType;
      state.currentEarningSummaryStatus = StatusEnum.Pending;
    },
    [getCurrentEarningSummary.fulfilled.type]: (
      state: IncentivesState,
      action: PayloadAction<CurrentEarningSummaryType>,
    ) => {
      state.currentEarningSummary = action.payload;
      state.currentEarningSummaryStatus = StatusEnum.Fulfilled;
    },
    [getCurrentEarningSummary.rejected.type]: (state: IncentivesState) => {
      state.currentEarningSummary = {} as CurrentEarningSummaryType;
      state.currentEarningSummaryStatus = StatusEnum.Rejected;
    },

    [getShortCurrentEarningSummary.pending.type]: (state: IncentivesState) => {
      state.currentShortEarningSummary = INCENTIVE_FOR_PRELOADER as CurrentShortEarningSummaryType;
      state.currentShortEarningSummaryStatus = StatusEnum.Pending;
    },
    [getShortCurrentEarningSummary.fulfilled.type]: (
      state: IncentivesState,
      action: PayloadAction<CurrentShortEarningSummaryType>,
    ) => {
      state.currentShortEarningSummary = action.payload;
      state.currentShortEarningSummaryStatus = StatusEnum.Fulfilled;
    },
    [getShortCurrentEarningSummary.rejected.type]: (state: IncentivesState) => {
      state.currentShortEarningSummary = {} as CurrentShortEarningSummaryType;
      state.currentShortEarningSummaryStatus = StatusEnum.Rejected;
    },

    [getEarningSummary.pending.type]: (
      state: IncentivesState,
      action: PayloadAction<CurrentEarningSummaryType, string, CurrentEarningSummaryMetaType>,
    ) => {
      state.earningSummaryById[action.meta.arg] = INCENTIVE_FOR_PRELOADER as CurrentEarningSummaryType;
      state.earningSummaryStatus = StatusEnum.Pending;
    },
    [getEarningSummary.fulfilled.type]: (
      state: IncentivesState,
      action: PayloadAction<CurrentEarningSummaryType, string, CurrentEarningSummaryMetaType>,
    ) => {
      state.earningSummaryById[action.meta.arg] = action.payload;
      state.earningSummaryStatus = StatusEnum.Fulfilled;
    },
    [getEarningSummary.rejected.type]: (
      state: IncentivesState,
      action: PayloadAction<CurrentEarningSummaryType, string, CurrentEarningSummaryMetaType>,
    ) => {
      state.earningSummaryById[action.meta.arg] = {} as CurrentEarningSummaryType;
      state.earningSummaryStatus = StatusEnum.Rejected;
    },

    [getHistoricalPrograms.pending.type]: (state: IncentivesState) => {
      state.historicalPrograms = [];
      state.historicalProgramsStatus = StatusEnum.Pending;
    },
    [getHistoricalPrograms.fulfilled.type]: (
      state: IncentivesState,
      action: PayloadAction<HistoricalProgramType[]>,
    ) => {
      state.historicalPrograms = action.payload;
      state.historicalProgramsStatus = StatusEnum.Fulfilled;
    },
    [getHistoricalPrograms.rejected.type]: (state: IncentivesState) => {
      state.historicalPrograms = [];
      state.historicalProgramsStatus = StatusEnum.Rejected;
    },

    [saveSelfReportedEntry.pending.type]: (state: IncentivesState) => {
      state.saveFormStatus = StatusEnum.Pending;
    },
    [saveSelfReportedEntry.fulfilled.type]: (
      state: IncentivesState,
      action: PayloadAction<unknown, string, { arg: SelfRecordedGoalType }>,
    ) => {
      state.saveFormStatus = StatusEnum.Fulfilled;
      state.selfReportedGoals = {
        ...state.selfReportedGoals,
        [action.meta.arg.id]: action.meta.arg,
      };
    },
    [saveSelfReportedEntry.rejected.type]: (state: IncentivesState) => {
      state.saveFormStatus = StatusEnum.Rejected;
    },

    [deleteSelfReportedEntry.pending.type]: (state: IncentivesState) => {
      state.saveFormStatus = StatusEnum.Pending;
    },
    [deleteSelfReportedEntry.fulfilled.type]: (
      state: IncentivesState,
      action: PayloadAction<number, string, { arg: number }>,
    ) => {
      state.saveFormStatus = StatusEnum.Fulfilled;
      state.selfReportedGoals = {
        ...state.selfReportedGoals,
        [action.meta.arg]: undefined,
      };
    },
    [deleteSelfReportedEntry.rejected.type]: (state: IncentivesState) => {
      state.saveFormStatus = StatusEnum.Rejected;
    },

    [getSelfReportedEntry.pending.type]: (state: IncentivesState) => {
      state.selfReportedGoalStatus = StatusEnum.Pending;
    },
    [getSelfReportedEntry.fulfilled.type]: (state: IncentivesState, action) => {
      state.selfReportedGoalStatus = StatusEnum.Fulfilled;
      state.selfReportedGoals = {
        ...state.selfReportedGoals,
        [action.payload.id]: action.payload,
      };
    },
    [getSelfReportedEntry.rejected.type]: (state: IncentivesState) => {
      state.selfReportedGoalStatus = StatusEnum.Rejected;
    },

    [getCurrentGoal.pending.type]: (state: IncentivesState) => {
      state.currentEarningSummaryStatus = StatusEnum.Pending;
    },
    [getCurrentGoal.fulfilled.type]: (state: IncentivesState, action) => {
      const { currentEarningSummary } = current(state);

      state.currentEarningSummaryStatus = StatusEnum.Fulfilled;
      state.incentiveDocumentRemoveStatus = StatusEnum.Uninitialized;
      state.currentEarningSummary = {
        ...currentEarningSummary,
        applicable_goals: currentEarningSummary.applicable_goals?.map?.((goal) =>
          goal.goal_id === action.payload.goal_id ? action.payload : goal,
        ),
      };
    },
    [getCurrentGoal.rejected.type]: (state: IncentivesState) => {
      state.currentEarningSummaryStatus = StatusEnum.Rejected;
      state.incentiveDocumentRemoveStatus = StatusEnum.Uninitialized;
    },

    [uploadIncentivesForm.pending.type]: (state) => {
      state.uploadingFormStatus = StatusEnum.Pending;
    },

    [uploadIncentivesForm.fulfilled.type]: (state) => {
      state.uploadingFormStatus = StatusEnum.Fulfilled;

      state.incentiveDocument = initialState.incentiveDocument;
      state.incentiveDocumentStatus = initialState.incentiveDocumentStatus;
    },

    [uploadIncentivesForm.rejected.type]: (state) => {
      state.uploadingFormStatus = StatusEnum.Rejected;
    },

    [getListOfDocuments.pending.type]: (state) => {
      state.documentsListStatus = StatusEnum.Pending;
    },

    [getListOfDocuments.fulfilled.type]: (state, action: PayloadAction<DocumentsResponse>) => {
      if (state.documentsListStatus === StatusEnum.Pending) {
        state.documentsListStatus = StatusEnum.Fulfilled;
        state.documentsList = action.payload;
      }
    },

    [getListOfDocuments.rejected.type]: (state) => {
      state.documentsListStatus = StatusEnum.Rejected;
    },

    [getIncentiveDocument.pending.type]: (state) => {
      state.incentiveDocumentStatus = StatusEnum.Pending;
    },

    [getIncentiveDocument.fulfilled.type]: (state: IncentivesState, action) => {
      state.incentiveDocumentStatus = StatusEnum.Fulfilled;
      state.incentiveDocument = action.payload;
    },

    [getIncentiveDocument.rejected.type]: (state) => {
      state.incentiveDocumentStatus = StatusEnum.Rejected;
    },

    [removeIncentiveDocument.pending.type]: (state) => {
      state.incentiveDocumentRemoveStatus = StatusEnum.Pending;
    },

    [removeIncentiveDocument.fulfilled.type]: (state: IncentivesState, action) => {
      state.incentiveDocumentRemoveStatus = StatusEnum.Fulfilled;
      state.incentiveDocument = INCENTIVE_DOCUMENT_FOR_PRELOADER || action;
    },

    [removeIncentiveDocument.rejected.type]: (state) => {
      state.incentiveDocumentRemoveStatus = StatusEnum.Rejected;
    },

    [getActivePrograms.pending.type]: (state) => {
      state.activeProgramsStatus = StatusEnum.Pending;
    },

    [getActivePrograms.fulfilled.type]: (state: IncentivesState, action: PayloadAction<ActiveProgramType[]>) => {
      state.activeProgramsStatus = StatusEnum.Fulfilled;
      state.activePrograms = action.payload;
    },

    [getActivePrograms.rejected.type]: (state) => {
      state.activeProgramsStatus = StatusEnum.Rejected;
    },

    [checkProgramCompletionAcknowledged.fulfilled.type]: (
      state,
      action: ReturnType<typeof checkProgramCompletionAcknowledged.fulfilled>,
    ) => {
      state.programCompletionAcknowledgedByProgramId[action.meta.arg.programId] = action.payload;
    },
    [setProgramCompletionAcknowledged.pending.type]: (
      state,
      action: ReturnType<typeof setProgramCompletionAcknowledged.pending>,
    ) => {
      state.programCompletionAcknowledgedByProgramId[action.meta.arg.programId] = true;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const incentivesReducer = incentivesSlice.reducer;
export const {
  openHistoricalPrograms,
  closeHistoricalPrograms,
  openSelfReportedGoal,
  closeSelfReportedGoal,
  resetUploadingFormStatus,
  resetDocumentsList,
  setActiveProgram,
} = incentivesSlice.actions;
