export enum MailboxTypeEnum {
  Inbox = 'inbox',
  Draft = 'draft',
  Sent = 'sentbox',
  Archive = 'archived',
}

export const DEFAULT_ITEMS_ON_PAGE = 10;
export const DEFAULT_PAGE = 1;
export const MESSAGE_LINKED_FAMILY_MEMBERS_ID = 'messaging-linked-family-id';
export const MEMBER_RELATIONS_NAME = 'Member Relations';
