import { PRIVACY_PRACTICES_LINK } from '@common/constants';

export const locale = {
  header: 'Marathon Health, LLC',
  sub_header: 'PRIVACY POLICY',
  p_1: 'Marathon Health, LLC (<b>"Marathon"</b>) is committed to building a lasting and trusted relationship with our clients and patients. This Privacy Policy sets forth how we collect, use and safeguard information you entrust with us. We use this data to help provide better care to you, and to keep you informed about opportunities to engage with Marathon and our affiliated companies, service providers and subcontractors that may help improve your health and wellness or the health and wellness of your employees.',
  p_2: 'This Privacy Policy describes the types of information we collect from you directly, from your interactions with us, how we use it, how we protect it, and your rights as our client or patient. We refer all websites and applications that are owned or operated by Marathon (each, a <b>"Website"</b> and collectively, the <b>"Websites"</b>), and we refer to all applications available through the Websites (including our Member Portal) and the Marathon Health Mobile App (available on the Apple App Store and the Google Play Store) as our <b>“Applications."</b> Our browser-based Websites are hosted on both the marathon-health.com and ourhealth.org domains.',
  p_3: `However, this Privacy Policy does not describe how we use, store and maintain personal health information. <a href="${PRIVACY_PRACTICES_LINK}" target="_blank">Please click here to view our HIPAA Notice of Privacy Practices.</a> You may also request a paper copy of our HIPAA Notice of Privacy Practices from any of our clinics.`,
  item_1: 'Personal Information We Collect or Maintain',
  p_item1_1:
    'When we refer in this Internet Privacy Policy to "Personal Information," we mean any information that can be used to identify you. Examples of Personal Information include your name, address, email address, telephone number, date of birth and social security number. Personal Information collected by us will vary depending on which of our Websites you use.',
  item_2: 'Retention of Personal Information',
  p_item2_1:
    'We retain Personal Information on our servers (or on servers hosted by our third-party service providers) for as long as the information is relevant to our business purposes or until you request that we remove the data by contacting us at <a href="mailto:privacy@marathon-health.com" target="_blank">privacy@marathon-health.com</a> or write to us at:',
  p_item2_2: 'Marathon Health, LLC 20 Winooski Falls Way, 4th Floor Winooski, VT 05404',
  item_3: 'E-mail Communications',
  p_item3_1:
    'By providing your electronic mail ("e-mail") address and other electronic means of communication to Marathon, you authorize Marathon to communicate with you by e-mail or other electronic means of communication regarding news about Marathon, marketing initiatives, workshop and educational opportunities, and other promotional communications. You may opt-out from promotional e-mail communications at any time by following the instructions to unsubscribe included in each e-mail.',
  p_item3_2:
    'We may also send appointment reminders and updates regarding our clinics, hours of operation and our providers to you via e-mail. You can opt-out of these communications by updating your profile on our Member Portal.',
  p_item3_3:
    'We strongly discourage our patients from communicating with us regarding any confidential health information via unsecured e-mail. If you choose to e-mail us regarding your medical conditions or any other health information, you should be aware that your e-mail may not remain secure and that your confidential health information may not remain confidential. Instead, we encourage you to contact us through our Member Portal or through the Marathon Health Mobile App.',
  item_4: 'Cookies',
  p_item4_1:
    'From time to time, our Websites may collect anonymous, non-personal information about your use of this site through the use of "cookies." Cookies are small bits of information that we transfer to your computer\'s hard drive that allow us to know how often you visit our Websites and the activities you conduct while on our Website (such as the pages you viewed or Website features you accessed). We automatically assign a different cookie to each user. The information collected by cookies helps us generate content and information on web pages specifically designed for you. It also allows us to monitor how many people use this site and for what purpose. We may use cookie information to target certain information to your browser or to determine the popularity of certain content.',
  p_item4_2:
    'Your browser software can be set to reject all cookies. Most browsers offer instructions on how to reset the browser to reject cookies in the "Help" section of the toolbar. If you reject our cookie, certain functions and conveniences of the Websites may not work properly. We never collect Personal Information through the use of cookies.',
  item_5: 'Other Analytics',
  p_item5_1:
    'We are constantly improving our Websites, including our Marathon Health mobile app. We may track and analyze your usage of the Marathon Health mobile app. The way you use the app will help us decide what features we should add or improve. We will never track Personal Information through our mobile app.',
  p_item5_2:
    '<b>As of the date of this Privacy Policy, the analytics tracking feature on our mobile app is not active. Once we enable it on our mobile app, you can always opt-out from analytics tracking by opening the “App Settings” and toggling the analytics setting to the “off” position.</b>',
  item_6: 'Our Internal Privacy and Confidentiality Policy',
  p_item6_1:
    'We value and respect the privacy and confidentiality of the individuals and organizations that use our Applications and our Websites. Subject to applicable law, Personal Information is accessed only to maintain and improve the Websites and our Applications, communicate with you and to otherwise deliver our services. Furthermore, we employ internal security measures to protect your information against both unauthorized access and misuse by authorized users. (See "Technical Security Measures" below.)',
  item_7: 'Use and Disclosure of Your Personal Information',
  p_item7_1:
    'We will not disclose or provide access to your Personal Information to any third party, unless: (a) we receive your prior consent directly from you or your authorized representative or in the case of children under the age of 18, to your parent or guardian, (b) we believe the recipient to be you or your authorized representative, (c) if required or permitted to do so by law, or in the good-faith belief that such action is necessary to protect and defend the rights or property of Marathon or the users of the Websites, to act under urgent circumstances to protect our safety or our employees or a member of the public, or to comply with a judicial proceeding, court order, or legal process.',
  p_item7_2:
    'In addition, we may work with our business partners or service providers who may perform certain functions on our behalf, such as managing data, hosting our Websites, sending e-mail messages, data analysis, and mailings. These business partners and service providers have access to your Personal Information only to the extent necessary to perform these specific functions and may not use it for any other purpose.',
  p_item7_3:
    'If another entity acquires us or our assets, Personal Information and other information that we have collected may be disclosed to such entity as one of the transferred assets. Also, if any bankruptcy or reorganization proceeding is brought by or against us, all such information may be considered an asset of ours and as such may be sold or transferred to third parties.',
  item_8: 'Technical Security Measures',
  p_item8_1:
    'We employ commercially reasonable security standards to protect Personal Information provided by you through the Websites. However, we cannot guarantee that the information submitted to, maintained on, or transmitted from the Websites will be completely secure and transmission of information over the Internet is susceptible to possible loss, misrouting, interception and misuse.',
  item_9: 'Links to Other Websites, Content Contractors and Their Cookies',
  p_item9_1:
    'Certain content and services offered to you through these Websites are stored on websites not hosted or operated by us. For your convenience, we also provide links to websites that are not operated by us. We refer to such web sites as "Third Party Websites." We do not disclose your Personal Information to Third Party Websites, but you should be aware that any information you disclose once you access these other websites is not subject to this Privacy Policy. In addition, Third Party Websites may use their own cookies when you click on their advertisements or link to their website or service. We have no access to, or control over, these cookies. We do not endorse and are not responsible for the privacy practices of any Third Party Website. You should review the privacy policy posted on each Third Party Website to understand how that web site collects and uses Personal Information.',
  item_10: 'Privacy of Children',
  p_item10_1:
    "We encourage parents and guardians to be aware of and participate in their children's online activities. We strictly adhere to the Children's Online Privacy Protection Act and will not knowingly collect, use or disclose Personal Information from any child under the age of 13 in any manner that violates this law.",
  item_11: 'Use of De-Identified Information',
  p_item11_1:
    'We may at times aggregate de-identified Personal Information and may compile and distribute statistical analyses and reports utilizing aggregated data derived from this information. We may also share such information with our partners. Any information that can be traced back to an individual, including, for example, name, address, telephone numbers and e-mail addresses, will not be included in the aggregate data.',
  item_12: 'Text/SMS Message Consent and Opt-Out',
  p_item12_1:
    'If you choose, you can provide your mobile phone number to receive text message alerts from us to receive periodic marketing messages or reminders from us. You agree that by providing your mobile phone number you expressly consent to receive automated text messages from us to the mobile phone number provided. Your consent to receiving text messages from us is never required to access our services. Message and data rates will apply and you should check the rates of your mobile carrier. You can opt out from further text marketing communications by texting STOP to the SMS number used by us to contact you.',
  p_item12_2:
    "We may share your mobile phone number with service providers with whom we contract to assist us with the above activities, but we will not share your mobile phone number with third parties for their own marketing purposes without your consent. Text messages are distributed via third party mobile network providers and, therefore, we cannot control certain factors relating to message delivery. Depending on the recipient's mobile carrier, it may not be possible to transmit the text message to the recipient successfully; nor is content available on all carriers. We do not claim or guarantee availability or performance of this service, including liability for transmission delays or message failures.",
  item_13: 'Changes to Privacy Statement; Contact Information',
  p_item13_1:
    'CIf you have any question regarding this Privacy Policy, if you want to correct any Personal Information that we have collected, or if you feel that we are not abiding by this Policy, you should contact us as follows:',
  p_item13_2: 'Marathon Health, LLC Attn: Privacy Officer 20 Winooski Falls Way, Suite 400 Winooski, VT 05404',
  p_item13_3: '<a href="mailto:privacy@marathon-health.com" target="_blank">privacy@marathon-health.com</a>',
  p_item13_4: 'Last Updated: October 7, 2021',
};
