import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { getProvider } from './actions';
import { ProviderBioState, ProviderBioType } from './types';

const initialState: ProviderBioState = {
  data: null,
  status: StatusEnum.Uninitialized,
};

export const providerBioSlice = createSlice({
  name: 'providerBio',
  initialState,
  reducers: {
    resetProviderBioData: () => initialState,
  },
  extraReducers: {
    [getProvider.pending.type]: (state: ProviderBioState) => {
      state.status = StatusEnum.Pending;
    },
    [getProvider.fulfilled.type]: (state: ProviderBioState, action: PayloadAction<ProviderBioType>) => {
      state.status = StatusEnum.Fulfilled;
      state.data = action.payload;
    },
    [getProvider.rejected.type]: (state: ProviderBioState) => {
      state.status = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const providerBioReducer = providerBioSlice.reducer;
export const { resetProviderBioData } = providerBioSlice.actions;
