import { createSelector, Selector } from 'reselect';

import { filterMenuItemsByFlippers, filterMenuItemsByUserId } from '@common/utils/filterMenuItems';
import { FlipperType } from '@modules/flippers';

import { TAB_LIST } from '../constants';

export const getFlippersSelector = (state: RootState) => state.flippers.flippers;

export const userIdSelector = (state: RootState) => state.userProfile.userProfileData.id;
const drySelectedFamilyMemberIdSelector = (state: RootState) => state.myHealth.selectedFamilyMemberId;

export const selectedFamilyMemberIdSelector = createSelector(
  userIdSelector,
  drySelectedFamilyMemberIdSelector,
  (userId, drySelectedFamilyMemberId) => drySelectedFamilyMemberId ?? userId,
);

export const isSourceUserAndSelectedFamilyMemberSimilarSelector: Selector<RootState, boolean> = createSelector(
  selectedFamilyMemberIdSelector,
  userIdSelector,
  (selectedFamilyMemberId, userId) => selectedFamilyMemberId === userId,
);

export const getVisibleMenuItems = createSelector(
  getFlippersSelector,
  isSourceUserAndSelectedFamilyMemberSimilarSelector,
  (flippers, isSourceUserAndSelectedFamilyMemberSimilar) => {
    const getFlipperValue = (flipper: FlipperType): boolean => flippers[flipper] ?? false;
    const filteredBySource = filterMenuItemsByUserId(TAB_LIST, isSourceUserAndSelectedFamilyMemberSimilar);

    return filterMenuItemsByFlippers(filteredBySource, getFlipperValue);
  },
);
