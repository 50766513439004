import React from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { Modal, useModal } from '@components/Modal';
import { getImpersonationStatus } from '@modules/Login';
import { getSupplementalWaiverSignStatusSelector } from '@modules/SuplementalWaiver';
import { supplementalWaiverRequiredSelector, userProfileDataSelector } from '@modules/userProfileData';

import { Content } from './components/Content';

import styles from './CapturePrimaryLanguage.module.css';

const MODAL_ID = 'capture_primary_language_modal';

const cn = classNames.bind(styles);
export const CapturePrimaryLanguage = () => {
  const { primary_language: primaryLanguage, id } = useSelector(userProfileDataSelector);
  const supplementalWaiverRequired = useSelector(supplementalWaiverRequiredSelector);
  const supplementalWaiverSignStatus = useSelector(getSupplementalWaiverSignStatusSelector);
  const isImpersonation = useSelector(getImpersonationStatus);

  const visible = !primaryLanguage && !isImpersonation && !!id;

  const visibleOnlyAfterSupplementalWaiverSigned = supplementalWaiverRequired
    ? supplementalWaiverSignStatus === StatusEnum.Fulfilled && visible
    : visible;

  const { onCloseModal, modalOpen } = useModal(visibleOnlyAfterSupplementalWaiverSigned);

  return (
    <Modal open={modalOpen} onClose={onCloseModal} id={MODAL_ID} paperClassName={cn('paper')}>
      <Content onClose={onCloseModal} />
    </Modal>
  );
};
