import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@common/routes';
import { ShowFlippersEnum, useFlippers } from '@modules/flippers';

export const useMaintenance = () => {
  const navigate = useNavigate();
  const getFlipperValue = useFlippers();
  const maintenanceFlipper = getFlipperValue(ShowFlippersEnum.maintenance_mode);

  useEffect(() => {
    if (maintenanceFlipper) {
      navigate(ROUTES.MAINTENANCE);
    }
  }, [maintenanceFlipper, navigate]);
};
