export const locale = {
  no_data: 'No workshops to display.',
  no_data_completed: 'No completed workshops to display.',
  no_data_in_progress: 'No in progress and/or incentivized workshops to display.',
  week: 'Week {{week}}',
  all: 'All',
  filter_names: {
    all: 'All Available Workshops',
    is_incented: 'In progress and/or incentivized workshops',
    is_completed: 'Completed workshops',
  },
};
