import { createSelector, Selector } from 'reselect';

import { UserProfileType } from '@common/types';
import { differenceInYears } from '@common/utils/dateTimeUtil';

import { FamilyMemberType } from '../types';
import { YEAR_UNDERAGE, LOW_YEAR_UNDERAGE } from './constants';

export const userProfileSelector = (state: RootState) => state.userProfile;
export const activeEligibilitiesSelector = (state: RootState) => state.userProfile.activeEligibilities;
export const activeEligibilitiesStatusSelector = (state: RootState) => state.userProfile.activeEligibilitiesStatus;

export const communicationPreferencesSelector = (state: RootState) => state.userProfile.communicationPreferences;
export const communicationPreferencesStatusSelector = (state: RootState) =>
  state.userProfile.communicationPreferencesStatus;
export const communicationPreferencesSaveStatusSelector = (state: RootState) =>
  state.userProfile.communicationPreferencesSaveStatus;

export const userProfileStatusSelector = (state: RootState) => state.userProfile.userProfileStatus;
export const familyMembersStatusSelector = (state: RootState) => state.userProfile.familyMembersStatus;
export const schedulableFamilyMembersSelector = (state: RootState) => state.userProfile.schedulableFamilyMembers;
export const familyMemberIdSelector = (state: RootState) => state.userProfile.familyMemberId;
export const familyMembersProfilesSelector = (state: RootState) => state.userProfile.familyMembersProfiles;
export const schedulableFamilyMembersStatusSelector = (state: RootState) =>
  state.userProfile.schedulableFamilyMembersStatus;
export const userProfileDataSelector = (state: RootState) => state.userProfile.userProfileData;
export const saveStatusSelector = (state: RootState) => state.userProfile.saveStatus;
export const currentEligibilitySelector = (state: RootState) => state.userProfile.currentEligibility;
export const getIsRestrictedSelector = (state: RootState) => state.userProfile.isRestricted;
export const currentEligibilityEmployerIdSelector = (state: RootState) =>
  state.userProfile.currentEligibility?.employer?.id;
export const currentEligibilityEmployerQuestionnaireToEnableSelector = (state: RootState) =>
  state.userProfile.currentEligibility?.employer?.questionnaire_to_enable;
export const supplementalWaiverRequiredSelector = (state: RootState) => state.userProfile.isSupplementalWaiverRequired;
export const getFamilyMembersSelector = (state: RootState) => state.userProfile.familyMembers;
export const getSaveStatusSelector = (state: RootState) => state.userProfile.saveFamilyMemberStatus;
export const getHcbbUrlSelector = (state: RootState) => state.userProfile.hcbbUrl;
export const changeCurrentEligibilityIdSelector = (state: RootState) => state.userProfile.changeCurrentEligibilityId;

export const homeAddressStatusSelector = (state: RootState) => state.userProfile.homeAddressStatus;
export const homeAddressSelector = (state: RootState) => state.userProfile.homeAddress;
export const homeAddressSaveStatusSelector = (state: RootState) => state.userProfile.homeAddressSaveStatus;
export const isActiveIncentiveProgramSelector = (state: RootState) => state.userProfile.isActiveIncentiveProgram;
export const isPreviewableIncentiveProgramSelector = (state: RootState) =>
  state.userProfile.isPreviewableIncentiveProgram;
export const isOnBoardingPassedSelector = (state: RootState) => state.userProfile.isOnBoardingPassed;
export const getUserIdSelector = (state: RootState) => state.userProfile.userProfileData.id;
export const saveRxWorthySelector = (state: RootState) => state.userProfile.saveRxWorthy;
export const wellnessIdSelector = (state: RootState) => state.userProfile.userProfileData.wellness_id;
export const userTimeZoneSelector = (state: RootState) => state.userProfile.ianaTimeZone;

export const getScheduledFamilyMembersForSelectSelector = createSelector(
  schedulableFamilyMembersSelector,
  userProfileDataSelector,
  (familyMembers, userProfileData) => {
    const filteredMembers = familyMembers.filter((familyMember) => !familyMember.restrict_health_center_activity);
    const currentUserData = userProfileData as unknown as FamilyMemberType;

    return [currentUserData, ...filteredMembers].map((familyMember) => ({
      ...familyMember,
      name: familyMember.first_name,
    }));
  },
);

export const isOneScheduledFamilyMemberSelector = createSelector(
  getScheduledFamilyMembersForSelectSelector,
  (familyMembers) => familyMembers.length === 1,
);

export const isCurrentUserSelector = createSelector(
  getUserIdSelector,
  familyMemberIdSelector,
  (userId, familyMemberId) => Number(userId) === Number(familyMemberId),
);

const currentUserForSelectSelector = createSelector(
  userProfileDataSelector,
  currentEligibilitySelector,
  (userProfileData, currentEligibility) => ({
    eligibility_type_name: currentEligibility?.eligibility_type_name,
    first_name: userProfileData.first_name,
    last_name: userProfileData.last_name,
    preferred_first_name: userProfileData.preferred_first_name,
    middle_name: userProfileData.middle_name,
    user_name: userProfileData.user_name,
    id: userProfileData.id,
  }),
);

const lowYearSelector = (_: unknown, lowYear = false) => lowYear;
export const getUnderageFamilyMembersSelector = createSelector(
  getFamilyMembersSelector,
  currentUserForSelectSelector,
  lowYearSelector,
  (familyMembers, currentUserForSelect, lowYear) => {
    const UNDERAGE = lowYear ? LOW_YEAR_UNDERAGE : YEAR_UNDERAGE;
    const filteredFamilyMembers = familyMembers.filter(
      (familyMember) => differenceInYears(new Date(), new Date(familyMember.born_on)) < UNDERAGE,
    );

    const currentUserData = currentUserForSelect as unknown as FamilyMemberType;

    return [currentUserData, ...filteredFamilyMembers].map((familyMember) => ({
      ...familyMember,
      name: familyMember.preferred_first_name ? familyMember.preferred_first_name : familyMember.first_name,
    }));
  },
);

export const userProfileByIdSelector = createSelector(
  familyMembersProfilesSelector,
  userProfileDataSelector,
  familyMemberIdSelector,
  (familyMembersProfiles, userProfileData, familyMemberId): UserProfileType => {
    if (familyMemberId && !!familyMembersProfiles[familyMemberId]?.data) {
      return familyMembersProfiles[familyMemberId]?.data as UserProfileType;
    }

    return userProfileData;
  },
);

export const userProfileStatusByIdSelector = createSelector(
  familyMembersProfilesSelector,
  userProfileStatusSelector,
  familyMemberIdSelector,
  (familyMembersProfiles, userProfileStatus, familyMemberId) => {
    if (familyMemberId && familyMembersProfiles[familyMemberId]?.gettingStatus) {
      return familyMembersProfiles[familyMemberId]?.gettingStatus;
    }

    return userProfileStatus;
  },
);

export const familyColorsSelector = createSelector(
  userProfileSelector,
  ({ familyMembersColors }) => familyMembersColors,
);

export const isSourceUserAndSelectedFamilyMemberSimilarSelector: Selector<RootState, boolean> = createSelector(
  familyMemberIdSelector,
  getUserIdSelector,
  (selectedFamilyMemberId, userId) => selectedFamilyMemberId === userId,
);
