import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { parseQueryParams } from '@common/router';

import { getRouteSelector, mobileLogin } from '../store';

export const useMobileLoginRedirect = () => {
  const route = useSelector(getRouteSelector);
  const dispatch = useDispatch();
  const { jump_token: jumpToken } = parseQueryParams(route?.from?.search ?? '');

  useEffect(() => {
    if (jumpToken) {
      dispatch(mobileLogin(jumpToken));
    }
  }, [dispatch, jumpToken]);
};
