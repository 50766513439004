import { createSelector, Selector } from 'reselect';

import { getNetworkByUserId, getNetworkForAllMembersSelector, ClinicType, ProviderType } from '@modules/Networks';
import {
  ALLOWED_ELIGIBILITIES_FOR_FAMILY_MEMBERS,
  currentEligibilitySelector,
  EligibilityTypesEnum,
  getFamilyMembersSelector,
  getUserIdSelector,
} from '@modules/userProfileData';

import { MailboxTypeEnum } from '../constants';
import { MailboxItemType, MailboxType, MessageType } from './types';

export const getMessagesSelector = (state: RootState) => state.messages;
export const unreadCountFamilyMembersSelector = (state: RootState) => state.messages.unreadMessages.users;

export const unreadMessagesSelector = createSelector(
  getUserIdSelector,
  currentEligibilitySelector,
  getFamilyMembersSelector,
  unreadCountFamilyMembersSelector,
  (userId, currentEligibility, familyMembers, unreadCountFamilyMembers) => {
    const isUserCouldSeeOtherFamilyMembersMessages = ALLOWED_ELIGIBILITIES_FOR_FAMILY_MEMBERS.includes(
      currentEligibility?.eligibility_type_name as EligibilityTypesEnum,
    );

    const familyMembersMessagesCount = isUserCouldSeeOtherFamilyMembersMessages
      ? familyMembers.map((member) => ({
          userId: member.id,
          count: unreadCountFamilyMembers[member.id],
          name: `${member.first_name} ${member.last_name}`,
        }))
      : [];

    return [{ userId, count: unreadCountFamilyMembers[userId], name: '' }, ...familyMembersMessagesCount].filter(
      ({ userId }) => unreadCountFamilyMembers[userId] > 0,
    );
  },
);

export const isOnlyCurrentUserUnreadMessagesSelector = createSelector(
  getUserIdSelector,
  unreadMessagesSelector,
  (userId, unreadMessages) => unreadMessages.length === 1 && unreadMessages[0].userId === userId,
);
export const unreadCountSelector = createSelector(unreadMessagesSelector, (unreadMessages) =>
  unreadMessages.reduce((acc, { count }) => acc + count, 0),
);
export const unreadCountStatusSelector = (state: RootState) => state.messages.unreadMessagesStatus;

const getMessagesListSelector: Selector<RootState, MailboxType> = createSelector(
  getMessagesSelector,
  ({ mailbox }) => mailbox,
);

const getMessageTypeSelector = (_: unknown, { type }: { type: MailboxTypeEnum }) => type;
const getMessageConversationid = (_: unknown, { conversationId }: { conversationId: string }) => conversationId;

export const getMessagesByTypeSelector: Selector<RootState, MailboxType[MailboxTypeEnum]['items']> = createSelector(
  getMessagesListSelector,
  getMessageTypeSelector,
  (mailbox, type) => mailbox[type]?.items ?? [],
);

export const messagesByTypeCountSelector: Selector<RootState, MailboxType[MailboxTypeEnum]['total']> = createSelector(
  getMessagesListSelector,
  getMessageTypeSelector,
  (mailbox, type) => mailbox[type]?.total,
);

export const getMailboxItemByConversationId: Selector<RootState, Nullable<MailboxItemType>> = createSelector(
  getMessagesByTypeSelector,
  getMessageConversationid,
  (messagesByType, conversationId) => messagesByType.find((message) => message.id === Number(conversationId)),
);

export const getConversationMessagesSelector: Selector<RootState, Nullable<Array<MessageType>>> = createSelector(
  getMessagesSelector,
  (messages) => messages.conversationMessages,
);

export const getConversationMessagesNoDraftSelector: Selector<RootState, Nullable<Array<MessageType>>> = createSelector(
  getConversationMessagesSelector,
  (conversationMessages) => conversationMessages?.filter(({ draft }) => !draft),
);

export const getConversationMessagesStatusSelector = createSelector(
  getMessagesSelector,
  ({ conversationMessagesStatus }) => conversationMessagesStatus,
);

export const getMailboxStatusSelector = createSelector(getMessagesSelector, ({ mailboxStatus }) => mailboxStatus);

export const draftIdSelector = createSelector(
  getConversationMessagesSelector,
  (conversationMessages) => conversationMessages?.find((conversationMessage) => conversationMessage.draft)?.id,
);

export const changeStatusSelector = createSelector(getMessagesSelector, ({ changeStatus }) => changeStatus);

export const getEditMessageSelector = (state: RootState) => state.editMessage;
export const sendStatusSelector = createSelector(getEditMessageSelector, ({ sendStatus }) => sendStatus);
export const getUserNameSelector = (state: RootState) =>
  `${state.userProfile.userProfileData.first_name} ${state.userProfile.userProfileData.last_name}`;

export const getNetworkSelector = createSelector(
  getNetworkForAllMembersSelector,
  getUserIdSelector,
  (networkForAllMembers, userId) => getNetworkByUserId(networkForAllMembers, userId),
);

export const getClinicGroupsSelector = createSelector(getNetworkSelector, (network) => network?.clinic_groupings ?? []);
export const getClinicsSelector: Selector<RootState, ClinicType[]> = createSelector(
  getClinicGroupsSelector,
  (clinicGroups) => clinicGroups.reduce<ClinicType[]>((acc, clinicGroup) => acc.concat(clinicGroup.clinics), []),
);
export const getProvidersSelector: Selector<RootState, ProviderType[]> = createSelector(
  getNetworkSelector,
  (network) => network?.providers ?? [],
);

export const pageChangingSelector: Selector<RootState, Nullable<boolean>> = createSelector(
  getMessagesSelector,
  ({ pageChanging }) => pageChanging,
);
