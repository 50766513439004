import React from 'react';

import { NavigateProps, useParams, Navigate } from 'react-router';

import { useQueryParamsObject } from '@common/router/hooks';
import { generatePathWithParamsAndQuery } from '@common/router/utils';

export const ParametricNavigate = ({ to, ...restProps }: NavigateProps) => {
  const params = useParams();
  const query = useQueryParamsObject();

  return <Navigate to={generatePathWithParamsAndQuery(to as string, params, query)} {...restProps} />;
};
