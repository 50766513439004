export enum AmPmEnum {
  AM = 'am',
  PM = 'pm',
}

export const INITIAL_HOURS = 1;
export const INITIAL_MINUTES = 0;
export const MAX_MINUTES = 59;
export const AM_PM_MAX_HOURS = 12;
export const MAX_HOURS = 24;

export enum ChangeValueActionsEnum {
  Add = 'add',
  Subtract = 'subtract',
}

export const ITEM_IDS = {
  HOURS: 'clock-spin-button-hours',
  MINUTES: 'clock-spin-button-minutes',
};
