import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { getArticlesByCategory } from './actions';
import { ArticleType, ArticlesState } from './types';

const initialState: ArticlesState = {
  data: [],
  status: StatusEnum.Uninitialized,
  error: {},
};

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    resetArticleState: (state) => {
      state.data = [];
    },
    resetStatus: (state) => {
      state.status = StatusEnum.Uninitialized;
    },
  },
  extraReducers: {
    [getArticlesByCategory.pending.type]: (state: ArticlesState) => {
      state.data = [];
      state.status = StatusEnum.Pending;
    },
    [getArticlesByCategory.fulfilled.type]: (state: ArticlesState, action: PayloadAction<ArticleType[]>) => {
      state.status = StatusEnum.Fulfilled;
      state.data = action.payload;
    },
    [getArticlesByCategory.rejected.type]: (state: ArticlesState) => {
      /** TODO-Samanth Accurate Error-Handling */
      state.status = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const articlesReducer = articlesSlice.reducer;

export const { resetArticleState, resetStatus } = articlesSlice.actions;
