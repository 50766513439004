import { MeasurementEnum } from '../../constants';
import { locale } from './locale';

export const MEASUREMENT_TYPES = [
  {
    title: locale.measurements[MeasurementEnum.Ounces],
    shortTitle: locale.measurementsShort[MeasurementEnum.Ounces],
    yScaleTitle: locale.measurementsYScaleTitle[MeasurementEnum.Ounces],
    type: MeasurementEnum.Ounces,
  },
];
