import React from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { useQueryParamsObject } from '@common/router';
import { SideBarWidget } from '@components/SideBarWidget';
import { useGlobalLocker } from '@modules/GlobalLocker';

import { useSamlLoginRedirect } from '../../hooks';
import { samlLogin, getLoginStatus } from '../../store';
import { Description } from '../Description';
import { Errors } from '../Errors';

import styles from './Saml.module.css';

const cn = classNames.bind(styles);

type Props = {
  className: string;
};

export const Saml = ({ className }: Props) => {
  const dispatch = useDispatch();
  const status = useSelector(getLoginStatus);
  const { token } = useQueryParamsObject();

  useSamlLoginRedirect();

  useIgnoreEffectDeps(() => {
    if (token) {
      dispatch(samlLogin(token));
    }
  }, [token]);

  useGlobalLocker(status === StatusEnum.Pending);

  return (
    <SideBarWidget className={cn('root', className)}>
      <Description />
      <div className={cn('wrapper')}>
        <div className={cn('container')}>
          <Errors />
        </div>
      </div>
    </SideBarWidget>
  );
};
