import React from 'react';

import classNames from 'classnames/bind';

import { useTimer } from '@common/hooks';
import { Button } from '@components/Button';
import { Paragraph } from '@components/Modal';
import { Typography } from '@components/Typography';

import { FINAL_TIMEOUT } from '../constants';
import { locale } from '../locale';

import styles from '../UserActivityModal.module.css';

const cn = classNames.bind(styles);

type Props = {
  handleSignout: (logoutInactivity: boolean) => unknown;
  handleExtend: () => unknown;
};

export const UserActivityModalContent = ({ handleExtend, handleSignout }: Props) => {
  useTimer(FINAL_TIMEOUT, () => handleSignout(true));

  return (
    <div className={cn('user-activity-modal__content')}>
      <Typography component="p" variant="body1-bold" className={cn('user-activity-modal__header')}>
        {locale.header}
      </Typography>
      <Paragraph html={locale.text} />
      <div className={cn('user-activity-modal__actions')}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleSignout(false)}
          className={cn('user-activity-modal__action')}
        >
          {locale.signout}
        </Button>
        <Button variant="contained" size="small" onClick={handleExtend} className={cn('user-activity-modal__action')}>
          {locale.extend}
        </Button>
      </div>
    </div>
  );
};
