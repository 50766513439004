import React, { useCallback, useState } from 'react';

import classNames from 'classnames/bind';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useScreenSize } from '@common/hooks/useScreenSize';
import { generatePathWithQuery, useQueryParamsObject } from '@common/router';
import { Button } from '@components/Button';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';
import {
  LinkedFamilyMembers as CommonLinkedFamilyMembers,
  LinkedFamilyMembersProps as CommonLinkedFamilyMembersProps,
  useGetFamilyMembers,
  useShowFamilyMembers,
} from '@modules/LinkedFamilyMembers';

import { DEFAULT_PAGE, MESSAGE_LINKED_FAMILY_MEMBERS_ID } from '../../constants';
import { FamilyMemberHintModal } from '../FamilyMemberHintModal';
import { FamilyMemberMessageCount } from '../FamilyMemberMessageCount';
import { locale } from './locale';

import styles from './LinkedFamilyMembers.module.css';

const cn = classNames.bind(styles);

type Props = {
  className?: string;
};
export const LinkedFamilyMembers = ({ className }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { mailboxType, memberId } = useQueryParamsObject();
  const { familyMembers, showFamilyMembers } = useShowFamilyMembers(true);
  const { isMobileView } = useScreenSize();
  useGetFamilyMembers();

  const handleSelectMemberId = useCallback(
    (id: number) => {
      navigate(generatePathWithQuery(pathname, { memberId: id, mailboxType, currentPage: DEFAULT_PAGE }));
    },
    [mailboxType, navigate, pathname],
  );

  const [isHintModalOpen, setIsHintModalOpen] = useState(false);
  const showHintModal = useCallback(() => setIsHintModalOpen(true), []);
  const closeHintModal = useCallback(() => setIsHintModalOpen(false), []);
  const renderCustomOptionValue: CommonLinkedFamilyMembersProps['renderCustomOption'] = (option) => (
    <div className={cn('select-item')}>
      {option.first_name} {option.last_name}
      <FamilyMemberMessageCount memberId={option.id} className={cn('count')} />
    </div>
  );

  if (!showFamilyMembers) {
    return null;
  }

  return (
    <div className={cn('root', className)}>
      <div className={cn('select-container')}>
        <Typography variant="body1" id={MESSAGE_LINKED_FAMILY_MEMBERS_ID}>
          {locale.title}
        </Typography>
        <CommonLinkedFamilyMembers
          className={cn('select')}
          selectedMemberId={Number(memberId)}
          onSelectFamilyMember={handleSelectMemberId}
          showMemberAmount={false}
          fullWidth={isMobileView}
          familyMembers={familyMembers}
          renderCustomOption={renderCustomOptionValue}
          labelId={MESSAGE_LINKED_FAMILY_MEMBERS_ID}
        />
      </div>
      <Typography className={cn('info-container')} variant="body2">
        <Button
          className={cn('info-button')}
          aria-label={locale.records}
          aria-haspopup="dialog"
          onClick={showHintModal}
          noHover
          disableRipple
        >
          <SvgIcon icon="infoOutline" className={cn('icon')} />
          <Typography variant="body2" component="span">
            {locale.records}
          </Typography>
        </Button>
      </Typography>
      <FamilyMemberHintModal open={isHintModalOpen} onClose={closeHintModal} />
    </div>
  );
};
