import React from 'react';

import classNames from 'classnames/bind';

import { Button } from '@components/Button';
import { Modal, ModalSize } from '@components/Modal';
import { Typography } from '@components/Typography';

import { locale } from './locale';

import styles from './FamilyMemberHintModal.module.css';

const cn = classNames.bind(styles);

type Props = {
  onClose: VoidFunction;
  open: boolean;
};

const TITLE_ID = 'family-members-hint-modal-title';

export function FamilyMemberHintModal({ onClose, open }: Props) {
  return (
    <Modal
      className={cn('no-providers-modal')}
      paperClassName={cn('container')}
      open={open}
      onClose={onClose}
      showCrossButton
      ariaLabelledby={TITLE_ID}
      size={ModalSize.Small}
    >
      <Typography variant="h5" className={cn('title')} id={TITLE_ID}>
        {locale.title}
      </Typography>
      <Typography variant="body1">{locale.content}</Typography>

      <Button onClick={onClose} variant="contained" className={cn('button')}>
        {locale.close}
      </Button>
    </Modal>
  );
}
