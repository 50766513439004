export const locale = {
  blood_pressure: 'Blood Pressure',
  show_graphs: 'Show Graphs',
  hide_graphs: 'Hide Graphs',

  show_graph: 'Show Graph',
  aria_show_graph: 'Show Graph for {{title}}',
  hide_graph: 'Hide Graph',
  aria_hide_graph: 'Hide Graph for {{title}}',
  no_data_title: 'No information available.',
  graph: 'graph',
  no_data_message:
    'When biometric information is recorded at an appointment, such as blood pressure or cholesterol, it will\n' +
    'display in your dashboard.',
};
