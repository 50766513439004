import { useDispatch, useSelector } from 'react-redux';

import { useIgnoreEffectDeps } from '@common/hooks';
import { familyMemberIdSelector, getUserIdSelector, selectFamilyMemberId } from '@modules/userProfileData';

export function useSelectFamilyMember() {
  const userId = useSelector(getUserIdSelector);
  const familyMemberId = useSelector(familyMemberIdSelector);
  const dispatch = useDispatch();

  useIgnoreEffectDeps(() => {
    if (!familyMemberId && userId) {
      dispatch(selectFamilyMemberId(userId));
    }
  }, [userId]);

  useIgnoreEffectDeps(
    () => () => {
      dispatch(selectFamilyMemberId(userId));
    },
    [],
  );
}
