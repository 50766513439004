import { StatusEnum } from '@common/constants';

import { FormValuesTypes, ReasonIdType } from '../types';

export enum InterfaceEnum {
  Athena = 'athena',
  Salesforce = 'salesforce',
}

export enum SendTypeEnum {
  Draft = 'draft',
  New = 'new',
}

export type ReasonType = {
  id: number;
  reason_id: ReasonIdType;
  name: string;
  interface: InterfaceEnum;
};

export type AttachmentType = {
  id: number;
  name: string;
  url: string;
};

export type AttachmentsFormatsType = string[];

export type editMessageState = {
  messageReasons: Nullable<ReasonType[]>;
  messageReasonsStatus: StatusEnum;
  attachments: Nullable<AttachmentType[]>;
  attachmentsStatus: StatusEnum;
  sendStatus: StatusEnum;
  sendType: Nullable<SendTypeEnum>;
  attachmentsFormats: { [key in ReasonIdType]?: AttachmentsFormatsType };
};

export type SendMessagePayloadType = {
  data: FormValuesTypes;
  draft?: boolean;
  conversationId?: number;
  id?: number;
  attachments: AttachmentType[];
};
