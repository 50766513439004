import React, { useMemo, useState, useCallback, useRef, RefObject } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { useScreenSize } from '@common/hooks/useScreenSize';
import { generatePathWithQuery, useQueryParamsObject } from '@common/router';
import { Button } from '@components/Button';
import { InnerLayout } from '@components/InnerLayout';
import { Slide } from '@components/Slide';
import { Typography } from '@components/Typography';
import { ConnectMyDeviceModal } from '@modules/ConnectMyDevice';
import { useFlippers, ShowFlippersEnum } from '@modules/flippers';
import { useFullContentLoaded } from '@modules/gtmFulContentLoaded';

import { FitnessDashboard, FitnessRecordActivityModal } from './components/FitnessDashboard';
import { SleepDashboard, SleepRecordActivityModal } from './components/SleepDashboard';
import { StepsDashboard, StepsRecordActivityModal } from './components/StepsDashboard';
import { WaterDashboard, WaterRecordActivityModal } from './components/WaterDashboard';
import { WeightDashboard, WeightRecordActivityModal } from './components/WeightDashboard';
import { WellnessCard, WellnessCardSkeleton } from './components/WellnessCard';
import { WellnessTypesEnum } from './constants';
import { locale } from './locale';
import { setEditModalVisible, mostResentSelector, getList, getRequestLogStatus } from './store';
import { WellnessLogType } from './types';

import styles from './WellnessLog.module.css';

const cn = classNames.bind(styles);

const getWellnessLogCardProps = (type: WellnessTypesEnum) => ({
  type,
  title: locale.card_titles[type],
  buttonLabel: locale.button_labels[type],
});

const WELLNESS_LOG_TYPES = [
  {
    ...getWellnessLogCardProps(WellnessTypesEnum.Fitness),
    DashboardComponent: FitnessDashboard,
  },
  {
    ...getWellnessLogCardProps(WellnessTypesEnum.Weight),
    DashboardComponent: WeightDashboard,
  },
  {
    ...getWellnessLogCardProps(WellnessTypesEnum.Steps),
    DashboardComponent: StepsDashboard,
  },
  {
    ...getWellnessLogCardProps(WellnessTypesEnum.Sleep),
    DashboardComponent: SleepDashboard,
  },
  {
    ...getWellnessLogCardProps(WellnessTypesEnum.Water),
    DashboardComponent: WaterDashboard,
  },
];

type WellnessLog = {
  dashboardVisible: boolean;
  onWellnessCardClick: VoidFunction;
};

export function WellnessLog({ dashboardVisible, onWellnessCardClick }: WellnessLog) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isDesktopView } = useScreenSize();
  const { pathname } = useLocation();
  const { type: wellnessType } = useQueryParamsObject<{ type: WellnessTypesEnum }>();
  const activity = useMemo(
    () => WELLNESS_LOG_TYPES.find(({ type }) => wellnessType === type) ?? WELLNESS_LOG_TYPES[0],
    [wellnessType],
  );
  const [selectedWellnessLogType, setSelectedWellnessLogType] = useState<WellnessLogType>(activity);
  const [connectMyDevice, setConnectMyDevice] = useState(false);
  const mostResent = useSelector(mostResentSelector);
  const fitnessStatus = useSelector(getRequestLogStatus(WellnessTypesEnum.Fitness));
  const sleepStatus = useSelector(getRequestLogStatus(WellnessTypesEnum.Sleep));
  const stepsStatus = useSelector(getRequestLogStatus(WellnessTypesEnum.Steps));
  const waterStatus = useSelector(getRequestLogStatus(WellnessTypesEnum.Water));
  const weightStatus = useSelector(getRequestLogStatus(WellnessTypesEnum.Weight));
  const dashboardRef = useRef<HTMLDivElement>(null);

  useIgnoreEffectDeps(() => {
    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(sleepStatus)) {
      dispatch(getList(WellnessTypesEnum.Sleep));
    }

    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(fitnessStatus)) {
      dispatch(getList(WellnessTypesEnum.Fitness));
    }

    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(stepsStatus)) {
      dispatch(getList(WellnessTypesEnum.Steps));
    }

    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(waterStatus)) {
      dispatch(getList(WellnessTypesEnum.Water));
    }

    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(weightStatus)) {
      dispatch(getList(WellnessTypesEnum.Weight));
    }
  });

  const handleCardButtonClick = useCallback(
    (type: WellnessTypesEnum) => {
      dispatch(setEditModalVisible({ type }));
    },
    [dispatch],
  );

  const handleCardClick = useCallback(
    (wellnessLogType: typeof WELLNESS_LOG_TYPES[number], ref: RefObject<HTMLElement>) => {
      onWellnessCardClick();
      setSelectedWellnessLogType(wellnessLogType);
      navigate(generatePathWithQuery(pathname, { type: wellnessLogType.type }));
      const focusable = ref?.current?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );
      (focusable?.[0] as Nullable<HTMLElement>)?.focus();

      window.scrollTo(0, 0);
    },
    [navigate, onWellnessCardClick, pathname],
  );
  const openConnectMyDevice = useCallback(() => setConnectMyDevice(true), []);

  const closeConnectMyDevice = useCallback(() => setConnectMyDevice(false), []);

  const getFlipperValue = useFlippers();
  const showConnectMyDevice = useMemo(() => getFlipperValue(ShowFlippersEnum.connect_my_devices), [getFlipperValue]);

  const isFitnessLoading = [StatusEnum.Pending, StatusEnum.Uninitialized].includes(fitnessStatus);

  useFullContentLoaded({
    loadingStarted: isFitnessLoading,
    loadingFinished: !isFitnessLoading,
  });

  const wellnessCards = useMemo(() => {
    if (isFitnessLoading) {
      return (
        <div className={cn('wellness-log__cards')}>
          <WellnessCardSkeleton className={cn('wellness-log__card')} />
          <WellnessCardSkeleton className={cn('wellness-log__card')} />
          <WellnessCardSkeleton className={cn('wellness-log__card')} />
          <WellnessCardSkeleton className={cn('wellness-log__card')} />
        </div>
      );
    }

    return (
      <div className={cn('wellness-log__cards')}>
        {WELLNESS_LOG_TYPES.map((wellnessLogType) => (
          <WellnessCard
            key={wellnessLogType.type}
            className={cn('wellness-log__card')}
            title={wellnessLogType.title}
            selected={isDesktopView ? selectedWellnessLogType.type === wellnessLogType.type : false}
            mostRecentEntry={mostResent[wellnessLogType.type]}
            buttonLabel={wellnessLogType.buttonLabel}
            onButtonClick={() => handleCardButtonClick(wellnessLogType.type)}
            onCardClick={() => handleCardClick(wellnessLogType, dashboardRef)}
          />
        ))}
      </div>
    );
  }, [selectedWellnessLogType, mostResent, isFitnessLoading, handleCardButtonClick, handleCardClick, isDesktopView]);

  const Dashboard = selectedWellnessLogType.DashboardComponent;

  return (
    <div className={cn('wellness-log')}>
      {!isDesktopView && (
        <div className={cn('wellness-log__dashboard')} ref={dashboardRef}>
          <Slide
            direction="left"
            in={!dashboardVisible}
            mountOnEnter
            unmountOnExit
            appear={false}
            style={{ transitionDelay: !dashboardVisible ? '125ms' : '0ms' }}
          >
            <div>
              <div className={cn('wellness-log__connect-my-device')}>
                <Typography variant="h1" className={cn('wellness-log__title')}>
                  {locale.title}
                </Typography>
                {showConnectMyDevice && (
                  <Button
                    variant="outlined"
                    onClick={openConnectMyDevice}
                    className={cn('wellness-log__connect-button')}
                  >
                    {locale.connect_device}
                  </Button>
                )}
              </div>

              {wellnessCards}
            </div>
          </Slide>

          <Slide
            direction="left"
            in={dashboardVisible}
            mountOnEnter
            unmountOnExit
            appear={false}
            style={{ transitionDelay: dashboardVisible ? '125ms' : '0ms' }}
          >
            <Dashboard />
          </Slide>
        </div>
      )}

      {isDesktopView && (
        <>
          <div className={cn('wellness-log__connect-my-device')}>
            <Typography variant="h1" className={cn('wellness-log__title')}>
              {locale.title}
            </Typography>
            {showConnectMyDevice && (
              <Button variant="outlined" onClick={openConnectMyDevice} className={cn('wellness-log__connect-button')}>
                {locale.connect_device}
              </Button>
            )}
          </div>

          <InnerLayout
            className={cn('wellness-log__inner')}
            contentClassName={cn('wellness-log__content')}
            leftSidebar={wellnessCards}
          >
            <div className={cn('wellness-log__dashboard')} ref={dashboardRef}>
              <Dashboard />
            </div>
          </InnerLayout>
        </>
      )}

      <FitnessRecordActivityModal />
      <SleepRecordActivityModal />
      <StepsRecordActivityModal />
      <WaterRecordActivityModal />
      <WeightRecordActivityModal />

      <ConnectMyDeviceModal open={connectMyDevice} closeModal={closeConnectMyDevice} />
    </div>
  );
}
