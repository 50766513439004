export const locale = {
  cancel: 'Cancel',
  draft: 'Save Draft',
  attachment: 'Attach File',
  send: 'Send Message',
  only_attaches: 'Only attach files necessary for your care. View our ',
  only_attaches2: ' for more.',
  privacy_policy: 'Privacy Policy',
  formats: 'Maximum File Size: 10 MB. Supported File Types: Only {{attachmentsFormats}}',
};
