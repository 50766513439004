import React, { useCallback, useMemo } from 'react';

import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { generatePathWithQuery, useQueryParamsObject } from '@common/router';
import { MenuInsideModule, MenuInsideModuleProps } from '@components/MenuInsideModule';
import { SizesEnum } from '@components/NumberCircle';

import { DEFAULT_PAGE, MailboxTypeEnum } from '../../constants';
import { locale } from '../../locale';
import { FamilyMemberMessageCount } from '../FamilyMemberMessageCount';

const getMenuItems = (memberId: number) => [
  {
    id: MailboxTypeEnum.Inbox,
    label: locale.messages_types[MailboxTypeEnum.Inbox],
    badge: <FamilyMemberMessageCount memberId={memberId} size={SizesEnum.Medium} />,
  },
  {
    id: MailboxTypeEnum.Sent,
    label: locale.messages_types[MailboxTypeEnum.Sent],
  },
  {
    id: MailboxTypeEnum.Draft,
    label: locale.messages_types[MailboxTypeEnum.Draft],
  },
  {
    id: MailboxTypeEnum.Archive,
    label: locale.messages_types[MailboxTypeEnum.Archive],
  },
];

type Props = {
  className?: string;
};

export const MailboxTypeMenu = ({ className }: Props) => {
  const location = useLocation();
  const { mailboxType, memberId } = useQueryParamsObject();
  const navigate = useNavigate();

  const handleOnMenuItemClick = useCallback<NonNullable<MenuInsideModuleProps['onMenuItemClick']>>(
    (item) => {
      if (mailboxType !== item.id) {
        navigate(
          generatePathWithQuery(location.pathname, { memberId, mailboxType: item.id, currentPage: DEFAULT_PAGE }),
        );
      }
    },
    [location.pathname, mailboxType, memberId, navigate],
  );
  const menuItems = getMenuItems(memberId);

  const selectedComponent = useMemo(
    () => menuItems.find(({ id }) => mailboxType === id)?.label,
    [mailboxType, menuItems],
  );

  return (
    <MenuInsideModule
      className={className}
      visibleMenuItems={menuItems}
      onMenuItemClick={handleOnMenuItemClick}
      selectedComponent={selectedComponent}
      initialCustomTabId={mailboxType}
      dropdownView
    />
  );
};
