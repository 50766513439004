import React from 'react';

import { TimePicker, TimePickerProps } from '@components/TimePicker';

import { useController, UseControllerProps, RegisterOptions, FieldValues } from '../hooks';
import { locale } from '../locale';

type TimePickerWithControlProps<FormValues extends FieldValues> = {
  name: string;
  placeholder?: string;
  required?: { value: boolean; message: string } | boolean;
  onChange?: TimePickerProps['onChange'];
  validate?: RegisterOptions<FormValues>['validate'];
} & UseControllerProps<FormValues> &
  Partial<Omit<TimePickerProps, 'value' | 'onChange' | 'required'>>;

export function TimePickerWithControl<FormValues extends FieldValues>({
  control,
  name,
  required,
  onChange,
  validate,
  ...restProps
}: TimePickerWithControlProps<FormValues>) {
  const requiredMessage = typeof required === 'boolean' && required ? locale.required_message : required;

  const {
    field: { onChange: controllerOnChange, value },
    fieldState,
  } = useController({
    name,
    control,
    rules: { required: requiredMessage, validate },
  });

  return (
    <TimePicker
      {...restProps}
      required={!!required}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      onChange={onChange || controllerOnChange}
      value={value}
      name={name}
    />
  );
}
