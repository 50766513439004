import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

export const getNextAppointment = createAsyncThunk(
  'NextAppointment/get-next-appointment',
  async (userId: number, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: `/Appointment/next-appointment/${userId}`,
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
