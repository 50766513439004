import React, { useCallback } from 'react';

import classNames from 'classnames/bind';

import { FieldValues } from '@components/Form';
import { ToggleButton } from '@components/ToggleButton';
import { ToggleButtonGroup } from '@components/ToggleButtonGroup';

import { useController, UseControllerProps } from '../../hooks';

import styles from './ToggleButtonGroupWithControl.module.css';

const cn = classNames.bind(styles);

type ToggleButtonGroupWithControlProps<FormValues extends FieldValues> = {
  firstBtnText: string;
  secondBtnText: string;
  name: string;
  firstBtnClass?: string;
  secondBtnClass?: string;
  controllerClass?: string;
  required?: boolean;
  firstBtnValue: string | boolean;
  secondBtnValue: string | boolean;
  controllerRootClass?: string;
  separatedButtons?: boolean;
  ariaLabel?: string;
  role?: string;
  groupRole?: string;
} & UseControllerProps<FormValues>;

export function ToggleButtonGroupWithControl<FormValues extends FieldValues>({
  control,
  name,
  firstBtnText,
  secondBtnText,
  firstBtnClass,
  secondBtnClass,
  controllerClass,
  controllerRootClass,
  required,
  firstBtnValue,
  secondBtnValue,
  separatedButtons,
  ariaLabel,
  groupRole,
  role,
}: ToggleButtonGroupWithControlProps<FormValues>) {
  const {
    field: { onChange, value },
  } = useController({ name, control, rules: { required } });

  const handleChange = useCallback(
    (e: unknown, value: unknown) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <ToggleButtonGroup
      toggleButtonGroupRootClass={controllerRootClass}
      className={controllerClass}
      value={value}
      exclusive
      onChange={handleChange}
      separatedButtons={separatedButtons}
      role={groupRole}
      ariaLabel={ariaLabel}
    >
      <ToggleButton
        className={cn('controller__yes', firstBtnClass)}
        value={firstBtnValue}
        role={role}
        aria-checked={role ? value === firstBtnValue : null}
      >
        {firstBtnText}
      </ToggleButton>
      <ToggleButton
        className={cn('controller__no', secondBtnClass)}
        value={secondBtnValue}
        role={role}
        aria-checked={role ? value === secondBtnValue : null}
      >
        {secondBtnText}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
