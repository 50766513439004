import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import {
  AdhocMedicationType,
  GetPersonalPharmaciesMetaType,
  MedicationType,
  MemberType,
  PharmacyRelativeType,
  MedRefillResponseType,
  GetPersonalMedicationsMetaType,
  PharmaciesInStoreType,
} from '../types';
import {
  getPharmaciesByMedicationId,
  getPharmaciesByMedicationIdStatus,
  getPharmaciesKey,
  pharmaciesRelativeToPharmacies,
  statusesByKey,
} from '../utils';
import { getMedRefill, getRelativeAdhocPharmacies, sendMedicationRefills } from './actions';

export interface MedicationRefillsState {
  medications: Array<MedicationType>;
  medicationsStatus: StatusEnum;
  personalPharmacies: PharmaciesInStoreType;
  personalPharmaciesStatus: Record<string, StatusEnum>;
  adhocPharmacies: PharmaciesInStoreType;
  adhocPharmaciesStatus: Record<string, StatusEnum>;
  selectedMember: MemberType | null;
  sendRefillsStatus: StatusEnum;
  sentRefills: Nullable<{
    employeeMedications: Array<MedicationType>;
    adhocMedications: Array<AdhocMedicationType>;
  }>;
}

const initialState: MedicationRefillsState = {
  medications: [],
  selectedMember: null,
  medicationsStatus: StatusEnum.Uninitialized,

  adhocPharmacies: {},
  adhocPharmaciesStatus: {},
  personalPharmacies: {},
  personalPharmaciesStatus: {},
  sendRefillsStatus: StatusEnum.Uninitialized,
  sentRefills: null,
};

export const medicationRefillsSlice = createSlice({
  name: 'medicationRefills',
  initialState,
  reducers: {
    selectMember(state: MedicationRefillsState, action: PayloadAction<MemberType>) {
      state.selectedMember = action.payload;
    },
    resetMember(state) {
      state.selectedMember = initialState.selectedMember;
    },
    resetSendRefillsStatus(state: MedicationRefillsState) {
      state.sendRefillsStatus = initialState.sendRefillsStatus;
      state.sentRefills = initialState.sentRefills;
    },
  },
  extraReducers: {
    [getMedRefill.pending.type]: (state: MedicationRefillsState) => {
      state.medicationsStatus = StatusEnum.Pending;
    },

    [getMedRefill.fulfilled.type]: (
      state: MedicationRefillsState,
      action: PayloadAction<MedRefillResponseType, string, GetPersonalMedicationsMetaType>,
    ) => {
      const { medications, pharmacies } = action.payload;
      const { userId } = action.meta.arg;

      const pharmaciesByMedicationId = getPharmaciesByMedicationId(medications, pharmacies, userId);
      const pharmaciesByMedicationIdStatus = getPharmaciesByMedicationIdStatus(
        medications,
        StatusEnum.Fulfilled,
        userId,
      );

      state.personalPharmaciesStatus = {
        ...state.personalPharmaciesStatus,
        ...pharmaciesByMedicationIdStatus,
      };

      state.personalPharmacies = {
        ...state.personalPharmacies,
        ...pharmaciesByMedicationId,
      };

      state.adhocPharmacies = {
        ...state.adhocPharmacies,
        [userId]: pharmacies,
      };

      state.adhocPharmaciesStatus = {
        ...state.adhocPharmaciesStatus,
        [userId]: StatusEnum.Fulfilled,
      };

      state.medications = medications;
      state.medicationsStatus = StatusEnum.Fulfilled;
    },
    [getMedRefill.rejected.type]: (state: MedicationRefillsState) => {
      state.medications = initialState.medications;
      state.medicationsStatus = StatusEnum.Rejected;
      const personalPharmaciesStatuses = statusesByKey(state.personalPharmaciesStatus, StatusEnum.Rejected);
      const ahdocPharmaciesStatuses = statusesByKey(state.adhocPharmaciesStatus, StatusEnum.Rejected);

      state.personalPharmaciesStatus = {
        ...state.personalPharmaciesStatus,
        ...personalPharmaciesStatuses,
      };

      state.adhocPharmaciesStatus = {
        ...state.adhocPharmaciesStatus,
        ...ahdocPharmaciesStatuses,
      };
    },

    [getRelativeAdhocPharmacies.pending.type]: (
      state: MedicationRefillsState,
      action: PayloadAction<unknown, string, GetPersonalPharmaciesMetaType>,
    ) => {
      state.adhocPharmaciesStatus = {
        ...state.adhocPharmaciesStatus,
        [getPharmaciesKey(action.meta.arg)]: StatusEnum.Pending,
      };
    },
    [getRelativeAdhocPharmacies.fulfilled.type]: (
      state: MedicationRefillsState,
      action: PayloadAction<Array<PharmacyRelativeType>, string, GetPersonalPharmaciesMetaType>,
    ) => {
      state.adhocPharmaciesStatus = {
        ...state.adhocPharmaciesStatus,
        [getPharmaciesKey(action.meta.arg)]: StatusEnum.Fulfilled,
      };
      state.adhocPharmacies = {
        ...state.adhocPharmacies,
        [getPharmaciesKey(action.meta.arg)]: pharmaciesRelativeToPharmacies(action.payload),
      };
    },
    [getRelativeAdhocPharmacies.rejected.type]: (
      state: MedicationRefillsState,
      action: PayloadAction<unknown, string, GetPersonalPharmaciesMetaType>,
    ) => {
      state.adhocPharmaciesStatus = {
        ...state.adhocPharmaciesStatus,
        [getPharmaciesKey(action.meta.arg)]: StatusEnum.Rejected,
      };
    },
    [sendMedicationRefills.pending.type]: (state: MedicationRefillsState) => {
      state.sendRefillsStatus = StatusEnum.Pending;
    },
    [sendMedicationRefills.fulfilled.type]: (
      state: MedicationRefillsState,
      action: ReturnType<typeof sendMedicationRefills.fulfilled>,
    ) => {
      state.sendRefillsStatus = StatusEnum.Fulfilled;
      state.sentRefills = {
        adhocMedications: action.meta.arg.data.adhoc_refills.filter(({ selected }) => selected),
        employeeMedications: action.meta.arg.data.medications.filter(({ selected }) => selected),
      };
    },
    [sendMedicationRefills.rejected.type]: (state: MedicationRefillsState) => {
      state.sendRefillsStatus = StatusEnum.Rejected;
    },
    ...resetStoreActions(() => initialState),
  },
});

export const { selectMember, resetSendRefillsStatus, resetMember } = medicationRefillsSlice.actions;
export const medicationRefillsReducer = medicationRefillsSlice.reducer;
