import { createSelector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';

const { dryDataSelector, currentUserStatusSelector } = createUserIdSpecificSelectors(
  (state) => state.healthIssues.healthIssuesRequest,
);

export const healthIssuesStatusSelector = currentUserStatusSelector;
export const healthIssuesSelector = createSelector(dryDataSelector, (data) => data ?? []);

export const downloadProblemsReportStatusSelector = (state: RootState) => state.healthIssues.downloadStatus;
