import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { categoriesSelector } from '../store';
import { UserCategoryType } from '../store/types';

export const useCategories = (): ((category: string) => boolean) => {
  const { categories } = useSelector(categoriesSelector);

  return useCallback(
    (category: string): boolean => categories.filter((cat: UserCategoryType) => cat.name === category).length > 0,
    [categories],
  );
};
