import { DashboardDataType } from './types';

export const DEFAULT_DATA: DashboardDataType = {
  current_weight: null,
  weights: {
    type_name: null,
    items: [],
  },
  current_bmi: null,
  bmis: {
    type_name: null,
    items: [],
  },
  current_a1c: null,
  a1cs: {
    type_name: null,
    items: [],
  },
  current_systolic: null,
  systolics: {
    type_name: null,
    items: [],
  },
  current_diastolic: null,
  diastolics: {
    type_name: null,
    items: [],
  },
  current_ldl: null,
  ldls: {
    type_name: null,
    items: [],
  },
  current_hdl: null,
  hdls: {
    type_name: null,
    items: [],
  },
  current_total_cholesterol: null,
  total_cholesterols: {
    type_name: null,
    items: [],
  },
  current_total_cholesterol_ratio: null,
  total_cholesterol_ratios: {
    type_name: null,
    items: [],
  },
  current_waist: null,
  waists: {
    type_name: null,
    items: [],
  },
  current_blood_glucose: null,
  blood_glucoses: {
    type_name: null,
    items: [],
  },
  blood_pressure: null,
};
