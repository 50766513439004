import { fetch } from '@common/fetch';

import { SubmitUploadDocumentParams } from '../types';

export const uploadDocument = async ({ document, attachment }: SubmitUploadDocumentParams): Promise<number> => {
  const formData = new FormData();
  formData.append('file', attachment);
  formData.append('addDocumentViewModelString', JSON.stringify(document));

  const data = fetch.post({
    url: '/Document/add-document',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });

  const res = await data.response;

  return res?.data;
};
