import * as React from 'react';

import classNames from 'classnames/bind';
import { useLocation } from 'react-router';

import { Button } from '@components/Button';

import { mainSkipLinks, skipLinkSupportedRoutes } from './constants';

import styles from './SkipLinks.module.css';

const cn = classNames.bind(styles);

export function SkipLinks() {
  const location = useLocation();

  const isSupported = skipLinkSupportedRoutes.some((route) => {
    if (route.equalityCheck) return route.path === location.pathname;

    return location.pathname.startsWith(route.path);
  });

  if (!isSupported) return null;

  return (
    <div className={cn('skip-links')}>
      {mainSkipLinks.map((skipLink) => (
        <Button component="a" key={skipLink.id} href={`#${skipLink.id}`} className={cn('skip-links__link')}>
          {skipLink.label}
        </Button>
      ))}
    </div>
  );
}
