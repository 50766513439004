import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';
import { gtmEvent, GtmEventsEnum } from '@modules/gtm';
import { validateRecaptcha } from '@modules/recaptcha';

import { FormValuesTypes } from '../types';
import { ValidateUserPreregistrationUserPayload } from './types';
import { convertFormToPotentialSignUpData, convertFormToValidateUserPreregistration } from './util';

type SignUpParams = {
  data: FormValuesTypes;
  campaign?: Nullable<string>;
  recaptchaToken: string;
};

export const signUp = createAsyncThunk(
  'Registration/sign-up',
  async ({ recaptchaToken, data, campaign }: SignUpParams, { rejectWithValue }) => {
    try {
      await validateRecaptcha(recaptchaToken);

      const responseData = fetch.post({
        url: '/Potential/sign-up',
        data: convertFormToPotentialSignUpData(data, campaign),
        skipAlert: true,
      });
      const res = await responseData.response;

      gtmEvent(GtmEventsEnum.signUp);

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const validateToken = createAsyncThunk(
  'Registration/validate-token',
  async (token: string, { rejectWithValue }) => {
    try {
      const responseData = fetch.get({
        url: `/Potential/validate-notify-token/${token}`,
        skipAlert: true,
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const validateUserPreregistrationUser = createAsyncThunk(
  'Registration/validate-user-pre-registration',
  async (data: ValidateUserPreregistrationUserPayload, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: `/Potential/validate-user-pre-registration`,
        data: convertFormToValidateUserPreregistration(data),
        skipAlert: true,
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getEmployer = createAsyncThunk(
  'Registration/employer-name',
  async (campaignSlug: string, { rejectWithValue }) => {
    try {
      const responseData = fetch.get({
        url: `/Potential/employer-name`,
        skipAlert: true,
        params: {
          campaignSlug,
        },
      });
      const res = await responseData.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
