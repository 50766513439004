import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { userProfileDataSelector, currentEligibilitySelector } from '@modules/userProfileData';

import { gtmSetUserProperties } from '../utils';

export const useGtmUserProfileParams = () => {
  const userProfileData = useSelector(userProfileDataSelector);
  const employer = useSelector(currentEligibilitySelector)?.employer;

  useEffect(() => {
    gtmSetUserProperties({
      wellnessId: userProfileData?.wellness_id,
      bornOn: userProfileData?.born_on ?? undefined,
      gender: userProfileData?.gender,
      employerId: employer?.id,
      employerName: employer?.name,
    });
  }, [userProfileData, employer]);
};
