import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import { submitFeedback } from './actions';
import { FeedbackState } from './types';

const initialState: FeedbackState = {
  submitFeedbackStatus: StatusEnum.Uninitialized,
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    resetSubmitStatus(state) {
      state.submitFeedbackStatus = StatusEnum.Uninitialized;
    },
  },
  extraReducers: {
    [submitFeedback.pending.type]: (state: FeedbackState) => {
      state.submitFeedbackStatus = StatusEnum.Pending;
    },
    [submitFeedback.fulfilled.type]: (state: FeedbackState) => {
      state.submitFeedbackStatus = StatusEnum.Fulfilled;
    },
    [submitFeedback.rejected.type]: (state: FeedbackState) => {
      state.submitFeedbackStatus = StatusEnum.Rejected;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const feedbackReducer = feedbackSlice.reducer;
export const { resetSubmitStatus } = feedbackSlice.actions;
