import { useCallback, useState } from 'react';

import { generatePathWithQuery, useQueryParamsObject } from '@common/router';
import { useGlobalLocker } from '@modules/GlobalLocker';

const getURLtoRedirect = () => {
  if (process.env.API_URL) {
    return process.env.API_URL.match('^http') ? process.env.API_URL : `https://${process.env.API_URL}`;
  }

  return process.env.PROXY_API_URL;
};

export const useSamlAuth = () => {
  const [redirectStarted, startRedirect] = useState(false);
  const { SAMLRequest } = useQueryParamsObject();
  const isSamlAuthAvailable = !!SAMLRequest;
  const API_URL = getURLtoRedirect();

  const callSamlAuth = useCallback(() => {
    if (isSamlAuthAvailable && API_URL) {
      startRedirect(true);
      window.location.replace(generatePathWithQuery(`${API_URL}/Authentication/saml-auth`, { SAMLRequest }));
    }
  }, [API_URL, SAMLRequest, isSamlAuthAvailable]);

  useGlobalLocker(redirectStarted);

  return {
    callSamlAuth,
    isSamlAuthAvailable,
  };
};
