import React, { useLayoutEffect, useState } from 'react';

import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { ErrorBoundaryProps } from './error-boundary-types';
import { useHandleError } from './useHandleError';

export function ErrorBoundary({ children, fallBackComponent }: ErrorBoundaryProps) {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const handleError = useHandleError();

  useLayoutEffect(() => setPath(location.pathname), [location.pathname]);

  return (
    <ReactErrorBoundary resetKeys={[path]} FallbackComponent={fallBackComponent} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
}
