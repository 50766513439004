export const TIME_ZONES_ADDITIONAL_INFO = [
  {
    ianaTimeZone: 'America/Adak',
    usIanaTimeZone: 'US/Hawaii',
    abbreviation: 'HT',
    displayName: 'US/Hawaii (HT)',
  },
  {
    ianaTimeZone: 'America/Atka',
    usIanaTimeZone: 'US/Hawaii',
    abbreviation: 'HT',
    displayName: 'US/Hawaii (HT)',
  },
  {
    ianaTimeZone: 'America/Anchorage',
    usIanaTimeZone: 'US/Alaska',
    abbreviation: 'AKT',
    displayName: 'US/Alaska (AKT)',
  },
  {
    ianaTimeZone: 'America/Boise',
    usIanaTimeZone: 'US/Mountain',
    abbreviation: 'MT',
    displayName: 'US/Mountain (MT)',
  },
  {
    ianaTimeZone: 'America/Chicago',
    usIanaTimeZone: 'US/Central',
    abbreviation: 'CT',
    displayName: 'US/Central (CT)',
  },
  {
    ianaTimeZone: 'America/Denver',
    usIanaTimeZone: 'US/Mountain',
    abbreviation: 'MT',
    displayName: 'US/Mountain (MT)',
  },
  {
    ianaTimeZone: 'America/Detroit',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'America/Indiana/Indianapolis',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'America/Indiana/Knox',
    usIanaTimeZone: 'US/Central',
    abbreviation: 'CT',
    displayName: 'US/Central (CT)',
  },
  {
    ianaTimeZone: 'America/Indianapolis',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'America/Juneau',
    usIanaTimeZone: 'US/Alaska',
    abbreviation: 'AKT',
    displayName: 'US/Alaska (AKT)',
  },
  {
    ianaTimeZone: 'America/Kentucky/Louisville',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'America/Kentucky/Monticello',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'America/Knox_IN',
    usIanaTimeZone: 'US/Central',
    abbreviation: 'CT',
    displayName: 'US/Central (CT)',
  },
  {
    ianaTimeZone: 'America/Los_Angeles',
    usIanaTimeZone: 'US/Pacific',
    abbreviation: 'PT',
    displayName: 'US/Pacific (PT)',
  },
  {
    ianaTimeZone: 'America/Louisville',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'America/Menominee',
    usIanaTimeZone: 'US/Central',
    abbreviation: 'CT',
    displayName: 'US/Central (CT)',
  },
  {
    ianaTimeZone: 'America/Metlakatla',
    usIanaTimeZone: 'US/Alaska',
    abbreviation: 'AKT',
    displayName: 'US/Alaska (AKT)',
  },
  {
    ianaTimeZone: 'America/New_York',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'America/Nome',
    usIanaTimeZone: 'US/Alaska',
    abbreviation: 'AKT',
    displayName: 'US/Alaska (AKT)',
  },
  {
    ianaTimeZone: 'America/North_Dakota/Beulah',
    usIanaTimeZone: 'US/Central',
    abbreviation: 'CT',
    displayName: 'US/Central (CT)',
  },
  {
    ianaTimeZone: 'America/North_Dakota/Center',
    usIanaTimeZone: 'US/Central',
    abbreviation: 'CT',
    displayName: 'US/Central (CT)',
  },
  {
    ianaTimeZone: 'America/North_Dakota/New_Salem',
    usIanaTimeZone: 'US/Central',
    abbreviation: 'CT',
    displayName: 'US/Central (CT)',
  },
  {
    ianaTimeZone: 'America/Phoenix',
    usIanaTimeZone: 'US/Mountain',
    abbreviation: 'MST',
    displayName: 'US/Mountain (MST)',
  },
  {
    ianaTimeZone: 'America/Shiprock',
    usIanaTimeZone: 'US/Mountain',
    abbreviation: 'MT',
    displayName: 'US/Mountain (MT)',
  },
  {
    ianaTimeZone: 'America/Sitka',
    usIanaTimeZone: 'US/Alaska',
    abbreviation: 'AKT',
    displayName: 'US/Alaska (AKT)',
  },
  {
    ianaTimeZone: 'America/Yakutat',
    usIanaTimeZone: 'US/Alaska',
    abbreviation: 'AKT',
    displayName: 'US/Alaska (AKT)',
  },
  {
    ianaTimeZone: 'Navajo',
    usIanaTimeZone: 'US/Mountain',
    abbreviation: 'MT',
    displayName: 'US/Mountain (MT)',
  },
  {
    ianaTimeZone: 'Pacific/Honolulu',
    usIanaTimeZone: 'US/Hawaii',
    abbreviation: 'HST',
    displayName: 'US/Hawaii (HST)',
  },
  {
    ianaTimeZone: 'Pacific/Johnston',
    usIanaTimeZone: 'US/Hawaii',
    abbreviation: 'HST',
    displayName: 'US/Hawaii (HST)',
  },
  {
    ianaTimeZone: 'America/Alaska',
    usIanaTimeZone: 'US/Alaska',
    abbreviation: 'AKT',
    displayName: 'US/Alaska (AKT)',
  },
  {
    ianaTimeZone: 'US/Aleutian',
    usIanaTimeZone: 'US/Hawaii',
    abbreviation: 'HST',
    displayName: 'US/Hawaii (HST)',
  },
  {
    ianaTimeZone: 'US/Arizona',
    usIanaTimeZone: 'US/Mountain',
    abbreviation: 'MST',
    displayName: 'US/Mountain (MST)',
  },
  {
    ianaTimeZone: 'US/Central',
    usIanaTimeZone: 'US/Central',
    abbreviation: 'CT',
    displayName: 'US/Central (CT)',
  },
  {
    ianaTimeZone: 'America/East-Indiana',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'America/Eastern',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'US/Hawaii',
    usIanaTimeZone: 'US/Hawaii',
    abbreviation: 'HST',
    displayName: 'US/Hawaii (HST)',
  },
  {
    ianaTimeZone: 'US/Indiana-Starke',
    usIanaTimeZone: 'US/Central',
    abbreviation: 'CT',
    displayName: 'US/Central (CT)',
  },
  {
    ianaTimeZone: 'US/Michigan',
    usIanaTimeZone: 'US/Eastern',
    abbreviation: 'ET',
    displayName: 'US/Eastern (ET)',
  },
  {
    ianaTimeZone: 'US/Mountain',
    usIanaTimeZone: 'US/Mountain',
    abbreviation: 'MT',
    displayName: 'US/Mountain (MT)',
  },
  {
    ianaTimeZone: 'US/Pacific',
    usIanaTimeZone: 'US/Pacific',
    abbreviation: 'PT',
    displayName: 'US/Pacific (PT)',
  },
  {
    ianaTimeZone: 'US/Samoa',
    usIanaTimeZone: 'US/Samoa',
    abbreviation: 'ST',
    displayName: 'US/Samoa (ST)',
  },
];
