export enum WellnessTypesEnum {
  Fitness = 'exercise',
  Weight = 'weight',
  Steps = 'steps',
  Sleep = 'sleep',
  Water = 'water',
}

export enum WellnessAdditionalTypesEnum {
  FitnessAndSteps = 'fitness_and_steps',
}

export enum MeasurementEnum {
  Weight = 'Weight',
  Ounces = 'ounces',
  Steps = 'steps',
  Hours = 'hours',
  Minutes = 'minutes',
  Miles = 'miles',
}

export const GRID_SPACING = { xs: 1, sm: 1, md: 2, lg: 2 };
export const GRID_ROW_SPACING = { xs: 1, sm: 1, md: 0, lg: 0 };

export enum FitnessTypes {
  OtherFitnessActivity = 'OtherFitnessActivity',
  BarreFitnessActivity = 'BarreFitnessActivity',
  BoxingMmaFitnessActivity = 'BoxingMmaFitnessActivity',
  CoreStrengtheningFitnessActivity = 'CoreStrengtheningFitnessActivity',
  DanceFitnessActivity = 'DanceFitnessActivity',
  MeditationFitnessActivity = 'MeditationFitnessActivity',
  PilatesFitnessActivity = 'PilatesFitnessActivity',
  StrengthTrainingFitnessActivity = 'StrengthTrainingFitnessActivity',
  YogaFitnessActivity = 'YogaFitnessActivity',
  ZumbaFitnessActivity = 'ZumbaFitnessActivity',
}

export const MAP_SOURCE_TYPES = {
  apple_health: 'Apple Health',
  google_fit_sdk: 'Google Fit',
};

export const ITEMS_ON_PAGE = 10;
