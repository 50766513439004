/* eslint-disable camelcase */
export enum GlobalWaiverPurposes {
  TermsOfUse = 'terms-of-use',
  PrivacyPolicy = 'privacy-policy',
  IncentiveMultipleActivePrograms = 'incentives-multiple-active-programs',
  SchedulingFilter = 'scheduling-filter',
  PortalWelcome = 'portal-welcome',
  SchedulePhoneVideoOneButton = 'scheduling-phone-video-one-button',
  SchedulingProviderBios = 'scheduling-provider-bios',
  LanguageAssistanceSpanish = 'language-assistance-spanish',
  InformedConsentHealthCare = 'informed-consent-healthcare',
  NoticePrivacyPractices = 'notice-privacy-practices',
}

export const JOINED_ToU_PP = 'terms-of-use&privacy-policy';
export const WAIWERS_MODAL_TITLE_ID = 'acknowledgments-title';

export const GLOBAL_WAIVER_PURPOSES = [
  GlobalWaiverPurposes.TermsOfUse,
  GlobalWaiverPurposes.PrivacyPolicy,
  GlobalWaiverPurposes.InformedConsentHealthCare,
  GlobalWaiverPurposes.NoticePrivacyPractices,
];
