import * as React from 'react';

import AppBarMaterial from '@mui/material/AppBar';
import classNames from 'classnames/bind';

import { AppBarProps } from './app-bar-types';

import styles from './AppBar.module.css';

const cn = classNames.bind(styles);

export function AppBar({ children, className, position = 'static', component, ariaLabel, id }: AppBarProps) {
  return (
    <AppBarMaterial
      className={cn('app-bar', className)}
      position={position}
      component={component || 'header'}
      aria-label={ariaLabel}
      id={id}
    >
      <div className={cn('app-bar__content')}>{children}</div>
    </AppBarMaterial>
  );
}
