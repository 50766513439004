import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActions } from '@modules/reset';

import {
  getAttachmentsFormatsByReasonId,
  getMessageReasons,
  replyMessage,
  sendMessage,
  uploadAttachments,
} from './actions';
import { AttachmentType, editMessageState, ReasonType, SendMessagePayloadType, SendTypeEnum } from './types';

const initialState: editMessageState = {
  messageReasons: null,
  messageReasonsStatus: StatusEnum.Uninitialized,
  attachments: null,
  attachmentsStatus: StatusEnum.Uninitialized,
  sendStatus: StatusEnum.Uninitialized,
  sendType: null,
  attachmentsFormats: {},
};

export const editMessageSlice = createSlice({
  name: 'editMessage',
  initialState,
  reducers: {
    deleteAttachmentFromMessage: (state: editMessageState, action: PayloadAction<Nullable<number>>) => {
      state.attachments = state.attachments?.filter(({ id }) => id !== action.payload);
    },
    resetStatus: (state: editMessageState) => {
      state.sendStatus = initialState.sendStatus;
      state.sendType = initialState.sendType;
    },
    setAttachments: (state: editMessageState, action: PayloadAction<AttachmentType[]>) => {
      state.attachments = action.payload;
    },
    resetAttachments: (state: editMessageState) => {
      state.attachments = initialState.attachments;
      state.attachmentsStatus = initialState.attachmentsStatus;
    },
  },
  extraReducers: {
    // REASONS
    [getMessageReasons.pending.type]: (state: editMessageState) => {
      state.messageReasonsStatus = StatusEnum.Pending;
    },
    [getMessageReasons.fulfilled.type]: (state: editMessageState, action: PayloadAction<ReasonType[]>) => {
      state.messageReasonsStatus = StatusEnum.Fulfilled;
      state.messageReasons = action.payload;
    },
    [getMessageReasons.rejected.type]: (state: editMessageState) => {
      state.messageReasonsStatus = StatusEnum.Rejected;
    },
    // ATTACHMENTS
    [uploadAttachments.pending.type]: (state: editMessageState) => {
      state.attachmentsStatus = StatusEnum.Pending;
    },
    [uploadAttachments.fulfilled.type]: (state: editMessageState, action: PayloadAction<AttachmentType[]>) => {
      state.attachmentsStatus = StatusEnum.Fulfilled;
      state.attachments = state.attachments ? [...state.attachments, ...action.payload] : action.payload;
    },
    [uploadAttachments.rejected.type]: (state: editMessageState) => {
      state.attachmentsStatus = StatusEnum.Rejected;
    },

    // SEND MESSAGES
    [sendMessage.pending.type]: (
      state: editMessageState,
      action: PayloadAction<unknown, string, Meta<SendMessagePayloadType>>,
    ) => {
      state.sendStatus = StatusEnum.Pending;

      state.sendType = action.meta.arg.draft ? SendTypeEnum.Draft : SendTypeEnum.New;
    },
    [sendMessage.fulfilled.type]: (state: editMessageState) => {
      state.sendStatus = StatusEnum.Fulfilled;
    },
    [sendMessage.rejected.type]: (state: editMessageState) => {
      state.sendStatus = StatusEnum.Rejected;
    },

    [replyMessage.pending.type]: (
      state: editMessageState,
      action: PayloadAction<unknown, string, Meta<SendMessagePayloadType>>,
    ) => {
      state.sendStatus = StatusEnum.Pending;

      state.sendType = action.meta.arg.draft ? SendTypeEnum.Draft : SendTypeEnum.New;
    },
    [replyMessage.fulfilled.type]: (state: editMessageState) => {
      state.sendStatus = StatusEnum.Fulfilled;
    },
    [replyMessage.rejected.type]: (state: editMessageState) => {
      state.sendStatus = StatusEnum.Rejected;
    },
    [getAttachmentsFormatsByReasonId.fulfilled.type]: (
      state: editMessageState,
      action: ReturnType<typeof getAttachmentsFormatsByReasonId.fulfilled>,
    ) => {
      state.attachmentsFormats[action.meta.arg] = action.payload;
    },
    ...resetStoreActions(() => initialState),
  },
});

export const editMessageReducer = editMessageSlice.reducer;
export const { deleteAttachmentFromMessage, resetStatus, resetAttachments, setAttachments } = editMessageSlice.actions;
