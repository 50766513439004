export enum StatusEnum {
  Uninitialized = 'uninitialized',
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
  Aborted = 'aborted',
}

export enum DisplayAs {
  ModalAlert = 'modal_alert',
  Post = 'post',
  Alert = 'alert',
  FAQ = 'faq',
  Link = 'link',
  Page = 'page',
}

export enum EnvironmentsEnum {
  development = 'development',
  qa = 'qa',
  staging = 'staging',
  testing = 'testing',
  production = 'production',
}

export const ADMIN_ROLE_NAME = 'admin';
export const SUPER_ADMIN_ROLE_NAME = 'super_admin';

export const PHONE_MASK = '000-000-0000';

export const PRIVACY_PRACTICES_LINK = `/assets/Notice_of_Privacy_Practices.pdf`;

export const MIN_YEAR = 1900;
