import React, { useMemo, useCallback } from 'react';

import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { generatePathWithQuery, useQueryParamsObject } from '@common/router';

import { DEFAULT_ITEMS_ON_PAGE, DEFAULT_PAGE } from '../constants';

type Parameters<T> = {
  items: T[];
  defaultPage?: number;
  itemsOnPage?: number;
};

type Result<T> = {
  itemsByPage: T[];
  page: number;
  onChangePage: (_: unknown, page: number) => void;
};

export const useQueryPagination = <T>({
  items,
  defaultPage = DEFAULT_PAGE,
  itemsOnPage = DEFAULT_ITEMS_ON_PAGE,
}: Parameters<T>): Result<T> => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useQueryParamsObject();
  const page = Number(params.currentPage) || defaultPage;

  const itemsByPage = useMemo(() => {
    if (items.length === itemsOnPage) {
      return items;
    }

    return items.slice(itemsOnPage * (page - 1), itemsOnPage * page);
  }, [page, items, itemsOnPage]);

  const handleChangePage = useCallback(
    (_: unknown, page: number) => {
      navigate(generatePathWithQuery(pathname, { ...params, currentPage: page }));
    },
    [navigate, params, pathname],
  );

  return { itemsByPage, page, onChangePage: handleChangePage };
};
