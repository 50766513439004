import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';

import { getUnreadMessages, unreadCountStatusSelector } from '../store';

export function useUnreadMessages() {
  const status = useSelector(unreadCountStatusSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if ([StatusEnum.Uninitialized].includes(status)) {
      dispatch(getUnreadMessages());
    }
  }, [status, dispatch]);
}
