import React, { forwardRef, Ref, useMemo } from 'react';

import classNames from 'classnames';
import DOMPurify from 'dompurify';

import './SafeHtml.modules.css';

type OwnProps = {
  allowedTags?: string[];
  allowedAttributes?: string[];
  className?: string;
  html: string | Node;
};

type Props = OwnProps & {
  forwardedRef: Ref<HTMLDivElement>;
};

const ALLOWED_TAGS = [
  'div',
  'span',
  'blockquote',
  'p',
  'a',
  'ul',
  'ol',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'b',
  'i',
  'strong',
  'em',
  'strike',
  'code',
  'pre',
  'table',
  'thead',
  'caption',
  'tbody',
  'tr',
  'th',
  'td',
  'hr',
  'br',
];

const ALLOWED_ATTRIBUTES = ['target'];

function SafeHtml({ allowedTags, allowedAttributes, className, html, forwardedRef }: Props) {
  const sanitizedHtml = useMemo(
    () =>
      DOMPurify.sanitize(html, {
        ALLOWED_TAGS: allowedTags,
        ADD_ATTR: allowedAttributes,
      }),
    [html, allowedTags, allowedAttributes],
  );

  return (
    <div
      ref={forwardedRef}
      className={classNames('safe-html', className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
}

SafeHtml.defaultProps = {
  allowedTags: ALLOWED_TAGS,
  allowedAttributes: ALLOWED_ATTRIBUTES,
  className: '',
};

export const SafeHtmlWithRef = forwardRef<HTMLDivElement, OwnProps>((props, ref) => (
  <SafeHtml forwardedRef={ref} {...props} />
));

SafeHtmlWithRef.displayName = 'SafeHtml';
