import { createSelector } from 'reselect';

import { StatusEnum } from '@common/constants';

const statesSelector = (state: RootState) => state.staticList.states;

export const getStatesStatusSelector = (state: RootState) => state.staticList.statesStatus;
export const getStatesSelector = createSelector(statesSelector, (states) =>
  states.map((state) => ({
    id: state.code,
    name: state.name,
  })),
);

const languagesSelector = (state: RootState) => state.staticList.languages;
export const getLanguagesStatusSelector = (state: RootState) => state.staticList.languagesStatus;
export const getLanguagesLoadingSelector = (state: RootState) =>
  [StatusEnum.Pending, StatusEnum.Uninitialized].includes(state.staticList.languagesStatus);
export const getAllLanguagesSelector = createSelector(languagesSelector, (languages) =>
  languages.map((language) => ({
    id: language.code,
    name: language.name,
  })),
);

const timezonesSelector = (state: RootState) => state.staticList.timezones;
export const getTimezonesStatusSelector = (state: RootState) => state.staticList.timezonesStatus;
export const getTimezonesLoadingSelector = (state: RootState) =>
  [StatusEnum.Pending, StatusEnum.Uninitialized].includes(state.staticList.timezonesStatus);
export const getTimezonesSelector = createSelector(timezonesSelector, (timezones) =>
  timezones.map((timezone) => ({
    id: timezone.rails_time_zone,
    name: `(${timezone.utc_offset.split(':').slice(0, 2).join(':')}) ${timezone.rails_time_zone}`,
  })),
);

export const getEligibilitiesStatusSelector = (state: RootState) => state.staticList.eligibilityStatus;
const eligibilitySelector = (state: RootState) => state.staticList.eligibility ?? {};
export const getEligibilitiesSelector = createSelector(eligibilitySelector, (eligibility) =>
  Object.keys(eligibility).map((key) => ({ id: key, name: eligibility[Number(key)] })),
);
