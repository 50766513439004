import React from 'react';

import classNames from 'classnames/bind';

import { Skeleton } from '@components/Skeleton';

import styles from '../MessageBox.module.css';

const cn = classNames.bind(styles);

type Props = {
  isLoading: boolean;
};

export const MessageSkeleton = ({ isLoading }: Props) => {
  const animation = isLoading ? 'pulse' : false;

  return (
    <div className={cn('message-box__header')}>
      <div className={cn('message-box__subject')}>
        <Skeleton animation={animation} className={cn('message-box__skeleton')} />
      </div>
      <div className={cn('message-box__sent-container')}>
        <div className={cn('message-box__sender')}>
          <Skeleton animation={animation} className={cn('message-box__skeleton')} />
        </div>
        <div className={cn('message-box__sent')}>
          <Skeleton animation={animation} className={cn('message-box__skeleton')} />
        </div>
      </div>
      <div className={cn('message-box__attachments')}>
        <Skeleton animation={animation} className={cn('message-box__skeleton')} />
      </div>
      <div className={cn('message-box__body')}>
        <Skeleton animation={animation} className={cn('message-box__skeleton')} />
        <Skeleton animation={animation} className={cn('message-box__skeleton')} />
        <Skeleton animation={animation} className={cn('message-box__skeleton')} />
      </div>
    </div>
  );
};
