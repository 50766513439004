import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';

import { getEligibilitiesStatusSelector, getEligibility } from '../store';

export function useFetchEligibilities() {
  const status = useSelector(getEligibilitiesStatusSelector);
  const dispatch = useDispatch();

  useIgnoreEffectDeps(() => {
    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(status)) {
      dispatch(getEligibility());
    }
  }, []);
}
