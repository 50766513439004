import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useScreenSize } from '@common/hooks/useScreenSize';
import { Button } from '@components/Button';
import { Modal, ModalSize } from '@components/Modal';

import { Content } from './components/Content';
import { locale } from './locale';
import { defaultClinicPhoneSelector, showDefaultClinicPhoneModal } from './store';

import styles from './DefaultClinicPhone.module.css';

const cn = classNames.bind(styles);

const MODAL_ID = 'default_clinic_phone_modal';
const TITLE_ID = 'default_clinic_phone_title';

export const DefaultClinicPhone = () => {
  const { show, status } = useSelector(defaultClinicPhoneSelector);
  const dispatch = useDispatch();
  const fulfilled = status === StatusEnum.Fulfilled;
  const { isMobileView } = useScreenSize();
  const handleClose = useCallback(() => {
    dispatch(showDefaultClinicPhoneModal(false));
  }, [dispatch]);

  const mobileActionButton = useMemo(
    () => (
      <Button onClick={handleClose} className={cn('default-clinic-phone__mobile-button')} variant="outlined">
        {locale.button_close_text}
      </Button>
    ),
    [handleClose],
  );

  return (
    <Modal
      open={show}
      onClose={handleClose}
      className={cn('default-clinic-phone')}
      paperClassName={cn('default-clinic-phone__paper')}
      id={MODAL_ID}
      ariaLabelledby={fulfilled ? TITLE_ID : undefined}
      actions={isMobileView ? mobileActionButton : null}
      modalActionsClassName={cn({ 'default-clinic-phone__mobile-action': isMobileView })}
      size={ModalSize.Medium}
    >
      <Content />
    </Modal>
  );
};
