import { createSlice } from '@reduxjs/toolkit';

import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { USER_SPECIFIC_INITIAL_STATE, addUserSpecificBuilderCases } from '../../../utils/userIdSpecificStoreData';
import { getCareGuidelines } from './actions';
import { CareGuidelinesState } from './types';

const initialState: CareGuidelinesState = {
  careGuidelinesRequest: USER_SPECIFIC_INITIAL_STATE,
};

export const careGuidelinesSlice = createSlice({
  name: 'careGuidelines',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSpecificBuilderCases({
      builder,
      asyncThunk: getCareGuidelines,
      updateState: (state, updates) => {
        state.careGuidelinesRequest = {
          ...state.careGuidelinesRequest,
          ...updates,
        };
      },
      getUserId: (state) => state.careGuidelinesRequest.userId,
    });

    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const careGuidelinesReducer = careGuidelinesSlice.reducer;
