import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetStoreActions } from '@modules/reset';

import { MedicationsState } from './types';

const initialState: MedicationsState = {
  selectedMenuId: null,
  selectedFamilyMemberId: null,
};

export const myHealthSlice = createSlice({
  name: 'myHealth',
  initialState,
  reducers: {
    selectMenuItem(state: MedicationsState, action: PayloadAction<MedicationsState['selectedMenuId']>) {
      state.selectedMenuId = action.payload;
    },
    selectFamilyMemberId(state: MedicationsState, action: PayloadAction<Nullable<number>>) {
      state.selectedFamilyMemberId = action.payload;
    },
  },
  extraReducers: {
    ...resetStoreActions(() => initialState),
  },
});

export const { selectMenuItem, selectFamilyMemberId } = myHealthSlice.actions;
export const myHealthReducer = myHealthSlice.reducer;
