import { fetch } from '@common/fetch';

import { GetDocumentsRequestPayloadType } from './types';

type Params = {
  dependentUserId?: number;
  data: GetDocumentsRequestPayloadType;
};

export const getDocumentsRequest = async ({ dependentUserId, data }: Params) => {
  const result = fetch.post({
    url: '/Document/documents',
    params: {
      dependentUserId,
    },
    data,
  });

  const res = await result.response;

  return res?.data;
};
