import { useEffect, useRef } from 'react';

import { noop } from '@common/utils';

export function useIgnoreEffectDeps(callback: () => void, deps: unknown[] = []): void {
  const callbackRef = useRef<() => void>(noop);

  callbackRef.current = callback;

  useEffect(() => callbackRef?.current?.(), deps); // eslint-disable-line react-hooks/exhaustive-deps
}
