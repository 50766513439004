import React, { useCallback, useState } from 'react';

import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';

type UseFocusElementType = {
  maxTabIndex: number;
  initialFocusIndex?: number | null;
  rootElement: HTMLDivElement | null;
  shouldSubscribe: boolean;
  subscribeRoot?: boolean;
  focusElement?: boolean;
};

export const useFocusElement = ({
  maxTabIndex,
  initialFocusIndex = 0,
  rootElement,
  shouldSubscribe,
  subscribeRoot,
  focusElement = false,
}: UseFocusElementType) => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(initialFocusIndex);

  const getElementByTabIndex = useCallback(
    (index: number): Nullable<HTMLElement> =>
      rootElement?.querySelector(`[data-tabindex='${index}']`) || rootElement?.querySelector(`[tabindex='${index}']`),
    [rootElement],
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useIgnoreEffectDeps(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      let nextIndex;

      if (subscribeRoot) {
        const currentElement: Nullable<HTMLElement> = focusedIndex !== null ? getElementByTabIndex(focusedIndex) : null;

        if (currentElement !== event.target) {
          return;
        }
      }

      if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
        nextIndex = focusedIndex !== null ? focusedIndex + 1 : 1;
        nextIndex = nextIndex > maxTabIndex ? 0 : nextIndex;
        const nextItem = getElementByTabIndex(nextIndex);

        if (focusElement) {
          nextItem?.focus();
        }

        return setFocusedIndex(nextIndex);
      }

      if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
        nextIndex = focusedIndex !== null ? focusedIndex - 1 : maxTabIndex;
        nextIndex = nextIndex < 0 ? maxTabIndex : nextIndex;

        const nextItem = getElementByTabIndex(nextIndex);

        if (focusElement) {
          nextItem?.focus();
        }

        return setFocusedIndex(nextIndex);
      }

      if (event.key === 'Enter' && typeof focusedIndex === 'number') {
        const item = getElementByTabIndex(focusedIndex);
        item?.click?.();
      }
    };

    const handleScroll = (event: KeyboardEvent) => {
      if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
        event.preventDefault();
      }
    };

    if (shouldSubscribe) {
      // separate subscriptions for TS works correctly
      if (subscribeRoot) {
        rootElement?.addEventListener('keyup', handleKeyUp);
        rootElement?.addEventListener('keydown', handleScroll);
      } else {
        document.addEventListener('keyup', handleKeyUp);
        document.addEventListener('keydown', handleScroll);
      }
    }

    return () => {
      if (subscribeRoot) {
        rootElement?.removeEventListener('keyup', handleKeyUp);
        rootElement?.removeEventListener('keydown', handleScroll);
      } else {
        document.removeEventListener('keyup', handleKeyUp);
        document.removeEventListener('keydown', handleScroll);
      }
    };
  }, [shouldSubscribe, focusedIndex]);

  return { focusedIndex, setFocusedIndex };
};
