import React, { useEffect } from 'react';

import { useSnackbar, SnackbarKey } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { Message } from './components/Message';
import { notificationsSelector, removeSnackbar } from './store';

let displayed = [] as SnackbarKey[];

const storeDisplayed = (id: SnackbarKey) => {
  displayed = [...displayed, id];
};

const removeDisplayed = (id: SnackbarKey) => {
  displayed = [...displayed.filter((key) => id !== key)];
};

export const useNotifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(<Message message={message} subtitle={options.subtitle} />, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (myKey) {
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};
