import qs from 'qs';
import { generatePath, Params } from 'react-router';

export const generatePathWithParamsAndQuery = (
  path: string,
  params: Params,
  queryParams: { [k: string]: string | number | boolean | null | undefined | object } | string,
): string => {
  const pathWithParams = generatePath(path, params);

  if (typeof queryParams === 'string') {
    return `${pathWithParams}?${queryParams}`;
  }

  return `${pathWithParams}?${qs.stringify(queryParams)}`;
};

type MaybeReadonlyArray<T> = readonly T[] | T[];

export const generatePathWithQuery = (
  path: string,
  queryParams: { [k: string]: string | number | null | undefined | boolean | MaybeReadonlyArray<string> | object },
) => `${path}?${qs.stringify(queryParams)}`;

type ParseQueryParamsReturn = { [k: string]: string };
export const parseQueryParams = (queryString: string): ParseQueryParamsReturn =>
  qs.parse(queryString.slice(1)) as ParseQueryParamsReturn;
