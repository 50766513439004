import { createAsyncThunk } from '@reduxjs/toolkit';

import { tokenService } from '@common/TokenService';
import { userProfileService } from '@common/UserProfileService';
import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';
import { EligibilitiesType } from '@common/types';
import { getErrorMessage } from '@common/utils/getErrorMessage';
import { matchPasswordErrors } from '@common/utils/matchPasswordErrors';
import { enqueueSnackbar } from '@modules/Snackbar';

import {
  AddressPayloadType,
  AddressResponseType,
  AddressType,
  FamilyMemberType,
  SaveCredentialsType,
  UserProfileResponseType,
  PersonalInformationType,
  ContactInformationType,
  EmergencyContactInformationType,
  CustomSettingsInformationType,
  CommunicationPreferencesPayload,
  CommunicationPreference,
} from '../types';
import { familyMembersStatusSelector } from './selectors';
import { ChangeEligibilityResponseType } from './types';

export const getActiveEligibilities = createAsyncThunk<Array<EligibilitiesType>, void>(
  'get-active-eligibilities',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: '/user/get-active-eligibilities' });
      const res = await data.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUserProfile = createAsyncThunk<UserProfileResponseType, void>(
  'get-user-profile',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: '/User/user-profile' });
      const res = await data.response;

      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCommunicationPreferences = createAsyncThunk<CommunicationPreference[], void>(
  'get-communication-preferences',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: '/User/communication-preferences' });
      const res = await data.response;

      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getFamilyMemberProfile = createAsyncThunk<UserProfileResponseType, { userId: number }>(
  'get-family-member-profile',
  async (params, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: '/User/user-profile', params });
      const res = await data.response;

      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getHomeAddress = createAsyncThunk<AddressResponseType, Nullable<number>>(
  'get-home-address',
  async (familyMemberId, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/User/home-address',
        params: {
          userId: familyMemberId,
        },
      });
      const res = await data.response;

      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveHomeAddress = createAsyncThunk<AddressType, AddressPayloadType>(
  'save-home-address',
  async (data, { rejectWithValue }) => {
    try {
      const { response } = fetch.post({ url: '/User/home-address', data });
      const res = await response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveUserProfile = createAsyncThunk<unknown, UserProfileResponseType>(
  'save-user-profile',
  async (data: UserProfileResponseType, { rejectWithValue }) => {
    try {
      const { response } = fetch.post({ url: '/User/user-profile', data });
      const res = await response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const savePersonalInformation = createAsyncThunk<unknown, PersonalInformationType>(
  'save-personal-information',
  async (data: PersonalInformationType, { rejectWithValue }) => {
    try {
      const { response } = fetch.post({ url: '/User/user-profile-personal-information', data });
      const res = await response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveContactInformation = createAsyncThunk<unknown, ContactInformationType>(
  'save-contact-information',
  async (data: ContactInformationType, { rejectWithValue }) => {
    try {
      const { response } = fetch.post({ url: '/User/user-profile-contact-information', data });
      const res = await response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveEmergencyContactInformation = createAsyncThunk<unknown, EmergencyContactInformationType>(
  'save-emergency-contact-information',
  async (data: EmergencyContactInformationType, { rejectWithValue }) => {
    try {
      const { response } = fetch.post({ url: '/User/user-profile-emergency-contact-information', data });
      const res = await response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveCustomSettingsInformation = createAsyncThunk<unknown, CustomSettingsInformationType>(
  'save-custom-settings-information',
  async (data: CustomSettingsInformationType, { rejectWithValue }) => {
    try {
      const { response } = fetch.post({ url: '/User/user-profile-custom-settings-information', data });
      const res = await response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveCredentials = createAsyncThunk<unknown, SaveCredentialsType>(
  'save-credentials',
  async (data: SaveCredentialsType, { rejectWithValue, dispatch }) => {
    try {
      const { response } = fetch.post({ url: '/User/save-login-credentials', data, skipAlert: true });
      const res = await response;

      return res?.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = matchPasswordErrors(error?.response?.data?.error);
      dispatch(
        enqueueSnackbar({
          message: message || getErrorMessage(error),
          options: {
            variant: 'error',
          },
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const changeCurrentEligibility = createAsyncThunk<ChangeEligibilityResponseType, number>(
  'change-current-eligibility',
  async (eligibilityId, { rejectWithValue }) => {
    try {
      const data = fetch.post({
        url: '/Authentication/change-current-eligibility',
        data: {
          eligibility_id: eligibilityId,
        },
      });

      const res = await data.response;
      const wellnessId = res?.data?.user?.wellness_id;
      const userProfile = userProfileService.getUserProfile(wellnessId);

      tokenService.saveToken(res?.data.token, wellnessId);
      await userProfileService.saveUserProfile(
        {
          ...userProfile,
          user: {
            ...userProfile.user,
            state_of_indiana_clinic_access: res?.data?.user.state_of_indiana_clinic_access,
            state_of_indiana_wellness_only: res?.data?.user.state_of_indiana_wellness_only,
          },
          user_auth: {
            current_eligibility: res?.data?.user_auth.current_eligibility,
            enabled_features: res?.data?.user_auth.enabled_features,
            hide_challenges: res?.data?.user_auth?.hide_challenges,
            hide_incentives: res?.data?.user_auth?.hide_incentives,
            roles: res?.data?.user_auth.roles,
            is_supplemental_waiver_required: res?.data?.user_auth.is_supplemental_waiver_required,
          },
        },
        wellnessId,
      );

      return res?.data || {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getFamilyMembers = createAsyncThunk<Array<FamilyMemberType>, void>(
  'user-get-family-members',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: '/User/get-family-members' });
      const res = await data.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => familyMembersStatusSelector(getState() as RootState) !== StatusEnum.Pending,
  },
);

export const getSchedulableFamilyMembers = createAsyncThunk<Array<FamilyMemberType>, void>(
  'user-get-schedulable-family-members',
  async (_, { rejectWithValue }) => {
    try {
      const data = fetch.get({ url: '/User/get-family-members?schedulable=true' });
      const res = await data.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveFamilyMembers = createAsyncThunk<Array<FamilyMemberType>, Array<FamilyMemberType>>(
  'set-approved-viewers',
  async (familyMembers, { rejectWithValue }) => {
    try {
      const requestData = familyMembers
        .filter((familyMember) => familyMember.approved_viewer)
        .map((familyMember) => familyMember.id);

      const { response } = fetch.post({ url: '/User/set-approved-viewers', data: requestData });
      const res = await response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveCommunicationPreferences = createAsyncThunk<void, CommunicationPreferencesPayload>(
  'save-communication-preferences',
  async (payload, { rejectWithValue }) => {
    try {
      const { response } = fetch.put({
        url: '/User/communication-preferences',
        data: payload,
      });
      const res = await response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
