import { useEffect } from 'react';

import qs from 'qs';
import { useLocation } from 'react-router';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useQueryParamsObject = <T extends { [k: string]: any }>(): T => {
  const { search } = useLocation();

  return (qs.parse(search?.slice(1)) as T) || {};
};

export const useReload = () => {
  const location = useLocation();

  useEffect(() => {
    document.body.tabIndex = -1;
    document.body.focus();
    document.body.removeAttribute('tabIndex');
  }, [location.pathname]);
};
