import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetStoreActions } from '@modules/reset';

import { getAttachmentsFormats } from './actions';
import { UploadDocumentsState } from './types';

const initialState: UploadDocumentsState = {
  attachmentsFormats: null,
};

export const uploadDocumentsSlice = createSlice({
  name: 'uploadDocuments',
  initialState,
  reducers: {},
  extraReducers: {
    [getAttachmentsFormats.fulfilled.type]: (state: UploadDocumentsState, action: PayloadAction<string[]>) => {
      state.attachmentsFormats = action.payload;
    },

    ...resetStoreActions(() => initialState),
  },
});

export const uploadDocumentsReducer = uploadDocumentsSlice.reducer;
