import React, { ReactElement } from 'react';

import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

import { useIsAuthorized } from '@common/hooks';
import { useQueryParamsObject } from '@common/router/hooks';
import { ROUTES } from '@common/routes';

type Props = {
  children: ReactElement;
};

export const PrivateRoute = ({ children }: Props) => {
  const location = useLocation();
  const { wellness_id: wellnessId } = useQueryParamsObject<{ wellness_id: string }>();
  const authorized = useIsAuthorized();

  if (wellnessId) {
    return null;
  }

  if (!authorized) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  return children;
};
