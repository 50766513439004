import React, { ReactElement } from 'react';

import SkeletonMaterial, { SkeletonProps as SkeletonPropsMaterial } from '@mui/material/Skeleton';

export type SkeletonProps = {
  className?: string;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
} & Pick<SkeletonPropsMaterial, 'variant' | 'width' | 'height' | 'animation' | 'children'>;

export function Skeleton({
  className,
  variant = 'text',
  width,
  height,
  animation,
  children,
  component,
  loading = true,
}: SkeletonProps): ReactElement {
  if (!loading) {
    return children as ReactElement;
  }

  return (
    <SkeletonMaterial
      component={component}
      className={className}
      variant={variant}
      width={width}
      height={height}
      animation={animation}
    >
      {children}
    </SkeletonMaterial>
  );
}
