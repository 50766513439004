import { MeasurementEnum } from '../../constants';

export const locale = {
  title: 'Fitness',
  dashboard_title: 'Fitness Activity',
  history_title: 'Recently Recorded Fitness Activity',
  record_activity: 'Record Activity',
  measurements: {
    [MeasurementEnum.Minutes]: 'Minutes Per Month',
    [MeasurementEnum.Miles]: 'Miles Per Month',
  },
  measurementsShort: {
    [MeasurementEnum.Minutes]: 'min',
    [MeasurementEnum.Miles]: 'mi',
  },
  measurementsYScaleTitle: {
    [MeasurementEnum.Minutes]: 'Minutes',
    [MeasurementEnum.Miles]: 'Miles',
  },
};
