import { SyntheticEvent, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useIgnoreEffectDeps } from '@common/hooks';
import { noop } from '@common/utils';
import { getLocale } from '@common/utils/locale';

import { locale } from '../locale';
import { getAttachmentsFormats, attachmentsFormatsSelector } from '../store';

const FILE_SIZE = 10485760;

type Params = {
  onChangeAttachment?: () => void;
};

export const useAttachment = (params: Params = {}) => {
  const { onChangeAttachment = noop } = params;
  const dispatch = useDispatch();
  const [attachmentsError, setAttachmentsError] = useState<Nullable<string>>(null);
  const [attachment, setAttachment] = useState<Nullable<File>>(null);
  const attachmentsFormats = useSelector(attachmentsFormatsSelector);

  const clearError = useCallback(() => {
    setAttachmentsError(null);
  }, []);

  const handleAttachmentsOnLoad = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const target = event.target as EventTarget & { files: File[] };
      const file = Array.from(target.files)[0];

      if (!file) {
        return;
      }

      const isExceededSize = file.size > FILE_SIZE;

      if (isExceededSize) {
        setAttachmentsError(getLocale(locale.exceeds, { file: file.name }));

        return;
      }

      clearError();
      onChangeAttachment();
      setAttachment(file);
    },
    [clearError, onChangeAttachment],
  );

  const handleAttachmentDelete = useCallback(() => {
    setAttachment(null);
  }, []);

  useIgnoreEffectDeps(
    () => () => {
      setAttachment(null);
    },
    [],
  );

  useIgnoreEffectDeps(() => {
    if (!attachmentsFormats) {
      dispatch(getAttachmentsFormats());
    }
  }, []);

  return {
    attachment,
    attachmentsFormats: attachmentsFormats ?? [],
    attachmentsError,
    handleAttachmentsOnLoad,
    handleAttachmentDelete,
    clearAttachmentError: clearError,
  };
};
