import { WELLNESS_IDS_KEY } from '@common/TokenService';
import { UserAuthResponseType } from '@common/types';
import { addToStorageObjectKeyValue, getLastStorageArrayItem } from '@common/utils/storage';
import { gtmSetUserProperties } from '@modules/gtm';

import { UserResponseType } from './types';

const USER_PROFILE_KEY = 'user_profile';

export interface IUserProfileService {
  getUserProfile: (wellnessId: string) => UserResponseType | null;
  saveUserProfile: (userProfile: UserResponseType, wellnessId: string) => void;
  removeUserProfile: () => void;
}

class UserProfileService implements IUserProfileService {
  /* eslint-disable class-methods-use-this */
  getUserProfile(wellnessId?: string) {
    const id = wellnessId || getLastStorageArrayItem(WELLNESS_IDS_KEY);
    const userProfiles = sessionStorage.getItem(USER_PROFILE_KEY);
    const parsedUserProfiles = userProfiles ? JSON.parse(userProfiles) : {};

    return parsedUserProfiles[id] ? JSON.parse(parsedUserProfiles[id]) : null;
  }

  saveUserProfile(userProfile: UserResponseType, wellnessId: string) {
    addToStorageObjectKeyValue(JSON.stringify(userProfile), wellnessId, USER_PROFILE_KEY);
  }

  saveAuthUserData(authData: UserAuthResponseType, wellnesId: string) {
    // TODO: convert other fields
    const { user } = authData;
    const { cell_phone: cellPhone, ...restUserFields } = user;

    const userProfile: UserResponseType = {
      ...(authData as UserResponseType),
      user: {
        ...(restUserFields as UserResponseType['user']),
        cell_phone_number: cellPhone,
      },
    };

    gtmSetUserProperties({
      wellnessId: userProfile.user?.wellness_id,
      gender: userProfile.user?.gender,
      bornOn: userProfile.user?.born_on ?? undefined,
      employerId: userProfile.user_auth?.current_eligibility?.employer?.id,
      employerName: userProfile.user_auth?.current_eligibility?.employer?.name,
    });

    this.saveUserProfile(userProfile, wellnesId);
  }

  removeUserProfile() {
    sessionStorage.removeItem(USER_PROFILE_KEY);
  }
  /* eslint-enable class-methods-use-this */
}

export const userProfileService = new UserProfileService();
