import { WellnessTypesEnum } from '../constants';

export const URLS_BY_WELLNESS_TYPE = {
  [WellnessTypesEnum.Fitness]: {
    saveUrl: '/WellnessLog/save-exercise',
    listUrl: '/WellnessLog/get-exercise',
    deleteUrl: '/WellnessLog/exercise',
  },
  [WellnessTypesEnum.Sleep]: {
    saveUrl: '/WellnessLog/save-sleep',
    listUrl: '/WellnessLog/get-sleep',
    deleteUrl: '/WellnessLog/sleep',
  },
  [WellnessTypesEnum.Steps]: {
    saveUrl: '/WellnessLog/save-steps',
    listUrl: '/WellnessLog/get-steps',
    deleteUrl: '/WellnessLog/steps',
  },
  [WellnessTypesEnum.Water]: {
    saveUrl: '/WellnessLog/save-water',
    listUrl: '/WellnessLog/get-water',
    deleteUrl: '/WellnessLog/water',
  },
  [WellnessTypesEnum.Weight]: {
    saveUrl: '/Measurement/save-weight',
    listUrl: '/Measurement/get-weights',
    deleteUrl: '/Measurement/weight',
  },
};
