import { createSelector } from 'reselect';

import { createUserIdSpecificSelectors } from '../../../utils/userIdSpecificStoreData';

const { currentUserStatusSelector, dryDataSelector } = createUserIdSpecificSelectors(
  (state) => state.visitSummaries.summaryRequest,
);

export const statusSelector = currentUserStatusSelector;

export const dataSelector = createSelector(dryDataSelector, (data) => data ?? []);

export const downloadVisitSummariesReportStatusSelector = (state: RootState) => state.visitSummaries.downloadStatus;
