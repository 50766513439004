import React, { useCallback } from 'react';

import { Select, SelectProps, OptionType } from '@components/Select';

import { useController, UseControllerProps, FieldValues } from '../hooks';
import { locale } from '../locale';

type TextFieldWithControlProps<FormValues extends FieldValues, CustomOptionType> = {
  name: string;
  required?: { value: boolean; message: string } | boolean;
  component?: 'li' | 'div' | null;
  autoComplete?: string;
} & UseControllerProps<FormValues> &
  Omit<SelectProps<CustomOptionType>, 'required'>;

export function SelectWithControl<FormValues extends FieldValues, CustomOptionType = OptionType>({
  control,
  name,
  required,
  onChange,
  optionComponent,
  inputRef,
  autoComplete,
  ...restProps
}: TextFieldWithControlProps<FormValues, CustomOptionType>) {
  const requiredMessage = typeof required === 'boolean' && required ? locale.required_message : required;

  const {
    field: { onChange: fieldOnChange, value = '', ref },
    fieldState,
  } = useController({
    name,
    control,
    rules: { required: requiredMessage },
  });

  const handleChange = useCallback<NonNullable<SelectProps['onChange']>>(
    (e, child) => {
      onChange?.(e, child);
      return fieldOnChange(e, child);
    },
    [onChange, fieldOnChange],
  );

  return (
    <Select
      {...restProps}
      inputRef={inputRef || ref}
      required={!!required}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      onChange={handleChange}
      value={value}
      name={name}
      optionComponent={optionComponent}
      autoComplete={autoComplete}
    />
  );
}
