import isNil from 'lodash.isnil';

import { getDaysInMonth } from '@common/utils/dateTimeUtil/dateTimeUtil';

const FULL_YEAR_LENGTH = 4;
const SHORT_YEAR_LENGTH = 2;

function prepareDate(str: string, nextStr: Nullable<string>, max?: number | string) {
  if (!str) {
    return '';
  }

  if (!isNil(max) && (str.charAt(0) !== '0' || str === '00')) {
    const num = Number(str);
    const isMoreThanMaxFirstChar = num > Number(max.toString().charAt(0));
    if (str.length === 1 && isMoreThanMaxFirstChar && isNil(nextStr)) {
      return `0${num}`;
    }

    if (str.length === 2 && Number(str) > max && isNil(nextStr)) {
      return str[0];
    }
  }

  return str;
}

function prepareYear(str: string, maxYear?: number | string, maxLength?: number) {
  if (!str) {
    return '';
  }

  if (!isNil(maxYear) && String(maxYear).length && str.length === SHORT_YEAR_LENGTH) {
    const date = new Date(Number(str), 1, 1);
    if (date.getFullYear() <= maxYear) {
      return str;
    }
  }

  if (maxLength === SHORT_YEAR_LENGTH && str.length > SHORT_YEAR_LENGTH) {
    return str.substring(0, 2);
  }

  if (!isNil(maxYear) && String(maxYear).length === FULL_YEAR_LENGTH) {
    if (str <= maxYear) {
      return str;
    }

    return str.substring(0, 3);
  }

  return str.substring(0, 2);
}

type OptionsType = {
  inputFormat: string;
  maxDate?: Date;
};

const getMaxLengths = (options: OptionsType, currentMonth = new Date().getMonth(), year = new Date().getFullYear()) => {
  const { inputFormat, maxDate } = options;
  const date = new Date(year, currentMonth - 1);
  const daysInMonth = getDaysInMonth(date);
  const maxFullYear = maxDate?.getFullYear()?.toString();

  const DATE_MAX_LENGTHS: PartialRecord<string, string | number> = {
    mm: 12,
    dd: daysInMonth || 31,
    yy: maxFullYear || 99,
    yyyy: maxFullYear || 9999,
  };
  const splittedFormat = inputFormat.toLowerCase().split('/');

  return splittedFormat.map((item) => DATE_MAX_LENGTHS[item]);
};

const addSlashes = (string: string) => {
  if (string.length < 3) {
    return string;
  }
  const lastThree = string.slice(string.length - 3);
  const lastThreeWithSlash = `${lastThree.substring(0, 2)}/${lastThree[2]}`;

  return lastThree.includes('/') ? string : `${string.slice(0, string.length - 3)}${lastThreeWithSlash}`;
};

export const dateFormatter = (options: OptionsType) => (rawValue?: string | number) => {
  const { inputFormat } = options;
  let newValue = rawValue ? rawValue.toString() : '';
  if (newValue === '/') {
    return '';
  }

  newValue = newValue.replace(/\/+/gi, '/').replace(/[^0-9/]/g, '');
  if (newValue.length === inputFormat.length) {
    return newValue;
  }

  if (newValue.length > inputFormat.length) {
    return newValue.substring(0, inputFormat.length);
  }

  if (newValue.length > 2 && !newValue.includes('/')) {
    newValue = addSlashes(newValue);
  }

  const shouldEndsWishSlash = newValue.endsWith('/');

  if (/\D\/$/.test(newValue)) {
    newValue = newValue.substring(0, newValue.length - 3);
  }
  let values = newValue.split('/');
  const year = values[2];
  const maxLengths = getMaxLengths(options, Number(values[0]), Number(year));
  values = values.slice(0, 2).map((value, index) => prepareDate(value, values[index + 1], maxLengths[index]));
  const yearFormat = inputFormat.split('/')[2];
  const outputYear = prepareYear(year, maxLengths[2], yearFormat?.length);
  newValue = values.join('/');
  newValue = addSlashes(newValue);
  // // add slash at the end if needed
  newValue = shouldEndsWishSlash && !newValue.endsWith('/') ? `${newValue}/` : newValue;
  const shouldRemoveLastSlash = `${rawValue}/` === newValue;
  // // remove slash at the end if needed
  newValue = shouldRemoveLastSlash ? newValue.substring(0, newValue.length - 1) : newValue;

  return outputYear ? `${newValue}/${outputYear}` : newValue;
};
