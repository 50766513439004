import { useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import {
  ALLOWED_ELIGIBILITIES_FOR_FAMILY_MEMBERS,
  EligibilityTypesEnum,
  currentEligibilitySelector,
  familyMembersStatusSelector,
  getUnderageFamilyMembersSelector,
} from '@modules/userProfileData';

export const useShowFamilyMembers = (lowYear = false) => {
  const familyMembers = useSelector((state: RootState) => getUnderageFamilyMembersSelector(state, lowYear));
  const familyMembersStatus = useSelector(familyMembersStatusSelector);

  const currentEligibility = useSelector(currentEligibilitySelector);
  const showFamilyMembers =
    ALLOWED_ELIGIBILITIES_FOR_FAMILY_MEMBERS.includes(
      currentEligibility?.eligibility_type_name as EligibilityTypesEnum,
    ) &&
    familyMembersStatus === StatusEnum.Fulfilled &&
    familyMembers.length > 1;

  return {
    showFamilyMembers,
    familyMembers,
  };
};
