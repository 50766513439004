export const locale = {
  title: 'Schedule A New Appointment',
  who_is_visit_for_error: 'Select who the visit is for to search visit reasons.',
  provider_hint: 'You are scheduling with {{name}}',
  breadcrumbs: {
    home: 'Home',
    appointments: 'Appointments',
    complete_ist: 'Complete list of visit reasons',
  },
};
