import { useDispatch } from 'react-redux';

import { useIgnoreEffectDeps, useIsAuthorized } from '@common/hooks';
import { getImpersonation } from '@common/utils';
import { setImpersonation } from '@modules/Login';

export function usePersistImpersonation() {
  const dispatch = useDispatch();
  const authorized = useIsAuthorized();

  useIgnoreEffectDeps(() => {
    const isImpersonation = getImpersonation();

    if (authorized && !!isImpersonation) {
      dispatch(setImpersonation());
    }
  }, [authorized]);
}
