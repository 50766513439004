import React, { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { SignModal } from '@components/SignModals';
import { logout } from '@modules/Login';
import { SignModalFormFields } from '@modules/globalWeaver/store/types';
import { currentEligibilitySelector, supplementalWaiverRequiredSelector } from '@modules/userProfileData';

import { locale } from './locale';
import {
  acceptSupplementalWaiver,
  getAcceptedSupplementalWaiverCurrentEligibilitySelector,
  getSupplementalWaiverData,
  getSupplementalWaiverTextSelector,
} from './store';

export const SupplementalWaiver = () => {
  const [isOpenSupplementalWaiver, setSupplementalWaiver] = useState(false);
  const dispatch = useDispatch();
  const form = useForm<SignModalFormFields>();
  const { handleSubmit, control, watch } = form;
  const { agreement, signature } = watch();
  const currentEligibility = useSelector(currentEligibilitySelector);
  const isSupplementalWaiverRequired = useSelector(supplementalWaiverRequiredSelector);
  const acceptedSupplementalWaiverCurrentEligibility = useSelector(
    getAcceptedSupplementalWaiverCurrentEligibilitySelector,
  );
  const { title, body, isSignatureRequired } = useSelector(getSupplementalWaiverTextSelector);

  const handleOnClose = useCallback(() => {
    dispatch(logout({ inactivityLogout: false }));
  }, [dispatch]);

  useEffect(() => {
    if (isSupplementalWaiverRequired && !acceptedSupplementalWaiverCurrentEligibility) {
      setSupplementalWaiver(true);
    }
  }, [isSupplementalWaiverRequired, acceptedSupplementalWaiverCurrentEligibility, currentEligibility?.id]);

  const onSubmit = useCallback(() => {
    // TODO-ekvasiuk check the data
    if (currentEligibility?.id) {
      dispatch(acceptSupplementalWaiver({ id: currentEligibility?.id, signature }));
    }
  }, [dispatch, currentEligibility?.id, signature]);

  const getData = useCallback(() => {
    dispatch(getSupplementalWaiverData());
  }, [dispatch]);

  useEffect(() => {
    if (isSupplementalWaiverRequired && !acceptedSupplementalWaiverCurrentEligibility) {
      setSupplementalWaiver(true);
    }
  }, [isSupplementalWaiverRequired, acceptedSupplementalWaiverCurrentEligibility, currentEligibility?.id]);

  useEffect(() => {
    if (acceptedSupplementalWaiverCurrentEligibility) {
      setSupplementalWaiver(false);
    }
  }, [acceptedSupplementalWaiverCurrentEligibility]);

  return (
    <FormProvider {...form}>
      <SignModal
        open={isOpenSupplementalWaiver}
        onClose={handleOnClose}
        onSubmit={handleSubmit(onSubmit)}
        getData={getData}
        title={title}
        body={body}
        cancelButtonTitle={locale.cancel}
        signButtonTitle={locale.accept}
        agreementFieldName="agreement"
        agreementTitle={locale.agreement}
        control={control}
        disabled={!agreement || (!signature && isSignatureRequired)}
        signatureRequired={isSignatureRequired}
      />
    </FormProvider>
  );
};
