import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { hmToMinutes, datePlusTime, formatDateForServer } from '@common/utils';
import { RecordActivityModal } from '@components/RecordActivityModal';

import { WellnessTypesEnum } from '../../../constants';
import { hideEditModal, saveLogItem, SaveSleepRequestType, sleepRecordActivitySelector } from '../../../store';
import { ExerciseItemType } from '../../../types';
import { useResetEntryCount } from '../../../useReserEntryCount';
import { FormValuesType } from '../types';
import { SleepForm } from './SleepForm';

export function SleepRecordActivityModal() {
  const { editModalVisible, selectedItem, saveFormStatus } = useSelector(sleepRecordActivitySelector);
  const dispatch = useDispatch();
  const [entryCount, setEntryCount] = useState(0);

  const handleSave = useCallback(
    (formValues: FormValuesType, recordAnotherEntry = false) => {
      const requestData: SaveSleepRequestType['data'] = {
        id: formValues.id,
        minutes: hmToMinutes(formValues),
        recorded_at: formatDateForServer(datePlusTime(formValues.date, formValues.time)),
      };
      dispatch(saveLogItem({ data: requestData, type: WellnessTypesEnum.Sleep, recordAnotherEntry, entryCount }));
      setEntryCount(entryCount + 1);
    },
    [dispatch, entryCount],
  );

  const handleClose = useCallback(() => {
    dispatch(hideEditModal({ type: WellnessTypesEnum.Sleep }));
  }, [dispatch]);

  useResetEntryCount(editModalVisible, setEntryCount);

  return (
    <RecordActivityModal open={editModalVisible} onClose={handleClose}>
      <SleepForm
        onClose={handleClose}
        onSave={handleSave}
        initialValues={selectedItem as Nullable<ExerciseItemType>}
        saveFormStatus={saveFormStatus}
      />
    </RecordActivityModal>
  );
}
