import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';

import { defaultClinicPhoneSelector, getDefaultClinicPhone } from './store';

export const useGetDefaultPhone = () => {
  const dispatch = useDispatch();
  const { status } = useSelector(defaultClinicPhoneSelector);

  useIgnoreEffectDeps(() => {
    if (![StatusEnum.Pending, StatusEnum.Fulfilled].includes(status)) {
      dispatch(getDefaultClinicPhone());
    }
  }, []);
};
