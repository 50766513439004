import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { secondsToHms } from '@common/utils/dateTimeUtil';
import { DEFAULT_OPTIONS, numberFormatter } from '@common/utils/numberFormatter';
import { FieldRow } from '@components/FieldRow';
import { NumberFieldWithControl, SelectWithControl, TextFieldWithControl, useForm } from '@components/Form';
import { Grid } from '@components/Grid';
import { RecordActivityForm } from '@components/RecordActivityForm';
import { Typography } from '@components/Typography';

import { GRID_SPACING, GRID_ROW_SPACING, FitnessTypes, WellnessTypesEnum } from '../../../constants';
import { getExerciseTypes } from '../../../store';
import { ExerciseItemType, ExerciseTypesItemType } from '../../../types';
import { useResetOnSuccess } from '../../../useResetFormOnSuccess';
import { DateTimePicker } from '../../DateTimePicker';
import { Duration } from '../../Duration';
import { FormValuesType } from '../types';
import { FIELD_NAMES } from './constants';
import { locale } from './locale';

type FormProps = {
  onClose?: VoidFunction;
  showTitle?: boolean;
  initialValues?: ExerciseItemType | null;
  exerciseTypes: ExerciseTypesItemType[];
  saveFormStatus: StatusEnum;
  exerciseTypesStatus: StatusEnum;
  className?: string;
  onSave: (formValues: FormValuesType, recordAnotherEntry?: boolean) => unknown;
};

const getDefaultInitialValues = () => ({
  id: 0,
  type: undefined,
  date: new Date(),
  time: new Date(),
  hours: 0,
  name: '',
  minutes: 0,
  seconds: 0,
  distance: 0,
  duration: 0,
});

const makeInitialValues = (initialValues: ExerciseItemType) => {
  const { hours = 0, minutes = 0, seconds = 0 } = secondsToHms(initialValues.duration);

  return {
    id: initialValues.id,
    type: initialValues.exercise_type,
    name: initialValues.name || '',
    date: new Date(initialValues.recorded_at),
    time: new Date(initialValues.recorded_at),
    hours,
    minutes,
    seconds,
    distance: initialValues.distance ?? 0,
  };
};

const distanceFormatter = numberFormatter({ ...DEFAULT_OPTIONS, precision: 3, max: 1000 });

export const FitnessForm = ({
  initialValues,
  onClose,
  exerciseTypes,
  exerciseTypesStatus,
  saveFormStatus,
  showTitle,
  className,
  onSave,
}: FormProps) => {
  const { handleSubmit, control, watch, formState, setError, clearErrors, resetField, reset } = useForm<FormValuesType>(
    {
      defaultValues: initialValues ? makeInitialValues(initialValues) : getDefaultInitialValues(),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    },
  );

  const dispatch = useDispatch();
  const isLoading = saveFormStatus === StatusEnum.Pending;
  const activityType = watch(FIELD_NAMES.ACTIVITY_TYPE);
  const miles = Number(watch(FIELD_NAMES.MILES));
  const showDescription = activityType?.type === FitnessTypes.OtherFitnessActivity;
  const showDistance = !!activityType?.allows_distance;
  const durationError = formState.errors?.duration?.message;
  const [edit] = useState(initialValues?.can_edit_or_delete);
  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    firstInputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if ([StatusEnum.Uninitialized, StatusEnum.Rejected].includes(exerciseTypesStatus)) {
      dispatch(getExerciseTypes());
    }
  }, [dispatch, exerciseTypes, exerciseTypesStatus]);

  useIgnoreEffectDeps(() => {
    if (initialValues === null) reset();
  }, [initialValues]);

  useIgnoreEffectDeps(() => {
    if (exerciseTypes.length) {
      const exerciseType = exerciseTypes.find(
        (exerciseType) => exerciseType.type === initialValues?.exercise_type?.type,
      );
      resetField(FIELD_NAMES.ACTIVITY_TYPE, { defaultValue: exerciseType });
    }
  }, [exerciseTypes]);

  useIgnoreEffectDeps(() => {
    if (!showDistance) {
      resetField(FIELD_NAMES.MILES);
    }
  }, [showDistance]);

  useIgnoreEffectDeps(() => {
    if (showDistance && formState.isSubmitted) {
      if (!durationError) {
        clearErrors(FIELD_NAMES.MILES);
      } else {
        setError(FIELD_NAMES.MILES, { type: 'required', message: '' });
      }
    }
  }, [durationError, showDistance, formState.isSubmitted]);

  useResetOnSuccess(saveFormStatus, reset, getDefaultInitialValues(), WellnessTypesEnum.Fitness);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <RecordActivityForm
      cancelText={locale.cancel}
      headerText={locale.header}
      isLoading={isLoading}
      onClose={handleClose}
      showTitle={showTitle}
      onSubmitRepeatForm={!edit ? handleSubmit((formValues) => onSave(formValues, true)) : undefined}
      onSubmit={handleSubmit((formValues) => onSave(formValues, false))}
      repeatFormText={locale.record_another_entry}
      submitText={locale.submit}
      className={className}
    >
      <FieldRow size={showDescription ? 'xsmall' : 'small'}>
        <SelectWithControl
          name={FIELD_NAMES.ACTIVITY_TYPE}
          labelId="select_fitness_form_activity_type"
          control={control}
          label={locale.field_labels.activity_type}
          fullWidth
          required={{
            value: true,
            message: locale.errors.activity_type,
          }}
          placeholder={locale.placeholders.activity_type}
          idKey="type"
          nameKey="label"
          selectOption
          options={exerciseTypes}
          disabled={isLoading}
          SelectDisplayProps={{
            role: 'listbox',
          }}
          inputRef={firstInputRef}
        />
      </FieldRow>

      {showDescription && (
        <FieldRow size="small">
          <TextFieldWithControl
            id="fitness_form_name"
            name={FIELD_NAMES.NAME}
            control={control}
            fullWidth
            label={locale.field_labels.name}
            placeholder={locale.placeholders.description}
            disabled={isLoading}
            required={showDescription ? locale.errors.description : undefined}
            maxLength={25}
          />
        </FieldRow>
      )}

      <Grid container rowSpacing={GRID_ROW_SPACING} columnSpacing={GRID_SPACING}>
        <Grid item xs={12}>
          <DateTimePicker<FormValuesType>
            control={control}
            dateLabel={locale.field_labels.date}
            isLoading={isLoading}
            label={
              <Typography variant="h5" component="h3">
                {locale.field_row_labels.started}
              </Typography>
            }
            timeLabel={locale.field_labels.time}
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={GRID_ROW_SPACING} columnSpacing={GRID_SPACING}>
        <Grid item xs={12} md={6}>
          <Duration<FormValuesType>
            locale={locale}
            control={control}
            watch={watch}
            formState={formState}
            clearErrors={clearErrors}
            setError={setError}
            disabled={isLoading}
            required={!showDistance}
            additionalFieldValue={showDistance ? miles : undefined}
            errorMessage={showDistance ? locale.errors.duration_or_distance : undefined}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {showDistance && (
            <FieldRow label={<Typography variant="h5">{locale.field_row_labels.distance}</Typography>} size="small">
              <NumberFieldWithControl
                name={FIELD_NAMES.MILES}
                control={control}
                fullWidth
                label={locale.field_labels.miles}
                ariaLabel={locale.field_labels.miles_aria}
                disabled={isLoading}
                formatter={distanceFormatter}
                id="fitness_form_distance"
              />
            </FieldRow>
          )}
        </Grid>
      </Grid>
    </RecordActivityForm>
  );
};
