import { createAsyncThunk } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';
import { downLoadFromBlob } from '@common/utils';

import { ParamsWithDependentUserId } from '../../../types';
import { DownloadsTypeNames } from '../constants';
import { dashboardStatusSelector } from './selectors';
import { DashboardDataType, DownloadMeasurementsType } from './types';

export const getDashboardData = createAsyncThunk<DashboardDataType, ParamsWithDependentUserId>(
  'Measurement/get-dashboard',
  async ({ dependentUserId }, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/Measurement/get-dashboard',
        params: {
          dependentUserId,
        },
      });

      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => dashboardStatusSelector(getState() as RootState) !== StatusEnum.Pending,
  },
);

export const saveDashboard = createAsyncThunk<unknown, string[]>(
  'Measurement/save-dashboard-configuration',
  async (dashboard: string[], { rejectWithValue }) => {
    try {
      const data = fetch.post({ url: '/Measurement/dashboard-configuration', data: { configuration: dashboard } });
      const res = await data.response;

      return res?.data ?? [];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadMeasurements = createAsyncThunk<unknown, ParamsWithDependentUserId<DownloadMeasurementsType>>(
  'Measurement/download-measurements',
  async (requestData, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/Measurement/download-measurements',
        params: {
          measurementTypeId: requestData.measurementTypeId,
          fileType: requestData.fileType,
          dependentUserId: requestData.dependentUserId,
        },
        responseType: 'blob',
      });

      const res = await data.response;

      const blob = res?.data;
      const contentDisposition = res?.headers?.['content-disposition'];
      const fileName =
        contentDisposition?.split('filename=')?.[1]?.split?.(';')?.[0] ||
        `${requestData.title}.${requestData.fileType}`;

      const mimeType =
        requestData.fileType === DownloadsTypeNames.Xlsx ? 'application/vnd.ms-excel' : 'application/csv';
      downLoadFromBlob(blob, mimeType, fileName);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
