import React, { ForwardedRef, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { getDataset, getOptions } from '@common/utils/dashboardAxisUtils';

import { WellnessTypesEnum } from '../../constants';
import { weightDataSelector, getItemsSelector } from '../../store';
import { Dashboard, useDashboard } from '../Dashboard';
import { MEASUREMENT_TYPES } from './constants';
import { locale } from './locale';

type WeightDashboardComponentProps = {
  setRef: ForwardedRef<HTMLDivElement>;
};
export function WeightDashboardComponent({ setRef }: WeightDashboardComponentProps) {
  const { requestLogStatus, removeStatus } = useSelector(weightDataSelector);
  const items = useSelector(getItemsSelector(WellnessTypesEnum.Weight));
  const chartData = useMemo(() => getDataset(items), [items]);

  const { handleHistoryItemClick, handleRecordButtonClick, handleDeleteItemClick } = useDashboard({
    type: WellnessTypesEnum.Weight,
  });

  return (
    <div ref={setRef}>
      <Dashboard
        type={WellnessTypesEnum.Weight}
        heading={locale.dashboard_title}
        chartTitle={locale.title}
        data={chartData}
        charOptions={getOptions(items)}
        measurements={MEASUREMENT_TYPES}
        selectedMeasurement={MEASUREMENT_TYPES[0]}
        historyItems={items}
        historyTitle={locale.history_title}
        loading={
          [StatusEnum.Pending, StatusEnum.Uninitialized].includes(requestLogStatus) ||
          removeStatus === StatusEnum.Pending
        }
        recordButtonText={locale.record_activity}
        onRecordButtonClick={handleRecordButtonClick}
        onEditItemClick={handleHistoryItemClick}
        onDeleteItemClick={handleDeleteItemClick}
      />
    </div>
  );
}

export const WeightDashboard = React.forwardRef<HTMLDivElement>((props, ref) => (
  <WeightDashboardComponent setRef={ref} {...props} />
));

WeightDashboard.displayName = 'WeightDashboard';
