import * as React from 'react';

import ToggleButtonMaterial, { ToggleButtonProps as ToggleButtonPropsMaterial } from '@mui/material/ToggleButton';
import classNames from 'classnames/bind';

import styles from './ToggleButton.module.css';

const cn = classNames.bind(styles);

export type ToggleButtonProps = {
  className?: string;
  ariaLabel?: string | undefined;
  selectedClass?: string;
} & Pick<
  ToggleButtonPropsMaterial,
  | 'children'
  | 'color'
  | 'disabled'
  | 'aria-label'
  | 'classes'
  | 'selected'
  | 'value'
  | 'disableRipple'
  | 'role'
  | 'onClick'
>;

export function ToggleButton({
  className,
  value,
  children,
  color,
  disabled,
  ariaLabel,
  selected,
  selectedClass,
  disableRipple = true,
  role,
  ...restProps
}: ToggleButtonProps) {
  return (
    <ToggleButtonMaterial
      {...restProps}
      disableRipple={disableRipple}
      className={cn('toggle-button', className)}
      value={value}
      color={color}
      disabled={disabled}
      aria-label={ariaLabel}
      selected={selected}
      role={role}
    >
      {children}
    </ToggleButtonMaterial>
  );
}
