import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { fetch } from '@common/fetch';

import { LoginErrorCodes, LoginInfoCodes } from '../constants';
import {
  impersonate,
  login,
  logout,
  logoutWithoutRevoke,
  mobileLogin,
  samlLogin,
  signUpAndLogin,
  reset,
  impersonateJump,
} from './actions';
import { LoginState, LogoutPayload } from './types';

const initialState: LoginState = {
  loginStatus: StatusEnum.Uninitialized,
  logoutStatus: StatusEnum.Uninitialized,
  error: null,
  info: null,
  failedAttempts: null,
  blockingTime: null,
  impersonation: false,
  impersonationUserId: null,
  salesForceImpersonation: null,
  adminReturnUrl: null,
  logoutOnce: false,
  impersonationRedirect: false,
  samlNavigationType: null,
  samlReturnUrl: null,
  samlRegistrationNoticeToken: null,
  logoutStarted: false,
  alreadyRegistered: false,
};

const loginFulfilled = (state: LoginState) => {
  state.loginStatus = StatusEnum.Fulfilled;
};
const impersonateFulfilled = (state: LoginState, action: ReturnType<typeof impersonate.fulfilled>) => {
  loginFulfilled(state);
  state.impersonation = true;
  state.impersonationUserId = action.meta.arg.userId;
  state.salesForceImpersonation = action.meta.arg.salesForceImpersonation;
  state.adminReturnUrl = process.env.ADMIN_URL;
  state.logoutStatus = StatusEnum.Uninitialized;
};

const impersonateJumpFulfilled = (state: LoginState, action: ReturnType<typeof impersonateJump.fulfilled>) => {
  loginFulfilled(state);
  state.impersonation = true;
  if (action.payload.user.id) state.impersonationUserId = action.payload.user.id;
  state.adminReturnUrl = process.env.ADMIN2_URL;
  state.logoutStatus = StatusEnum.Uninitialized;
};

const samlLoginFulfilled = (state: LoginState, action: PayloadAction<UnknownObject>) => {
  state.loginStatus = StatusEnum.Fulfilled;
  state.samlNavigationType = action.payload?.saml_navigation_type as typeof state.samlNavigationType;
  state.samlReturnUrl = action.payload?.return_url as typeof state.samlReturnUrl;
  state.samlRegistrationNoticeToken = action.payload
    ?.registration_notice_token as typeof state.samlRegistrationNoticeToken;

  if ((action.payload?.user_auth as UnknownObject)?.impersonator) {
    state.impersonation = true;
  }
  state.logoutStatus = StatusEnum.Uninitialized;
};

const loginOrImpersonatePending = (state: LoginState) => {
  state.loginStatus = StatusEnum.Pending;
  state.error = initialState.error;
  state.info = initialState.info;
};

const loginOrImpersonateRejected =
  (impersonation: boolean) =>
  (
    state: LoginState,
    error: PayloadAction<UnknownObject<UnknownObject<UnknownObject>>, string, { arg: UnknownObject }>,
  ) => {
    state.error = (error.payload.response?.data?.error_code ||
      error.payload.response?.data?.message ||
      LoginErrorCodes.Default) as typeof state.error;

    state.loginStatus = StatusEnum.Rejected;

    if (error.payload.response?.data?.error_code === LoginErrorCodes.FailedLogin) {
      state.failedAttempts = error.payload.response?.data?.additional_data as typeof state.failedAttempts;
    }

    if (error.payload.response?.data?.error_code === LoginErrorCodes.AccountLocked) {
      state.blockingTime = error.payload.response?.data?.additional_data as typeof state.blockingTime;
    }

    if (impersonation) {
      state.impersonationUserId = error.meta.arg.userId as typeof state.impersonationUserId;
      state.salesForceImpersonation = error.meta.arg.salesForceImpersonation as typeof state.salesForceImpersonation;
    }
  };

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAlreadyRegistered(state) {
      state.info = LoginInfoCodes.AlreadyRegistered;
    },

    setImpersonation(state) {
      state.impersonation = true;
    },
    setError(state, action: PayloadAction<LoginErrorCodes>) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = initialState.error;
      state.failedAttempts = initialState.failedAttempts;
    },
    clearInfo(state) {
      state.info = initialState.info;
    },
    clearLogoutStarted(state) {
      state.logoutStarted = false;
    },
    logoutStart(state, action: PayloadAction<LogoutPayload>) {
      if (action.payload.inactivityLogout) {
        state.info = LoginInfoCodes.InactivityLogout;
      }

      state.logoutStarted = true;
      fetch.abortAll();
    },
    cancelPreviousLogin(state: LoginState) {
      state.logoutStatus = StatusEnum.Fulfilled;
      state.loginStatus = StatusEnum.Uninitialized;
      state.impersonation = false;
      state.logoutOnce = true;
    },
  },
  extraReducers: {
    [login.pending.type]: loginOrImpersonatePending,
    [login.fulfilled.type]: loginFulfilled,
    [login.rejected.type]: loginOrImpersonateRejected(false),
    [signUpAndLogin.pending.type]: loginOrImpersonatePending,
    [signUpAndLogin.fulfilled.type]: loginFulfilled,
    [signUpAndLogin.rejected.type]: loginOrImpersonateRejected(false),
    [mobileLogin.pending.type]: loginOrImpersonatePending,
    [mobileLogin.fulfilled.type]: loginFulfilled,
    [mobileLogin.rejected.type]: loginOrImpersonateRejected(false),
    [samlLogin.pending.type]: loginOrImpersonatePending,
    [samlLogin.fulfilled.type]: samlLoginFulfilled,
    [samlLogin.rejected.type]: loginOrImpersonateRejected(false),
    [impersonate.pending.type]: loginOrImpersonatePending,
    [impersonate.fulfilled.type]: impersonateFulfilled,
    [impersonate.rejected.type]: loginOrImpersonateRejected(true),
    [impersonateJump.pending.type]: loginOrImpersonatePending,
    [impersonateJump.fulfilled.type]: impersonateJumpFulfilled,
    [impersonateJump.rejected.type]: loginOrImpersonateRejected(true),
    [logout.pending.type]: (state: LoginState) => {
      state.logoutStatus = StatusEnum.Pending;
    },
    [logout.fulfilled.type]: (state: LoginState, action) => {
      state.logoutStatus = StatusEnum.Fulfilled;
      state.loginStatus = StatusEnum.Uninitialized;
      if (action.meta.arg.inactivityLogout) {
        state.info = LoginInfoCodes.InactivityLogout;
      }
      if (action.meta.arg.impersonationRedirect && !state.salesForceImpersonation) {
        state.impersonationRedirect = true;
      }
      state.impersonation = false;
      state.logoutOnce = true;
      state.logoutStarted = false;
    },
    [logout.rejected.type]: (state: LoginState, error) => {
      state.logoutStatus = StatusEnum.Rejected;
      if (error.meta.arg.inactivityLogout) {
        state.info = LoginInfoCodes.InactivityLogout;
      }
      if (error.meta.arg.impersonationRedirect && !state.salesForceImpersonation) {
        state.impersonationRedirect = true;
      }

      state.logoutStarted = false;
    },
    [logoutWithoutRevoke.fulfilled.type]: (state: LoginState) => {
      state.logoutStatus = StatusEnum.Fulfilled;
      state.loginStatus = StatusEnum.Uninitialized;
      state.impersonation = false;
      state.logoutOnce = true;
    },
    [reset.fulfilled.type]: (state: LoginState) => {
      state.loginStatus = StatusEnum.Uninitialized;
    },
  },
});

export const loginReducer = loginSlice.reducer;
export const {
  clearError,
  clearInfo,
  setImpersonation,
  logoutStart,
  setError,
  clearLogoutStarted,
  cancelPreviousLogin,
  setAlreadyRegistered,
} = loginSlice.actions;
