import { ROUTES } from '@common/routes';

export enum LoginErrorCodes {
  NotOldEnough = 'not_old_enough',
  FailedLogin = 'failed_login',
  AccountLocked = 'account_locked',
  NoCurrentEligibility = 'no_current_eligibility',
  RegistrationLinkInvalid = 'registration_link_invalid',
  NoMatchFound = 'no_match_found',
  Default = 'something_went_wrong',
}

export enum LoginInfoCodes {
  InactivityLogout = 'inactivity_logout',
  AlreadyRegistered = 'already_registered',
}

export const IGNORE_TO_REDIRECT_URLS = [
  ROUTES.LOGIN,
  ROUTES.SIGN_IN,
  ROUTES.PASSWORD_RECOVERY.ROOT,
  ROUTES.PASSWORD_RECOVERY.RESET_PASSWORD,
  ROUTES.REGISTRATION.ROOT,
  ROUTES.REGISTRATION.COMPLETE,
  ROUTES.REGISTRATION.SIGN_UP_CAMPAIGN,
  ROUTES.REGISTRATION.REGISTRATION_CAMPAIGN,
  ROUTES.SAML,
];
