import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

import { RequestCallbackForAppointmentApiParamsType } from './types';

export const requestCallbackForAppointment = createAsyncThunk<unknown, RequestCallbackForAppointmentApiParamsType>(
  'Appointment/request-callback-for-appointment',
  async (data: RequestCallbackForAppointmentApiParamsType, { rejectWithValue }) => {
    try {
      const responseData = fetch.post({
        url: `/Appointment/request-callback-for-appointment`,
        data,
      });
      const res = await responseData.response;

      return res?.data ?? {};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
