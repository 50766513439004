import React from 'react';

import CardMaterial, { CardProps as CardPropsMaterial } from '@mui/material/Card';

export type CardProps = {
  ariaLabel?: string;
} & Pick<CardPropsMaterial, 'children' | 'className' | 'onClick' | 'onKeyPress' | 'role' | 'tabIndex'>;

export function Card({ children, className, onClick, onKeyPress, role, tabIndex, ariaLabel }: CardProps) {
  return (
    <CardMaterial
      className={className}
      onClick={onClick}
      onKeyPress={onKeyPress}
      role={role}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
    >
      {children}
    </CardMaterial>
  );
}
