/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { currentEligibilitySelector } from '@modules/userProfileData';

import { GlobalWaiverPurposes, JOINED_ToU_PP } from '../constants';
import { idByPurposeSelector } from '../store';
import { acceptWaiverAcknowledgment, getWaiverAcknowledgments } from '../store/actions';
import { SignModalFormFields } from '../store/types';
import { useGlobalWaivers } from './useGlobalWaivers';

export const useGlobalWaiversForm = (purposes: GlobalWaiverPurposes[]) => {
  const dispatch = useDispatch();
  const currentEligibility = useSelector(currentEligibilitySelector);
  const termsOfUseWaiverId = useSelector((state: RootState) =>
    idByPurposeSelector(state, { purpose: GlobalWaiverPurposes.TermsOfUse }),
  );

  const privacyPolicyWaiverId = useSelector((state: RootState) =>
    idByPurposeSelector(state, { purpose: GlobalWaiverPurposes.PrivacyPolicy }),
  );

  const { acknowledgments, requiredWaivers } = useGlobalWaivers(purposes);

  const form = useForm<SignModalFormFields>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { formState, reset, watch } = form;

  const [activeStep, setActiveStep] = useState(0);

  const signature = watch('signature');
  const activeWaiver = requiredWaivers[activeStep] ?? {};
  const { purpose, waiverId } = activeWaiver;

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
      if (activeStep < requiredWaivers.length - 1) {
        setActiveStep((step) => step + 1);
      }
    }
  }, [formState.isSubmitSuccessful, activeStep, requiredWaivers.length, reset, setActiveStep]);

  const submitTermsOfUseAndPrivacyPolicy = useCallback(
    (signature?: string) => {
      if (termsOfUseWaiverId) {
        dispatch(
          acceptWaiverAcknowledgment({
            purpose: GlobalWaiverPurposes.TermsOfUse,
            waiverId: termsOfUseWaiverId,
            signature,
          }),
        );
      }
      if (privacyPolicyWaiverId) {
        dispatch(
          acceptWaiverAcknowledgment({
            purpose: GlobalWaiverPurposes.PrivacyPolicy,
            waiverId: privacyPolicyWaiverId as number,
            signature,
          }),
        );
      }
    },
    [dispatch, termsOfUseWaiverId, privacyPolicyWaiverId],
  );

  const onSubmit = useCallback(
    (values: SignModalFormFields) => {
      if (!currentEligibility?.id) {
        return;
      }
      const { signature } = values;

      if (purpose === JOINED_ToU_PP) {
        submitTermsOfUseAndPrivacyPolicy(signature);
      } else if (waiverId && purpose) {
        dispatch(
          acceptWaiverAcknowledgment({
            purpose: purpose as GlobalWaiverPurposes,
            waiverId,
            signature,
          }),
        );
      }
    },
    [currentEligibility?.id, purpose, waiverId, submitTermsOfUseAndPrivacyPolicy, dispatch],
  );

  useEffect(() => {
    if (currentEligibility) {
      dispatch(getWaiverAcknowledgments(purposes));
    }
  }, [currentEligibility, dispatch, purposes]);

  return {
    activeStep,
    totalSteps: requiredWaivers.length,
    activeWaiver,
    onSubmit,
    required: !!acknowledgments?.length,
    signature,
    form,
  };
};
